/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";
import jumpTo from "../../modules/Navigation";
import mechanicDefaultCar from "../../assets/images/mechwangu_default_car.svg";

function CarHolderOnHomePage(props) {
  const { productItem } = props;
  return (
    <div className="product-item men pb-5">
      <div
        className="product discount product_filter pb-5"
      >
        <div className="product_image">
          <img src={productItem.imagePath || mechanicDefaultCar} alt="" className="img-fluid" />
        </div>
        <div className="favorite favorite_left">
          <i className="far fa-heart"></i>
        </div>
        {/* <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
          <span>-$20</span>
        </div> */}
        <div className="product_info">
          <h6 className="product_name">
            <div>{productItem.carRegNumber}</div>
          </h6>
          {/* <div className="product_price">
            KSh. {productItem.price}
            <span> KSh. {(parseFloat(productItem.price) + 30).toFixed(2)}</span>
          </div> */}
        </div>
      </div>
      <div
        className="red_button add_to_cart_button"
        onClick={() =>
          jumpTo(`/single-car/${productItem._id}`)
        }
      >
        <div style={{ color: "#ffffff" }}>View car</div>
      </div>
    </div>
  );
}

export default CarHolderOnHomePage;
