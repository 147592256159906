/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";

function Footer(props) {
  return (
    <footer className="footer mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="footer_nav_container d-flex flex-sm-row flex-column align-items-center justify-content-lg-start justify-content-center text-center">
              <ul className="footer_nav">
                <li>
                  <a href="https://connect.mechwangu.co.ke/blog">Blog</a>
                </li>
                <li>
                  <a href="/guide">How it works</a>
                </li>
                <li>
                  <a href="/contact-us">Contact us</a>
                </li>
                <li>
                  <a href="/rating">Rate a Mechanic</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="socials col-lg-6">
            <div className="footer_social d-flex flex-row align-items-center justify-content-lg-end justify-content-center">
              <ul>
                <li>
                  <a href="https://connect.mechwangu.co.ke/terms/">Terms & Conditions</a>
                </li>
                <li>
                  <a href="https://connect.mechwangu.co.ke/privacy-policy/">Privacy policy</a>
                </li>
                <li>
                  <a href="https://web.facebook.com/mech.wangu.9" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/MechWangu" target="_blank">
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/mechwangu/" target="_blank">
                    <i className="fab fa-instagram"></i>{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer_nav_container">
              <div className="cr">
                ©{(new Date().getFullYear())} All Rights Reserved. {" "}
                <a href="https://mechwangu.co.ke" target="_blank">
                  Mech Wangu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
