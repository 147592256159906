/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import React, { Component } from "react";
 import LoginRegister from "../../components/LoginRegisterModal";
 import Loader from "../../components/Loader/Loader";
import MyShops from "../../components/Shop/MyShops";
let loggedInUser = JSON.parse(localStorage.getItem("auth"));

 
class ShopList extends Component {
   constructor(props) {
     super(props);
     this.state = {
       data: null,
       modalShow: false,
       login: true,
       shopOwnerId:''
     };
   }
   
   
   componentDidMount() {
    this.props.getShopOwnerIdUsingUserId(loggedInUser.id).then((res) => {
      this.setState({shopOwnerId: res});
      this.props.getAllShops(this.state.shopOwnerId);
     }).catch((error) => {
       console.log(error);
     });
     
   }
 
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
   getShopOwnerId=async(userId)=>{
    await this.getShopOwnerIdUsingUserId(userId)
   }
   render() {
     const { shops } = this.props;
     return (
       <div style={!shops ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
         {shops ?
           <>
             <br />
             {shops ? (
               <MyShops
                 shops={shops}
               />
             ) : null}
             <LoginRegister
               show={this.state.modalShow}
               login={this.state.login}
               registerClicked={() => this.registerClicked()}
               loginClicked={() => this.loginClicked()}
               onHide={() => this.showHideModal()}
             /> </> :
           <Loader />}
       </div>
     );
   }
 }
 
 export default ShopList;
 