/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";
import jumpTo from "../../modules/Navigation";

import mechanicDefaultRequest from "../../assets/images/mechwangu_default_request.svg";
let loggedInUser = JSON.parse(localStorage.getItem("auth"));

function SingleClientRequest(props) {
  const { productItem } = props;
  return (
    <div className="product-item men">
      <div
        className="product discount product_filter"
        onClick={() =>
          jumpTo(`/single-request/${productItem._id}`)
        }
      >
        <div className="product_image">
          <img src={productItem.imagePath || mechanicDefaultRequest} alt="" className="img-fluid" />
        </div>
        <div className="favorite favorite_left">
          <i className="far fa-heart"></i>
        </div>
        {/* <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
          <span>-$20</span>
        </div> */}
        <div className="product_info">
          <h6 className="product_name">
            <div>{productItem.requestDetail}</div>
          </h6>
          {/* <div className="product_price">
            KSh. {productItem.price}
            <span> KSh. {(parseFloat(productItem.price) + 30).toFixed(2)}</span>
          </div> */}
        </div>
      </div>
      <div
        className="red_button add_to_cart_button"
        onClick={() => {
          props.modalHandler(`/single-request/${productItem._id}`)
        }
        }
      >
        <div style={{ color: "#ffffff" }}>View request</div>
      </div>
    </div >
  );
}

export default SingleClientRequest;
