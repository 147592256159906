/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoginRegister from "../../../components/LoginRegisterModal";
import { updateGarage } from "../../../redux/actions/garageAction";
import { getGarage } from "../../../redux/actions/garageAction";
import { getAllLoggedInUserGarages } from "../../../redux/actions/garageAction";
import { getMechanicIdUsingUserId } from "../../../redux/actions/mechanicAction";
import MyGarages from "../../../components/Garage/MyGarages";
// import { addCar} from "../../../redux/actions/carAction";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
let loggedInUserIsMechanic = null;
if (loggedInUser) {
  loggedInUserIsMechanic = loggedInUser.tenants[0].roles[0];
}

class EditGarage extends Component {
  constructor(props) {
    console.log("loggedInUserIs: " + loggedInUserIsMechanic);
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true,
      garageId: "",
      garageName: "",
      garageOwnedByMechanic: "",
      garageDescription: "",
      garageLocation: "",
      showMessage: true
    };
  }

  componentDidMount() {
    this.props.getAllLocations();
    // let loggedInUser = JSON.parse(localStorage.getItem("auth"));
    // this.props.getMechanic(loggedInUser.id);
    this.props
      .getMechanicIdUsingUserId(loggedInUser.id)
      .then((res) => {
        console.log("LoggedInMechIs " + res);
        this.setState({ garageOwnedByMechanic: res });
        this.props.getAllLoggedInUserGarages(res);
      })
      .catch((error) => {
        console.log(error);
      });

    console.log(
      "Garagezz " +
      JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0])
    );
    this.props
      .getGarage(this.props.location.pathname.split("/").slice(-1)[0])
      .then(() => {
        console.log("Garagez " + JSON.stringify(this.props.garage));
        console.log("Ownerx " + JSON.stringify(this.props.garage.garageOwnedByMechanic.id));
        this.setState({ garageId: this.props.location.pathname.split("/").slice(-1)[0] });
        this.setState({ garageName: this.props.garage.garageName });
        this.setState({
          garageDescription: this.props.garage.garageDescription,
        });
      });
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  submitHandler = (event) => {
    // e.preventDefault();
    event.preventDefault();
    console.log(this.state);
    console.log("garageNameYY " + this.state.garageName);
    // let email = "bob@bob.com";
    // let password = "Ab123123";
    const {
      garageId,
      garageName,
      garageDescription,
      garageOwnedByMechanic,
      garageLocation,
      showMessage,
    } = this.state;
    if (showMessage === false) {
      this.setState({ loading: true });
      this.props
        .updateGarage(
          garageId,
          garageName,
          garageDescription,
          garageOwnedByMechanic,
          garageLocation
        )
        .then((res) => {
          console.log("AddCarResults " + res);
          this.setState({ loading: false });
          window.location.reload();
        })
        .catch((error) => {
          console.log("Addcar error");
          console.log(error.response);
          this.setState({ loading: false });
        });
    }
  };

  render() {
    const { garageName, garageDescription } = this.state;
    const { garages } = this.props;
    //  console.log("MAGee"+garages)

    if (!loggedInUser) {
      return <Redirect to="/" />;
    } else if (loggedInUserIsMechanic !== "userMechanic") {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <div className="container mt-5">
          {this.props.locations && (
            <div className="row mt-5">
              <div className="col-md-6 mb-5 mb-md-0">
                <form onSubmit={this.submitHandler}>
                  <h2 className="h3 mb-3 text-black">Add Garage</h2>
                  <br />
                  <div className="p-3 p-lg-5 border">
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label htmlFor="garageName" className="text-black">
                          Enter Garage name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="garageName"
                          name="garageName"
                          value={garageName}
                          onChange={this.changeHandler}
                          placeholder="e.g. ABC Garage"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <label
                          htmlFor="garageDescription"
                          className="text-black"
                        >
                          Describe your Garage{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="garageDescription"
                          name="garageDescription"
                          value={garageDescription}
                          onChange={this.changeHandler}
                          placeholder="e.g. We are aimed at ..."
                        />
                      </div>
                    </div>
                    {/* <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="c_car_reg_number" className="text-black">Car Owner <span
                                    className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="c_car_reg_number" name="c_car_reg_number"
                                      placeholder="Type your name" />
                            </div>
                        </div> */}

                    <div className="form-group">
                      <label htmlFor="garageLocation" className="text-black">
                        Garage Located At <span className="text-danger">*</span>
                      </label>
                      <select
                        id="garageLocation"
                        name="garageLocation"
                        className="form-control"
                        onChange={(location) =>
                          this.setState({
                            garageLocation: location.target.value,
                            showMessage: false,
                          })
                        }
                        required
                      >
                        <option selected="true" disabled="disabled">
                          Select location
                        </option>
                        {this.props.locations.map((location) => (
                          <option value={location.id}>
                            {location.locationName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {this.state.showMessage && (
                      <p style={{ color: "red" }}>
                        You have to select the location to continue
                      </p>
                    )}
                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        type="submit"
                        onClick={() => this.submitHandler}
                      >
                        Edit Garage
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6 mb-5 mb-md-0 border">
                {garages ? <MyGarages garages={garages} /> : null}
              </div>
            </div>
          )}
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateGarage,
  getGarage,
  getAllLoggedInUserGarages,
  getMechanicIdUsingUserId,
};
const mapStoreToProps = (state) => ({
  login_loading: state.login.login_loading,
  login_error: state.login.error,
});

export default connect(mapStoreToProps, mapDispatchToProps)(EditGarage);
