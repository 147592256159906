import React, { useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CircleSpinner from '../../components/Spinner/Circle';
import { getMechanicUserByEmailorPhone, getMechanicUsingUserId } from '../../redux/actions/mechanicAction';

import Validator from "../../utils/Validator";
import { EMAIL_RULE, PHONE_RULE, NAME_RULE } from "../../utils/Validator/rule";
import StarRatings from "react-star-ratings";
import LoadingButton from '../../components/LoadingButton';
import { postRating, sendInvite } from '../../redux/actions/requestAction';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const RatePage = (props) => {
    const [entry, setEntry] = useState();
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [entryErr, setEntryErr] = useState(false);
    const [userDetErr, setuserDetErr] = useState({});
    const [isEmail, setIsEmail] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [userClicked, setUserClicked] = useState(false);
    const [mechanic, setMechanic] = useState();
    const [notFound, setNotFound] = useState(false);
    const [message, setMessage] = useState(null);
    const [getUserLoading, setGetUserLoading] = useState(false);
    const [getMechLoading, setGetMechLoading] = useState(false);
    const [rating, setRating] = useState(0);
    const [actionLoading, setActionLoading] = useState(false);
    const [comments, setComments] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    const [invited, setInvited] = useState(false);
    const [valError, setValError] = useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    const focus = (e) => {
        switch (e.target.name) {
            case "entry":
                setEntryErr(false);
                setMechanic(null);
                setUserClicked(false);
                setNotFound(false);
                setMessage(null);
                setErrMessage(null)
                break;
            case "phone":
                setuserDetErr({ ...userDetErr, phone: false });
                break;
            case "jina":
                setuserDetErr({ ...userDetErr, name: false });
                break;
            default:
                break;
        }
    }
    const blur = (e) => {
        switch (e.target.name) {
            case "entry":
                if (!Validator(e.target.value, EMAIL_RULE) && !Validator(e.target.value, PHONE_RULE)) {
                    setEntryErr(true);
                }
                break;
            case "jina":
                if (!Validator(e.target.value, NAME_RULE)) {
                    setuserDetErr({ ...userDetErr, name: true });
                }
                break;
            case "phone":
                if (!Validator(e.target.value, PHONE_RULE)) {
                    setuserDetErr({ ...userDetErr, phone: true });
                }
                break;
            default:
                break;
        }
    }
    const handleSubmit = async () => {
        if (entryErr) return;
        let data, user;
        data = {
            value: entry
        }
        setMessage("Looking up user")
        setGetUserLoading(true);
        await dispatch(getMechanicUserByEmailorPhone(data)).then(res => {
            if (typeof res.data !== 'object') {
                setErrMessage("User not found")
                setNotFound(true);
            }
            user = res.data;
            setGetUserLoading(false);
        }).catch(err => {
            setGetUserLoading(false);
            setErrMessage("User not found")
            setNotFound(true);
        })
        if (user) {
            setMessage("Getting mechanic details")
            setGetMechLoading(true);
            await dispatch(getMechanicUsingUserId(user.id)).then(res => {
                if (res.rows.length > 0) {
                    setMechanic(res.rows[0]);
                    setGetMechLoading(false);
                } else {
                    setGetMechLoading(false);
                    setErrMessage("User is not a mechanic")
                }
            })
        }
    }
    const rateHandler = async () => {
        setActionLoading(true);
        let tenantId = "61d1ce08b967711d2952da3f";
        if (userDetErr.phone) {
            setValError(true)
            setActionLoading(false);
            return
        }
        let data = {
            reviewOfMechanic: mechanic._id,
            comments: comments,
            mechanicReviewRating: rating,
            tenant: tenantId,
            fullName: name,
            phoneNumber: phone
        }
        await dispatch(postRating(data)).then(res => {
            setActionLoading(false);
            window.location.reload();
        })
    }
    const inviteHandler = async () => {
        setActionLoading(true);
        let tenantId = "61d1ce08b967711d2952da3f";
        let data = {
            invitee: entry,
            inviter: name,
            inviterNumber: phone,
            tenant: tenantId
        }
        await dispatch(sendInvite(data)).then(res => {
            setActionLoading(false);
            setInvited(true);
        })
    }
    if (!matches) {
        return (
            <>
                <div style={{
                    "marginTop": "80px",
                    "marginLeft": "15%",
                }}>
                    <h1>Rate Mechanic</h1>
                </div>
                <div className='mt-5'>
                    <div className="form-group ">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="email or phone"
                            id="entry"
                            name="entry"
                            value={entry}
                            style={entryErr ? { "border": "2px solid red", "width": "80%", "marginLeft": "10%" } : { "width": "80%", "marginLeft": "10%" }}
                            onChange={e => setEntry(e.target.value)}
                            autoComplete="false"
                            onBlur={e => blur(e)}
                            onFocus={e => focus(e)}
                        />
                        {entryErr && <span style={{ "fontSize": "15px", "color": "red" }}>use Email or Phone: username@email.com or 0712345678</span>}
                        <i className="fa fa-user"></i>
                    </div>
                    <Button variant="success" style={{
                        "width": "70%",
                        "marginLeft": "15%",
                    }}
                        onClick={handleSubmit}
                    >Find</Button>
                </div>
                <div>
                    {(getUserLoading || getMechLoading) && <div style={{
                        "marginTop": "50px",
                    }}><CircleSpinner message={message} /></div>}
                    {errMessage && <div style={{
                        "marginTop": "50px",
                        "width": "100%",
                        "textAlign": "center",
                    }}><span style={{ "fontSize": "15px", "color": "red" }}>{errMessage}</span></div>
                    }
                    {notFound && <div style={{
                        "width": "90%",
                        "marginLeft": "5%",
                    }}>
                        <div>
                            <div className="form-group" style={{ "display": "flex", "marginTop": "20px" }}>
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="First & Last Name"
                                    id="jina"
                                    name="jina"
                                    value={name}
                                    style={userDetErr.name ? { "border": "2px solid red", "width": "50%" } : { "width": "50%" }}
                                    onChange={e => setName(e.target.value)}
                                    autoComplete="false"
                                    onBlur={e => blur(e)}
                                    onFocus={e => focus(e)}
                                />
                                <i className="fa fa-user"></i>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone"
                                    id="phone"
                                    name="phone"
                                    value={phone}
                                    style={userDetErr.phone ? { "border": "2px solid red", "width": "40%" } : { "width": "40%" }}
                                    onChange={e => setPhone(e.target.value)}
                                    autoComplete="false"
                                    onBlur={e => blur(e)}
                                    onFocus={e => focus(e)}
                                />
                            </div>
                            {userDetErr.name && <span style={{ "fontSize": "15px", "color": "red" }}>Format: John Doe</span>}
                            {" "}
                            {userDetErr.phone && <span style={{ "fontSize": "15px", "color": "red" }}>Format: 0712345678</span>}
                        </div>
                        <LoadingButton
                            type="button"
                            className={!invited ? "btn btn-secondary" : "btn btn-success"}
                            loading={actionLoading}
                            onClick={() => inviteHandler()}
                            disabled={!invited ? false : true}
                        >
                            {!invited ? `Invite ${entry}` : `${entry} Invited`}
                        </LoadingButton>
                    </div>}
                    {mechanic && <div style={{
                        "width": "90%",
                        "marginLeft": "5%",
                    }}><UserList userClicked={setUserClicked} mechanic={mechanic} /></div>}
                </div>
                {userClicked && <div style={{
                    "width": "90%",
                    "marginLeft": "5%",
                }}>
                    <div style={{
                        "marginTop": "100px",
                    }}>
                        Rating:
                        <div className="star_rating" style={{ "display": "inline" }}>
                            <StarRatings
                                rating={rating}
                                starRatedColor="yellow"
                                changeRating={setRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="30px"
                                starSpacing="1px"
                            />
                        </div>
                    </div>
                    <br />
                    <div>
                        <div>
                            <div>
                                <textarea name="reason" id="reason" cols="5" rows="3"
                                    className="form-control" placeholder="Review comments. Fill before rating" onChange={e => setComments(e.target.value)}></textarea>
                            </div>
                        </div>
                        <br />
                    </div>
                    <div>
                        <div className="form-group" style={{ "display": "flex" }}>
                            <input
                                type="text"
                                className="form-control mr-2"
                                placeholder="First & Last Name"
                                id="jina"
                                name="jina"
                                value={name}
                                style={userDetErr.name ? { "border": "2px solid red", "width": "50%" } : { "width": "50%" }}
                                onChange={e => setName(e.target.value)}
                                autoComplete="false"
                                onBlur={e => blur(e)}
                                onFocus={e => focus(e)}
                            />
                            <i className="fa fa-user"></i>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                id="phone"
                                name="phone"
                                value={phone}
                                style={userDetErr.phone ? { "border": "2px solid red", "width": "40%" } : { "width": "40%" }}
                                onChange={e => setPhone(e.target.value)}
                                autoComplete="false"
                                onBlur={e => blur(e)}
                                onFocus={e => focus(e)}
                            />
                        </div>
                        {userDetErr.name && <span style={{ "fontSize": "15px", "color": "red" }}>Format: John Doe</span>}
                        {" "}
                        {userDetErr.phone && <span style={{ "fontSize": "15px", "color": "red" }}>Format: 0712345678</span>}
                    </div>
                    <LoadingButton
                        type="button"
                        className="btn btn-success btn-lg btn-block"
                        loading={actionLoading}
                        onClick={() => rateHandler()}
                        disabled={rating ? false : true}
                    >
                        Rate
                    </LoadingButton>
                    {valError && <span style={{ "fontSize": "15px", "color": "red" }}>* Phone is a required field</span>}
                </div>}
            </>
        )
    } else {
        return (
            <Container>
                <div class="vl" style={{
                    "borderLeft": "1px solid grey",
                    "height": "300px",
                    "position": "absolute",
                    "left": "50%",
                    "marginLeft": "-3px",
                    "top": "35%",
                }}></div>
                <div className='mt-5'>
                    <h1>Rate Mechanic</h1>
                </div>
                <Row>
                    <Col>
                        <div className='mt-5'>
                            <Row>
                                <Col md={9}>
                                    <div className="form-group ">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter mechanic's E-Mail or Phone"
                                            id="entry"
                                            name="entry"
                                            value={entry}
                                            style={entryErr ? { "border": "2px solid red" } : null}
                                            onChange={e => setEntry(e.target.value)}
                                            autoComplete="false"
                                            onBlur={e => blur(e)}
                                            onFocus={e => focus(e)}
                                        />
                                        {entryErr && <span style={{ "fontSize": "15px", "color": "red" }}>use Email or Phone: username@email.com or 0712345678</span>}
                                        <i className="fa fa-user"></i>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <Button variant="success" style={{
                                        "width": "100%",
                                    }}
                                        onClick={handleSubmit}
                                    >Continue</Button>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            {(getUserLoading || getMechLoading) && <div style={{
                                "marginTop": "50px",
                            }}><CircleSpinner message={message} /></div>}
                            {errMessage && <div style={{
                                "marginTop": "50px",
                                "width": "100%",
                                "textAlign": "center",
                            }}><span style={{ "fontSize": "15px", "color": "red" }}>{errMessage}</span></div>
                            }
                            {notFound && <div>
                                <div>
                                    <div className="form-group" style={{ "display": "flex", "marginTop": "20px" }}>
                                        <input
                                            type="text"
                                            className="form-control mr-2"
                                            placeholder="First & Last Name"
                                            id="jina"
                                            name="jina"
                                            value={name}
                                            style={userDetErr.name ? { "border": "2px solid red", "width": "50%" } : { "width": "50%" }}
                                            onChange={e => setName(e.target.value)}
                                            autoComplete="false"
                                            onBlur={e => blur(e)}
                                            onFocus={e => focus(e)}
                                        />
                                        <i className="fa fa-user"></i>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone"
                                            id="phone"
                                            name="phone"
                                            value={phone}
                                            style={userDetErr.phone ? { "border": "2px solid red", "width": "40%" } : { "width": "40%" }}
                                            onChange={e => setPhone(e.target.value)}
                                            autoComplete="false"
                                            onBlur={e => blur(e)}
                                            onFocus={e => focus(e)}
                                        />
                                    </div>
                                    {userDetErr.name && <span style={{ "fontSize": "15px", "color": "red" }}>Format: John Doe</span>}
                                    {" "}
                                    {userDetErr.phone && <span style={{ "fontSize": "15px", "color": "red" }}>Format: 0712345678</span>}
                                </div>
                                <LoadingButton
                                    type="button"
                                    className={!invited ? "btn btn-secondary" : "btn btn-success"}
                                    loading={actionLoading}
                                    onClick={() => inviteHandler()}
                                    disabled={!invited ? false : true}
                                >
                                    {!invited ? `Invite ${entry}` : `${entry} Invited`}
                                </LoadingButton>
                            </div>}
                            {mechanic && <UserList userClicked={setUserClicked} mechanic={mechanic} />}
                        </div>
                    </Col>
                    <Col>
                        {userClicked && <>
                            <div style={{
                                "marginTop": "100px",
                            }}>
                                Rating:
                                <div className="star_rating" style={{ "display": "inline" }}>
                                    <StarRatings
                                        rating={rating}
                                        starRatedColor="yellow"
                                        changeRating={setRating}
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension="30px"
                                        starSpacing="1px"
                                    />
                                </div>
                            </div>
                            <br />
                            <div>
                                <div>
                                    <div>
                                        <textarea name="reason" id="reason" cols="5" rows="3"
                                            className="form-control" placeholder="Review comments. Fill before rating" onChange={e => setComments(e.target.value)}></textarea>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div>
                                <div className="form-group" style={{ "display": "flex" }}>
                                    <input
                                        type="text"
                                        className="form-control mr-2"
                                        placeholder="First & Last Name"
                                        id="jina"
                                        name="jina"
                                        value={name}
                                        style={userDetErr.name ? { "border": "2px solid red", "width": "50%" } : { "width": "50%" }}
                                        onChange={e => setName(e.target.value)}
                                        autoComplete="false"
                                        onBlur={e => blur(e)}
                                        onFocus={e => focus(e)}
                                    />
                                    <i className="fa fa-user"></i>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        id="phone"
                                        name="phone"
                                        value={phone}
                                        style={userDetErr.phone ? { "border": "2px solid red", "width": "40%" } : { "width": "40%" }}
                                        onChange={e => setPhone(e.target.value)}
                                        autoComplete="false"
                                        onBlur={e => blur(e)}
                                        onFocus={e => focus(e)}
                                    />
                                </div>
                                {userDetErr.name && <span style={{ "fontSize": "15px", "color": "red" }}>Format: John Doe</span>}
                                {" "}
                                {userDetErr.phone && <span style={{ "fontSize": "15px", "color": "red" }}>Format: 0712345678</span>}
                            </div>
                            <LoadingButton
                                type="button"
                                className="btn btn-success btn-lg btn-block"
                                loading={actionLoading}
                                onClick={() => rateHandler()}
                                disabled={rating ? false : true}
                            >
                                Rate
                            </LoadingButton>
                            {valError && <span style={{ "fontSize": "15px", "color": "red" }}>* Phone is a required field</span>}
                        </>}
                    </Col>
                </Row>
            </Container>
        )
    }
}

const UserList = ({
    userClicked,
    mechanic
}) => {
    const [hovered, setHovered] = React.useState(false);

    const tooltip = (
        <Tooltip id="tooltip">
            Click to rate
        </Tooltip>
    );
    return (
        <div className='mt-2' >
            <div className="mb-3">
                <span>Found:</span>
            </div>
            <OverlayTrigger placement="bottom" overlay={tooltip}>
                <div style={{
                    "cursor": "pointer",
                }}>
                    <div
                        style={hovered ? {
                            "borderRadius": "20px",
                            "padding": "10px",
                            "box-shadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        } : {
                            "borderRadius": "20px",
                            "padding": "10px",
                            "border": "1px solid #ccc",
                        }}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        onClick={() => userClicked(true)}
                    >
                        <Row>
                            <Col md={3}>
                                {mechanic.mechanicUserId.avatars.length > 0 ?
                                    <img class="rounded-circle" width="70px" src={`https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${mechanic.mechanicUserId?.avatars[mechanic.mechanicUserId?.avatars.length - 1].privateUrl}`} />
                                    :
                                    <img class="rounded-circle" width="70px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                                }
                            </Col>
                            <Col md={9}>
                                <h5>{mechanic.mechanicUserId.fullName}</h5>
                                <p><strong>Located at: </strong>{mechanic.areaOfOperation.locationName}</p>
                                <p><strong>Services provided: </strong><ul>{mechanic.mechanicServiceSpecialities.map(item => (
                                    <li>{item.servicesName}</li>
                                ))}</ul></p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default RatePage;