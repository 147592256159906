/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";
import Banner1 from "../../assets/images/banner_1.jpg";
import Banner2 from "../../assets/images/banner_2.jpg";
import Banner3 from "../../assets/images/banner_3a.jpg";
import Banner4 from "../../assets/images/banner_4.jpg";
import { Link } from "react-router-dom";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

function CategoryBannerMob(props) {
  return (
    <div className="banner">
      <div className="container">
        <div className="row" style={{
          "display": "flex",
          "padding": "10px",
        }}>
          <div className={"col-md-3 mt-5"} style={{
            "width": "25%",
          }}>
            <div
              className="banner_item align-items-center"
              style={{
                backgroundImage: `url(${Banner1})`,
                height: "100px",
                marginBottom: "0px",
              }}
              data-aos="fade-right"
            >
            </div>
            <div style={{
              "margin-top": "10px",
            }}><Link to={
              {
                pathname: loggedInUser ? "/addrequest" : "/express",
              }
            }
              style={{
                "color": "black",
                "font-weight": "bold",
                "font-size": "13px",
                "cursor": "pointer",
              }}
            >Request Car Mechanic</Link></div>
          </div>
          <div className={"col-md-3 mt-5"} style={{
            "width": "25%",
          }}>
            <div
              className="banner_item align-items-center"
              style={{
                backgroundImage: `url(${Banner2})`,
                height: "100px",
                marginBottom: "0px",
              }}
              data-aos="fade-right"
            >
            </div>
            <div style={{
              "margin-top": "10px",
            }}><Link to={
              {
                pathname: loggedInUser ? "/addrequest" : "/express",
                state: {
                  service: "622089ea3520715540d86c53",
                  vType: "car"
                }
              }
            }
              style={{
                "color": "black",
                "font-weight": "bold",
                "font-size": "13px",
                "cursor": "pointer",
              }}
            >Request Car Towing</Link>
            </div>
          </div>
          <div className={"col-md-3 mt-5"} style={{
            "width": "25%",
          }}>
            <div
              className="banner_item align-items-center"
              style={{
                backgroundImage: `url(${Banner3})`,
                height: "100px",
                marginBottom: "0px",
              }}
              data-aos="fade-right"
            >
            </div>
            <div style={{
              "margin-top": "10px",
            }}><Link to={
              {
                pathname: loggedInUser ? "/addrequest" : "/express",
                state: {
                  service: "622089ea3520715540d86c35",
                  vType: "car",
                  hideDesc: true
                }
              }
            }
              style={{
                "color": "black",
                "font-weight": "bold",
                "font-size": "13px",
                "cursor": "pointer",
              }}
            >Request Car Care</Link>
            </div>
          </div>
          <div className={"col-md-3 mt-5"} style={{
            "width": "25%",
          }}>
            <div
              className="banner_item align-items-center"
              style={{
                backgroundImage: `url(${Banner4})`,
                height: "100px",
                marginBottom: "0px",
              }}
              data-aos="fade-right"
            >
            </div>
            <div style={{
              "margin-top": "10px",
            }}><Link to={
              {
                pathname: loggedInUser ? "/addrequest" : "/express",
                state: {
                  service: "6267bedf75ed802d1cbd4b1e",
                  vType: "motorbike"
                }
              }
            }
              style={{
                "color": "black",
                "font-weight": "bold",
                "font-size": "13px",
                "cursor": "pointer",
              }}
            >Request Motorbike Mech</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryBannerMob;
