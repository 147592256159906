import {
    GET_ALL_SHOPPRODUCTS_BEGIN,
    GET_ALL_SHOPPRODUCTS_SUCCESS,
    GET_ALL_SHOPPRODUCTS_FAIL,
    GET_SHOPOWNER_SHOPPRODUCTS_BEGIN,
    GET_SHOPOWNER_SHOPPRODUCTS_SUCCESS,
    GET_SHOPOWNER_SHOPPRODUCTS_FAIL,
    GET_SHOPPRODUCT_BEGIN,
    GET_SHOPPRODUCT_SUCCESS,
    GET_SHOPPRODUCT_FAIL,
    GET_SHOPPRODUCTS_BY_CATEGORY_BEGIN,
    GET_SHOPPRODUCTS_BY_CATEGORY_SUCCESS,
    GET_SHOPPRODUCTS_BY_CATEGORY_FAIL,
    SEARCH_BEGIN,
    SEARCH_SUCCESS,
    SEARCH_FAIL,
    APPLY_FILTERS_BEGIN,
    APPLY_FILTERS_SUCCESS,
    APPLY_FILTERS_FAIL,
    ADD_SHOPPRODUCT_BEGIN,
    ADD_SHOPPRODUCT_SUCCESS,
    ADD_SHOPPRODUCT_FAIL,
    UPDATE_SHOPPRODUCT_BEGIN,
    UPDATE_SHOPPRODUCT_SUCCESS,
    UPDATE_SHOPPRODUCT_FAIL,
    UPDATE_SHOPPRODUCT_PRIORITY_BEGIN,
    UPDATE_SHOPPRODUCT_PRIORITY_SUCCESS,
    UPDATE_SHOPPRODUCT_PRIORITY_FAIL,
    DELETE_SHOPPRODUCT_BEGIN,
    DELETE_SHOPPRODUCT_SUCCESS,
    DELETE_SHOPPRODUCT_FAIL,
    SET_USER_LOCATION,
    SORT_PRODUCTS_BY_PROXIMITY,
    SORT_PRODUCTS_BY_PRICE,
    FILTER_PRODUCTS_BY_SHOPOWNER
  } from "../actions/shopProductAction";
  
  const initialState = {
    shopproducts: [],
    shopproduct: null,
    sortedshopproducts: null,
    userLocation: null,
    loading: false,
    error: null
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case ADD_SHOPPRODUCT_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case ADD_SHOPPRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproducts: action.payload.data.rows
        };
      case ADD_SHOPPRODUCT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case UPDATE_SHOPPRODUCT_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
    case UPDATE_SHOPPRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
        };
    case UPDATE_SHOPPRODUCT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case UPDATE_SHOPPRODUCT_PRIORITY_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
    case UPDATE_SHOPPRODUCT_PRIORITY_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproduct: action.payload.data
        };
    case UPDATE_SHOPPRODUCT_PRIORITY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
        case DELETE_SHOPPRODUCT_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case DELETE_SHOPPRODUCT_SUCCESS:
          return {
            ...state,
            loading: false,
            shopproduct: action.payload.id
          };
        case DELETE_SHOPPRODUCT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
      case GET_ALL_SHOPPRODUCTS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_ALL_SHOPPRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproducts: action.payload.data.rows
        };
      case GET_ALL_SHOPPRODUCTS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case GET_SHOPOWNER_SHOPPRODUCTS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_SHOPOWNER_SHOPPRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproducts: action.payload.data.rows
        };
      case GET_SHOPOWNER_SHOPPRODUCTS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case GET_SHOPPRODUCT_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_SHOPPRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproduct: action.payload.data
        };
      case GET_SHOPPRODUCT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case GET_SHOPPRODUCTS_BY_CATEGORY_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_SHOPPRODUCTS_BY_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproducts: action.payload.data.rows
        };
      case GET_SHOPPRODUCTS_BY_CATEGORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case SEARCH_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproducts: action.payload.data.shopproducts
        };
      case SEARCH_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case APPLY_FILTERS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case APPLY_FILTERS_SUCCESS:
        return {
          ...state,
          loading: false,
          shopproducts: action.payload.data.shopproducts
        };
      case APPLY_FILTERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case SET_USER_LOCATION:
        return {
           ...state,
          userLocation: action.payload
        };
        case SORT_PRODUCTS_BY_PROXIMITY:
          return {
            ...state,
            sortedshopproducts: action.payload
          };
        case SORT_PRODUCTS_BY_PRICE:
          return {
            ...state,
            sortedshopproducts: action.payload
          };
        case FILTER_PRODUCTS_BY_SHOPOWNER:
          return {
            ...state,
            shopproducts: action.payload
          };
    
      default:
        return state;
    }
  };
  