/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllMechanics,
  applyFilters
} from "../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import Mechanic from "./Mechanic";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  mechanics: state.mechanic.mechanics,
  departments: state.department.departments,
  loading: state.car.loading,
  sorted: state.mechanic.sorted
});
const mapDispatchToProps = dispatch => ({
  getAllMechanics: (args) => dispatch(getAllMechanics(args)),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(Mechanic);
