import React, { Component, PropTypes } from 'react'
import {
    ThreeBounce
} from 'better-react-spinkit';

class ThreeBounceBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // Inline props override the contextual settings.
        return (
            <>
                <div style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center"
                }}>
                    <ThreeBounce color="red" size={24} />
                </div>
                <div style={this.props.matches ? {
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "16px",
                } : {
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "20px",
                }}>
                    <span
                    >{this.props.message}</span>
                </div>
                {this.props.alt &&
                    <div style={{
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "fontSize": "14px",
                    }}>
                        <span
                        >{this.props.alt}</span>
                    </div>}
            </>
        )
    }
}
export default ThreeBounceBanner;
