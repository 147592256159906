/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { useState } from "react";
import jumpTo from "../../modules/Navigation";
import mechanicDefaultProfile from "../../assets/images/mechwangu_default_prof.svg";
import verified from "../../assets/images/Verified_Badge.svg.png";
import StarRatings from "react-star-ratings";

function SingleMechanic(props) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  React.useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);
  const { productItem } = props;
  let mechImage = productItem.image;

  const clickHandler = () => {
    jumpTo(`/single-mechanic/${productItem._id}`);
  }
  return (
    <>
      <div className="product-item men"
        style={{
          borderRadius: "24px",
          marginBottom: "50px"
        }}
      >
        <div
          className="product discount product_filter"
          style={{
            borderRadius: "24px",
            display: "flex",
          }}
        >
          <div className="col-md-4">
            <div className="product_image">
              {mechImage ?
                <img src={`https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${mechImage.privateUrl}`} alt=""
                  className="img-fluid"
                  style={{
                    borderRadius: "24px"
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = mechanicDefaultProfile;
                  }}
                /> :
                <img src={mechanicDefaultProfile} alt="" className="img-fluid" />
              }
            </div>
          </div>
          {/* <div className="favorite favorite_left">
            <StarRatings
              // rating={rating}
              starRatedColor="yellow"
              // changeRating={setRating}
              numberOfStars={5}
              name='rating'
              starDimension="30px"
              starSpacing="1px"
            />
          </div> */}
          {/* <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
          <span>-$20</span>
        </div> */}
          <div className="col-md-8">
            <div className="product_info" style={{
              "alignItems": "left",
            }}>
              <div style={{
                "display": "flex",
                "marginTop": "5px",
              }}>
                <h6 className="product_name">
                  <div style={{
                    "textTransform": "capitalize",
                  }}>
                    {productItem.mechanicUserId?.fullName ? <><strong>{productItem.mechanicUserId?.fullName}</strong></> : <><strong>{productItem.mechanicUserId?.firstName || productItem.mechanicUserId?.lastName}</strong></>}
                  </div>
                </h6>
              </div>
              {productItem.mechanicUserId?.phoneVerified && productItem.mechanicUserId?.tenants[0]?.status === "active" && productItem.mechanicIdentificationDocument !== null ? <div style={{
                "display": "flex",
                "marginTop": "5px",
              }}>
                < img width="32" alt="Verified Badge" src={verified} style={{
                  width: "20px",
                  height: "20px",
                }} />
                <span style={{ "marginLeft": "5px", "color": "#1DA1F2" }}>Verified</span>
              </div> :
                <div style={{
                  "display": "flex",
                  "marginTop": "5px",
                }}>
                  {/* <i class="fas fa-exclamation-circle" style={{
                    width: "20px",
                    height: "20px",
                    "color": "#FF0000",
                  }}></i> */}
                  <span style={{ "marginLeft": "5px", "color": "red" }}>{" "}</span>
                </div>
              }
              <div style={{
                "display": "inline-block",
                "marginTop": "5px",
              }}>
                <StarRatings
                  rating={productItem.rating}
                  starRatedColor="yellow"
                  // changeRating={setRating}
                  numberOfStars={5}
                  name='rating'
                  starDimension="25px"
                  starSpacing="1px"
                />
                <span style={{
                  "marginLeft": "5px",
                }}>({`${productItem.mechanicReviews.length}`})</span>
              </div>
              <div style={{
                "display": "flex",
                "marginTop": "5px",
              }}>
                <i class="fas fa-map-marker-alt" style={{
                  width: "20px",
                  height: "20px",
                }}></i>
                <span style={{ "marginLeft": "5px" }}>{productItem.areaOfOperation.locationName.split(",")[0]}</span>
              </div>
            </div >
            <button
              className="red_button add_to_cart_button"
              onClick={clickHandler}
              style={{
                color: "white",
                borderRadius: "14px",
                marginTop: "5px",
                border: "0px",
              }}
            >
              View Mech Profile
            </button>
          </div>
        </div >
      </div >
    </>
  );
}

export default SingleMechanic;
