/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
    ADD_PACKAGE_ORDER_BEGIN,
    ADD_PACKAGE_ORDER_SUCCESS,
    ADD_PACKAGE_ORDER_FAIL,
    UPDATE_PACKAGE_ORDER_BEGIN,
    UPDATE_PACKAGE_ORDER_SUCCESS,
    UPDATE_PACKAGE_ORDER_FAIL,
    GET_PACKAGE_ORDER_BEGIN,
    GET_PACKAGE_ORDER_SUCCESS,
    GET_PACKAGE_ORDER_FAIL,
} from "../actions/packageOrderAction";

const initialState = {
    loading: false,
    error: null,
    packageorder: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_PACKAGE_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_PACKAGE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_PACKAGE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        case UPDATE_PACKAGE_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_PACKAGE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_PACKAGE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        case GET_PACKAGE_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_PACKAGE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                packageorder: action.payload.data.rows[0]
            };
        case GET_PACKAGE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        default:
            return state;
    }
};