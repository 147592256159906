/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import PhoneVerification from "./PhoneVerification";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  mechanics: state.mechanic.mechanics,
  loading: state.car.loading
});
const mapDispatchToProps = dispatch => ({
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(PhoneVerification);
