/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';


export const mechUserDetails = (userID) => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();
  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/user?filter%5B_id%5D=${userID}`;
  return await axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }).then(res => {
    console.log("mechUserDetails: " + JSON.stringify(res))
    return res;
  })
}

export const getMechanicIdUsingUserId = (loggedInUserID) => async dispatch => {
  let mechId;
  console.log("GetUserLoggedInUserID " + loggedInUserID);
  dispatch({
    type: GET_MECHANIC_ID_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic?filter%5BmechanicUserId%5D=${loggedInUserID}&offset=0&limit=1`;

  await axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    // console.log("GetMechanicIdWithUserId: "+JSON.stringify(res.data.rows[0].id));
    console.log("GetMechanicIdWithUserId: " + res.data.rows[0].id);
    mechId = res.data.rows[0].id;
    dispatch({
      type: GET_MECHANIC_ID_SUCCESS,
      payload: res.data.rows[0].id
    });
    return res.data.rows[0].id;
  })
    .catch(error => {
      dispatch({
        type: GET_MECHANIC_ID_FAIL,
        payload: { error }
      });
      return error;
    });

  return mechId;
};
export const getMechanicUsingUserId = (loggedInUserID) => async dispatch => {
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic?filter%5BmechanicUserId%5D=${loggedInUserID}&offset=0&limit=1`;

  return await axios.get(webApiUrl).then(res => {
    return res.data;
  })
    .catch(error => {
      dispatch({
        type: GET_MECHANIC_ID_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const getMechanicsUsingSpecialityServiceId = (specialityServiceId) => async dispatch => {
  console.log("GetMechanicIdUsingSpecialityServiceId " + specialityServiceId);
  dispatch({
    type: GET_ALL_MECHANICS_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic?filter%5BmechanicServiceSpecialities%5D=${specialityServiceId}`;

  console.log("SearchMechanicsApiUrl: " + JSON.stringify(webApiUrl));
  await axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("GetMamechsSearched: " + JSON.stringify(res.data.rows));
    dispatch({
      type: GET_ALL_MECHANICS_SUCCESS,
      payload: res
    });
  })
    .catch(error => {
      dispatch({
        type: GET_ALL_MECHANICS_FAIL,
        payload: { error }
      });
    });
};

export const postRating = (data) => async dispatch => {
  dispatch({
    type: POST_RATING_BEGIN
  });
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/review-of-mechanic`;
  return await axios.post(url, data,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("PostRating: " + JSON.stringify(res));
      dispatch({
        type: POST_RATING_SUCCESS,
        payload: res
      });
      return res;
    }).catch(error => {
      dispatch({
        type: POST_RATING_FAIL,
        payload: { error }
      });
      return error;
    });
}

export const searchByName = (data) => async dispatch => {
  dispatch({
    type: MECH_SEARCH_BEGIN
  });
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/user/byName`;
  return await axios.post(url, {
    data
  },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      dispatch({
        type: MECH_SEARCH_SUCCESS,
        payload: res
      });
      return res;
    }).catch(error => {
      dispatch({
        type: MECH_SEARCH_FAIL,
        payload: { error }
      });
      return error;
    });
}

export const getAllMechanics = (args) => async dispatch => {
  dispatch({
    type: GET_ALL_MECHANICS_BEGIN
  });

  //const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic?limit=${args.limit ? args.limit : 0}`;

  return axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("Mamechs " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_MECHANICS_SUCCESS,
        payload: res
      });
      return res.data;
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_MECHANICS_FAIL,
        payload: { error }
      });
    });
};

export const getMechanic = id => dispatch => {
  dispatch({
    type: GET_MECHANIC_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    dispatch({
      type: GET_MECHANIC_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_MECHANIC_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const setSortedData = data => dispatch => {
  dispatch({
    type: SET_SORTED_DATA,
    payload: data
  });
};
export const getMechanicUserByEmailorPhone = userData => dispatch => {

  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/user/find`;
  return axios.post(
    webApiUrl,
    {
      data: userData
    }
  )
};
export const updateMechanic = data => async dispatch => {

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let locationData;
  let locationId;
  if (data.location) {
    locationData = {
      locationName: data.location.locationName,
      locationDescription: data.location.locationDescription,
      coordinates: data.location.coordinates,
    }
    const { data: { _id } } = await axios.post(
      `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/location`, {
      data: locationData
    },
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
    );
    locationId = _id;
  }

  let idData = data.doc;
  let id;
  if (data.doc) {
    const { data: { _id } } = await axios.post(
      `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/identification-document`, {
      data: idData
    }, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    )
    id = _id;
  }

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic/${data.mechanic.id}`;

  if (locationId) {
    let mech = data.mechanic;
    mech.areaOfOperation = locationId;
    if (data.doc) { mech.mechanicIdentificationDocument = id; }
    return await axios.put(webApiUrl, { data: mech }, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    ).then(res => {
      console.log("UpdateMechanic: " + JSON.stringify(res));
      return res;
    })
      .catch(error => {
        console.log("Error: " + JSON.stringify(error));
        return error;
      });
  } else {
    let mech = data.mechanic;
    if (data.doc) { mech.mechanicIdentificationDocument = id; }
    return await axios.put(webApiUrl, { data: mech }, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    ).then(res => {
      console.log("UpdateMechanic: " + JSON.stringify(res));
      return res;
    })
      .catch(error => {
        console.log("Error: " + JSON.stringify(error));
        return error;
      });
  }
};


// export const  updateMechanic = id => dispatch => {
//   dispatch({
//     type: GET_MECHANIC_BEGIN
//   });

//   const token_stored = AuthToken.get();
//   const tenantId = AuthCurrentTenant.get();

//   let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic/${id}`;

//   return axios.put(webApiUrl, {
//        headers: {
//            "Authorization" : `Bearer ${token_stored}`
//        }
//    }
//   ).then(res => {
//       console.log("1Mechanic: "+JSON.stringify(res));
//       dispatch({
//         type: GET_MECHANIC_SUCCESS,
//         payload: res
//       });
//       return res;
//     })
//     .catch(error => {
//       dispatch({
//         type: GET_MECHANIC_FAIL,
//         payload: { error }
//       });
//       return error;
//     });
// };

export const getMechanicsByCategory = c => dispatch => {
  dispatch({
    type: GET_MECHANICS_BY_CATEGORY_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?category=${c}`
  })
    .then(res => {
      dispatch({
        type: GET_MECHANICS_BY_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_MECHANICS_BY_CATEGORY_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const search = text => dispatch => {
  dispatch({
    type: SEARCH_BEGIN
  });
  return API({
    method: "GET",
    url: `/search?query=${text}`
  })
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: SEARCH_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const applyFilters = filter_string => dispatch => {
  dispatch({
    type: APPLY_FILTERS_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?${filter_string}`
  })
    .then(res => {
      dispatch({
        type: APPLY_FILTERS_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: APPLY_FILTERS_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_MECHANICS_BEGIN = "GET_ALL_MECHANIC_BEGIN";
export const GET_ALL_MECHANICS_SUCCESS = "GET_ALL_MECHANICS_SUCCESS";
export const GET_ALL_MECHANICS_FAIL = "GET_ALL_MECHANICS_FAIL";

export const GET_MECHANIC_BEGIN = "GET_MECHANIC_BEGIN";
export const GET_MECHANIC_SUCCESS = "GET_MECHANIC_SUCCESS";
export const GET_MECHANIC_FAIL = "GET_MECHANIC_FAIL";

export const POST_RATING_BEGIN = "POST_RATING_BEGIN";
export const POST_RATING_SUCCESS = "POST_RATING_SUCCESS";
export const POST_RATING_FAIL = "POST_RATING_FAIL";

export const GET_MECHANICS_BY_CATEGORY_BEGIN = "GET_MECHANICS_BY_CATEGORY_BEGIN";
export const GET_MECHANICS_BY_CATEGORY_SUCCESS = "GET_MECHANICS_BY_CATEGORY_SUCCESS";
export const GET_MECHANICS_BY_CATEGORY_FAIL = "GET_MECHANICS_BY_CATEGORY_FAIL";

export const GET_MECHANIC_ID_BEGIN = "GET_MECHANIC_ID_BEGIN";
export const GET_MECHANIC_ID_SUCCESS = "GET_MECHANIC_ID_SUCCESS";
export const GET_MECHANIC_ID_FAIL = "GET_MECHANIC_ID_FAIL"

export const SET_SORTED_DATA = "SET_SORTED_DATA";

export const MECH_SEARCH_BEGIN = "MECH_SEARCH_BEGIN";
export const MECH_SEARCH_SUCCESS = "MECH_SEARCH_SUCCESS";
export const MECH_SEARCH_FAIL = "MECH_SEARCH_FAIL";

