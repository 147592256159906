/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */
 import jumpTo from "../Navigation";

 class AuthUserToken {
  constructor() {
    this.user_details = JSON.parse(localStorage.getItem("auth"));
  }
  getUserId() {
    return this.user_details.id;
  }

  getUserDetails() {
    return this.user_details;
  }

  setUserToken(new_token) {
    this.user_details = new_token;
    localStorage.setItem("auth", JSON.stringify(new_token));
  }
  logout() {
    localStorage.removeItem("auth");
    localStorage.removeItem("jwt");
    jumpTo("/");
    window.location.reload();
  }
}
export default new AuthUserToken();
