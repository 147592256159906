import { 
ADD_SHOP_BEGIN,
ADD_SHOP_SUCCESS,
ADD_SHOP_FAIL,
GET_ALL_SHOPS_BEGIN,
GET_ALL_SHOPS_SUCCESS,
GET_ALL_SHOPS_FAIL,
GET_SHOP_BEGIN,
GET_SHOP_SUCCESS,
GET_SHOP_FAIL, 
UPDATE_SHOP_BEGIN,
UPDATE_SHOP_SUCCESS,
UPDATE_SHOP_FAIL, 
DELETE_SHOP_BEGIN,
DELETE_SHOP_SUCCESS,
DELETE_SHOP_FAIL, 

} from '../actions/shopAction';

const initialState = {
  shops: null,
  shop: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
switch (action.type) {
  case ADD_SHOP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        shops: action.payload.data.rows
      };
    case ADD_SHOP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
      case UPDATE_SHOP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SHOP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
      case DELETE_SHOP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DELETE_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        shop: action.payload.id
      };
    case DELETE_SHOP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
case GET_ALL_SHOPS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
case GET_ALL_SHOPS_SUCCESS:
      return {
        ...state,
        loading: false,
        shops: action.payload.data.rows
      };
case GET_ALL_SHOPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
case GET_SHOP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
case GET_SHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        shop: action.payload.data
      };
case GET_SHOP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
default:
return state;
}
}

