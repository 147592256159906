import React, { Component, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import mongoose from 'mongoose';
import CircleSpinner from '../../components/Spinner/Circle';
import jumpTo from '../../modules/Navigation';
import { io } from "socket.io-client";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
let socket;
class PackageCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantCode: '4751314498',
            wallet: '0932844522',
            paymentTimeLimit: '15mins',
            tenant: AuthCurrentTenant.get(),
            shopOwnerId: '',
            postalCodeZip: '',
            address: '',
            totalAmount: 0,
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            phone: '',
            notes: '',
            orderRef: mongoose.Types.ObjectId(),
            affects: null,
            mpesa: true,
        };
    }
    componentDidMount() {
        socket = io("https://mapi.mechwangu.co.ke", {
            withCredentials: true,
            // restrict to websockets over polling
            transports: ['websocket'],
            autoConnect: false,
        });
        this.props.getPaymentToken();
        this.props.getPackage(this.props.location.pathname.split("/").slice(-1)[0]);
        this.props.getShopOwnerIdUsingUserId(loggedInUser.id).then((res) => {
            this.setState({shopOwnerId: res});
           }).catch((error) => {
             console.log(error);
           });
    }
    setFirstName = (e) => {
        this.setState({
            firstName: e.target.value
        });
    }
    setLastName = (e) => {
        this.setState({
            lastName: e.target.value
        });
    }
    setCompanyName = (e) => {
        this.setState({
            companyName: e.target.value
        });
    }
    setEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }
    setPhone = (e) => {
        this.setState({
            phone: e.target.value
        });
    }
    setNotes = (e) => {
        this.setState({
            notes: e.target.value
        });
    }
    setAddress = (e) => {
        this.setState({
            address: e.target.value
        });
    }
    setPostalCodeZip = (e) => {
        this.setState({
            postalCodeZip: e.target.value
        });
    }
    setPaymentMethod = (e) => {
        if (e === 'mpesa') {
            this.setState({
                mpesa: true
            });
        } else {
            this.setState({
                mpesa: false
            });
        }
    }
    render() {
        return (
            <>
                <div className="container mt-5">

                    <div className="row mt-5">
                        {this.state.mpesa ?
                            <div className="col-md-6 mt-5 mb-5 mb-md-0">
                                <h2 className="h3 mb-3 text-black">M-Pesa Billing Details</h2>
                                <div className="p-3 p-lg-5 border">
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label htmlFor="c_fname" className="text-black">First Name <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_fname" name="c_fname" onChange={(e) => this.setFirstName(e)} placeholder='John' />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="c_lname" className="text-black">Last Name <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_lname" name="c_lname" onChange={(e) => this.setLastName(e)} placeholder='Doe' />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-5">
                                        <div className="col-md-6">
                                            <label htmlFor="c_email_address" className="text-black">Email Address</label>
                                            <input type="text" className="form-control" id="c_email_address"
                                                name="c_email_address" onChange={(e) => this.setEmail(e)} placeholder='johndoe@mail.com' />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="c_phone" className="text-black">Phone <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_phone" name="c_phone"
                                                placeholder="0712345678" onChange={(e) => this.setPhone(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-md-6 mt-5 mb-5 mb-md-0">
                                <h2 className="h3 mb-3 text-black">Card Billing Details</h2>
                                <div className="p-3 p-lg-5 border">
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label htmlFor="c_fname" className="text-black">First Name <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_fname" name="c_fname" onChange={(e) => this.setFirstName(e)} placeholder='John' />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="c_lname" className="text-black">Last Name <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_lname" name="c_lname" onChange={(e) => this.setLastName(e)} placeholder='Doe' />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <label htmlFor="c_companyname" className="text-black">Company Name </label>
                                            <input type="text" className="form-control" id="c_companyname" name="c_companyname" placeholder='This is Optional' onChange={(e) => this.setCompanyName(e)} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-5">
                                        <div className="col-md-6">
                                            <label htmlFor="c_email_address" className="text-black">Email Address <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_email_address"
                                                name="c_email_address" onChange={(e) => this.setEmail(e)} placeholder='johndoe@mail.com' />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="c_phone" className="text-black">Phone <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_phone" name="c_phone"
                                                placeholder="0712345678" onChange={(e) => this.setPhone(e)} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-5">
                                        <div className="col-md-6">
                                            <label htmlFor="c_address" className="text-black">Address <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_address"
                                                name="c_address" onChange={(e) => this.setAddress(e)} placeholder='0000' />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="c_postal" className="text-black">Postal Code <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="c_postal" name="c_postal"
                                                placeholder="12345" onChange={(e) => this.setPostalCodeZip(e)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="c_order_notes" className="text-black">Order Notes</label>
                                        <textarea name="c_order_notes" id="c_order_notes" cols="30" rows="5"
                                            className="form-control" placeholder="Write your notes here..." onChange={(e) => this.setNotes(e)}></textarea>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-md-6 mt-5">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Your Order</h2>
                                <>
                                    {(this.props.adpackage && this.props.tokens) ?
                                        <Cart
                                            addPackageOrder={this.props.addPackageOrder}
                                            data={this.props.adpackage}
                                            tokens={this.props.tokens}
                                            merchantCode={this.state.merchantCode}
                                            wallet={this.state.wallet}
                                            orderRef={this.state.orderRef}
                                            shopOwnerId={this.state.shopOwnerId}
                                            paymentTimeLimit={this.state.paymentTimeLimit}
                                            firstName={this.state.firstName}
                                            lastName={this.state.lastName}
                                            postalCodeZip={this.state.postalCodeZip}
                                            address={this.state.address}
                                            email={this.state.email}
                                            phone={this.state.phone}
                                            setPaymentMethod={this.setPaymentMethod}
                                            initiateMpesa={this.props.initiateMpesa}
                                            addAdvertPayment={this.props.addAdvertPayment}
                                            advertpayment={this.props.advertpayment}
                                            msResponse={this.props.response}
                                            updateShopOwnerPackage={this.props.updateShopOwnerPackage}
                                            updatePackageOrder={this.props.updatePackageOrder}
                                        /> : <Loader />}
                                </>

                            </div>
                            {/* <div>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <h2 className="h3 mb-3 text-black">Coupon Code</h2>
                                        <div className="p-3 p-lg-5 border">
                                            <label htmlFor="c_code" className="text-black mb-3">Enter your coupon code if you
                                                have one</label>
                                            <div className="input-group w-75">
                                                <input type="text" className="form-control" id="c_code"
                                                    placeholder="Coupon Code" aria-label="Coupon Code"
                                                    aria-describedby="button-addon2" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary btn-sm px-4" type="button"
                                                        id="button-addon2">Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                </div >
            </>
        );
    }
}

const Cart = ({
    addPackageOrder,
    data,
    tokens,
    merchantCode,
    wallet,
    orderRef,
    shopOwnerId,
    paymentTimeLimit,
    firstName,
    lastName,
    postalCodeZip,
    address,
    email,
    phone,
    setPaymentMethod,
    initiateMpesa,
    addAdvertPayment,
    advertpayment,
    updateShopOwnerPackage,
    updatePackageOrder,
    msResponse
}) => {
    const [orderLoading, setOrderLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [mpesaChecked, setMpesaChecked] = useState(true);
    const [cardChecked, setCardChecked] = useState(false);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [paymentData, setPaymentData] = useState(null);
    const [paymentCancelled, setPaymentCancelled] = useState(false);
    const [wrongUserPin, setWrongUserPin] = useState(false);
    const [insufficientFunds, setInsufficientFunds] = useState(false);
    const [unknownError, setUnknownError] = useState(false);
    const [visible, setVisible] = useState(true);
    const [errMsg, setErrMsg] = useState(null);
    const [packageOrder, setPackageOrder] = useState(null);

    let adpackage;
    adpackage = data;

    useEffect(() => {
        if (msResponse !== null) {
            localStorage.setItem('transactionId', msResponse.data.CheckoutRequestID);
        }
    }, [msResponse]);

    useEffect(() => {
        socket.on('connect', () => {
            setIsConnected(true);
            console.log('connected');
        });

        socket.on('disconnect', () => {
            setIsConnected(false);
            console.log('disconnected');
        });

        socket.on("mpesa", (data) => {
            let transId = localStorage.getItem('transactionId');
            if (data.valid === true) {
                switch (data.status) {
                    case "success":
                        if (data.data.id === transId) {
                            setPaymentData(data.data)
                            setPaymentSuccessful(true);
                        }
                        break;
                    case "cancelled":
                        if (data.data.id === transId) {
                            setPaymentCancelled(true);
                            setErrMsg("Payment cancelled");
                            setOrderLoading(false);
                        }
                        break;
                    case "pin":
                        if (data.data.id === transId) {
                            setWrongUserPin(true);
                            setErrMsg("Wrong user pin");
                            setOrderLoading(false);
                        }
                        break;
                    case "insufficient":
                        if (data.data.id === transId) {
                            setInsufficientFunds(true);
                            setErrMsg("Insufficient funds");
                            setOrderLoading(false);
                        }
                        break;
                    default:
                        if (data.data.id === transId) {
                            setUnknownError(true);
                            setErrMsg("An error occurred. Please try again");
                            setOrderLoading(false);
                        }
                        break;
                }
            }
        })

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('pong');
            socket.off("mpesa");
        };
    }, []);

    useEffect(() => {
        if (phone && email && firstName && lastName) socket.connect();
    }, [phone, email, firstName, lastName]);

    useEffect(() => {
        async function toDb(data) {
            await updatePackageOrder(packageOrder._id,data);
            await addAdvertPayment(data).then(() => jumpTo(`/manageshopproducts`));
        }
        if (paymentSuccessful) {
            setMessage("Payment Successful,  redirecting...");
            let data = {
                advertPaymentTransactionCode: paymentData.id,
                advertPaymentStatus: "Complete",
                advertPaymentAmount: paymentData.amount,
                advertPaymentOfAdvertisement: [],
                advertPaymentOrderDetails: packageOrder.id,
                shopOwnersPackageSubscription: adpackage.id,
                adOrderStatus: "PAID",
            }
            localStorage.removeItem('transactionId');
            toDb(data);
            updateShopOwnerPackage(shopOwnerId,data);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentSuccessful]);

    useEffect(() => {
        async function toDb(data) {
            await addAdvertPayment(data);
            await updatePackageOrder(packageOrder._id,data);
        }
        if (paymentCancelled) {
            let data = {
                advertPaymentTransactionCode:"Null",
                advertPaymentStatus: "Cancelled",
                advertPaymentAmount: adpackage.advertPackageCostOfPackage,
                advertPaymentOfAdvertisement: [],
                advertPaymentOrderDetails: packageOrder.id,  
                adOrderStatus: "CANCELLED", 
            }
            toDb(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentCancelled]);

    useEffect(() => {
        async function toDb(data) {
            await addAdvertPayment(data);
            await updatePackageOrder(packageOrder._id,data);
        }
        if (wrongUserPin) {
            let data = {
                advertPaymentTransactionCode: "Null",
                advertPaymentStatus: "WrongPin",
                advertPaymentAmount: adpackage.advertPackageCostOfPackage,
                advertPaymentOfAdvertisement: [],
                advertPaymentOrderDetails: packageOrder.id,
                adOrderStatus: "CANCELLED",   
            }
            toDb(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrongUserPin]);

    useEffect(() => {
        async function toDb(data) {
            await addAdvertPayment(data);
            await updatePackageOrder(packageOrder._id,data);
        }
        if (insufficientFunds) {
            let data = {
                advertPaymentTransactionCode: "Null",
                advertPaymentStatus: "InsufficientFunds",
                advertPaymentAmount: adpackage.advertPackageCostOfPackage,
                advertPaymentOfAdvertisement: [],
                advertPaymentOrderDetails: packageOrder.id,
                adOrderStatus: "CANCELLED", 
            }
            toDb(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insufficientFunds]);


    {/*const signUpGuest = async () => {
        setErrMsg(null);
        setMessage("Checking if user exists");
        setRegisterLoading(true);
        let guestData;
        if (firstName && lastName && email && phone) {
            setErrMsg(null);
            guestData = {
                fullname: `${firstName} ${lastName}`,
                phone: phone,
                email: email,
                userRole: "userCustomer",
                password: 'Guest12345',
                verifyPassword: 'Guest12345',
                type: 'guest',
            }
        } else {
            setErrMsg("Please fill in all fields");
            setVisible(true);
            setRegisterLoading(false);
            return;
        }

        let { data } = await checkExisting(guestData);
        if (data.ok) {
            setMessage("User exists");
            setloggedInUser(ata.user);
            return data.user;
        }
        setMessage("Creating user");
        return await register(guestData).then((res) => {
            setProfile(res);
            setRegisterLoading(false);
            return res;
        }).catch(err => {
            setRegisterLoading(false);
            console.log("Guest signUp failed with err: ", err);
            //log data
            console.log("Guest signUp failed with err:data: ", err.data);
            return err.data;
        });
    }*/}
    {/*const createRequest = async () => {
        setMessage("Creating request");
        setRequestLoading(true);
        let reqData;
        if (express) {
            reqData = {
                requestForCar: undefined,
                requestDetail: express.detail,
                affects: express.service,
                mechanicId: express.mech,
            }
        }
        return await addRequest(
            reqData.requestForCar,
            reqData.requestDetail,
            reqData.affects,
            reqData.mechanicId,
        ).then((res) => {
            setRequest(res);
            setRequestLoading(false);
            return res;
        }).catch(err => {
            setRequestLoading(false);
            console.log("Request creation failed with err: ", err);
        });
    }*/}
    const onBoardUser = async () => {
        setErrMsg(null);
        setVisible(false);
        let data = {
            adOrderBillingFirstName: firstName,
            adOrderBillingLastName: lastName,
            adOrderForPackageId: adpackage,
            adOrderOfLoggedInUserId: loggedInUser.id,
            adOrderBillingPhoneNumber: phone,
            adOrderBillingEmail: email,
            adOrderCompanyName: "MechWangu Ltd",
            adOrderPaymentId: orderRef || "Null",
            adOrderTotal: adpackage.advertPackageCostOfPackage,
            adOrderBillingAdditionalInfo: "",
        }
        console.log("order data: ", data);
        setMessage("Creating order");
        setOrderLoading(true);
        await addPackageOrder(data).then((res) => {
            if (mpesaChecked) {
                setPackageOrder(res.data);
                setMessage("Initiating M-Pesa prompt on your phone");
                initiateMpesa({
                    phone,
                    amount: JSON.stringify(adpackage.advertPackageCostOfPackage),
                }).then(res => {
                    setMessage("Prompt initiated, waiting for payment");
                }).catch(err => {
                    setMessage("An error occurred. Please try again");
                    setOrderLoading(false);
                })
            }
            if (cardChecked) {
                setOrderLoading(false);
                // get form
                const form = document.getElementById("submitcheckout");
                form.submit();
            }
        }).catch(() => {
            setOrderLoading(false);
            return
        })

    }

    return (
        <>
            <div className="p-3 p-lg-5 border">
                {orderLoading ?
                    <div style={{
                        "marginTop": "50px",
                        "marginBottom": "50px",
                    }}>
                        <CircleSpinner message={message} />
                    </div>
                    :
                    <>
                        <table className="table site-block-order-table mb-5">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{adpackage.advertPackageName} <strong className="mx-2">x</strong> 1</td>
                                    <td>KSh.{adpackage.advertPackageCostOfPackage}</td>
                                </tr>
                                <tr>
                                    <td className="text-black font-weight-bold"><strong>Cart Subtotal</strong>
                                    </td>
                                    <td className="text-black">KSh.{adpackage.advertPackageCostOfPackage}</td>
                                </tr>
                                <tr>
                                    <td className="text-black font-weight-bold"><strong>Order Total</strong>
                                    </td>
                                    <td className="text-black font-weight-bold"><strong>KSh.{adpackage.advertPackageCostOfPackage}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='form-group'>
                            <label style={{
                                "marginRight": "10px",
                            }}>
                                <input type="radio" value="mpesa" name="paymentMethod"
                                    id="c_create_account" checked={mpesaChecked} onChange={(e) => { setPaymentMethod(e.currentTarget.value); setMpesaChecked(true); setCardChecked(false); }} />M-Pesa</label>
                            <label>
                                <input type="radio" value="jenga" name="paymentMethod"
                                    id="c_create_account" checked={cardChecked} onChange={(e) => { setPaymentMethod(e.currentTarget.value); setMpesaChecked(false); setCardChecked(true); }} /> Card/Other</label>
                        </div>
                    </>
                }
                <div className="form-group">
                    <form id="submitcheckout" action="https://v3.jengapgw.io/processPayment" method="POST">
                        <input type="hidden" id="token" name="token" value={tokens.accessToken} />
                        <input type="hidden" id="merchantCode" name="merchantCode" value={merchantCode} />
                        <input type="hidden" id="wallet" name="wallet" value={wallet} />
                        <input type="hidden" id="orderAmount" name="orderAmount" value={adpackage.adpackagePrice} />
                        <input type="hidden" id="orderReference" name="orderReference" value={orderRef} />
                        <input type="hidden" id="productType" name="productType" value={"package"} />
                        <input type="hidden" id="productDescription" name="productDescription" value={"Mechanic request"} />
                        <input type="hidden" id=" paymentTimeLimit " name=" paymentTimeLimit " value={paymentTimeLimit} />
                        <input type="hidden" id="customerFirstName" name="customerFirstName" value={firstName} />
                        <input type="hidden" id="customerLastName" name="customerLastName" value={lastName} />
                        <input type="hidden" id="customerPostalCodeZip" name="customerPostalCodeZip" value={postalCodeZip} />
                        <input type="hidden" id="customerAddress" name="customerAddress" value={address} />
                        <input type="hidden" id="customerEmail" name="customerEmail" value={email} />
                        <input type="hidden" id="customerPhone" name="customerPhone" value={phone} />
                        <input type="hidden" id="extraData" name="extraData" value={loggedInUser ? loggedInUser.id : "GUEST"} />
                        <input type="hidden" id="callbackUrl" name="callbackUrl" value={"https://mechwangu.co.ke/PaymentConfirmation"} />
                        {(visible && !errMsg) && <button type='button' id="submitcheckout" className="btn btn-success btn-md btn-block" onClick={onBoardUser} >Place Order</button>}
                        {errMsg && <button type='button' id="submitcheckout" className="btn btn-success btn-md btn-block" onClick={onBoardUser} >Retry</button>}
                        {errMsg && <div style={{
                            "display": "flex",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "marginTop": "10px",
                        }}><button type='button' id="submitcheckout" className="btn btn-primary btn-md" onClick={() => jumpTo("/")} >Go home</button></div>}
                    </form>
                </div>
                {errMsg && <div style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "10px",
                    "backgroundColor": "red",
                    "color": "white",
                    "padding": "5px",
                    "borderRadius": "5px",
                }}>{errMsg}</div>}
            </div>
        </>
    );

}

export default PackageCheckout;
