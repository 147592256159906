/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";
import jumpTo from "../../modules/Navigation";
import mechanicDefaultGarage from "../../assets/images/mechwangu_default_garage.svg";

function GarageHolderOnMyGaragePage(props) {
  const { garageItem } = props;

  return (
    <div className="product-item men">
      <div
        className="product discount product_filter"
        onClick={() =>
          jumpTo(`/single-garage/${garageItem._id}`)
        }
      >
        <div className="product_image">
          <img src={garageItem.imagePath || mechanicDefaultGarage} alt="" className="img-fluid" />
        </div>
        <div className="favorite favorite_left">
          <i className="far fa-heart"></i>
        </div>
        {/* <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
          <span>-$20</span>
        </div> */}
        <div className="product_info">
          <h6 className="product_name">
            <div>{garageItem.garageName}</div>
          </h6>
          {/* <div className="product_price">
            KSh. {garageItem.price}
            <span> KSh. {(parseFloat(garageItem.price) + 30).toFixed(2)}</span>
          </div> */}
        </div>
      </div>
      <div
        className="red_button add_to_cart_button"
        onClick={() =>
           jumpTo(`/editgarage/${garageItem._id}`)
          }
      >
        <div style={{ color: "#ffffff" }}>Edit Garage</div>
      </div>
    </div>
  );
}

export default GarageHolderOnMyGaragePage;
