/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */
import { getAllMechanics, getMechanicsUsingSpecialityServiceId, searchByName } from "../../redux/actions/mechanicAction";
import { getAllServices } from "../../redux/actions/serviceAction";
import { connect } from "react-redux";
import Search from "./Search";

const mapStoreToProps = state => ({
  mechanics: state.mechanic.mechanics,
  services: state.service.services,
  loading: state.mechanic.loading,
  serviceLoading: state.service.loading,
  searchResults: state.mechanic.searchResults
});
const mapDispatchToProps = dispatch => ({
  getMechanicsUsingSpecialityServiceId: serviceId => dispatch(getMechanicsUsingSpecialityServiceId(serviceId)),
  searchByName: data => dispatch(searchByName(data)),
  getAllMechanics: () => dispatch(getAllMechanics()),
  getAllServices: () => dispatch(getAllServices()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Search);
