import React, { useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import "./index.css"
import { updateProfile } from "../../redux/actions/profileAction";
import LoadingButton from "../../components/LoadingButton";
import Storage from "../../security/storage";
import ImageModal from "../../components/ImageModal/ImageModal";
import ImagesUploaderWrapper from "../../components/Wrappers/ImagesUploaderWrapper";
import styled from 'styled-components';
import { uploadFile } from "../../utils/fileUploader";
import { getProfile } from "../../redux/actions/profileAction";
import Loader from "../../components/Loader/Loader";
import { Form } from "react-bootstrap";
import Select from "react-select";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { getShopOwner } from "../../redux/actions/ShopOwnerAction";
import { Link } from "react-router-dom";


const Profile = ({
    updateProfile,
    services,
    mechanic,
    getAllServices,
    getMechanicId,
    getMechanic,
    getShopOwner,
    getShopOwnerId,
    updateMechanic
}) => {
    const profile = JSON.parse(localStorage.getItem("auth"));
    const [fullName, setFullName] = React.useState(profile.fullName);
    const [email, setEmail] = React.useState(profile.email);
    const [phone, setPhone] = React.useState(profile.phoneNumber);
    const [loading, setLoading] = React.useState(false);
    // const [ifile, setiFile] = React.useState([]);
    // const [files, setFiles] = React.useState([]);
    // const [image, setImage] = React.useState();
    const [iFiles, setiFiles] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [image, setImage] = React.useState();
    const [mounted, setMounted] = React.useState(false)
    const [selectedServices, setSelectedServices] = React.useState([]);
    const [coordinates, setCoordinates] = React.useState()
    const [LocationName, setLocationName] = React.useState();
    const [locationDescription, setLocationDescription] = React.useState();
    const input = useRef();
    const input2 = useRef();
    const input3 = useRef();

    const {
        // ready,
        value: locationName,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
        },
        debounce: 300,
    })

    const dispatch = useDispatch();
    useEffect(() => {
        if (!mounted) {
            dispatch(getProfile()).then((res) => {
                localStorage.setItem("auth", JSON.stringify(res.data));
            })
            getAllServices();
            if (profile.tenants[0].roles[0] === "userMechanic") {
                getMechanicId(profile.id).then(res => getMechanic(res).then(res => {
                    let selected = res.data.mechanicServiceSpecialities.map(service => {
                        return {
                            value: service.id,
                            label: service.servicesName
                        }
                    })
                    setSelectedServices(selected);
                }))
            }
            if (profile.tenants[0].roles[0] === "userShopOwner") {
                getShopOwnerId(profile.id).then(res => getShopOwner(res));
            }
        }
        setMounted(true)
    }, [dispatch, mounted])
    const profileState = useSelector(state => state.profile.profile);
    const shopownerState = useSelector(state => state.shopowner.shopowner);
    const mechanicState = useSelector(state => state.mechanic.mechanic);
    const ids = (mechanicState?.mechanicIdentificationDocument?.idImages?.length === 2);
    const storage = Storage.values.userAvatarsProfiles

    const onLocationSelect = ({ description, structured_formatting: { main_text } }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();
        setLocationName(description);
        setLocationDescription(main_text);

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log("📍 Coordinates: ", { lat, lng });
                setCoordinates([lat, lng].join(","))
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const handleUpdate = async () => {
        setLoading(true);
        let data = {
            id: profileState._id,
            fullName,
            firstName: fullName,
            email,
            phoneNumber: phone,
            avatars: files.filter(file => file.type === "profile") || profile.avatars,
        }
        if (profile.tenants[0].roles[0] === "userMechanic") {
            let entry = { mechanic };
            if (locationName && locationDescription && coordinates) {
                entry.location = {
                    locationName,
                    locationDescription,
                    coordinates
                }
            }
            entry.mechanic.mechanicServiceSpecialities = selectedServices.map(service => service.value);
            let iDs = files.filter(file => file.type !== "profile");
            if (iDs.length > 0) entry.doc = {
                idNumber: entry.mechanic.mechanicsIdNo,
                idImages: [...iDs],
                tenant: profile.tenants[0].id
            }
            await updateMechanic(entry)
        }
        updateProfile(data).then(res => {
            setLoading(false);
            if (res.success) {
                window.location.reload();
            }
        });
    }

    const handleFileChange = async (event, type) => {
        event.preventDefault();
        try {
            const upload = event.target.files;

            if (!upload || !upload.length) {
                return;
            }

            let file = upload[0];

            setiFiles([...iFiles, file])
            console.log("handleChange:file", file);
            // FileUploader.validate(file, {
            //     storage: props.storage,
            //     image: true,
            // });

            const res = await uploadFile(file, {
                storage,
                image: true,
            });
            res.type = type;
            console.log("handleChange:res", res);
            if (res.type === "profile") {
                input.current.value = "";
            } else {
                input2.current.value = "";
                input3.current.value = "";
            }
            setFiles([...files, res]);
        } catch (error) {
            input.current.value = "";
            input2.current.value = "";
            input3.current.value = "";
            console.error(error);
        }
    };
    const handleRemove = (id) => {
        const list = files.filter((item) => item.id !== id);
        setFiles(list);
    };
    const doPreviewImage = (image) => {
        setImage({
            src: image.downloadUrl,
            alt: image.name,
        });
    };

    const doCloseImageModal = () => {
        setImage(null);
    };

    return (
        <div style={!profileState ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
            {profileState ?
                <div class="container rounded bg-white mt-5 mb-5" >
                    <div class="row">
                        <div class="col-md-3 border-right">
                            <div class="d-flex flex-column align-items-center text-center p-3 py-5">
                                {files.downloadUrl || profileState?.avatars.length > 0 ?
                                    <img class="rounded-circle mt-5" width="150px" src={files.downloadUrl || `https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${profileState?.avatars[profileState?.avatars.length - 1].privateUrl}`} />
                                    :
                                    <img class="rounded-circle mt-5" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
                                }
                                <h4 class="font-weight-bold">{profileState?.fullName}</h4><span class="text-black-50">{profileState?.email}</span><span> </span>
                                {profile.tenants[0].roles[0] === "userShopOwner" &&
                                <div>
                                <span className="font-weight-bold">Shops:</span>
                                <ul>
                                {shopownerState?.shops.map(shop=>(
                                    <li>
                                     <Link to={{pathname: `/singleshop/${shop.id}`}}><span className="text-danger">{shop.shopName}</span></Link>
                                    </li>
                                    ))}
                                </ul>
                                <span className="font-weight-bold">Package Subscription:</span>
                                {shopownerState?.shopOwnersPackageSubscription==null?<span className="text-black-50">None <br/><a href="#packages" className="btn btn-outline-secondary mt-2">Browse Packages</a></span> : <span className="text-black-50">{shopownerState.shopOwnersPackageSubscription.advertPackageName}</span>}
                                </div>
                                }
                                </div>
                        </div>
                        <div class="col-md-6 border-right">
                            <div class="p-3 py-5">
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                    <h4 class="text-right">Profile Settings</h4>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <label class="labels">Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder={profileState?.fullName}
                                            onChange={(e) => { setFullName(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-12">
                                        <label class="labels">Mobile Number</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder={profileState?.phoneNumber}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                    </div>
                                    <div class="col-md-12">
                                        <label class="labels">Email ID</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder={profileState?.email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                    {profile.tenants[0].roles[0] === "userMechanic" &&
                                        <div class="col-md-12">
                                            <label class="labels">Location</label>
                                            <input type="text" className="form-control" value={locationName} placeholder={mechanic?.areaOfOperation?.locationName} onChange={(e) => setValue(e.target.value)} />
                                            {status === "OK" && <ul className="list-group">
                                                {
                                                    data.map((suggestion) => {
                                                        const {
                                                            place_id,
                                                            structured_formatting: { main_text, secondary_text },
                                                        } = suggestion;

                                                        return (
                                                            <li className="list-group-item" key={place_id} onClick={onLocationSelect(suggestion)}>
                                                                <strong>{main_text}</strong><small>{` ${secondary_text}`}</small>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>}
                                        </div>}
                                    {/* <div class="col-md-12"><label class="labels">Address Line 1</label><input type="text" class="form-control" value="" /></div>
                            <div class="col-md-12"><label class="labels">Postcode</label><input type="text" class="form-control" value="" /></div>
                            <div class="col-md-12"><label class="labels">State</label><input type="text" class="form-control" value="" /></div>
                            <div class="col-md-12"><label class="labels">Area</label><input type="text" class="form-control" value="" /></div>
                            <div class="col-md-12"><label class="labels">Education</label><input type="text" class="form-control" value="" /></div> */}
                                </div>
                                {/* <div class="row mt-3">
                            <div class="col-md-6"><label class="labels">Country</label><input type="text" class="form-control" value="" /></div>
                            <div class="col-md-6"><label class="labels">State/Region</label><input type="text" class="form-control" value="" /></div>
                        </div> */}
                                {profile.tenants[0].roles[0] === "userMechanic" &&
                                    <div className="form-group">
                                        <label class="labels">Choose a few or all of your specialities</label>
                                        <Select placeholder={"Click to choose"} isMulti onChange={setSelectedServices} value={selectedServices} options={services?.map(service => {
                                            return {
                                                value: service.id,
                                                label: service.servicesName
                                            }
                                        })} />
                                    </div>
                                }
                                <div class="row mt-3">
                                    <ImagesUploaderWrapper>
                                        <label
                                            className="btn btn-outline-secondary px-4 mb-2 ml-3"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className="fas fa-plus"></i>
                                            {' Upload profile picture'}
                                            <input
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                type="file"
                                                onChange={e => handleFileChange(e, "profile")}
                                                ref={input}
                                            />
                                        </label>
                                        {profile.tenants[0].roles[0] === "userMechanic" &&
                                            <>
                                                <label
                                                    className="btn btn-outline-secondary px-4 mb-2 ml-3"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                    {"ID front"}
                                                    <input
                                                        style={{ display: "none" }}
                                                        accept="image/*"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, "idFront")}
                                                        ref={input2}
                                                    />
                                                </label>
                                                <label
                                                    className="btn btn-outline-secondary px-4 mb-2 ml-3"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                    {"ID back"}
                                                    <input
                                                        style={{ display: "none" }}
                                                        accept="image/*"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, "idBack")}
                                                        ref={input3}
                                                    />
                                                </label>
                                            </>
                                        }
                                        {files ? (
                                            <div className="d-flex flex-row flex-wrap">
                                                {files.map((item) => {
                                                    return (
                                                        <div
                                                            className="mr-2 mb-2 img-card"
                                                            style={{ height: "100px" }}
                                                            key={item.id}
                                                        >
                                                            <img
                                                                alt={item.name}
                                                                src={item.downloadUrl}
                                                                className="img-thumbnail"
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />

                                                            <div className="img-buttons rounded-bottom">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link"
                                                                    onClick={() => doPreviewImage(item)}
                                                                >
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link ml-2"
                                                                    onClick={() => handleRemove(item.id)}
                                                                >
                                                                    <i className="fas fa-times"></i>
                                                                </button>
                                                            </div>
                                                            <span style={{
                                                                "marginLeft": "2px",
                                                                "marginRight": "2px",
                                                                "padding": "3px",
                                                                "backgroundColor": "grey",
                                                                "color": "white",
                                                                "borderRadius": "2px"
                                                            }}>{item.type}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                        {image && (
                                            <ImageModal
                                                src={image.src}
                                                alt={image.alt}
                                                onClose={() => doCloseImageModal()}
                                            />
                                        )}
                                    </ImagesUploaderWrapper>
                                </div>
                                {ids && <div style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}>
                                    <span>
                                        <i class="fas fa-check" style={{
                                            color: "green",
                                            marginRight: "5px",
                                        }}></i>
                                        ID's have been uploaded
                                    </span>
                                </div>}
                                <div class="mt-5 text-center">
                                    <LoadingButton
                                        type="button"
                                        className="btn btn-success btn-lg btn-block"
                                        onClick={handleUpdate}
                                        loading={loading}
                                    >
                                        Save Profile
                                    </LoadingButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loader />}
        </div >
    )
}

export default Profile;