import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import axios from 'axios';

export const addPackageOrder = data => dispatch => {
    console.log("Adding Package order " + JSON.stringify(data));
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
    dispatch({
        type: ADD_PACKAGE_ORDER_BEGIN
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/ad-package-order`;
    return axios.post(url, { data }, {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
        }
    }
    ).then(res => {
        console.log("Package order response: " + res)
        dispatch({
            type: ADD_PACKAGE_ORDER_SUCCESS,
            payload: res
        });
        return res;
    }).catch(error => {
        dispatch({
            type: ADD_PACKAGE_ORDER_FAIL,
            payload: { error }
        });
    });
}
export const getPackageOrder = userId => dispatch => {
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();
    dispatch({
        type: GET_PACKAGE_ORDER_BEGIN
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/ad-package-order?filter%5BadOrderOfLoggedInUserId%5D=${userId}&filter%5BadOrderStatus%5D=PAID&offset=0&limit=10`;
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
        }
    }
    ).then(res => {
      console.log("Package Order: " +JSON.stringify(res))
        dispatch({
            type: GET_PACKAGE_ORDER_SUCCESS,
            payload: res
        });
        return res;
    }).catch(error => {
        dispatch({
            type: GET_PACKAGE_ORDER_FAIL,
            payload: { error }
        });
        return error;
    });
}

export const updatePackageOrder = (id,data) => async dispatch => {
    const tenantId = AuthCurrentTenant.get();
    const token_stored = AuthToken.get();
    dispatch({
      type: UPDATE_PACKAGE_ORDER_BEGIN
    });
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/ad-package-order/${id}`;

    const { data: existingData } = await axios.get(webApiUrl, 
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },);

  const res = await axios.put(
    webApiUrl,
    {
      "data": {
            "adOrderBillingFirstName": existingData.adOrderBillingFirstName,
            "adOrderBillingLastName": existingData.adOrderBillingLastName,
            "adOrderForPackageId": existingData.adOrderForPackageId,
            "adOrderOfLoggedInUserId": existingData.adOrderOfLoggedInUserId,
            "adOrderBillingPhoneNumber": existingData.adOrderBillingPhoneNumber,
            "adOrderBillingEmail": existingData.adOrderBillingEmail,
            "adOrderCompanyName": existingData.adOrderCompanyName,
            "adOrderPaymentId":existingData.adOrderPaymentId,
            "adOrderStatus" : data.adOrderStatus,
            "adOrderTotal" : existingData.adOrderTotal,
            "adOrderBillingAdditionalInfo": "",
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("UpdateShopOwner " + JSON.stringify(res));
      dispatch({
        type: UPDATE_PACKAGE_ORDER_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_PACKAGE_ORDER_FAIL,
        payload: { error }
      });
      return res;
    })
  };

export const ADD_PACKAGE_ORDER_BEGIN = "ADD_PACKAGE_ORDER_BEGIN";
export const ADD_PACKAGE_ORDER_SUCCESS = "ADD_PACKAGE_ORDER_SUCCESS";
export const ADD_PACKAGE_ORDER_FAIL = "ADD_PACKAGE_ORDER_FAIL";

export const UPDATE_PACKAGE_ORDER_BEGIN = "UPDATE_PACKAGE_ORDER_BEGIN"
export const UPDATE_PACKAGE_ORDER_SUCCESS = "UPDATE_PACKAGE_ORDER_SUCCESS"
export const UPDATE_PACKAGE_ORDER_FAIL = "UPDATE_PACKAGE_ORDER_FAIL"

export const GET_PACKAGE_ORDER_BEGIN = "GET_PACKAGE_ORDER_BEGIN"
export const GET_PACKAGE_ORDER_SUCCESS = "GET_PACKAGE_ORDER_SUCCESS"
export const GET_PACKAGE_ORDER_FAIL = "GET_PACKAGE_ORDER_FAIL"