 import React, { useState } from "react";
 import jumpTo from "../../modules/Navigation";
 import Validator from "../../utils/Validator";
 import { DEFAULT_RULE, EMAIL_RULE, PHONE_RULE, PASSWORD_RULE } from "../../utils/Validator/rule";
 import PropTypes from "prop-types";
 import { connect } from "react-redux";
 import { shopOwnerRegister } from "../../redux/actions/RegisterAction";
 import LoadingButton from "../LoadingButton";
 import { FormControl, InputGroup } from "react-bootstrap";

   const RegisterShopForm = props =>{
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [userRole] = useState("userShopOwner")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [nameErr, setNameErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [phoneErr, setPhoneErr] = useState(false)
    const [passwordErr, setPasswordErr] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorModal, setErrorModal] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [loading, setLoading] = useState(false)

   const focus = (e) => {
     switch (e.target.name) {
       case "name":
       setNameErr(false);
         break;
       case "phone":
         setPhoneErr(false);
         break;
       case "email":
         setEmailErr(false);
         break;
       case "password":
         setPasswordErr(false);
         break;
       default:
         break;
     }
   }
   const blur = (e) => {
     switch (e.target.name) {
       case "name":
         if (!Validator(e.target.value, DEFAULT_RULE)) {
          setNameErr(true);
         }
         break;
       case "phone":
         if (!Validator(e.target.value, PHONE_RULE)) {
           setPhoneErr(true);
         }
         break;
       case "email":
         if (!Validator(e.target.value, EMAIL_RULE)) {
           setEmailErr(true);
         }
         break;
       case "password":
         if (!Validator(e.target.value, PASSWORD_RULE)) {
           setPasswordErr(true);
         }
         break;
       default:
         break;
     }
   }
 
   const handleSubmit = () => {
    if (
      name !== undefined &&
      phone !== undefined &&
      email !== undefined &&
      userRole !== undefined &&
      password !== undefined &&
      password !== undefined &&
      !nameErr && !emailErr && !phoneErr && !passwordErr
    ) {
      setLoading(true);
      props.shopOwnerRegister(
        name,
        phone,
        email,
        userRole,
        password,
        password,
      )
        .then((res) => {
          setLoading(false);
          jumpTo("/verifyphone");
        })
        .catch((error) => {
          console.log(error.response);
          if(error.response.data.substring(0,6)==="E11000"){
            setErrorMessage("Email has already been registered to another user")
            setLoading(false)
          }else{
            setErrorMessage(error.response.data);
            setLoading(false);
          }
        });
    } else {
      setErrorModal(true);
    }
  };

  const subBtnEnable = () => {
    if (email && password && name && phone) {
      return true;
    } else {
      return false;
    }
  };

  const passClickHandler = () => {
    setShowPass(!showPass);
  };
  
     return (
       <div>
         <div style={{
           "marginTop": "5px",
           "padding": "10px",
           "color": "white",
           "backgroundColor": "grey",
           "borderRadius": "5px",
           "textAlign": "center",
           "fontSize": "20px",
           "fontWeight": "bold"
         }}>{"Register Shop Owner"}</div>
         <div className="login-form mt-3">
           {errorMessage ? <div style={{
             "marginTop": "5px",
             "padding": "5px",
             "color": "white",
             "backgroundColor": "red",
             "borderRadius": "5px",
             "textAlign": "center"
           }}>{errorMessage}</div> : null}
           <div className="form-group mt-3">
             <input
               type="text"
               className="form-control has-error"
               placeholder="Name "
               id="name"
               name="name"
               value={name}
               onChange={e => setName(e.target.value)}
               autoComplete="false"
               style={nameErr ? { "border": "2px solid red" } : null}
               onBlur={e => blur(e)}
               onFocus={e => focus(e)}
             />
             {nameErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: John Doe</span>}
             <i className="fa fa-user"></i>
           </div>
 
           <div className="form-group ">
             <input
               type="text"
               className="form-control"
               placeholder="Phone "
               id="phone"
               name="phone"
               value={phone}
               onChange={e => setPhone(e.target.value)}
               autoComplete="false"
               style={phoneErr ? { "border": "2px solid red" } : null}
               onBlur={e => blur(e)}
               onFocus={e => focus(e)}
             />
             {phoneErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: 0712345678</span>}
             <i className="fa fa-phone"></i>
           </div>
 
           <div className="form-group ">
             <input
               type="text"
               className="form-control"
               placeholder="Email "
               id="email"
               name="email"
               value={email}
               onChange={e => setEmail(e.target.value)}
               autoComplete="false"
               required={true}
               style={emailErr ? { "border": "2px solid red" } : null}
               onBlur={e => blur(e)}
               onFocus={e => focus(e)}
             />
             {emailErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: name@email.com</span>}
             <i className="fa fa-envelope"></i>
           </div>
 
           <div className="form-group log-status">
             <InputGroup>
               <FormControl
                 type={showPass ? "text" : "password"}
                 className="form-control"
                 placeholder="Password"
                 id="password"
                 name="password"
                 value={password}
                 onChange={e => setPassword(e.target.value)}
                 autoComplete="false"
                 style={passwordErr ? { "border": "2px solid red" } : null}
                 onBlur={e => blur(e)}
                 onFocus={e => focus(e)}
               />
               <InputGroup.Append>
                 <InputGroup.Text>
                   <i onClick={passClickHandler} className={showPass ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                 </InputGroup.Text>
               </InputGroup.Append>
             </InputGroup>
             {passwordErr && <span style={{ "fontSize": "15px", "color": "red" }}>Minimum eight characters, at least one capital letter and one number</span>}
           </div>
           <LoadingButton
             type="button"
             className="log-btn"
             loading={loading}
             onClick={() => handleSubmit()}
             disabled={!subBtnEnable()}
           >
             Register
           </LoadingButton>
           <div
             onClick={props.loginClicked}
             style={{
               textAlign: "center",
               fontSize: 12,
               color: "black",
               cursor: "pointer"
             }}
           >
             Already have an account ? Please login.
           </div>
         </div>
       </div>
     );
   }
 
 RegisterShopForm.propTypes = {
   loginClicked: PropTypes.func
 };
 
 const mapDispatchToProps = {
   shopOwnerRegister
 };
 const mapStoreToProps = state => ({
   register_loading: state.register.register_loading,
   register_error: state.register.error
 });
 
 export default connect(mapStoreToProps, mapDispatchToProps)(RegisterShopForm);
 