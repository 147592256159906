import React, {  useRef, useState, useEffect } from "react";
import Validator from "../../utils/Validator";
import { DATE_RULE } from "../../utils/Validator/rule";
import { connect, useDispatch } from "react-redux";
import LoadingButton from "../../components/LoadingButton";
import { getShopOwnerShopProducts, updateShopProductPriority } from "../../redux/actions/shopProductAction";
import Select from 'react-select'
import ValidationModal from "../../components/ValidationModal/ValidationModal";
import { getShopOwner, getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";
import { addAdvert, getAllAdverts } from "../../redux/actions/advertisementAction";
import jumpTo from "../../modules/Navigation";
import { getPackageOrder } from "../../redux/actions/packageOrderAction";
import ShopOwnerPackageValidatonModal from "../../components/ValidationModal/ShopOwnerPackageValidatonModal";

const AddAdvert = props => {
  const [showMessage, setShowMessage] = useState(false)
  const [advertExpiry, setAdvertxpiry] = useState()
  const [advertBelongToShopOwner, setAdvertBelongToShopOwner] = useState()
  const [advertPackage, setAdvertPackage] = useState()
  const [advertPaymentTransactionCode, setAdvertPaymentTransactionCode] = useState()
  const [selectedShopProduct, setSelectedShopProduct] = useState([])
  const [sponsorshipPriority, setSponsorshipPriority] = useState()
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [dateErr, setDateErr] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [productModal, setProductModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  let loggedInUser = JSON.parse(localStorage.getItem("auth"));
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!mounted) {
        dispatch(getShopOwnerIdUsingUserId(loggedInUser.id)).then( async (res) => {
            await dispatch(getShopOwner(res)).then((response) => {
              setAdvertBelongToShopOwner(response.data);
              setAdvertPackage(response.data.shopOwnersPackageSubscription);
              setSponsorshipPriority(response.data.shopOwnersPackageSubscription.advertPackagePriorityLevel);
            })
            await dispatch(getAllAdverts(res))
          }).catch((error) => {
            console.log(error);
          });
    dispatch(getShopOwnerShopProducts(loggedInUser.id));
    dispatch(getPackageOrder(loggedInUser.id)).then((res)=>{
      setAdvertPaymentTransactionCode(res.data.rows[0].adOrderPaymentId);
    })
        setMounted(true)
    }

  }, [dispatch, mounted])

  const focus = (e) => {
    switch (e.target.name) {
      case "advertExpiry":
        setDateErr(false);
        break;
      default:
        break;
    }
  }
  const blur = (e) => {
    switch (e.target.name) {
      case "advertExpiry":
        if (!Validator(e.target.value, DATE_RULE)) {
          setDateErr(true);
        }
        break;
      default:
        break;
    }
  }

   const checkSelectedProduct = async() => {
    const adverts = props.adverts;
     for(let i = 0;i < adverts.length; i++){
      if(selectedShopProduct.value == adverts[i].advertProduct.id){
        setProductModal(true);
      }
    }
  }
 
  const handleSubmit = async () => {
    if (
      advertExpiry !== undefined &&
      advertPackage !== undefined &&
      sponsorshipPriority !== undefined &&
      advertPaymentTransactionCode !== undefined &&
      selectedShopProduct.value !== undefined &&
      !dateErr) {
        setLoading(true);
        const adverts = props.adverts; 
        let productModalFlag= false;
        for(let i = 0;i < adverts.length; i++){
          if(selectedShopProduct.value == adverts[i].advertProduct.id){
            setProductModal(true);
            productModalFlag = true;
          }       
        }
        let selectedshopproduct = selectedShopProduct.value
        let data = {
          selectedshopproduct,
          advertBelongToShopOwner,
          advertExpiry,
          advertPackage,
          sponsorshipPriority,
          advertPaymentTransactionCode,
        }
        if(!productModalFlag){
          await dispatch(updateShopProductPriority(selectedshopproduct,data))
          await dispatch(addAdvert(data))
          setLoading(false);
          jumpTo("/manageadverts");
        }          
    } else {
        setErrorModal(true);
    }
};

  return (

    <div className="" >
      <div className="container mt-5">
        <div className="row mt-5" style={{"paddingTop":"50px", "alignItems": "center" ,"justifyContent": "center"}}>
          <div className="col-md-6 text-center mt-3">
            <span style={{ "display": "flex", "marginLeft": "20%" }}>
              <h2 className="display-5 text-black">Set up Promotion</h2>
            </span>
            <div className="login-form mt-3">
              <div className="form-group">
                <Select placeholder={"Choose Product to Promote"} onChange={setSelectedShopProduct} options={props.shopproducts?.map(shopproduct => {
                  return {
                    value: shopproduct.id,
                    label: shopproduct.productName
                  }
                })} />
              </div>
              </div>
              <div className="form-group">
                    <input
                    className="form-control"
                    type="date"
                    name="advertExpiry"
                    placeholder="Advert Expiry Date"
                    value={advertExpiry}
                    onBlur={e => blur(e)} onFocus={e => focus(e)}
                    onChange={e => setAdvertxpiry(e.target.value)}
                    style={dateErr ? { "border": "2px solid red" } : null}
                    {...dateErr && <span style={{ "fontSize": "15px", "color": "red" }}>Wrong format</span>}
                    required
                    />
                    </div>
              <div style={{ "marginBottom": "20px" }}>
                {errorMessage && <span
                  style={{
                    "padding": "10px",
                    "backgroundColor": "red",
                    "color": "white",
                  }}
                >{errorMessage}</span>}</div>
              <ShopOwnerPackageValidatonModal show={productModal} onHide={setProductModal} title={`Product Already Advertised`} message={
                `The Product you Have Selected has already been advertised. You can freely extend the product advertisement at the Manage Adverts Page`
              }  link={`/manageadverts`} button={`Go to Advertisements Page`}/>
              <ValidationModal show={errorModal} onHide={setErrorModal} message={
                `Please fill in all the required fields correctly`
              } />
              <LoadingButton
                  type="button"
                  className="log-btn"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Post Advert
                </LoadingButton>
             
            </div>
          </div>
        </div>
      </div>
  );
}

const mapDispatchToProps = {
  addAdvert,
  updateShopProductPriority,
  getShopOwnerShopProducts,
  getShopOwner,
  getPackageOrder,
  getAllAdverts,
};
const mapStoreToProps = (state) => ({
    shopproducts: state.shopproduct.shopproducts,
    shopowner: state.shopowner.shopowner,
    packageorder: state.packageorder.packageorder,
    adverts: state.advert.adverts
});

export default connect(mapStoreToProps, mapDispatchToProps)(AddAdvert);