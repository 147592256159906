/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllCars,
  applyFilters
} from "../../redux/actions/carAction";
import { connect } from "react-redux";
import Car from "./Car";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  cars: state.car.cars,
  departments: state.department.departments,
  loading: state.car.loading
});
const mapDispatchToProps = dispatch => ({
  getAllCars: () => dispatch(getAllCars()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(Car);
