/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
  GET_ALL_CARMODELS_BEGIN,
  GET_ALL_CARMODELS_SUCCESS,
  GET_ALL_CARMODELS_FAIL,
  GET_CARMODEL_BEGIN,
  GET_CARMODEL_SUCCESS,
  GET_CARMODEL_FAIL,
  GET_CARMODELS_BY_CATEGORY_BEGIN,
  GET_CARMODELS_BY_CATEGORY_SUCCESS,
  GET_CARMODELS_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL
} from "../actions/carModelsAction";

const initialState = {
  carmodels: null,
  carmodel: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CARMODELS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_CARMODELS_SUCCESS:
      return {
        ...state,
        loading: false,
        carmodels: action.payload.data.rows
      };
    case GET_ALL_CARMODELS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_CARMODEL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CARMODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        carmodel: action.payload.data
      };
    case GET_CARMODEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_CARMODELS_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CARMODELS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        carmodels: action.payload.data.carmodels
      };
    case GET_CARMODELS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        carmodels: action.payload.data.carmodels
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        carmodels: action.payload.data.carmodels
      };
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    default:
      return state;
  }
};
