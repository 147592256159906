/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import API from "../../axioss/API";

import axios from "axios";
import { AuthToken } from "../../axioss/modules/Auth/authToken";
import AuthCurrentTenant from "../../axioss/modules/Auth/authCurrentTenant";
import { tenantSubdomain } from '../../axioss/tenant/tenantSubdomain';

export const addGarage =
  (data) =>
    async (dispatch) => {
      // console.log("garageOwnedByMechanicXX "+garageOwnedByMechanic);
      dispatch({
        type: ADD_GARAGES_BEGIN,
      });

      const tenantId = AuthCurrentTenant.get();
      const token_stored = AuthToken.get();

      let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/garage`;

      let locationData = {
        locationName: data.garageLocationName,
        locationDescription: data.garageLocationDesc,
        coordinates: data.coordinates,
      }

      const { data: { _id } } = await axios.post(
        `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/location`, {
        data: locationData
      },
        {
          headers: {
            "Authorization": `Bearer ${token_stored}`
          },
        },
      );
      if (_id) {
        return await axios
          .post(
            webApiUrl,
            {
              data: {
                garageName: data.garageName,
                garageOwnedByMechanic: data.garageOwnedByMechanic,
                garageDescription: data.garageDescription,
                garageLocation: _id,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token_stored}`,
              },
            }
          )
          .then((res) => {
            console.log("AddGarageVV " + JSON.stringify(res));
            dispatch({
              type: ADD_GARAGE_SUCCESS,
              payload: res,
            });
            return res;
          })
          .catch((error) => {
            console.error("Error", error);
            dispatch({
              type: ADD_GARAGE_FAIL,
              payload: { error },
            });
            return error;
          });
      }
    };

export const getAllGarages = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_GARAGES_BEGIN,
  });

  //const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  //  console.log("GarageTenantId "+tenantId);

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/garage`;

  //  console.log("GarageUrl "+webApiUrl);

  await axios
    .get(webApiUrl, {
      headers: {
        Authorization: `Bearer ${token_stored}`,
      },
    })
    .then((res) => {
      console.log("MaGarages " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_GARAGES_SUCCESS,
        payload: res,
      });
    })
    .catch((error) => {
      console.error("Error", error);
      dispatch({
        type: GET_ALL_GARAGES_FAIL,
        payload: { error },
      });
    });
};

export const getAllLoggedInUserGarages =
  (loggedInUserID) => async (dispatch) => {
    console.log("GetGaragesForLoggedInUserID " + loggedInUserID);
    dispatch({
      type: GET_ALL_GARAGES_BEGIN,
    });

    //const invitationToken = undefined; //Todo

    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();

    //  console.log("GarageTenantId "+tenantId);

    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/garage?filter%5BgarageOwnedByMechanic%5D=${loggedInUserID}`;

    //  console.log("GarageUrl "+webApiUrl);

    await axios
      .get(webApiUrl, {
        headers: {
          Authorization: `Bearer ${token_stored}`,
        },
      })
      .then((res) => {
        console.log("MaGarages " + JSON.stringify(res));
        dispatch({
          type: GET_ALL_GARAGES_SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        console.error("Error", error);
        dispatch({
          type: GET_ALL_GARAGES_FAIL,
          payload: { error },
        });
      });
  };

export const getGarage = (id) => (dispatch) => {
  dispatch({
    type: GET_GARAGE_BEGIN,
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/garage/${id}`;

  return axios
    .get(webApiUrl, {
      headers: {
        Authorization: `Bearer ${token_stored}`,
      },
    })
    .then((res) => {
      console.log("Garago " + JSON.stringify(res));
      dispatch({
        type: GET_GARAGE_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_GARAGE_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const updateGarage =
  (id, garageName, garageDescription, garageOwnedByMechanic, garageLocation) =>
    async (dispatch) => {
      dispatch({
        type: GET_GARAGE_BEGIN,
      });

      const token_stored = AuthToken.get();
      const tenantId = AuthCurrentTenant.get();

      let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/garage/${id}`;

      await axios
        .put(
          webApiUrl,
          {
            data: {
              garageName: garageName,
              garageOwnedByMechanic: garageOwnedByMechanic,
              garageDescription: garageDescription,
              garageLocation: garageLocation,
              tenantId: tenantSubdomain.isSubdomain
                ? AuthCurrentTenant.get()
                : undefined,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token_stored}`,
            },
          }
        )
        .then((res) => {
          console.log("AddGarageVV " + JSON.stringify(res));
          dispatch({
            type: ADD_GARAGE_SUCCESS,
            payload: res,
          });
        })
        .catch((error) => {
          console.error("Error", error);
          dispatch({
            type: ADD_GARAGE_FAIL,
            payload: { error },
          });
        });
    };

export const getGaragesByCategory = (c) => (dispatch) => {
  dispatch({
    type: GET_GARAGES_BY_CATEGORY_BEGIN,
  });
  return API({
    method: "GET",
    url: `/products?category=${c}`,
  })
    .then((res) => {
      dispatch({
        type: GET_GARAGES_BY_CATEGORY_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: GET_GARAGES_BY_CATEGORY_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const search = (text) => (dispatch) => {
  dispatch({
    type: SEARCH_BEGIN,
  });
  return API({
    method: "GET",
    url: `/search?query=${text}`,
  })
    .then((res) => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const applyFilters = (filter_string) => (dispatch) => {
  dispatch({
    type: APPLY_FILTERS_BEGIN,
  });
  return API({
    method: "GET",
    url: `/products?${filter_string}`,
  })
    .then((res) => {
      dispatch({
        type: APPLY_FILTERS_SUCCESS,
        payload: res,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: APPLY_FILTERS_FAIL,
        payload: { error },
      });
      return error;
    });
};

export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_GARAGES_BEGIN = "GET_ALL_GARAGE_BEGIN";
export const GET_ALL_GARAGES_SUCCESS = "GET_ALL_GARAGES_SUCCESS";
export const GET_ALL_GARAGES_FAIL = "GET_ALL_GARAGES_FAIL";

export const GET_GARAGE_BEGIN = "GET_GARAGE_BEGIN";
export const GET_GARAGE_SUCCESS = "GET_GARAGE_SUCCESS";
export const GET_GARAGE_FAIL = "GET_GARAGE_FAIL";

export const GET_GARAGES_BY_CATEGORY_BEGIN = "GET_GARAGES_BY_CATEGORY_BEGIN";
export const GET_GARAGES_BY_CATEGORY_SUCCESS =
  "GET_GARAGES_BY_CATEGORY_SUCCESS";
export const GET_GARAGES_BY_CATEGORY_FAIL = "GET_GARAGES_BY_CATEGORY_FAIL";

export const ADD_GARAGES_BEGIN = "ADD_GARAGES_BEGIN";
export const ADD_GARAGE_SUCCESS = "ADD_GARAGE_SUCCESS";
export const ADD_GARAGE_FAIL = "ADD_GARAGE_FAIL";
