import React from "react";
import { Modal } from "react-bootstrap";

const DeleteConfirmationModal = ({ show, onHide, message, onClose, onConfirm}) => {
    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            onClose={() => onClose()}
            onConfirm={() => onConfirm()}
            centered
        >
            <Modal.Header>
            <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body centered>
                {message ? <span>{message}</span> : <strong>Are you sure you want to Delete?</strong>}
                <div className="m-auto">
                <button className="btn-outline-danger mr-2" onClick={onConfirm}>Delete</button>
                <button className="btn-outline-success" onClick={onClose}>Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default DeleteConfirmationModal;