/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import AuthUserToken from "../../modules/Auth";
import EmptyCart from "../../assets/images/emptyCart.png";
import jumpTo from "../../modules/Navigation";
import Loader from "../Loader/Loader";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
class HomeCartView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
    };
  }

  componentDidMount() {
    if (loggedInUser) {
      this.props.getActiveRequests();
    }
  }
  handleCancel = (id) => {
    let entry = this.props.requests.find(request => request.id === id);
    Object.assign(entry, { status: "Cancelled" });
    this.props.cancelRequest(entry);
  }

  goToChechout = () => {
    jumpTo("/checkout");
  };
  CartList = ({ requests, cancel }) => {
    let totalPrice = 0;
    if (requests) {
      totalPrice = requests.reduce((total, request) => {
        return total + request.affects.servicePrice;
      }, 0);
    }
    return <>
      {requests.map((order) => {
        return (
          <div key={order.id} className="basket--item">
            {/* <div className="basket--item--img">
              <img src={items[id].item.imagePath} alt="Image goes here" />
            </div> */}
            <div className="basket--item--details" style={{ "width": "90%" }}>
              <div className="basket--item--title">
                {order.affects.servicesName}
              </div>
              <div>
                {order.requestForCar?.carRegNumber}
              </div>
              <div className="basket--item--quantity">
                Quantity: <span>1</span>
              </div>
              <div className="basket--item--price">
                {" "}
                Price: <span>KSh.{order.affects.servicePrice}</span>
              </div>
            </div>
            <div>
              <Button variant="danger" size="sm" onClick={(e) => {
                e.preventDefault();
                cancel(order._id);
              }}><i class="fa fa-trash" aria-hidden="true"></i></Button>
            </div>
          </div>
        );
      })}
      <div className="total--price-container">
        <h3 style={{ textAlign: "center" }}>
          Total: <span style={{ color: "#E40D0D" }}>KSh.{totalPrice}</span>{" "}
        </h3>
        <button
          className="btn btn-wide log-btn"
          style={{ marginTop: 20 }}
          onClick={() => this.goToChechout()}
        >
          Checkout
        </button>
      </div>
    </>
  }
  render() {

    return (
      <Modal {...this.props} className="right">
        <Modal.Header closeButton>
          <Modal.Title>Your Cart</Modal.Title>
          {this.props.requests ? (
            <span className="checkout--btn" onClick={() => this.goToChechout()}>
              checkout{" "}
            </span>
          ) : <span></span>}
        </Modal.Header>
        <Modal.Body className="modal-body-content">
          {AuthUserToken.getUserDetails() !== undefined &&
            AuthUserToken.getUserDetails() !== null ? (
            <div>
              {this.props.requests ? <div>
                {this.props.requests ? <this.CartList requests={this.props.requests} cancel={this.handleCancel} /> : (
                  <div className="empty--basket">
                    <img src={EmptyCart} className="img-fluid" />
                    <h4 style={{ textAlign: "center" }}>Empty cart</h4>
                  </div>
                )} </div>
                :
                <>
                  {loggedInUser ? <Loader /> :
                    <>
                      <span
                        style={{ "padding": "7px", "backgroundColor": "grey", "fontSize": "20px", "color": "white", "margin": "auto", "display": "block", "textAlign": "center" }}
                      >Please log in</span>
                      <div className="empty--basket">
                        <img src={EmptyCart} className="img-fluid" />
                        <h4 style={{ textAlign: "center" }}>Empty cart</h4>
                      </div>
                    </>
                  }
                </>
              }
            </div>
          ) : (
            <div className="empty--basket">
              <h4>Please login to view cart</h4>
            </div>
          )}
        </Modal.Body>
      </Modal >
    );
  }
}

export default HomeCartView;