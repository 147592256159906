import React from "react";
import { Modal } from "react-bootstrap";

const ShopOwnerPackageValidatonModal = ({ show, onHide, message, title, link, button }) => {
    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            centered
        >
            <Modal.Header closeButton>
                {title && <Modal.Title>{title}</Modal.Title>}
            </Modal.Header>
            <Modal.Body centered>
                {message ? <span>{message}</span> : <strong>No Package Detected</strong>}
                <div>
                {link &&<a href={link} className="btn btn-outline-secondary mt-2">{button}</a>}
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ShopOwnerPackageValidatonModal;