/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import React, { Component } from "react";
 import LoginRegister from "../../components/LoginRegisterModal";
 import Loader from "../../components/Loader/Loader";
 import MyAdverts from "../../components/Advertisement/MyAdverts";
 import jumpTo from "../../modules/Navigation";
 import Heading from "../../components/Heading";
import ShopOwnerPackageValidatonModal from "../../components/ValidationModal/ShopOwnerPackageValidatonModal";


let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class AdvertList extends Component {
   constructor(props) {
     super(props);
     this.state = {
       data: null,
       modalShow: false,
       admodalShow:false,
       adlimitmodal:false,
       login: true,
       shopOwnerId:''
     };
   }
   
  componentDidMount() {
     this.props.getShopOwnerIdUsingUserId(loggedInUser.id).then( async (res) => {
      await this.props.getShopOwner(res);
      await this.setState({shopOwnerId: res});
      await this.props.startAdvertExpiryChecker();
      await this.props.getAllAdverts(this.state.shopOwnerId);
     }).catch((error) => {
       console.log(error);
     });
     
   }
 
   /*async componentDidUpdate(prevProps){
    const today = new Date();
     const {adverts} = this.props;
    if(adverts&& adverts !==prevProps.adverts){
     for(let i = 0; i < adverts.length;){
      if(adverts[i].advertExpiry < today){
        let data = {
          advertExpiry : adverts[i].advertExpiry,
          advertStatus: "Expired",
          sponsorshipPriority: 0,
        }
         await this.props.updateAdvert(adverts[i]._id,data);
         await this.props.updateShopProductPriority(adverts[i]._id,data);
         await this.props.getAllAdverts(this.state.shopOwnerId);
      }
     }
   }
  }*/
   showHideModal = () => {
     this.setState({ modalShow: false });
   };

   hideAdModal = () => {
    this.setState({ admodalShow: false });
    this.setState({ adlimitmodal: false });
  };

   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };

   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };

   getShopOwnerId=async(userId)=>{
    await this.getShopOwnerIdUsingUserId(userId)
   }

   advertiseButtonHandler =() =>{
    const {shopowner} = this.props;
    const advertCount = shopowner.shopOwnersAdverts.length;
    const advertLimit = shopowner.shopOwnersPackageSubscription.advertPackageNoOfProducts;
    if(shopowner.shopOwnersPackageSubscription == null){
      this.setState({admodalShow: true});
    }else{
        if(advertCount < advertLimit){
          jumpTo(`/addadvert`);
        }else{
          this.setState({adlimitmodal: true});
          }
        }
    }

   render() {
     const { adverts } = this.props;
     return (
       <div className="container"  style={!adverts ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        <div className="advert-title">
          <Heading title="Your Promotions" data-aos="fade-up" />
            <button className="btn btn-outline-secondary ml-2 mt-3" onClick={() => this.advertiseButtonHandler()}>Promote Products</button>
        </div>
         {adverts ?
           <>
             <br />
             {adverts ? (
               <MyAdverts
                 adverts={adverts}
               />
             ) : <div><h2>You Have No Products being Advertised</h2></div>}
             <ShopOwnerPackageValidatonModal show={this.state.adlimitmodal} onHide={() => this.hideAdModal()} title={`Advertisement Limit Reached`} message={
                `You have exceeded the limit of advertisements for your package subscription. You can delete them or wait for expiry.`
              }  link={`/manageadverts`} button={`Manage Advertisements`}/>
              <ShopOwnerPackageValidatonModal show={this.state.admodalShow} onHide={() => this.hideAdModal()} title={`Package Subscription Required!`} message={
                `You are not subscribed to any Package subscription. Please ensure you purchase a subscription inorder to advertise your products.`
              } link={`/#packages`} button={`Go to Packages`} />
             <LoginRegister
               show={this.state.modalShow}
               login={this.state.login}
               registerClicked={() => this.registerClicked()}
               loginClicked={() => this.loginClicked()}
               onHide={() => this.showHideModal()}
             /> </> :
           <Loader />}
       </div>
     );
   }
 }
 
 export default AdvertList;
 