/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { VERIFICATION_BEGIN, VERIFICATION_SUCCESS, VERIFICATION_FAIL } from "../actions/verifyPhoneAction";

const initialState = {
  login_loading: false,
  error: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_BEGIN:
      return {
        ...state,
        signin_loading: true
      };
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        signin_loading: false
      };
    case VERIFICATION_FAIL:
      return {
        ...state,
        signin_loading: false,
        error: action.payload.error.response.data
      };
    default:
      return state;
  }
};
