/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import Reset from "./ResetForm";
import "./style.css";
import PropTypes from "prop-types";
import RegisterShopForm from "./RegisterShopForm";
class LoginRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showlogin: this.props.showlogin,
      reset: false
    };
  }
  renderReset = () => {
    this.setState({ reset: true });
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="loginModal"
        className="modal fade login"
      >
        <Modal.Body>
          <div className="modal--close--button" onClick={this.props.onHide}>
            <i className="fas fa-times"></i>
          </div>
          {!this.state.reset ? this.props.login ? (
            <LoginForm registerClicked={() => this.props.registerClicked()} reset={this.renderReset} />
          ) : (this.props.registershopowner ?
            <RegisterShopForm loginClicked={() => this.props.loginClicked()} /> : <RegisterForm loginClicked={() => this.props.loginClicked()} />
          ) : <Reset loginClicked={() => this.setState({ reset: !this.state.reset })} registerClicked={() => { this.setState({ reset: !this.state.reset }); this.props.registerClicked(); }} />}
          
        </Modal.Body>
      </Modal>
    );
  }
}
LoginRegister.propTypes = {
  login: PropTypes.bool,
  registerClicked: PropTypes.func,
  loginClicked: PropTypes.func
};
export default LoginRegister;
