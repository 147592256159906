/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import CircleSpinner from "../../../components/Spinner/Circle";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container">
        {this.props.matches ? (
          <div class="sidebar_section">
            <div class="sidebar_title">
              <h5>Services offered</h5>
            </div>
            <ul class="sidebar_categories">
              {this.props.services && this.props.services.map((service) => {
                return (
                  <li class="active">
                    <Link to={{
                      pathname: `/single-service/${service.id}`,
                      state: { service: service.id, serviceName: service.servicesName }
                    }}>
                      <a>
                        <span>
                          <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        {service.servicesName}
                      </a>
                    </Link>
                  </li>)
              })}
            </ul>
          </div>
        ) : (
          <div className="mt-5">
            <div>
              <h5>Services offered</h5>
            </div>
            {!this.props.loading ? <ul>
              {this.props.services && this.props.services.map((service) => {
                return (
                  <li class="active">
                    <Link to={{
                      pathname: `/single-service/${service.id}`,
                      state: { service: service.id, serviceName: service.servicesName }
                    }}
                      style={{
                        "color": "red",
                      }}
                    >
                      <span>
                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                      </span>
                      {service.servicesName}
                    </Link>
                  </li>)
              })}
            </ul> : <CircleSpinner message="Loading" />}
          </div>
        )}
      </div>)
  }
}

export default Filter;
