import React, { useRef, useState, useEffect } from "react";
import { ScrollView } from "@cantonjs/react-scroll-view";
import Validator from "../../utils/Validator";
import { DEFAULT_RULE, EMAIL_RULE, PASSWORD_RULE, PHONE_RULE } from "../../utils/Validator/rule";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mechanicRegister } from "../../redux/actions/RegisterAction";
import LoadingButton from "../../components/LoadingButton";
import { getAllServices } from "../../redux/actions/serviceAction";
import { FormControl, InputGroup } from "react-bootstrap";
import Storage from "../../security/storage";
import ImagesUploaderWrapper from "../../components/Wrappers/ImagesUploaderWrapper";
import ImageModal from "../../components/ImageModal/ImageModal";
import { uploadFile } from "../../utils/fileUploader";
import Select from 'react-select'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import jumpTo from "../../modules/Navigation";
import ValidationModal from "../../components/ValidationModal/ValidationModal";

const MechRegister = props => {
  const input = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const [isHidden, setisHidden] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [userRole] = useState("userMechanic")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [idno, setIdno] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const [selectedServices, setSelectedServices] = useState([])
  const [ifiles, setiFiles] = useState([])
  const [files, setFiles] = useState([])
  const [image, setImage] = useState("")
  const [loading, setLoading] = useState(false)
  const [storage] = useState(Storage.values)
  const [mounted, setMounted] = useState(false)
  const [coordinates, setCoordinates] = React.useState()
  const [LocationName, setLocationName] = React.useState();
  const [locationDescription, setLocationDescription] = React.useState();
  const [nameErr, setNameErr] = useState(false)
  const [emailErr, setEmailErr] = useState(false)
  const [phoneErr, setPhoneErr] = useState(false)
  const [passwordErr, setPasswordErr] = useState(false)
  const [idErr, setIdErr] = useState(false)
  const [locationErr, setLocationErr] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [showPass, setShowPass] = useState(false)
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )
  const [curr, setCurr] = useState(0)

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);
  const {
    // ready,
    value: locationName,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
    },
    debounce: 300,
  })

  useEffect(() => {
    if (!mounted) {
      props.getAllServices();
    }
    setMounted(true)
  }, [mounted, props])

  const focus = (e) => {
    switch (e.target.name) {
      case "name":
        setNameErr(false);
        break;
      case "phone":
        setPhoneErr(false);
        break;
      case "email":
        setEmailErr(false);
        break;
      case "password":
        setPasswordErr(false);
        break;
      case "idno":
        setIdErr(false);
        break;
      case "location":
        setLocationErr(false);
        break;
      default:
        break;
    }
  }
  const blur = (e) => {
    switch (e.target.name) {
      case "name":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
          setNameErr(true);
        }
        break;
      case "phone":
        if (!Validator(e.target.value, PHONE_RULE)) {
          setPhoneErr(true);
        }
        break;
      case "email":
        if (!Validator(e.target.value, EMAIL_RULE)) {
          setEmailErr(true);
        }
        break;
      case "password":
        if (!Validator(e.target.value, PASSWORD_RULE)) {
          setPasswordErr(true);
        }
        break;
      case "idno":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
          setIdErr(true);
        }
        break;
      case "location":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
          setLocationErr(true);
        }
        break;

      default:
        break;
    }
  }
  const onLocationSelect = ({ description, structured_formatting: { main_text } }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    setLocationName(description);
    setLocationDescription(main_text);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setCoordinates([lat, lng].join(","))
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };
  const handleEndReached = () => {
    console.log("load more");
  };

  const isChecked = () => {
    setisHidden(!isHidden);
    setShowMessage(false);
  };

  const onButtonClickHandler = () => {
    setShowMessage(!showMessage);
  };
  const passClickHandler = () => {
    setShowPass(!showPass);
  }

  const handleSubmit = () => {
    if (
      name !== undefined &&
      phone !== undefined &&
      email !== undefined &&
      userRole !== undefined &&
      password !== undefined &&
      password !== undefined &&
      idno !== undefined &&
      files.length > 0 &&
      locationName !== undefined &&
      locationDescription !== undefined &&
      coordinates !== undefined &&
      selectedServices.length > 0 &&
      !nameErr && !emailErr && !phoneErr && !passwordErr && !idErr && !locationErr
    ) {
      setLoading(true);
      let selected = selectedServices.map(item => item.value)
      props.mechanicRegister(
        name,
        phone,
        email,
        userRole,
        password,
        password,
        selected,
        idno,
        files,
        locationName,
        locationDescription,
        coordinates
      )
        .then((res) => {
          setLoading(false);
          jumpTo("/verifyphone");
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMessage(error.response.data);
          setLoading(false);
        });
    } else {
      setErrorModal(true);
    }
  };


  const handleFileChange = async (event) => {
    event.preventDefault();
    try {
      const upload = event.target.files;
      const name = event.target.name;

      if (!upload || !upload.length) {
        return;
      }

      let file = upload[0];

      setiFiles([...ifiles, file])
      console.log("handleChange:file", file);
      // FileUploader.validate(file, {
      //     storage: props.storage,
      //     image: true,
      // });

      let res;
      if (name === "mechanic") {
        res = await uploadFile(file, {
          storage: storage.mechanicImages,
          image: true,
        });
      } else {
        res = await uploadFile(file, {
          storage: storage.identificationDocumentIdImages,
          image: true,
        });
      }
      console.log("handleChange:res", res);
      res.type = name;

      input.current.value = "";
      input2.current.value = "";
      input3.current.value = "";
      setFiles([...files, res]);
    } catch (error) {
      input.current.value = "";
      input2.current.value = "";
      input3.current.value = "";
      console.error(error);
    }
  };
  const handleRemove = (id) => {
    const list = files.filter((item) => item.id !== id);
    setFiles(list);
  };
  const doPreviewImage = (image) => {
    setImage({
      src: image.downloadUrl,
      alt: image.name,
    });
  };

  const doCloseImageModal = () => {
    setImage(null);
  };

  return (

    <div className="">
      <div className="container mt-5">
        <div className="row mt-5">
          {matches && <Terms handleEnd={handleEndReached} />}
          <div className="col-md-6 text-center mt-3">
            <span style={{ "display": "flex", "marginLeft": "20%" }}>
              <span className="icon-check_circle display-4 text-success">
                <i className="fas fa-user"></i>
              </span>
              <h2 className="display-5 text-black">Register</h2>
            </span>
            <div style={{
              "marginTop": "5px",
              "padding": "5px",
              "color": "white",
              "backgroundColor": "grey",
              "borderRadius": "5px",
              "textAlign": "center",
              "fontSize": "14px",
              "fontWeight": "bold"
            }}>{"Welcome to a world of opportunity."}</div>
            <div className="login-form mt-3">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name "
                  id="name"
                  name="name"
                  value={name}
                  style={nameErr ? { "border": "2px solid red" } : null}
                  onChange={e => setName(e.target.value)}
                  autoComplete="false"
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {nameErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: John Doe</span>}
                <i className="fa fa-user"></i>
              </div>
              <div className="form-group">
                <input
                  type="idno"
                  className="form-control"
                  placeholder="Identification Number"
                  id="idno"
                  name="idno"
                  value={idno}
                  onChange={e => setIdno(e.target.value)}
                  autoComplete="false"
                  style={idErr ? { "border": "2px solid red" } : null}
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {idErr && <span style={{ "fontSize": "15px", "color": "red" }}>Enter your ID number.</span>}
                <i className="fa fa-hashtag"></i>
              </div>
              <div className="form-group">
                <ImagesUploaderWrapper>
                  <label
                    className="btn btn-outline-secondary px-4 mb-2 ml-3"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fas fa-plus"></i>
                    {"ID front"}
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      name="front"
                      onChange={(e) => handleFileChange(e)}
                      ref={input}
                    />
                  </label>
                  <label
                    className="btn btn-outline-secondary px-4 mb-2 ml-3"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fas fa-plus"></i>
                    {"ID back"}
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      name="back"
                      onChange={(e) => handleFileChange(e)}
                      ref={input2}
                      disabled={files.length !== 1}
                    />
                  </label>
                  <label
                    className="btn btn-outline-secondary px-4 mb-2 ml-3"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fas fa-plus"></i>
                    {" Mechanic/Business Image"}
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      name="mechanic"
                      onChange={(e) => handleFileChange(e)}
                      ref={input3}
                    // disabled={files.length !== 2}
                    />
                  </label>
                  {files ? (
                    <div className="d-flex flex-row flex-wrap">
                      {files.map((item) => {
                        return (
                          <div
                            className="mr-2 mb-2 img-card"
                            style={{ height: "100px" }}
                            key={item.id}
                          >
                            <img
                              alt={item.name}
                              src={item.downloadUrl}
                              className="img-thumbnail"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />

                            <div className="img-buttons rounded-bottom">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => doPreviewImage(item)}
                              >
                                <i className="fas fa-search"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-link ml-2"
                                onClick={() => handleRemove(item.id)}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  {image && (
                    <ImageModal
                      src={image.src}
                      alt={image.alt}
                      onClose={() => doCloseImageModal()}
                    />
                  )}
                </ImagesUploaderWrapper>
              </div>
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone "
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  autoComplete="false"
                  style={phoneErr ? { "border": "2px solid red" } : null}
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {phoneErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: 0712345678</span>}
                <i className="fa fa-phone"></i>
              </div>
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email "
                  id="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  autoComplete="false"
                  style={emailErr ? { "border": "2px solid red" } : null}
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {emailErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: name@email.com</span>}
                <i className="fa fa-envelope"></i>
              </div>
              <div className="form-group log-status">
                <InputGroup>
                  <FormControl
                    type={showPass ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="false"
                    style={passwordErr ? { "border": "2px solid red" } : null}
                    onBlur={e => blur(e)}
                    onFocus={e => focus(e)}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i onClick={passClickHandler} className={showPass ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                {passwordErr && <span style={{ "fontSize": "15px", "color": "red" }}>Minimum eight characters, at least one capital letter and one number</span>}
              </div>
              <div className="form-group">
                <input type="text" name="location" className="form-control" value={locationName} placeholder="Area of Operation"
                  onChange={(e) => setValue(e.target.value)}
                  style={locationErr ? { "border": "2px solid red" } : null}
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {locationErr && <span style={{ "fontSize": "15px", "color": "red" }}>Please select a location</span>}
                <i className="fa fa-map-marker"></i>
                {status === "OK" && <ul className="list-group">
                  {
                    data.map((suggestion) => {
                      const {
                        place_id,
                        structured_formatting: { main_text, secondary_text },
                      } = suggestion;

                      return (
                        <li className="list-group-item" key={place_id} onClick={onLocationSelect(suggestion)}>
                          <strong>{main_text}</strong><small>{` ${secondary_text}`}</small>
                        </li>
                      );
                    })
                  }
                </ul>}
              </div>
              <div className="form-group">
                <span>Choose a few or all of your specialities</span>
                <Select placeholder={"Click to choose"} isMulti onChange={setSelectedServices} options={props.services?.map(service => {
                  return {
                    value: service.id,
                    label: service.servicesName
                  }
                })} />
              </div>
              <div className="check">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="termschkbx"
                  onChange={isChecked}
                  required="required"
                />
                <span><b> I agree to Terms & Conditions.</b></span>
                <br />
                <br />
              </div>
              <div style={{ "marginBottom": "20px" }}>
                {errorMessage && <span
                  style={{
                    "padding": "10px",
                    "backgroundColor": "red",
                    "color": "white",
                  }}
                >{errorMessage}</span>}</div>
              <ValidationModal show={errorModal} onHide={setErrorModal} message={
                `Please fill in all the required fields correctly. Ensure that you have uploaded at least the business image.`
              } />
              {isHidden ? (
                <LoadingButton
                  type="button"
                  className="log-btn"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Register
                </LoadingButton>
              ) : (
                <LoadingButton
                  style={{
                    background: "#AEAEAE",
                    color: "#ffffff",
                    margin: "2%",
                  }}
                  type="button"
                  className="btn"
                  loading={loading}
                  onClick={onButtonClickHandler}
                >
                  Register
                </LoadingButton>
              )}

              {showMessage && (
                <p style={{ color: "red" }}>
                  Accept Terms & Conditions to continue
                </p>
              )}
              <div
                onClick={props.loginClicked}
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Already have an account ? Please login.
              </div>
            </div>
            <div className="mt-5">
              {!matches && <Terms handleEnd={handleEndReached} />}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

MechRegister.propTypes = {
  loginClicked: PropTypes.func,
};

const mapDispatchToProps = {
  mechanicRegister,
  getAllServices,
};
const mapStoreToProps = (state) => ({
  register_loading: state.register.register_loading,
  register_error: state.register.error,
  services: state.service.services,
});

export default connect(mapStoreToProps, mapDispatchToProps)(MechRegister);

const Terms = ({ handleEnd }) => {
  return (
    <div className="col-md-6 text-center">
      <ScrollView
        onEndReached={handleEnd}
        style={{ height: "140vh" }}
      >
        <h1>Terms and conditions</h1>
        <div id="content" class="site-content" role="main">
          <article
            id="post-108"
            class="post-108 page type-page status-publish hentry"
          >
            <header class="entry-header">
              <h1 class="entry-title">Terms of Service</h1>
            </header>

            <div class="entry-content">
              <p>
                <strong>MECH WANGU </strong>
              </p>
              <p>
                <strong>
                  FULL TERMS OF ENGAGEMENT WITH SERVICE PROVIDERS{" "}
                </strong>
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <ol>
                <li>
                  <strong>General</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                This document constitutes the terms, conditions and
                notices (the "Terms of Engagement") between Mech Wangu
                Ltd (its successors in title and permitted assigns) who
                owns and operates{" "}
                <a href="https://mechwangu.co.ke">
                  https://mechwangu.co.ke
                </a>{" "}
                (&lsquo;the Website&rsquo;) and services providers
                offering services on the Website. For purposes of the
                terms of engagement service providers include mechanics,
                car repair garages, car wash providers and all persons
                offering any service on the Mech Wangu Website.
              </p>
              <p>&nbsp;</p>
              <p>
                This document governs your relationship with Mech Wangu
                -{" "}
                <a href="https://mechwangu.co.ke">
                  https://mechwangu.co.ke
                </a>{" "}
                and all its derivatives (&ldquo;Website&rdquo;). Access
                to and use of this Website, login portal, and all other
                services available through this Website (collectively,
                the "Services") are subject to these terms of
                engagement. By using the Services, you are agreeing to
                all of the Terms of engagement, as may be updated by us
                from time to time. You should check this page regularly
                to take notice of any changes we may have made to the
                Terms of Service.
              </p>
              <p>&nbsp;</p>
              <p>
                Access to this Website is permitted on a temporary
                basis, and we reserve the right to withdraw or amend the
                Services without notice. We will not be liable if for
                any reason this Website is unavailable at any time or
                for any period. From time to time, we may restrict
                access to some parts or all of the Website. We may
                permanently restrict your access to any or all parts of
                the website.
              </p>
              <p>&nbsp;</p>
              <p>
                This Website may contain links to other websites (the
                "Linked Sites"), which are not operated by Mech Wangu -
                https://mechwangu.co.ke. Mech Wangu has no control over
                the Linked Sites and accepts no responsibility for them
                or for any loss or damage that may arise from your use
                of them. Your use of the Linked Sites will be subject to
                the terms of use and service contained within each such
                site.
              </p>
              <p>&nbsp;</p>
              <ol start="2">
                <li>
                  <strong>Prohibitions</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                You must not misuse this Website. You will not: commit
                or encourage a criminal offense; transmit or distribute
                a virus, trojan, worm, logic bomb or any other material
                which is malicious, technologically harmful, in breach
                of confidence or in any way offensive or obscene; hack
                into any aspect of the Service; corrupt data; cause
                annoyance to other users; infringe upon the rights of
                any other person's proprietary rights; send any
                unsolicited advertising or promotional material,
                commonly referred to as "spam"; attempt to affect the
                performance or functionality of any computer facilities
                of or accessed through this Website, or in engage in any
                nature of cybercriminal activities. If we receive any
                reports of you misusing the Website in any way we will
                permanently suspend your account. At all times,
                breaching this provision would constitute a criminal
                offense and Mech Wangu will report any such breach to
                the relevant law enforcement authorities and disclose
                your identity to them.
              </p>
              <p>&nbsp;</p>
              <p>
                We will not be liable for any loss or damage caused by a
                distributed denial-of-service attack, viruses or other
                technologically harmful material that may infect your
                computer equipment, computer programs, data or other
                proprietary material due to your use of this Website or
                to your downloading of any material posted on it, or on
                any website linked to it.
              </p>
              <p>&nbsp;</p>
              <p>
                We will not be liable for any loss suffered through
                interactions between yourself and other users of the
                website. It is your responsibility to protect yourself
                against any unlawful and illegal activities carried out
                by any persons through the Website.
              </p>
              <p>&nbsp;</p>
              <ol start="3">
                <li>
                  <strong>Our contract </strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <ul>
                <li>Requirements to use Mech Wangu</li>
              </ul>
              <p>&nbsp;</p>
              <p>
                To contract with Mech Wangu (through registration and
                creating an account) you must be over 18 years of age.
                At registration you must provide a copy of either, a
                Kenyan national ID or a copy of business/company
                registration document. If you provide any details of a
                business name or registered body as the service provider
                you undertake that you are an authorized person in the
                entity. Mech Wangu retains the right to refuse you to
                use any of its services upon review of documents
                provided.
              </p>
              <p>&nbsp;</p>
              <p>
                You undertake that all details you provide to Mech Wangu
                and users of Mech Wangu are true and accurate. Mech
                Wangu will reject use of the website should any details
                given be false, misleading or inaccurate. Mech Wangu
                will also reserve the right to institute any legal
                action against you for providing false, inaccurate, or
                misleading information through the website.
              </p>
              <p>&nbsp;</p>
              <p>
                Mech Wangu does not act as either agent or principal
                between yourself and car owners or users contracted
                through the Website. The contract for offering services
                is made between yourself and users and you will be
                required to comply with the terms agreed between you as
                the service provider and the car owners. Mech Wangu will
                not be liable for any illegality, unlawful behaviour,
                negligence, fraudulent misrepresentation,
                misrepresentation as to a fundamental matter or any
                other liability occasioned by any of the car owners that
                you contract through the Website.
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>Commission charged for using the Website&nbsp;</li>
              </ul>
              <p>&nbsp;</p>
              <p>
                Mech Wangu will charge a 10% commission on all payments
                made by car owners to service providers for services
                contracted through the website. The commission will be
                collected by Mech Wangu upfront from all users
                contacting mechanics through Mech Wangu.
              </p>
              <p>&nbsp;</p>
              <p>
                The commission will be collected when a car owner
                requests for a service. Upon request for a service, they
                will be required to pay an initial deposit (the
                commission) upfront before contacting any service
                provider. Upon payment of the deposit (the commission)
                the car owner will contact the service provider to
                receive a full quote for the services ordered. The car
                owner should pay the service provider the quoted amount
                less the deposit paid through the website.
              </p>
              <p>&nbsp;</p>
              <p>
                Any service provider who requires a car owner to refund
                the commission will be in breach of these terms of
                engagement. Mech Wangu reserves the right to remove such
                a service provider from the website.
              </p>
              <p>&nbsp;</p>
              <p>
                When car owner places an order for a service Mech Wangu
                will receive a notification. At all times Mech Wangu
                will only consider the services contained in this
                acknowledgement as the services contracted for with the
                car owner. Should a service provider offer any
                additional services you will be required to notify Mech
                Wangu and an appropriate commission will be charged from
                the payment made by the car owner to you for the
                service. Any service provider who breaches this term may
                be removed from the website.
              </p>
              <p>&nbsp;</p>
              <ul>
                <li>Description of services offered</li>
              </ul>
              <p>&nbsp;</p>
              <p>
                A service provider will be required to state all
                services they are competent to offer to car owners. The
                Website provides many types of services that a service
                provider can choose. At all times it is the
                responsibility of the service provider to ensure that
                they are competent and available to offer the services
                that they indicate that they are capable of offering.
              </p>
              <p>&nbsp;</p>
              <p>
                Should any service provider make any false, misleading,
                fraudulent or inaccurate information in this regard they
                will be in breach of these terms of engagement. Upon
                notification of the breach Mech Wangu will remove the
                service provider from the Website and will reserve the
                right to seek any indemnity for breach of these terms.
                Giving any false, misleading or inaccurate description
                of services that a service provider indicates they can
                offer will constitute a prohibited activity under clause
                2 of these terms.
              </p>
              <p>&nbsp;</p>
              <ol start="4">
                <li>
                  <strong>Privacy Policy</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/privacy-policy/">
                  https://connect.mechwangu.co.ke/privacy-policy/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="5">
                <li>
                  <strong>
                    Intellectual Property, Software and Content
                  </strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="6">
                <li>
                  <strong>Disclaimer of Liability</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="7">
                <li>
                  <strong>Linking to this Website</strong>
                </li>
              </ol>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="8">
                <li>
                  <strong>
                    Disclaimer as to ownership of trade marks, images of
                    personalities and third party copyright
                  </strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="9">
                <li>
                  <strong>Indemnity</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <ol start="10">
                <li>
                  <strong>Variation</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <ol start="11">
                <li>
                  <strong>Invalidity</strong>
                </li>
              </ol>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="12">
                <li>
                  <strong>Complaints</strong>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="13">
                <li>
                  <strong>Waiver</strong>
                </li>
              </ol>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
              <ol start="14">
                <li>
                  <strong>Entire Agreement</strong>
                </li>
              </ol>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <p>
                Consult the general terms and conditions of use at{" "}
                <a href="https://connect.mechwangu.co.ke/terms/">
                  https://connect.mechwangu.co.ke/terms/
                </a>
              </p>
              <p>&nbsp;</p>
            </div>
          </article>
        </div>
      </ScrollView>
      <br />
    </div>
  )
}