/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    getPaymentToken,
    initiateMpesa,
    queryMpesa,
} from "../../redux/actions/checkoutAction"
import { connect } from "react-redux";
import PackageCheckout from "./PackageCheckout";
import { getAllPackages, getPackage } from "../../redux/actions/packageAction";
import { addAdvertPayment } from "../../redux/actions/advertPaymentAction";
import { getShopOwnerIdUsingUserId, updateShopOwnerPackage } from "../../redux/actions/ShopOwnerAction";
import { addPackageOrder, updatePackageOrder } from "../../redux/actions/packageOrderAction";

const mapStoreToProps = state => ({
    tokens: state.checkout.tokens,
    adpackages: state.adpackage.adpackages,
    adpackage: state.adpackage.adpackage,
    packageorder: state.packageorder.packageorder,
    shopowner: state.shopowner.shopowner,
    response: state.checkout.response,
});
const mapDispatchToProps = dispatch => ({
    getPaymentToken: () => dispatch(getPaymentToken()),
    addPackageOrder: (order) => dispatch(addPackageOrder(order)),
    addAdvertPayment: (data) => dispatch(addAdvertPayment(data)),
    getAllPackages: () => dispatch(getAllPackages()),
    getPackage: (id) => dispatch(getPackage(id)),
    initiateMpesa: (data) => dispatch(initiateMpesa(data)),
    queryMpesa: (data) => dispatch(queryMpesa(data)),
    getShopOwnerIdUsingUserId: (userId) =>dispatch(getShopOwnerIdUsingUserId(userId)),
    updateShopOwnerPackage: (id, data) =>dispatch(updateShopOwnerPackage(id, data)),
    updatePackageOrder: (id, data) =>dispatch(updatePackageOrder(id, data)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(PackageCheckout);
