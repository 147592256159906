/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    ADD_ORDER_BEGIN,
    ADD_ORDER_SUCCESS,
    ADD_ORDER_FAIL,
    UPDATE_ORDER_BEGIN,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL,
    GET_ORDER_BEGIN,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAIL,
} from "../actions/orderAction";

const initialState = {
    loading: false,
    error: null,
    order: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        case UPDATE_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        case GET_ORDER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                order: action.payload.data.rows[0]
            };
        case GET_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        default:
            return state;
    }
};