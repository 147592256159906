import {
    ADD_ADVERTPAYMENT_BEGIN,
    ADD_ADVERTPAYMENT_SUCCESS,
    ADD_ADVERTPAYMENT_FAIL,
    UPDATE_ADVERTPAYMENT_BEGIN,
    UPDATE_ADVERTPAYMENT_SUCCESS,
    UPDATE_ADVERTPAYMENT_FAIL,
    GET_ADVERTPAYMENT_BEGIN,
    GET_ADVERTPAYMENT_SUCCESS,
    GET_ADVERTPAYMENT_FAIL,
} from "../actions/advertPaymentAction";

const initialState = {
    loading: false,
    error: null,
    advertpayment: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ADVERTPAYMENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ADD_ADVERTPAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ADD_ADVERTPAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        {/*case UPDATE_ADVERTPAYMENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case UPDATE_ADVERTPAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_ADVERTPAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };*/}
        case GET_ADVERTPAYMENT_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_ADVERTPAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                advertpayment: action.payload.data.rows[0]
            };
        case GET_ADVERTPAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error.response.data
            };
        default:
            return state;
    }
};