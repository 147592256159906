import React, { Component, PropTypes } from 'react'
import {
    Circle
} from 'better-react-spinkit';

class CircleSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // Inline props override the contextual settings.
        return (
            <div style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center"
            }}>
                <Circle color="red" />
                <span
                    style={{
                        "marginTop": "7px",
                        "marginLeft": "5px",
                    }}
                >{this.props.message}</span>
            </div>
        )
    }
}
export default CircleSpinner;
