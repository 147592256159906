import axios from "axios";
import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthCurrentTenant from "../../axioss/modules/Auth/authCurrentTenant";
import { AuthToken } from "../../axioss/modules/Auth/authToken";
import Validator from "../../utils/Validator";
import { PHONE_RULE, PASSWORD_RULE } from "../../utils/Validator/rule";
import LoadingButton from "../LoadingButton";

const Reset = (props) => {
    const [phone, setPhone] = React.useState();
    const [phoneErr, setPhoneErr] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [resetCode, setResetCode] = React.useState();
    const [guess, setGuess] = React.useState();
    const [resetCodeErr, setResetCodeErr] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [password, setPassword] = React.useState();
    const [passwordErr, setPasswordErr] = React.useState(false);
    const [showPass, setShowPass] = React.useState(false);
    const [accErr, setAccErr] = React.useState(false);
    const focus = (e) => {
        switch (e.target.name) {
            case "phone":
                setPhoneErr(false);
                break;
            case "password":
                setPasswordErr(false);
                break;
            default:
                break;
        }
    }
    const blur = (e) => {
        switch (e.target.name) {
            case "phone":
                if (!Validator(e.target.value, PHONE_RULE)) {
                    setPhoneErr(true);
                }
                break;
            case "password":
                if (!Validator(e.target.value, PASSWORD_RULE)) {
                    setPhoneErr(true);
                }
                break;
            default:
                break;
        }
    }
    const sendResetCode = async (phone) => {
        const token_stored = AuthToken.get();
        const tenantId = AuthCurrentTenant.get();

        setLoading(true);
        await axios.post("https://mapi.mechwangu.co.ke/api/auth/reset-code", {
            phoneNumber: phone,
            tenantId
        }, {
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        }).then(res => {
            setLoading(false);
            if (res.data.ok) {
                setResetCode(res.data.token);
            }
        }).catch(error => {
            setLoading(false);
            setAccErr(true);
        });
    }

    const verifyResetCode = (code, guess) => {
        setLoading(true);
        if (code === guess) {
            setLoading(false);
            setVerified(true);
        } else {
            setLoading(false);
            setResetCodeErr(true);
        }
    }
    const submitReset = async (password) => {
        const token_stored = AuthToken.get();
        setLoading(true);
        await axios.put("https://mapi.mechwangu.co.ke/api/auth/password-reset", {
            password,
            token: resetCode
        }, {
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        }).then(res => {
            setLoading(false);
            props.loginClicked();
        });
    }

    const passClickHandler = () => {
        setShowPass(!showPass);
    }


    return (
        <>
            <div style={{
                "marginTop": "5px",
                "padding": "10px",
                "color": "white",
                "backgroundColor": "grey",
                "borderRadius": "5px",
                "textAlign": "center",
                "fontSize": "20px",
                "fontWeight": "bold"
            }}>{"Reset Password"}</div>
            {accErr &&
                <Link
                    onClick={() => props.registerClicked()}
                >
                    <div
                        style={{
                            "marginTop": "5px",
                            "padding": "5px",
                            "color": "white",
                            "backgroundColor": "red",
                            "borderRadius": "5px",
                            "textAlign": "center",
                            "fontSize": "12px",
                        }}

                    >{`Account doesn't exist, click to sign up?`}
                    </div>
                </Link>}
            {!verified ? !resetCode ? <>
                <div className="mt-3">
                    <span>Please enter your registered phone number:</span>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="07xxxxxxxx"
                            onChange={(e) => setPhone(e.target.value)}
                        ></FormControl>
                        <InputGroup.Text>
                            <i className="fa fa-phone"></i>
                        </InputGroup.Text>
                    </InputGroup>
                </div>
                <Link
                    className="link"
                    onClick={() => props.loginClicked()}
                    style={{ color: 'red' }}
                >
                    back to login
                </Link>
                <LoadingButton
                    type="button"
                    className="log-btn"
                    loading={loading}
                    onClick={(e) => {
                        e.preventDefault();
                        sendResetCode(phone)
                    }}
                >
                    Send Reset Code
                </LoadingButton>

            </> :
                <>
                    <div>
                        <span>Please enter the code you received:</span>
                        <div className="form-group ">
                            <input
                                type="text"
                                placeholder="xxxx"
                                className="form-control"
                                id="reset"
                                name="reset"
                                onChange={(e) => setGuess(e.target.value)}
                            />
                        </div>
                    </div>
                    <LoadingButton
                        type="button"
                        className="log-btn"
                        loading={loading}
                        onClick={(e) => { e.preventDefault(); verifyResetCode(resetCode, guess) }}
                    >
                        Verify
                    </LoadingButton>
                </>
                : <>
                    <div>
                        <span>Enter new password</span>
                        <InputGroup className="mb-3">
                            <FormControl
                                type={showPass ? "text" : "password"}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <i onClick={passClickHandler} className={showPass ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <LoadingButton
                        type="button"
                        className="log-btn"
                        loading={loading}
                        onClick={(e) => { e.preventDefault(); submitReset(password) }}
                    >
                        Reset
                    </LoadingButton>
                </>
            }
        </>
    )
}
export default Reset;