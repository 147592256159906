/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    getMechanicsUsingSpecialityServiceId
} from "../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import SingleService from "./SingleService"

const mapStoreToProps = state => ({
    mechanics: state.mechanic.mechanics,
    departments: state.department.departments,
    loading: state.mechanic.loading
});
const mapDispatchToProps = dispatch => ({
    getMechanicsUsingSpecialityServiceId: id => dispatch(getMechanicsUsingSpecialityServiceId(id))
});

export default connect(mapStoreToProps, mapDispatchToProps)(SingleService);
