/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */
  import React from "react";
  import mechanicDefaultProfile from "../../assets/images/mechwangu_default_prof.svg";
  import { useState } from "react";
  import { useDispatch } from "react-redux";
  import jumpTo from "../../modules/Navigation";
  import { deleteShopProduct } from "../../redux/actions/shopProductAction";
  import DeleteConfirmationModal from "../ValidationModal/DeleteConfirmationModal";
 
 function ManageShopProductCard(props) {
    const { shopproductItem } = props;
    const [modalShow, setModalShow] = useState(true);
    const [shopProductIdToDelete, setShopProductIdToDelete] = useState(null);
    const dispatch = useDispatch();
    const OpenDeleteConfirmModal = (id) => {
          setShopProductIdToDelete(id);
        };
    const CloseDeleteConfirmModal = () => {
          setShopProductIdToDelete(null);
        };
    const Delete = async (id) => {
          CloseDeleteConfirmModal();
          await dispatch(deleteShopProduct(id));
          window.location.reload();
          
        };

   let productImage = shopproductItem.productImage[0];
   // console.log("ProductId: "+shopproductItem._id);
   return (
     <div className="shop-product-item">
       <div
         className="shop-product discount product_filter"
       >
         <div className="product_image">
         {productImage ?
                <img src={`https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${productImage.privateUrl}`} alt=""
                  className="img-fluid"
                  style={{
                    borderRadius: "24px"
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = mechanicDefaultProfile;
                  }}
                /> :
                <img src={mechanicDefaultProfile} alt="" className="img-fluid" />
              }
         </div>
         <div className="product_info">
           <h6 className="product_name">
            <div>{shopproductItem.productName}</div> 
           </h6>
           <div className="meta product_category">{shopproductItem.productOfCategory.categoryName}</div>
           <div className="product_of_shop">{shopproductItem.productOfShop.shopName}</div>
            <div className="product_price">
             KSh. {shopproductItem.productPrice}
             {/*<span> KSh. {(parseFloat(shopproductItem.price) + 30).toFixed(2)}</span>*/}
           </div>
         </div>
       </div>
       <div
         className="red_button add_to_cart_button mb-2"
         onClick={() =>jumpTo(`/singleshopproduct/${shopproductItem._id}`)}
       > 
         <div style={{ color: "#ffffff" }}>View Product</div>
       </div>
       <div
         className="red_button add_to_cart_button"
         onClick={() => OpenDeleteConfirmModal(shopproductItem.id)}
       > 
         <div style={{ color: "#ffffff" }}>Delete</div>
       </div>
       {shopProductIdToDelete && (
              <DeleteConfirmationModal
                  show={modalShow}
                  onHide={setModalShow}
                  message={"Are you sure you want to delete this Product?"}
                  onConfirm={() => Delete(shopProductIdToDelete)}
                  onClose={() => CloseDeleteConfirmModal()}
                />
            )}
     </div>

   );
 }
 
 export default ManageShopProductCard;
 