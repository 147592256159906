/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { getAllLoggedInUserGarages } from "../../redux/actions/garageAction";
import { getMechanicIdUsingUserId } from "../../redux/actions/mechanicAction";
import Auth from "../../modules/Auth";
import MyGarages from "../../components/Garage/MyGarages";
import PropTypes from "prop-types";
import LoginRegister from "../../components/LoginRegisterModal";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class Garages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true,
      redirect: false
    };
    this.addToBag = this.addToBag.bind(this);
  }

  componentDidMount() {
    this.props.getMechanicIdUsingUserId(loggedInUser.id).then(res => {
      console.log("LoggedInMechIs " + res);
      this.props.getAllLoggedInUserGarages(res);
    }).catch(error => {
      console.log(error);
    });
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  addToBag = params => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      let cart = this.props.postCart(params);
      cart.then(res => {
        console.log(res);
      });
    } else {
      this.setState({ modalShow: true });
    }
  };

  render() {
    const { garages } = this.props;

    if (!loggedInUser) {
      return <Redirect to='/' />;
    }

    return (
      <div>
        <br />
        {/* <CarCategoryBanner /> */}
        {garages ? (
          <MyGarages
            garages={garages}
          />
        ) : null}
        {/* <Benefit /> */}
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

Garages.propTypes = {
  forgotPasswordClicked: PropTypes.func,
  registerClicked: PropTypes.func
};

const mapDispatchToProps = {
  getAllLoggedInUserGarages,
  getMechanicIdUsingUserId
};
const mapStoreToProps = state => ({
  login_loading: state.login.login_loading,
  login_error: state.login.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(Garages);
