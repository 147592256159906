/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import NavBar from "./NavBar";
const mapStoreToProps = state => ({
  cart: state.cart.cart
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStoreToProps, mapDispatchToProps)(NavBar);
