/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

export const addClientRequest = (requestForCar, requestDetail, affects, mechanicId) => async dispatch => {
  // console.log("carRegNumberXX "+carRegNumber);
  dispatch({
    type: ADD_MECHANIC_BEGIN
  });
  console.log("aCR:mechanicId " + mechanicId);

  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
  const token_stored = AuthToken.get();

  // let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic`;
  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic`;


  return await axios.post(
    webApiUrl,
    {
      "data": {
        "requestForCar": requestForCar,
        "requestDetail": requestDetail,
        "affects": affects,
        "status": "Active",
        "mechanicId": mechanicId
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
  ).then(res => {
    console.log("AddCar " + JSON.stringify(res));
    dispatch({
      type: ADD_MECHANIC_SUCCESS,
      payload: res
    });
    return res.data;
  }).catch(error => {
    console.error("Error", error)
    dispatch({
      type: ADD_MECHANIC_FAIL,
      payload: { error }
    });
  })
};

export const getAllRequests = () => async dispatch => {
  dispatch({
    type: GET_ALL_REQUESTS_BEGIN
  });

  const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  //  console.log("RequestTenantId "+tenantId);

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic`;


  //  console.log("RequestUrl "+webApiUrl);

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("Marequests " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_REQUESTS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_REQUESTS_FAIL,
        payload: { error }
      });
    })
};
export const getActiveRequests = () => async dispatch => {
  dispatch({
    type: GET_ALL_ACTIVE_REQUESTS_BEGIN
  });

  const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  //  console.log("RequestTenantId "+tenantId);

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic?filter%5Bstatus%5D=Active&filter%5BcreatedBy%5D=${loggedInUser.id}`;

  //  console.log("RequestUrl "+webApiUrl);

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      //console.log("Marequests " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_ACTIVE_REQUESTS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_ACTIVE_REQUESTS_FAIL,
        payload: { error }
      });
    })
};

export const updateRequest = data => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic/${data.id}`;

  return await axios.put(webApiUrl, { data }, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("urequests " + JSON.stringify(res));
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_REQUEST_FAIL,
        payload: { error }
      });
      return error;
    });
}

export const getRequest = id => async dispatch => {
  dispatch({
    type: GET_REQUEST_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic/${id}`;

  const request = await axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Kirequests " + JSON.stringify(res));
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_REQUEST_FAIL,
        payload: { error }
      });
      return error;
    });
  console.log("Request " + JSON.stringify(request));
  const reqId = await request.data.id;
  const orderStatus = await axios.get(`https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-order/${reqId}`, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  })
  console.log("Order Status " + JSON.stringify(orderStatus));
  return await axios.get(`https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/user/${request.data.mechanicId.mechanicUserId}`, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }).then(user => {
    request.data.mechanicId.mechanicUserId = user.data;
    request.data.paymentStatus = orderStatus.data.orderStatus;
    dispatch({
      type: GET_REQUEST_SUCCESS,
      payload: request
    })
  }).catch(error => {
    console.error("Error", error)
  });
};

export const getExpRequest = id => async dispatch => {
  dispatch({
    type: GET_REQUEST_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic/${id}`;

  const request = await axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Kirequests " + JSON.stringify(res));
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_REQUEST_FAIL,
        payload: { error }
      });
      return error;
    });
  console.log("Request " + JSON.stringify(request));
  return await axios.get(`https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/user/${request.data.mechanicId.mechanicUserId}`, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }).then(user => {
    request.data.mechanicId.mechanicUserId = user.data;
    dispatch({
      type: GET_REQUEST_SUCCESS,
      payload: request
    })
  }).catch(error => {
    console.error("Error", error)
  });
};

export const getRequestsByMechanicId = id => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  dispatch({
    type: GET_MECH_REQUESTS_BEGIN
  });
  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic?filter%5Bstatus%5D=Complete&filter%5BmechanicId%5D=${id}`;

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      dispatch({
        type: GET_MECH_REQUESTS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      dispatch({
        type: GET_MECH_REQUESTS_FAIL,
        payload: { error }
      });
    })

}

export const cancelRequest = data => dispatch => {
  console.log("Cancel Action called with id " + data.id);
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();
  dispatch({
    type: REMOVE_REQUEST_BEGIN
  })

  let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-request-to-mechanic/${data.id}`;
  return axios.put(url, {
    data
  }, {
    headers: {
      "Authorization": `Bearer ${token_stored}`,
    }
  }
  ).then(res => {
    dispatch({
      type: REMOVE_REQUEST_SUCCESS,
      payload: res
    });
    dispatch(getActiveRequests());
    return res;
  })
    .catch(error => {
      dispatch({
        type: REMOVE_REQUEST_FAIL,
        payload: { error }
      });
      return error;
    });
}

export const respondToRequest = data => async dispatch => {
  console.log("responseData: ", data)
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();
  dispatch({
    type: RESPOND_REQUEST_BEGIN
  })
  let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic-response-to-client-request`

  return await axios.post(url, {
    data
  }, {
    headers: {
      "Authorization": `Bearer ${token_stored}`,
    }
  }
  ).then(res => {
    dispatch({
      type: RESPOND_REQUEST_SUCCESS,
      payload: res
    })
  }).catch(err => {
    dispatch({
      type: RESPOND_REQUEST_FAIL,
      payload: { err }
    })
  })
}
export const getResponse = id => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
  dispatch({
    type: RESPOND_REQUEST_BEGIN
  })
  let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/mechanic-response-to-client-request?filter%5BresponseToClientRequestId%5D=${id}`

  return await axios.get(url, {
    headers: {
      "Authorization": `Bearer ${token_stored}`,
    }
  }
  ).then(res => {
    dispatch({
      type: RESPOND_REQUEST_SUCCESS,
      payload: res
    })
  }).catch(err => {
    dispatch({
      type: RESPOND_REQUEST_FAIL,
      payload: { err }
    })
  })
}
export const postRating = data => async dispatch => {
  const token_stored = AuthToken.get();
  // const tenantId = AuthCurrentTenant.get();
  dispatch({
    type: POST_RATING_BEGIN
  })

  let url = `https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/review-of-mechanic`

  return await axios.post(url, { data }, {
    headers: {
      "Authorization": `Bearer ${token_stored}`,
    }
  }
  ).then(res => {
    dispatch({
      type: POST_RATING_SUCCESS,
      payload: res
    })
    return res;
  }).catch(err => {
    dispatch({
      type: POST_RATING_FAIL,
      payload: { err }
    })
    console.log("Rating Error", err)
  })
}
export const sendInvite = data => async dispatch => {
  const token_stored = AuthToken.get();
  // const tenantId = AuthCurrentTenant.get();

  let url = `https://mapi.mechwangu.co.ke/api/auth/send-invite`

  return await axios.post(url, { data }, {
    headers: {
      "Authorization": `Bearer ${token_stored}`,
    }
  }
  ).then(res => {
    return res;
  }).catch(err => {
    console.log("Rating Error", err)
  })
}
export const getRating = data => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
  dispatch({
    type: GET_RATING_BEGIN
  })

  let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/review-of-mechanic?filter%5BreviewOfMechanic%5D=${data.mech}&filter%5BbyCustomer%5D=${data.customer}`

  return await axios.get(url, {
    headers: {
      "Authorization": `Bearer ${token_stored}`,
    }
  }
  ).then(res => {
    dispatch({
      type: GET_RATING_SUCCESS,
      payload: res
    })
    return res.data.rows;
  }).catch(err => {
    dispatch({
      type: GET_RATING_FAIL,
      payload: { err }
    })
    console.log("Rating Error", err)
  })
}

export const getRequestsByCategory = c => dispatch => {
  dispatch({
    type: GET_REQUESTS_BY_CATEGORY_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?category=${c}`
  })
    .then(res => {
      dispatch({
        type: GET_REQUESTS_BY_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_REQUESTS_BY_CATEGORY_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const search = text => dispatch => {
  dispatch({
    type: SEARCH_BEGIN
  });
  return API({
    method: "GET",
    url: `/search?query=${text}`
  })
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: SEARCH_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const applyFilters = filter_string => dispatch => {
  dispatch({
    type: APPLY_FILTERS_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?${filter_string}`
  })
    .then(res => {
      dispatch({
        type: APPLY_FILTERS_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: APPLY_FILTERS_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const GET_RATING_BEGIN = "GET_RATING_BEGIN";
export const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";
export const GET_RATING_FAIL = "GET_RATING_FAIL";

export const POST_RATING_BEGIN = "POST_RATING_BEGIN";
export const POST_RATING_SUCCESS = "POST_RATING_SUCCESS";
export const POST_RATING_FAIL = "POST_RATING_FAIL";

export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_REQUESTS_BEGIN = "GET_ALL_REQUEST_BEGIN";
export const GET_ALL_REQUESTS_SUCCESS = "GET_ALL_REQUESTS_SUCCESS";
export const GET_ALL_REQUESTS_FAIL = "GET_ALL_REQUESTS_FAIL";

export const GET_ALL_ACTIVE_REQUESTS_BEGIN = "GET_ALL_ACTIVE_REQUEST_BEGIN";
export const GET_ALL_ACTIVE_REQUESTS_SUCCESS = "GET_ALL_ACTIVE_REQUESTS_SUCCESS";
export const GET_ALL_ACTIVE_REQUESTS_FAIL = "GET_ALL_ACTIVE_REQUESTS_FAIL";

export const GET_REQUEST_BEGIN = "GET_REQUEST_BEGIN";
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS";
export const GET_REQUEST_FAIL = "GET_REQUEST_FAIL";

export const REMOVE_REQUEST_BEGIN = "REMOVE_REQUEST_BEGIN";
export const REMOVE_REQUEST_SUCCESS = "REMOVE_REQUEST_SUCCESS";
export const REMOVE_REQUEST_FAIL = "REMOVE_REQUEST_FAIL";

export const GET_REQUESTS_BY_CATEGORY_BEGIN = "GET_REQUESTS_BY_CATEGORY_BEGIN";
export const GET_REQUESTS_BY_CATEGORY_SUCCESS = "GET_REQUESTS_BY_CATEGORY_SUCCESS";
export const GET_REQUESTS_BY_CATEGORY_FAIL = "GET_REQUESTS_BY_CATEGORY_FAIL";

export const ADD_MECHANIC_BEGIN = "ADD_MECHANIC_BEGIN";
export const ADD_MECHANIC_SUCCESS = "ADD_MECHANIC_SUCCESS";
export const ADD_MECHANIC_FAIL = "ADD_MECHANIC_FAIL";

export const GET_MECH_REQUESTS_BEGIN = "GET_MECH_REQUESTS_BEGIN";
export const GET_MECH_REQUESTS_SUCCESS = "GET_MECH_REQUESTS_SUCCESS";
export const GET_MECH_REQUESTS_FAIL = "GET_MECH_REQUESTS_FAIL";

export const RESPOND_REQUEST_BEGIN = "RESPOND_REQUEST_BEGIN";
export const RESPOND_REQUEST_SUCCESS = "RESPOND_REQUEST_SUCCESS";
export const RESPOND_REQUEST_FAIL = "RESPOND_REQUEST_FAIL";


