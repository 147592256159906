/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import React, { Component } from "react";
 import LoginRegister from "../../components/LoginRegisterModal";
 import { Link } from "react-router-dom";
 import Loader from "../../components/Loader/Loader";
 
 class SinglePackageView extends Component {
   constructor(props) {
     super(props);
     this.state = {
       color: "",
       size: "",
       pic: "",
       selectedSize: "",
       id: "",
       quantity: 1,
       modalShow: false,
       login: true,
       matches: window.matchMedia("(min-width: 768px)").matches
     };
   }
   componentDidMount() {
     this.props.getPackage(this.props.location.pathname.split("/").slice(-1)[0]);
   }
 
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
 
 
   render() {
     console.log("Locationzz" + JSON.stringify(this.props.adpackage));
     return (
       <div style={!this.props.adpackage ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
         <div className="container single_product_container">
           {this.props.adpackage ? (
             <div>
               <div className="row">
                 <div className="col">
                   <div className="breadcrumbs d-flex flex-row align-items-center">
                     <ul>
                       <li>
                         <a href="/">Home</a>
                       </li>
                       <li>
                         <a href="/shoplist">
                           <i className="fa fa-angle-right" aria-hidden="true"></i>
                           Shops
                         </a>
                       </li>
                       <li className="active">
                         <a href="#">
                           <i className="fa fa-angle-right" aria-hidden="true"></i>
                           {this.props.adpackage.advertPackageName}
                         </a>
                       </li>
                     </ul>
                   </div>
                 </div>
               </div>
 
               <div className="row">
                 <div className="col-lg-12 text-center">
                   <div className="product_details">
                     <div className="product_details_title">
                       <h2>{this.props.adpackage.advertPackageName}</h2>
                       <p><span className="text-danger">Package ID: </span>{this.props.adpackage._id}</p>
                       <p><span className="text-danger">Package Description: </span>{this.props.adpackage.advertPackageDescription}</p>
                       <p><span className="text-danger">Package Details: </span></p> 
                       <ul>
                        <li>
                          <p><span className="text-danger">Number of Products: </span>{this.props.adpackage.advertPackageNoOfProducts}</p>
                        </li>
                        <li>
                          <p><span className="text-danger">Package Duration: </span>{this.props.adpackage.advertPackageDuration} Hours</p>
                        </li>
                        <li>
                          <p><span className="text-danger">Advert Radius Distance: </span>{this.props.adpackage.advertPackageRadiusDistance} Kilometers</p>
                        </li>
                       </ul>
                       <p><span className="text-danger">Package Cost: </span></p>
                       <h4>Kes {this.props.adpackage.advertPackageCostOfPackage}</h4>
                     </div>
                     <Link to={{
                       pathname: `/packagecheckout/${this.props.adpackage._id}`,
                       state: {
                         adpackage: this.props.adpackage,
                       }
                     }}>
                       <div className="red_button product-add_to_cart_button align-items-center justify-content-center">
                         <span>Purchase Package</span>
                         <span>
                           <i className="fas fa-cart-arrow-down ml-2"></i>
                         </span>
                       </div>
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
           ) :
             <Loader />
           }
         </div>
         <LoginRegister
           show={this.state.modalShow}
           registerClicked={() => this.registerClicked()}
           loginClicked={() => this.loginClicked()}
           onHide={() => this.showHideModal()}
         />
       </div>
     );
   }
 }
 
 export default SinglePackageView;
 