/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
    
    GET_SHOPOWNER_ID_BEGIN,
    GET_SHOPOWNER_ID_SUCCESS,
    GET_SHOPOWNER_ID_FAIL,
    GET_SHOPOWNER_BEGIN,
    GET_SHOPOWNER_SUCCESS,
    GET_SHOPOWNER_FAIL,
    GET_SHOPOWNER_DETAILS_BEGIN,
    GET_SHOPOWNER_DETAILS_SUCCESS,
    GET_SHOPOWNER_DETAILS_FAIL,
    UPDATE_SHOPOWNER_PACKAGE_BEGIN,
    UPDATE_SHOPOWNER_PACKAGE_SUCCESS,
    UPDATE_SHOPOWNER_PACKAGE_FAIL,
    
  } from "../actions/ShopOwnerAction";
  
  const initialState = {
    shopowners: null,
    shopowner: null,
    shopownerdetails: null,
    loggedinshopowner: null,
    loading: false,
    error: null,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) { 
      case GET_SHOPOWNER_ID_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_SHOPOWNER_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          loggedinshopowner: action.payload
        };
      case GET_SHOPOWNER_ID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
        case GET_SHOPOWNER_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case GET_SHOPOWNER_SUCCESS:
          return {
            ...state,
            loading: false,
            shopowner: action.payload.data
          };
        case GET_SHOPOWNER_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
        case GET_SHOPOWNER_DETAILS_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case GET_SHOPOWNER_DETAILS_SUCCESS:
          return {
            ...state,
            loading: false,
            shopownerdetails: action.payload.data
          };
        case GET_SHOPOWNER_DETAILS_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
          case UPDATE_SHOPOWNER_PACKAGE_BEGIN:
            return {
              ...state,
              loading: true,
              error: null
            };
          case UPDATE_SHOPOWNER_PACKAGE_SUCCESS:
            return {
              ...state,
              loading: false,
            };
          case UPDATE_SHOPOWNER_PACKAGE_FAIL:
            return {
              ...state,
              loading: false,
              error: action.payload.error.response.data
            };
      default:
        return state;
    }
  };
  