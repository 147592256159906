/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  GET_ALL_REQUESTS_BEGIN,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS_FAIL,
  GET_ALL_ACTIVE_REQUESTS_BEGIN,
  GET_ALL_ACTIVE_REQUESTS_SUCCESS,
  GET_ALL_ACTIVE_REQUESTS_FAIL,
  GET_REQUEST_BEGIN,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAIL,
  GET_REQUESTS_BY_CATEGORY_BEGIN,
  GET_REQUESTS_BY_CATEGORY_SUCCESS,
  GET_REQUESTS_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL,
  REMOVE_REQUEST_BEGIN,
  REMOVE_REQUEST_SUCCESS,
  REMOVE_REQUEST_FAIL,
  GET_PAYMENT_TOKEN_BEGIN,
  GET_PAYMENT_TOKEN_SUCCESS,
  GET_PAYMENT_TOKEN_FAIL,
  RESPOND_REQUEST_BEGIN,
  RESPOND_REQUEST_SUCCESS,
  RESPOND_REQUEST_FAIL,
  GET_MECH_REQUESTS_BEGIN,
  GET_MECH_REQUESTS_SUCCESS,
  GET_MECH_REQUESTS_FAIL,
  GET_RATING_BEGIN,
  GET_RATING_SUCCESS,
  GET_RATING_FAIL,
  POST_RATING_BEGIN,
  POST_RATING_SUCCESS,
  POST_RATING_FAIL,
} from "../actions/requestAction";

const initialState = {
  requests: null,
  request: null,
  response: null,
  mechRequests: null,
  activeReqs: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ACTIVE_REQUESTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_ACTIVE_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        activeReqs: action.payload.data.rows
      };
    case GET_ALL_ACTIVE_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_ALL_ACTIVE_REQUESTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: action.payload.data.rows
      };
    case GET_ALL_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_MECH_REQUESTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_MECH_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        mechRequests: action.payload.data.rows
      };
    case GET_MECH_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };

    case GET_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        request: action.payload.data
      };
    case GET_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case RESPOND_REQUEST_BEGIN:
      return {
        ...state,
        error: null
      };
    case RESPOND_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload.data.rows[0]
      };
    case RESPOND_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_REQUESTS_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_REQUESTS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: action.payload.data.requests
      };
    case GET_REQUESTS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: action.payload.data.requests
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: action.payload.data.requests
      };
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case REMOVE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case REMOVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_RATING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        rating: action.payload.data.rows
      };
    case GET_RATING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case POST_RATING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case POST_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        rating: action.payload.data.rows
      };
    case POST_RATING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
