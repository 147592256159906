/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

export const DEFAULT_RULE = {
  isRequired: true
};
export const NAME_RULE = {
  isRequired: true,
  isName: true
};
export const SHOPNAME_RULE = {
  isRequired: true,
  isShopName: true
};
export const PHONE_RULE = {
  isRequired: true,
  isPhone: true
};
export const EMAIL_RULE = {
  isRequired: true,
  isEmail: true
};
export const PASSWORD_RULE = {
  isRequired: true,
  isValidPassword: true
};
export const LICENSE_PLATE_RULE = {
  isRequired: true,
  isPlate: true
};
export const DATE_RULE = {
  isRequired:true,
  isDate:true
};
