/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Button, Col, ListGroup, Row, Badge } from "react-bootstrap";
import LoginRegister from "../../components/LoginRegisterModal";
import Auth from "../../modules/Auth";
import clientRequestDefaultProfile from "../../assets/images/mechwangu_default_request.svg";
import Loader from "../../components/Loader/Loader";
import AuthCurrentTenant from "../../axioss/modules/Auth/authCurrentTenant";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
class SingleClientMechRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
      size: "",
      pic: "",
      selectedSize: "",
      id: "",
      quantity: 1,
      modalShow: false,
      login: true,
      reason: ""
    };
  }
  componentDidMount() {
    console.log("Locationz " + JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0]));
    this.props.getRequest(this.props.location.pathname.split("/").slice(-1)[0]).then(() => {
      if (this.props.request) {
        this.props.getResponse(this.props.request.id)
      }
    });
    this.props.getVariantsByProductId(
      this.props.location.pathname.split("/").slice(-1)[0]
    );
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  handleThumbnailClick = (item) => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: "",
      id: item._id,
      cartItem: null,
    });
  };

  onAddClicked = () => {
    this.setState({ quantity: this.state.quantity + 1 });
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      true,
      false
    );
  };
  onRemoveClicked = () => {
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      false,
      true
    );

    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.props
        .postCart(
          this.state.id || this.props.location.pathname.split("/").slice(-1)[0]
        )
        .then((res) => {
          console.log(res);
        });
    } else {
      this.setState({ modalShow: true });
    }
  };

  productInCart = () => {
    let available = false;
    // let items = this.props.cart.items;
    // if (items !== undefined && items !== null) {
    //   let itemCheck = Object.keys(items).map(
    //     id => items[id].item.title === this.props.product.title
    //   );

    //   if (itemCheck !== undefined && itemCheck !== null) {
    //     this.setState({ cartItem: itemCheck });
    //     available = true;
    //   } else {
    //     available = false;
    //   }
    // }

    return available;
  };

  onSubmit = (action, e) => {
    e.preventDefault();
    let data = {
      reason: this.state.reason,
      responseToClientRequestId: this.props.request.id,
      tenant: AuthCurrentTenant.get()
    }
    switch (action) {
      case "Accept":
        data.response = action;
        this.props.respondToRequest(data).then(() => window.location.reload());
        break;
      case "Reject":
        data.response = action;
        this.props.respondToRequest(data).then(() => window.location.reload());
        break;
      default:
        break;
    }
  }

  render() {
    console.log("Locationzz" + JSON.stringify(this.props.request));
    return (
      <div style={this.props.loading ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        {!this.props.loading ?
          <div className="container single_product_container">
            {this.props.request && (
              <div>
                <div className="row">
                  <div className="col">
                    <div className="breadcrumbs d-flex flex-row align-items-center">
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            Client Requests
                          </a>
                        </li>
                        <li className="active">
                          <a href="#">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            {this.props.request.requestDetail}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="single_product_pics">
                      <div className="row">
                        <div className="col-lg-9 image_col order-lg-2 order-1">
                          <div className="single_product_image">
                            <div
                              className="single_product_image_background"
                              style={{
                                backgroundImage: `url(${clientRequestDefaultProfile
                                  })`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="product_details">
                      <div className="product_details_title">
                        <h2>{this.props.request.requestDetail} </h2>
                        <span>
                          <i className="fas fa-car"></i> For Car <a href="/single-car/">{this.props.request.requestForCar.carRegNumber}</a>
                        </span>
                        <p>{this.props.request.affects.servicesName}</p>
                      </div>
                      <span>
                        <h6>Request for service: <b>{this.props.request.affects.servicesName}</b></h6>
                      </span>
                      <br />
                      {loggedInUser.tenants[0].roles[0] === "userMechanic" && this.props.response === undefined ?
                        <div>
                          <div>
                            <div>
                              <textarea name="reason" id="reason" cols="5" rows="3"
                                className="form-control" placeholder="Optional. Rejection reason/Acceptance notes." value={this.state.reason} onChange={(e) => this.setState({ reason: e.target.value })}></textarea>
                            </div>
                          </div>
                          <br />
                          <Row>
                            <Col>
                              <Button variant="success" onClick={e => this.onSubmit("Accept", e)}> Accept </Button>
                            </Col>
                            <Col>
                              <Button variant="danger" onClick={e => this.onSubmit("Reject", e)}> Reject </Button>
                            </Col>
                          </Row>
                        </div> : <span></span>
                      }
                      {this.props.response !== undefined ? this.props.response?.response === "Accept" ?
                        <span style={{ "backgroundColor": "green", "padding": "10px", "color": "white" }}>Accepted</span>
                        :
                        <span style={{ "backgroundColor": "red", "padding": "10px", "color": "white" }}>Rejected</span>
                        : null}
                      {/* <span>
                    <h6>Mech's Garages:</h6>
                    <ul className="star_rating">
                      {
                        this.props.request.mechanicsGarages.map(garage => <li>{garage.garageName}</li>)
                      }
                    </ul>
                  </span>
                  <div className="red_button product-add_to_cart_button align-items-center justify-content-center">
                    <span>Request service  </span>
                    <span>
                      <i className="fas fa-wrench"></i>
                    </span>
                  </div> */}
                      {/* <div className="original_price">
                    {" "}
                    KSh. {(parseFloat(this.props.request.price) + 30).toFixed(2)}
                  </div> */}
                      {/* <div className="product_price">
                    Located at {this.props.request.areaOfOperation.locationName}
                  </div> */}

                      <div className="quantity d-flex flex-column flex-sm-row align-items-sm-center">
                        {/* <span>Quantity:</span> */}

                        {/* <div className="quantity_selector">
                      <span
                        className={
                          this.state.quantity > 1 ? "minus" : "minus disabled"
                        }
                        onClick={() => this.onRemoveClicked()}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      <span id="quantity_value">{this.state.quantity}</span>
                      <span
                        className="plus"
                        onClick={() => this.onAddClicked()}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </div> */}

                        {/* <div
                      className="red_button product-add_to_cart_button"
                      onClick={this.addToBag}
                    >
                      <a href="#">add to cart</a>
                    </div> */}

                        {/* <div className="red_cart_button product_add_to_cart_icon">
                      <a href="#">
                        <i className="fas fa-cart-arrow-down"></i>
                      </a>
                    </div> */}

                        {/* <div className="product_favorite d-flex flex-column align-items-center justify-content-center">
                      <i className="far fa-heart"></i>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          </div> : <Loader />}
      </div>
    );
  }
}

export default SingleClientMechRequest;
