/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllMechanics,
  applyFilters
} from "../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import Home from "./Home";
import { getMechanicIdUsingUserId, getMechanic, setSortedData } from "../../redux/actions/mechanicAction"
import { getAllPackages } from "../../redux/actions/packageAction";
import { startAdvertExpiryChecker } from "../../redux/actions/advertisementAction";
import { getAllShopProducts, setUserLocation, sortProductsByProximity } from "../../redux/actions/shopProductAction";


const mapStoreToProps = state => ({
  mechanics: state.mechanic.mechanics,
  departments: state.department.departments,
  loading: state.mechanic.loading,
  mechanic: state.mechanic.mechanic,
  adpackages: state.adpackage.adpackages,
  shopproducts: state.shopproduct.sortedshopproducts,
  loading: state.shopproduct.loading,
});
const mapDispatchToProps = dispatch => ({
  getMechanicId: (id) => dispatch(getMechanicIdUsingUserId(id)),
  getMechanic: (id) => dispatch(getMechanic(id)),
  getAllPackages: () => dispatch(getAllPackages()),
  setSortedData: (sorted) => dispatch(setSortedData(sorted)),
  startAdvertExpiryChecker: () => dispatch(startAdvertExpiryChecker()),
  getAllShopProducts: () => dispatch(getAllShopProducts()),
  setUserLocation: (userLocation) =>dispatch(setUserLocation(userLocation)),
  sortProductsByProximity: () => dispatch(sortProductsByProximity()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Home);
