/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import SingleMechanic from "./SingleMechanic";
import Heading from "../Heading";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import CircleSpinner from "../Spinner/Circle";
import "./mech.css"
class NewArrivals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getMoreLoading: false,
      endOfData: false,
    }
  }

  async loadMore() {
    this.setState({
      getMoreLoading: true,
    });
    await this.props.getMore().then(res => {
      if (!res.ok) this.setState({ endOfData: true })
    });
    this.setState({
      getMoreLoading: false,
    });
  }

  render() {
    return (
      <div className="new_arrivals" data-aos="fade-up">
        <div className="container">
          {!this.props.search && <div className="row">
            <Heading title="Top Mechanics" data-aos="fade-up" />
          </div>}
          <div className="row align-items-center" data-aos="fade-up">
            <div className="col text-center">
              <div className="new_arrivals_sorting">
                {/* <ul className="arrivals_grid_sorting clearfix button-group filters-button-group">
                  <li
                    onClick={() => this.optionClicked("All")}
                    className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${this.state.selectedOption === "All"
                        ? "active is-checked"
                        : null
                      }`}
                  >
                    all
                  </li>
                  <li
                    className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${this.state.selectedOption === "Engine"
                        ? "active is-checked"
                        : null
                      }`}
                    onClick={() => this.optionClicked("Engine")}
                  >
                    Engine
                  </li>

                  <li
                    className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${this.state.selectedOption === "Body"
                        ? "active is-checked"
                        : null
                      }`}
                    onClick={() => this.optionClicked("Body")}
                  >
                    Body care
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="row">
            {this.props.products.map((item, index) => {
              return (
                <div
                  className="col-lg-4 col-sm-6 mech"
                  key={index}
                // data-aos="zoom-in"
                >
                  <SingleMechanic
                    productItem={item}
                    addToBag={this.props.addToBag}
                    {...this.props}
                  />
                </div>
              );
            })}
          </div>
          {this.props.getMore && <div>
            {!this.state.endOfData ? <div className="row" style={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "center",
              "fontSize": "16px",
              "marginTop": "10px",
            }}>
              {!this.state.getMoreLoading ? <Button bsSize="small" variant="dark" onClick={(e) => { e.preventDefault(); this.loadMore() }}>
                Load more
              </Button> :
                <CircleSpinner />
              }
            </div> :
              <div className="row" style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "fontSize": "16px",
                "marginTop": "10px",
                "color": "red",
              }}>
                <span>All mechanics in your area have been loaded!</span>
              </div>
            }
          </div>}
        </div>
      </div>
    );
  }
}

NewArrivals.propTypes = {
  addToCart: PropTypes.func
};

export default NewArrivals;
