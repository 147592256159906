/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    getAllRequests
} from "../../redux/actions/requestAction";
import { getAllServices } from "../../redux/actions/serviceAction"
import {
    getPaymentToken
} from "../../redux/actions/checkoutAction"
import {
    addOrder
} from "../../redux/actions/orderAction";
import { connect } from "react-redux";
import Checkout from "./Checkout";

const mapStoreToProps = state => ({
    requests: state.request.requests,
    tokens: state.checkout.tokens,
    services: state.service.services
});
const mapDispatchToProps = dispatch => ({
    getAllRequests: () => dispatch(getAllRequests()),
    getPaymentToken: () => dispatch(getPaymentToken()),
    addOrder: (order) => dispatch(addOrder(order)),
    getAllServices: () => dispatch(getAllServices())
});

export default connect(mapStoreToProps, mapDispatchToProps)(Checkout);
