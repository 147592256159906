/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Auth from "../../../modules/Auth";
import jumpTo from "../../../modules/Navigation";
import LoginRegister from "../../../components/LoginRegisterModal";
import { addClientRequest } from "../../../redux/actions/requestAction";
// import { addCar} from "../../../redux/actions/carAction";
import LoadingButton from "../../../components/LoadingButton";
import Loader from "../../../components/Loader/Loader";

import Validator from "../../../utils/Validator";
import { DEFAULT_RULE } from "../../../utils/Validator/rule";

import ValidationModal from "../../../components/ValidationModal/ValidationModal";
import PromptModal from "../../../components/PromptModal";
import { Button, Row } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class AddClientRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      errorModal: false,
      promptModal: false,
      login: true,
      loading: false,
      requestForCar: undefined,
      requestDetail: undefined,
      reqDetErr: false,
      affects: undefined,
      status: "Active",
      mechanicId: undefined,
      userLoc: undefined,
      fetch: false,
    };
    this.addToBag = this.addToBag.bind(this);
  }

  componentDidMount() {
    this.props.getAllCars();
    this.props.getAllServices();
    this.props.getAllMechanics();
    if (this.props.location.state !== undefined) {
      this.setState({
        mechanicId: this.props.location.state.mechId,
        affects: this.props.location.state.service
      })
    }
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  addToBag = params => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      let cart = this.props.postCart(params);
      cart.then(res => {
        console.log(res);
      });
    } else {
      this.setState({ modalShow: true });
    }
  };

  changeHandler = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  focus = (e) => {
    switch (e.target.name) {
      case "requestDetail":
        this.setState({ reqDetErr: false });
        break;
      default:
        break;
    }
  }
  blur = (e) => {
    switch (e.target.name) {
      case "requestDetail":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
          this.setState({ reqDetErr: true });
        }
        break;
      default:
        break;
    }
  }

  submitHandler = () => {
    const { requestForCar, requestDetail, affects, mechanicId } = this.state;
    if (requestForCar !== undefined && requestDetail !== undefined && affects !== undefined && mechanicId !== undefined) {
      this.setState({ loading: true });
      this.props.addClientRequest(requestForCar, requestDetail, affects, mechanicId)
        .then(res => {
          this.setState({ loading: false });
          //jumpTo("/requests");
          this.setState({ promptModal: true })
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ errorModal: true });
    }
  };

  carLocationHandler = () => {
    let car = this.props.cars.find(car => car.id === this.state.requestForCar)
    console.log(car)
    this.setState({
      userLoc: car.carLocatedAt.locationName
    })
  }

  render() {
    const { requestDetail, locations, carmakes, carmodels, departments } = this.state;
    const { cars } = this.props;

    if (!loggedInUser) {
      return <Redirect to='/' />;
    }
    if (cars) {
      if (cars.length <= 0) return <Redirect to='/addcar' />;
    }

    return (
      <div style={!this.props.cars ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        {this.props.cars ?
          <>
            <div className="container mt-5">
              {(this.props.cars && this.props.services && this.props.mechanics) && (
                <div className="row mt-5">
                  <div className="col-md-6 mt-5 mb-5 mb-md-0" style={{
                    "margin-left": "25%",
                  }}>
                    <form>
                      <h2 className="h3 mb-3 text-black">Add Client Request</h2>
                      <br />
                      <div className="p-3 p-lg-5 border">
                        <div className="form-group">
                          <label htmlFor="carRegNumber" className="text-black">Select Car <span
                            className="text-danger">*</span></label>
                          <Row>
                            <select id="carRegNumber"
                              className="form-control"
                              onChange={(car => {
                                this.setState({ requestForCar: car.target.value }, this.carLocationHandler)
                              })}
                              defaultValue={`Select one of your cars`}
                              style={{
                                "width": "70%",
                                "margin-left": "3%",
                                "margin-right": "4%",
                              }}
                            >
                              <option disabled="disabled">Select one of your cars</option>
                              {
                                this.props.cars.map(car => <option value={car.id}>{car.carRegNumber}</option>)
                              }
                            </select>
                            <Button variant="success" onClick={() => jumpTo("/addcar")}>Add Car</Button>
                          </Row>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <label htmlFor="requestDetail" className="text-black">Describe your car issue <span
                              className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="requestDetail" name="requestDetail" value={requestDetail} onChange={this.changeHandler}
                              placeholder="Type your request here... e.g. I need a car oil change" style={this.state.reqDetErr ? { "border": "2px solid red" } : null} onBlur={e => this.blur(e)} onFocus={e => this.focus(e)} />
                            {this.state.reqDetErr && <span style={{ "fontSize": "15px", "color": "red" }}>This field is required</span>}
                          </div>
                        </div>
                        {this.state.affects ?
                          <div className="form-group">
                            <label htmlFor="affects" className="text-black">Select service <span
                              className="text-danger">*</span></label>
                            <select id="affects" className="form-control" onChange={(service => this.setState({ affects: service.target.value, mechanicId: undefined, fetch: true }))} defaultValue={this.state.affects}>
                              <option disabled="disabled">Select service e.g. Buffing</option>
                              {
                                this.props.services.map(service => <option value={service.id}>{service.servicesName}</option>)
                              }
                            </select>
                          </div>
                          :
                          <div className="form-group">
                            <label htmlFor="affects" className="text-black">Select service <span
                              className="text-danger">*</span></label>
                            <select id="affects" className="form-control" onChange={(service => this.setState({ affects: service.target.value, mechanicId: undefined, fetch: true }))} defaultValue={`Select service e.g. Buffing`}>
                              <option disabled="disabled">Select service e.g. Buffing</option>
                              {
                                this.props.services.map(service => <option value={service.id}>{service.servicesName}</option>)
                              }
                            </select>
                          </div>
                        }
                        <div className="form-group">
                          <label htmlFor="mechanicId" className="text-black">Select Mechanic <span
                            className="text-danger">*</span></label>
                          {this.state.affects !== undefined && this.state.userLoc !== undefined ?
                            <MechVals
                              mechanics={this.props.mechanics}
                              affects={this.state.affects}
                              location={this.state.userLoc}
                              changeHandler={option => this.setState({ mechanicId: option.value })}
                              fetch={this.state.fetch}
                              setFetch={(bool) => this.setState({ fetch: bool })}
                            />
                            :
                            <Select placeholder={"Select a Mechanic"} />
                          }
                        </div>
                        <div className="form-group">
                          <LoadingButton
                            type="button"
                            className="btn btn-success btn-lg btn-block"
                            loading={this.state.loading}
                            onClick={() => this.submitHandler()}
                          >
                            Make request
                          </LoadingButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
            <ValidationModal show={this.state.errorModal} onHide={(bool) => { this.setState({ errorModal: bool }); window.location.reload() }} />
            <PromptModal show={this.state.promptModal} onHide={(bool) => { this.setState({ promptModal: bool }); jumpTo("/requests") }} />
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          </>
          : <Loader />}
      </div>
    );
  }
}

const MechVals = ({ mechanics, affects, location, changeHandler, fetch, setFetch }) => {
  const [options, setOptions] = React.useState([]);
  const [val, setVal] = React.useState(undefined);

  let mechs = mechanics.filter(mech => {
    let ids = mech.mechanicServiceSpecialities.map(item => item.id);
    return ids.includes(affects);
  })
  React.useEffect(() => {
    if (fetch) {
      setVal(false);
      let data = mechs.map(mechanic => {
        let mechLoc = mechanic.areaOfOperation.locationName;
        return axios.post(`https://mapi.mechwangu.co.ke/api/auth/directions`, {
          data: {
            origin: location,
            destination: mechLoc
          }
        }).then(res => {
          let option = {
            value: mechanic.id,
            label: mechanic.mechanicUserId.lastName === undefined || mechanic.mechanicUserId.lastName === null ?
              `${mechanic.mechanicUserId.firstName}: ${res.data.distance}, ${res.data.duration}`
              :
              `${mechanic.mechanicUserId.firstName} ${mechanic.mechanicUserId.lastName}: ${res.data.distance} ${res.data.duration}`,
            distance: parseFloat(res.data.distance.split(" ")[0]),
          }
          return option;
        })
      })
      Promise.all(data).then(res => {
        let sorted = res.sort((a, b) => a.distance - b.distance);
        setOptions(sorted);
      });
      setFetch(false);
    }
  }, [mechanics, affects, location, fetch, setFetch])

  const handleChange = (option) => {
    changeHandler(option);
    setVal(option);
  }

  if (mechs.length <= 0) return (
    <Select placeholder={"Select a Mechanic"} options={[]} value={null}></Select>
  )

  return (
    <Select placeholder={"Select a Mechanic"} options={options} onChange={handleChange} value={val} />
  )
}

const mapDispatchToProps = {
  addClientRequest
};
const mapStoreToProps = state => ({
  login_loading: state.login.login_loading,
  login_error: state.login.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(AddClientRequest);
