/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  GET_ALL_CARS_BEGIN,
  GET_ALL_CARS_SUCCESS,
  GET_ALL_CARS_FAIL,
  GET_CAR_BEGIN,
  GET_CAR_SUCCESS,
  GET_CAR_FAIL,
  GET_CARS_BY_CATEGORY_BEGIN,
  GET_CARS_BY_CATEGORY_SUCCESS,
  GET_CARS_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL,
  ADD_CARS_BEGIN,
  ADD_CAR_SUCCESS,
  ADD_CAR_FAIL,
  UPDATE_CAR_BEGIN,
  UPDATE_CAR_SUCCESS,
  UPDATE_CAR_FAIL,
} from "../actions/carAction";

const initialState = {
  cars: null,
  car: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CARS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_CAR_SUCCESS:
      return {
        ...state,
        loading: false,
        cars: action.payload.data.rows
      };
    case ADD_CAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_ALL_CARS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_CARS_SUCCESS:
      return {
        ...state,
        loading: false,
        cars: action.payload.data.rows
      };
    case GET_ALL_CARS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_CAR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CAR_SUCCESS:
      return {
        ...state,
        loading: false,
        car: action.payload.data
      };
    case GET_CAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_CARS_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CARS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        cars: action.payload.data.cars
      };
    case GET_CARS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        cars: action.payload.data.cars
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        cars: action.payload.data.cars
      };
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case UPDATE_CAR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_CAR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    default:
      return state;
  }
};
