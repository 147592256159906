 import React, { Component } from "react";
 import Heading from "../Heading";
 import PropTypes from "prop-types";
 import { Button } from "react-bootstrap";
import SingleShopProduct from "./SingleShopProduct";
import jumpTo from "../../modules/Navigation";
import CircleSpinner from "../Spinner/Circle";

 class LatestShopProducts extends Component {
   constructor(props) {
     super(props);
     this.state = {
       getMoreLoading: false,
       endOfData: false,
     }
   }
   componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            userLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          });
        },
        error => console.log(error)
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    const { userLocation } = this.state;
    // Sort the products when the user location is available
    if (userLocation && userLocation !== prevState.userLocation) {
      this.props.setUserLocation(userLocation);
      await this.props.getAllShopProducts();
      setTimeout(() => {
        this.props.sortProductsByProximity()
      },5000);
    }
  }
   render() {
    const {shopproducts} = this.props;
     return (
       <div className="new_arrivals" data-aos="fade-up">
         <div className="container">
           <div className="row">
             <Heading title="Shop For Latest Products" data-aos="fade-up" />
           </div>
           <div className="row align-items-center" data-aos="fade-up">
             <div className="col text-center">
               <div className="new_arrivals_sorting">
                 {/* <ul className="arrivals_grid_sorting clearfix button-group filters-button-group">
                   <li
                     onClick={() => this.optionClicked("All")}
                     className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${this.state.selectedOption === "All"
                         ? "active is-checked"
                         : null
                       }`}
                   >
                     all
                   </li>
                   <li
                     className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${this.state.selectedOption === "Engine"
                         ? "active is-checked"
                         : null
                       }`}
                     onClick={() => this.optionClicked("Engine")}
                   >
                     Engine
                   </li>
 
                   <li
                     className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${this.state.selectedOption === "Body"
                         ? "active is-checked"
                         : null
                       }`}
                     onClick={() => this.optionClicked("Body")}
                   >
                     Body care
                   </li>
                 </ul> */}
               </div>
             </div>
           </div>
           <div className="row d-flex justify-content-center align-items-center">
            {!this.props.loading? <>
             {shopproducts && shopproducts.slice(0,4).map((item, index) => {
               return (
                 <div
                   className="col-lg-6 col-sm-6 pb-3"
                   key={index}
                   data-aos="zoom-in"
                 >
                   <SingleShopProduct
                     shopproductItem={item}
                     addToBag={this.props.addToBag}
                   />
                   
                 </div>
               );
             })}
              <Button bsSize="small" variant="dark" onClick={() => jumpTo(`/shop`)}>
                  View more
                </Button></>:<CircleSpinner/>}
           </div>      
          </div>
        </div>

     );
   }
 }
 
 LatestShopProducts.propTypes = {
   addToCart: PropTypes.func
 };
 
 export default LatestShopProducts;
 