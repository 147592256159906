/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import API from "../axioss/API";
import Auth from "../modules/Auth";
import { AuthToken } from '../axioss/modules/Auth/authToken';
import { AuthUser } from '../axioss/modules/Auth/authUser';
import axios from 'axios';
import { tenantSubdomain } from '../axioss/tenant/tenantSubdomain';
import AuthCurrentTenant from '../axioss/modules/Auth/authCurrentTenant';
import AuthService from '../axioss/modules/Auth/authService';

export const login = async (email, password) => {
  const invitationToken = undefined; //Todo
  let currentUser = null;

  const response = await axios.post('https://mapi.mechwangu.co.ke/api/auth/sign-in', {
    email,
    password,
    invitationToken,
    tenantId: tenantSubdomain.isSubdomain
      ? AuthCurrentTenant.get()
      : undefined,
  });
  // console.log("This is the token: "+response.data);

  AuthToken.set(response.data, true);

  const token_stored = await AuthToken.get();

  let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';

  currentUser = await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then((response) => {
      AuthUser.set(response.data, true);
      return response;
    },
      (error) => {
        return error;
      }
    )
};

export const verifyPhoneNumber = async (token) => {
  let currentUser = null;

  return await axios.put('https://mapi.mechwangu.co.ke/api/auth/verify-phone', {
    token,
    tenantId: "61d1ce08b967711d2952da3f",
  });
};

export const register = async (fullname, phoneNumber, email, userRole, password, verifyPassword) => {
  const invitationToken = undefined; //Todo
  let currentUser = null;

  const response = await axios.post('https://mapi.mechwangu.co.ke/api/auth/sign-up', {
    fullname,
    email,
    phoneNumber,
    password,
    invitationToken,
    userRole,
    tenantId: tenantSubdomain.isSubdomain
      ? AuthCurrentTenant.get()
      : undefined,
  });
  // console.log("This is the token: "+response.data);

  AuthToken.set(response.data, true);

  const token_stored = await AuthToken.get();

  let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';

  currentUser = await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then((responseB) => {
      responseB = responseB.data;
      AuthUser.set(responseB, true);
      // console.log("Response "+responseB.firstName);
    },
      (error) => {
        var status = error.response.status
        console.log("Status " + status);
      }
    )


  // return await API({
  //   method: "POST",
  //   url: "/users/signin",
  //   data: {
  //     fullname,
  //     email,
  //     password,
  //     verifyPassword
  //   }
  // }).then(res => {
  //   // Auth.setUserToken(res.data.user_token);
  //   console.log(res);
  //   return res;
  // });

};


export const mechRegister = async (fullname, phoneNumber, email, userRole, password, verifyPassword, services, idno, images, locationName, locationDescription, coordinates) => {
  const invitationToken = undefined; //Todo
  let currentUser = null;
  let tenantId = AuthCurrentTenant.get();

  let locationData = {
    locationName: locationName,
    locationDescription: locationDescription,
    coordinates: coordinates,
  }
  let response = await axios.post('https://mapi.mechwangu.co.ke/api/auth/sign-up', {
    fullname,
    email,
    phoneNumber,
    password,
    invitationToken,
    userRole,
    tenantId: tenantSubdomain.isSubdomain
      ? AuthCurrentTenant.get()
      : undefined,
    services,
    idno,
    images,
    location: locationData,
  });
  // console.log("This is the token: "+response.data);

  AuthToken.set(response.data, true);
  const token_stored = await AuthToken.get();

  let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';

  currentUser = await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then((responseB) => {
      responseB = responseB.data;
      AuthUser.set(responseB, true);
      // console.log("Response "+responseB.firstName);
      return responseB;
    },
      (error) => {
        var status = error.response.status
        console.log("Status " + status);
        return error;
      }
    )
  console.log("CurrentUser " + currentUser);

  return response;

};

export const shopownerRegister = async (fullname, phoneNumber, email, userRole, password, verifyPassword) => {
  const invitationToken = undefined; //Todo
  let currentUser = null;
  let shopowner=null;

  const response = await axios.post('https://mapi.mechwangu.co.ke/api/auth/sign-up', {
    fullname,
    email,
    phoneNumber,
    password,
    invitationToken,
    userRole,
    tenantId: tenantSubdomain.isSubdomain
      ? AuthCurrentTenant.get()
      : undefined,
  });
  // console.log("This is the token: "+response.data);

  AuthToken.set(response.data, true);

  const token_stored = await AuthToken.get();

  let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';

  currentUser = await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(async (responseB) => {
      responseB = responseB.data;
      AuthUser.set(responseB, true);
      //console.log("Response "+responseB.firstName);
      const tenantId = AuthCurrentTenant.get();
      let webApUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-owner`;
  
        shopowner = await axios.post(
            webApUrl,
            {
              "data":{
                "isAlsoUser" : responseB.id,
              }
            },
            {
              headers: {
                "Authorization": `Bearer ${token_stored}`
              },
            },
            ).then(shopowner => {
              console.log("Success");
            }).catch(error => {
              console.error("Error", error)
            })
      },
        (error) => {
          var status = error.response.status
          console.log("Status " + status);
        }
      )
      }


export const gstRegister = async (data) => {
  const invitationToken = undefined; //Todo
  let currentUser = null;

  const response = await axios.post('https://mapi.mechwangu.co.ke/api/auth/sign-up', {
    fullname: data.fullname,
    email: data.email,
    phoneNumber: data.phone,
    password: data.password,
    invitationToken: undefined,
    userRole: data.userRole,
    tenantId: "61d1ce08b967711d2952da3f",
  });
  // console.log("This is the token: "+response.data);

  AuthToken.set(response.data, true);

  const token_stored = await AuthToken.get();

  let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';

  return await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then((responseB) => {
      responseB = responseB.data;
      // console.log("Response "+responseB.firstName);
      return responseB;
    },
      (error) => {
        var status = error.response.status
        console.log("Status " + status);
      }
    )


  // return await API({
  //   method: "POST",
  //   url: "/users/signin",
  //   data: {
  //     fullname,
  //     email,
  //     password,
  //     verifyPassword
  //   }
  // }).then(res => {
  //   // Auth.setUserToken(res.data.user_token);
  //   console.log(res);
  //   return res;
  // });

};
export const checkExisting = async (data) => {
  console.log("Checking existing: ", data);
  return await axios.post('https://mapi.mechwangu.co.ke/api/auth/sign-up/cr', {
    phone: data.phone,
    password: data.password,
  });
};