/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */
import React, { Component } from "react";
import Auth from "../../modules/Auth";
import LoginRegister from "../../components/LoginRegisterModal";
import Filter from "./components/Filter";
import Fuse from "fuse.js";
import SingleShopProduct from "../../components/ShopProducts/SingleShopProduct";
import CircleSpinner from "../../components/Spinner/Circle";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      searchQuery:'',
      searchMessage:'',
      userLocaton:null,
      productLimit: 0,
      sortingLabel: 'Default Sorting',
      shopproducts: this.props.shopproducts,
      modalShow: false,
      login: true
    };
    this.addToBag = this.addToBag.bind(this);
  }
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            userLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          });
        },
        error => console.log(error)
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
    this.props.getAllCategories();
  }
  async componentDidUpdate(prevProps, prevState) {
    const { userLocation } = this.state;
    // Sort the products when the user location is available
    if (userLocation && userLocation !== prevState.userLocation) {
      this.props.setUserLocation(userLocation);
      await this.props.getAllShopProducts();
      setTimeout(() => {
        this.props.sortProductsByProximity()
        this.setState({productLimit: this.props.products.length});
      },5000);
    }
  }
  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  addToBag = params => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      let cart = this.props.postCart(params);
      cart.then(res => {
        console.log(res);
      });
    } else {
      this.setState({ modalShow: true });
    }
  };


handleChange = e => {
    const { value } = e.target;
    this.setState({ searchQuery: value });
  };

  setMessage = (message) => {
    this.setState({ searchMessage: message });
    console.log("searchMessage " + message);
  };

  search = async (query) => {
    const fuseInstance = new Fuse(this.props.shopproducts, {
      keys: [
        'productName',
        'productOfCategory.categoryName',
        'productOfShop.shopName'
      ],
      includeScore: true
    });
    const results = await fuseInstance.search(query);
    if (results.length > 0) {
      const productResults = results.map(shopproduct => shopproduct.item);
      this.setState({searchResults: productResults})
    } else this.setMessage("No Product found");
  }
  handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      //check that searchQuery is not empty, not just spaces and return if it is
      if (this.state.searchQuery.trim() === '') return
      this.search(this.state.searchQuery);
      console.log("Search Query: "+this.state.searchQuery)
    }
  }

  render() {
    const { shopproducts, products } = this.props;
    const {productLimit} = this.state;
    const {sortingLabel} = this.state;
    return (
      <div className="container product_section_container">
        <div className="row">
          <div className="col product_section clearfix">
            <div className="breadcrumbs d-flex flex-row align-items-center my-3">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">
                  <a href="/shop">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    Products
                  </a>
                </li>
              </ul>
            </div>
            <div className="search-bar pb-3">
            <input
                  type="text"
                  className="form-control"
                  placeholder="Search for a Specific Product"
                  id="searchText"
                  name="search"
                  value={this.state.searchQuery}
                  onChange={this.handleChange}
                  autoComplete="false"
                  onKeyDown={this.handleKeyDown}
                  style={{
                   "width": "100%",
                    }}
                  />
            </div>
           <div className="sidebar">
              <Filter categories={this.props.categories} loading={this.props.categoryLoading} />
            </div>
            <div className="main_content">
              <div className="products_iso">
                <div className="row">
                  <div className="col">
                    <div className="product_sorting_container product_sorting_container_top">
                      <ul className="product_sorting">
                        <li>
                          <span className="type_sorting_text">{sortingLabel}</span>
                          <i className="fa fa-angle-down"></i>
                          <ul className="sorting_type">
                            <li
                              className="type_sorting_btn"
                              onClick={()=> this.props.sortProductsByProximity()}
                            >
                              <span>Default Sorting</span>
                            </li>
                            <li
                              className="type_sorting_btn"
                              onClick={()=>{this.setState({sortingLabel: 'Price'}); this.props.sortProductsByPrice()}}
                            >
                              <span>Price</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <span>Show</span>
                          <span className="num_sorting_text">{productLimit}</span>
                          <i className="fa fa-angle-down"></i>
                          <ul className="sorting_num">
                          <li onClick={()=>this.setState({productLimit: products.length})} className="num_sorting_btn">
                               <span>All</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 4})} className="num_sorting_btn">
                               <span>4</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 8})} className="num_sorting_btn">
                               <span>8</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 12})} className="num_sorting_btn">
                               <span>12</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 24})} className="num_sorting_btn">
                               <span>24</span>
                             </li>
                          </ul>
                        </li>
                      </ul>
                      <div className="pages d-flex flex-row align-items-center">
                       {/*<div className="page_current">
                          <span>1</span>
                          <ul className="page_selection">
                            <li>
                              <a href="#">1</a>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li>
                              <a href="#">3</a>
                            </li>
                          </ul>
                        </div>
                        <div className="page_total">
                          <span>of</span> 3
                        </div>
                        <div id="next_page" className="page_next">
                          <a href="#">
                            <i className="fas fa-long-arrow-alt-right"></i>
                          </a>
                  </div>*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-3">
                  {!this.props.loading ? <>
                    {this.state.searchResults?.length > 0 &&
                    this.state.searchResults.slice(0, productLimit).map((item, index) => {
                      return(
                      <div
                          className="col-lg-3 col-sm-6"
                          key={index}
                          data-aos="zoom-in"
                        >
                          <SingleShopProduct
                            shopproductItem={item}
                            addToBag={this.props.addToBag}
                          />
                        </div>);
                      })}
                    {this.state.searchResults.length==0 && shopproducts &&
                    shopproducts.slice(0, productLimit).map((item, index) => {
                      return (
                        <div
                          className="col-lg-3 col-sm-6"
                          key={index}
                          data-aos="zoom-in"
                        >
                          <SingleShopProduct
                            shopproductItem={item}
                            addToBag={this.props.addToBag}
                          />
                        </div>
                      );
                    })}
                    </>:<CircleSpinner message={"Searching"}/>}
                  
                </div>
                <div className="product_sorting_container product_sorting_container_bottom clearfix">
                  <span className="showing_results">Showing {productLimit} results</span>
                  <div className="pages d-flex flex-row align-items-center">
                    {/*<div className="page_current">
                      <span>1</span>
                      <ul className="page_selection">
                        <li>
                          <a href="#">1</a>
                        </li>
                        <li>
                          <a href="#">2</a>
                        </li>
                        <li>
                          <a href="#">3</a>
                        </li>
                      </ul>
                    </div>
                    <div className="page_total">
                      <span>of</span> 3
                    </div>
                    <div id="next_page_1" className="page_next">
                      <a href="#">
                        <i
                          className="fas fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                  </div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default Category;
