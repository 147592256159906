

import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthCurrentTenant from '../axioss/modules/Auth/authCurrentTenant';
import { AuthToken } from '../axioss/modules/Auth/authToken';
import { paymentToDb, getOrder } from '../redux/actions/orderAction';
import { mechUserDetails } from '../redux/actions/mechanicAction';
import LoadingButton from '../components/LoadingButton';
import jumpTo from '../modules/Navigation';

let loggedInUser = JSON.parse(localStorage.getItem("auth"));


const ThankYou = ({ location }) => {
    const [mounted, setMounted] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()

    const url = new URL(`https://mechwangu.co.ke/${location.pathname}${location.search}`);
    const paymentStatus = url.searchParams.get("transactionStatus")
    const orderRef = url.searchParams.get("orderReference")
    const paymentPrice = url.searchParams.get("transactionAmount")
    const paymentCurr = url.searchParams.get("transactionCurrency")
    const paymentChannel = url.searchParams.get("paymentChannel")


    useEffect(() => {
        if (!mounted) {
            dispatch(getOrder(orderRef))
        }
        setMounted(true)
    }, [mounted])

    const order = useSelector(state => state.order.order);
    //dispatch(mechUserDetails(order))
    let data = {
        _id: orderRef,
        paymentOfUser: loggedInUser.id,
        status: paymentStatus,
        requests: order?.orderClientRequestIds,
        paymentAmount: paymentPrice,
        paymentCurrency: paymentCurr,
        paymentChannel,
        orderRef: order?._id,
        clientNumber: loggedInUser.phoneNumber,
        tenant: AuthCurrentTenant.get(),
    }
    if (order) {
        if (data.status === "SUCCESS") {
            data.status = "Complete"
            console.log("order: " + JSON.stringify(data))
            dispatch(paymentToDb(data)).then(() => {
                setLoading(false)
            })
        } else {
            data.status = "Cancelled"
            dispatch(paymentToDb(data))
        }

    }



    return (
        <div className="site-section">
            <div className="container">
                <div className="row">
                    {paymentStatus === "SUCCESS" ? <div className="col-md-12 text-center">
                        <span className="icon-check_circle display-3 text-success">
                            <i className="fas fa-check-circle"></i>
                        </span>
                        <h2 className="display-3 text-black">Thank you!</h2>
                        <p className="lead mb-5">You order was successfuly completed.</p>
                        <p>
                            <LoadingButton
                                type="button"
                                className="btn btn-success btn-lg btn-block"
                                loading={loading}
                                onClick={() => jumpTo("/requests")}
                                disabled={loading}
                            >Back to requests</LoadingButton>
                        </p>
                    </div> :
                        <div className="col-md-12 text-center">
                            <span className="icon-check_circle display-3 text-danger">
                                <i class="fas fa-exclamation"></i>
                            </span>
                            <h2 className="display-3 text-black">Oops!</h2>
                            <p className="lead mb-5">Your order was cancelled.</p>
                            <p><a href="/requests" className="btn btn-sm height-auto px-4 py-3 btn-danger">Back my
                                requests</a></p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );

}

export default ThankYou;