/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { userLogin } from "../../redux/actions/LoginAction";
import Validator from "../../utils/Validator";
import {EMAIL_RULE,PASSWORD_RULE } from "../../utils/Validator/rule";
import PropTypes from "prop-types";
import LoadingButton from "../LoadingButton";
import { Link } from "react-router-dom";
import { FormControl, InputGroup } from "react-bootstrap";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      emailErr: false,
      password: undefined,
      passwordErr: false,
      loading: false,
      toast: false,
      message: undefined,
      login_error: false,
      showPass: false
    };
  }

  isLoggedIn() {
    let loggedInUser = JSON.parse(localStorage.getItem("auth"));
    if (loggedInUser) {
      return true
    } else {
      return false
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  focus = (e) => {
    switch (e.target.name) {
      case "email":
        this.setState({ emailErr: false });
        break;
      case "password":
        this.setState({ passwordErr: false });
        break;
      default:
        break;
    }
  }
  blur = (e) => {
    switch (e.target.name) {
      case "email":
        if (!Validator(e.target.value, EMAIL_RULE)) {
          this.setState({ emailErr: true });
        }
        break;
      case "password":
        if (!Validator(e.target.value, PASSWORD_RULE)) {
          this.setState({ passwordErr: true });
        }
        break;
      default:
        break;
    }
  }

  handleSubmit = () => {
    const { email, password } = this.state;

    this.setState({ loading: true });
    this.props
      .userLogin(email, password)
      .then(res => {
        console.log(res);
        this.setState({ loading: false });
        window.location.reload();
      })
      .catch(error => {
        // console.log('loginsignin error')
        console.log(error.response);
        this.setState({ loading: false, login_error: true });
      });
  };
  subBtnEnable = () => {
    const { email, password, passwordErr } = this.state;
    if (email && password && !passwordErr) {
      return true;
    } else {
      return false;
    }
  };

  passClickHandler = () => {
    this.setState({ showPass: !this.state.showPass });
  }

  render() {
    return (
      <div>
        <div style={{
          "marginTop": "5px",
          "padding": "10px",
          "color": "white",
          "backgroundColor": "grey",
          "borderRadius": "5px",
          "textAlign": "center",
          "fontSize": "20px",
          "fontWeight": "bold"
        }}>{"Login"}</div>
        {/* <SocialButtons>
          <a
            href={`https://mapi.mechwangu.co.ke/api/auth/social/facebook`}
          >
            <i
              className="fab fa-facebook"
              style={{
                color: '#3B5998',
              }}
            />
          </a>

          <a
            href={`https://mapi.mechwangu.co.ke/api/auth/social/google`}
          >
            <i
              className="fab fa-google"
              style={{
                color: '#DB4437',
              }}
            />
          </a>
        </SocialButtons> */}
        <div className="login-form mt-3">
          {this.state.login_error && <div style={{
            "marginTop": "5px",
            "padding": "5px",
            "color": "white",
            "backgroundColor": "red",
            "borderRadius": "5px",
            "textAlign": "center"
          }}>{"Wrong Email or Password"}</div>}
          <div className="form-group mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Email "
              id="UserName"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              autoComplete="false"
              style={this.state.emailErr ? { "border": "2px solid red" } : null}
              onBlur={e => this.blur(e)}
              onFocus={e => this.focus(e)}
            />
            {this.state.emailErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: name@email.com</span>}
            <i className="fa fa-user"></i>
          </div>
          <div className="form-group">
            <InputGroup>
              <FormControl
                type={this.state.showPass ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                id="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                autoComplete="false"
                style={this.state.passwordErr ? { "border": "2px solid red" } : null}
                onBlur={e => this.blur(e)}
                onFocus={e => this.focus(e)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i onClick={this.passClickHandler} className={this.state.showPass ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {this.state.passwordErr && <span style={{ "fontSize": "15px", "color": "red" }}>Minimum eight characters, at least one capital letter and one number</span>}
          </div>
          <Link
            className="link"
            onClick={() => this.props.reset()}
            style={{ color: 'red' }}
          >
            Lost your password?
          </Link>
          <LoadingButton
            type="button"
            className="log-btn"
            loading={this.state.loading}
            onClick={() => this.handleSubmit()}
            disabled={!this.subBtnEnable()}
          >
            Log in
          </LoadingButton>
          <div
            onClick={this.props.registerClicked}
            style={{
              textAlign: "center",
              fontSize: 12,
              color: "#black",
              cursor: "pointer"
            }}
          >
            New user ? Please Register
          </div>
        </div>

      </div>
    );
  }
}



LoginForm.propTypes = {
  forgotPasswordClicked: PropTypes.func,
  registerClicked: PropTypes.func
};

const mapDispatchToProps = {
  userLogin
};
const mapStoreToProps = state => ({
  login_loading: state.login.login_loading,
  login_error: state.login.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(LoginForm);
