/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllRequests,
  applyFilters
} from "../../../redux/actions/requestAction";
import { getAllGarages } from "../../../redux/actions/garageAction";
import { getAllLocations } from "../../../redux/actions/locationAction";
import { getMechanic } from "../../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import EditGarage from "./index";
import { postCart } from "../../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  garage: state.garage.garage,
  loggedinmechanic: state.mechanic.loggedinmechanic,
  garages: state.garage.garages,
  locations: state.location.locations,
  mechanics: state.mechanic.mechanics,
  mechanic: state.mechanic.mechanic,
  requests: state.request.requests,
  departments: state.department.departments,
  loading: state.request.loading
});
const mapDispatchToProps = dispatch => ({
  getMechanic: () => dispatch(getMechanic()),
  getAllGarages: () => dispatch(getAllGarages()),
  getAllLocations: () => dispatch(getAllLocations()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(EditGarage);
