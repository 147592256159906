/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import GarageHolderOnMyGaragesPage from "./SingleGarage";
import Heading from "../Heading";

// import "../../assets/css/button.css";

class MyGarages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      garages: this.props.garages
    };
  }

  render() {
    const { garages, departments } = this.props;

    console.log("Garages: "+garages)

    return (
      <div className="best_sellers">
        <div className="container">
          <div className="row">
            <Heading title="My Garages" data-aos="fade-up" />
          </div>

          <div className="row" style={{ marginTop: 50 }}>
            {garages &&
              garages.slice(0, 8).map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-sm-6"
                    key={index}
                    data-aos="zoom-in"
                  >
                    <GarageHolderOnMyGaragesPage
                      garageItem={item}
                      addToBag={this.props.addToBag}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default MyGarages;
