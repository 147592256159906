/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import BaseLayout from "../layouts/BaseLayout";
import MechRegisterBaseLayout from "../layouts/MechRegisterBaseLayout";

// Route Views
import Home from "../views/Home/HomeContainer";
import Cars from "../views/Car/CarContainer";
import Garages from "../views/Garage/GarageContainer";
import Services from "../views/Service/ServiceContainer";
import Mechanics from "../views/Mechanic/MechanicContainer"; 
import Shops from "../views/Shop/ShopListContainer";
import ShopProducts from "../views/ShopProduct/ShopProductContainer"
import PhoneVerification from "../views/Verification/PhoneVerificationContainer";
import MechRegister from "../views/MechRegister/MechRegister";
import ThankYou from "../views/ThankYou";
import ContactUs from "../views/ContactUs";
import HowItWorks from "../views/HowItWorks";
import Express from "../views/Express/ExpressContainer";
// import AddCar from "../views/Car/AddCar/LocationContainer";
// import AddCar from "../views/Car/AddCar/AddCarContainer";
import AddCar from "../views/Car/AddCar/AddCar";
import AddClientRequest from "../views/ClientRequests/AddClientRequest/AddClientRequestsContainer";
import AddGarage from "../views/Garage/AddGarage/index";
import EditGarage from "../views/Garage/EditGarage/EditGarageContainer";
import ClientRequests from "../views/ClientRequests/ClientRequestsContainer";
import ClientRequestsMech from "../views/MechanicResponse/ClientRequestsMechContainer";
import SingleProductContainer from "../views/Product/SingleProductContainer";
import SingleShopProductContainer from "../views/ShopProduct/SingleShopProductContainer";
import SingleMechanicContainer from "../views/Mechanic/SingleMechanicContainer";
import SingleCarContainer from "../views/Car/SingleCarContainer";
import SingleRequestContainer from "../views/ClientRequests/SingleClientRequestContainer";
import ExpressRequestContainer from "../views/Express/ExpressClientRequestContainer";
import SingleClientRequestMech from "../views/MechanicResponse/SingleClientRequestContainerMech";
import SingleGarageContainer from "../views/Garage/SingleGarageContainer";
import CategoryContainer from "../views/Category/CategoryContainer";
import SearchContainer from "../views/Search/SearchContainer";
import SingleServiceContainer from "../views/Service/SingleServiceContainer";
import Status from "../components/Verification/StatusPage";
import EditCar from "../views/Car/EditCar/EditCar";
import ProfileContainer from "../views/Profile/ProfileContainer";
import Rating from "../views/Rating/Rating";
import CheckoutContainer from "../views/CheckoutExpress/CheckoutContainer";
import AddShopProduct from "../views/ShopProduct/AddShopProduct";
import AddShop from "../views/Shop/AddShop";
import ManageShopProductContainer from "../views/ShopProduct/ManageShopProductContainer";
import EditShop from "../views/Shop/EditShop";
import SingleShopContainer from "../views/Shop/SingleShopContainer";
import EditShopProduct from "../views/ShopProduct/EditShopProduct";
import AddAdvert from "../views/Advertisements/AddAdvert";
import SingleShopProductViewContainer from "../views/ShopProduct/SingleShopProductViewContainer";
import AdvertListContainer from "../views/Advertisements/AdvertListContainer";
import EditAdvert from "../views/Advertisements/EditAdvert";
import SingleAdvertContainer from "../views/Advertisements/SingleAdvertContainer";
import SinglePackageViewContainer from "../views/Packages/SinglePackageViewContainer";
import PackageCheckoutContainer from "../views/PackageCheckout/PackageCheckoutContainer";

let phoneVerified = false;
let status;
let loggedInUser = JSON.parse(localStorage.getItem("auth"));
if (loggedInUser) {
  phoneVerified = loggedInUser.phoneVerified;
  status = loggedInUser.tenants[0].status;
  console.log("phoneVerified " + phoneVerified);
}

var routes = [
  {
    path: "/verifyphone",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? !phoneVerified ? PhoneVerification : () => <Redirect to="/" /> : PhoneVerification,
  },
  {
    path: "/status",
    exact: true,
    layout: BaseLayout,
    component: Status
  },
  {
    path: "/express",
    exact: true,
    layout: BaseLayout,
    component: Express,
  },
  {
    path: "/express/checkout",
    exact: true,
    layout: BaseLayout,
    component: CheckoutContainer,
  },
  {
    path: "/rating",
    exact: true,
    layout: BaseLayout,
    component: Rating
  },
  {
    path: "/",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : Home : () => <Redirect to="/verifyphone" /> : Home,
  },
  {
    path: "/car",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : Cars : () => <Redirect to="/verifyphone" /> : Cars,
  },
  {
    path: "/addcar",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AddCar : () => <Redirect to="/verifyphone" /> : AddCar,
  },
  {
    path: "/requests",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : ClientRequests : () => <Redirect to="/verifyphone" /> : ClientRequests,
  },
  {
    path: "/profile",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : ProfileContainer : () => <Redirect to="/verifyphone" /> : ProfileContainer,
  },
  {
    path: "/mechanic-requests",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : ClientRequestsMech : () => <Redirect to="/verifyphone" /> : ClientRequestsMech,
  },
  {
    path: "/addrequest",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AddClientRequest : () => <Redirect to="/verifyphone" /> : AddClientRequest,
  },
  {
    path: "/addgarage",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AddGarage : () => <Redirect to="/verifyphone" /> : AddGarage,
  },
  {
    path: "/editgarage/:id",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : EditGarage : () => <Redirect to="/verifyphone" /> : EditGarage,
  },
  {
    path: "/garages",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : Garages : () => <Redirect to="/verifyphone" /> : Garages,
  },
  {
    path: "/services",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : Services : () => <Redirect to="/verifyphone" /> : Services,
  },
  {
    path: "/mechanics",
    exact: true,
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : status === "empty-permissions" ? () => <Redirect to="/status" /> : Mechanics : () => <Redirect to="/verifyphone" /> : Mechanics,
  },
  {
    path: "/home",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : () => <Redirect to="/" /> : () => <Redirect to="/verifyphone" /> : () => <Redirect to="/" />,
  },
  {
    path: "/shop",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : CategoryContainer : () => <Redirect to="/verifyphone" /> : CategoryContainer,
  },
  {
    path: "/shoplist",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : Shops : () => <Redirect to="/verifyphone" /> : Shops,
  },
  {
    path: "/addshop",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AddShop : () => <Redirect to="/verifyphone" /> : AddShop,
  },
  {
    path: "/editshop/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : EditShop : () => <Redirect to="/verifyphone" /> : EditShop,
  },
  {
    path: "/singleshop/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleShopContainer : () => <Redirect to="/verifyphone" /> : SingleShopContainer,
  },
  {
    path: "/manageshopproducts",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : ManageShopProductContainer : () => <Redirect to="/verifyphone" /> : ManageShopProductContainer,
  },
  {
    path: "/addshopproduct",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AddShopProduct : () => <Redirect to="/verifyphone" /> :  AddShopProduct,
  },
  {
    path: "/editshopproduct/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : EditShopProduct : () => <Redirect to="/verifyphone" /> : EditShopProduct,
  },
  {
    path: "/singleshopproduct/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleShopProductContainer : () => <Redirect to="/verifyphone" /> : SingleShopProductContainer,
  },
  {
    path: "/singleshopproductview/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleShopProductViewContainer : () => <Redirect to="/verifyphone" /> : SingleShopProductViewContainer,
  },
  {
    path: "/addadvert",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AddAdvert : () => <Redirect to="/verifyphone" /> :  AddAdvert,
  },
  {
    path: "/editadvert/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : EditAdvert : () => <Redirect to="/verifyphone" /> :  EditAdvert,
  },
  {
    path: "/manageadverts",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : AdvertListContainer : () => <Redirect to="/verifyphone" /> :  AdvertListContainer,
  },
  {
    path: "/singleadvert/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleAdvertContainer : () => <Redirect to="/verifyphone" /> : SingleAdvertContainer,
  },
  {
    path: "/singlepackage/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SinglePackageViewContainer : () => <Redirect to="/verifyphone" /> : SinglePackageViewContainer,
  },
  {
    path: "/packagecheckout/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : PackageCheckoutContainer : () => <Redirect to="/verifyphone" /> : PackageCheckoutContainer,
  },
  {
    path: "/single-mechanic/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleMechanicContainer : () => <Redirect to="/verifyphone" /> : SingleMechanicContainer,
  },
  {
    path: "/single-service/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleServiceContainer : () => <Redirect to="/verifyphone" /> : SingleServiceContainer,
  },
  {
    path: "/single-car/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleCarContainer : () => <Redirect to="/verifyphone" /> : SingleCarContainer,
  },
  {
    path: "/edit-car/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : EditCar : () => <Redirect to="/verifyphone" /> : EditCar,
  },
  {
    path: "/single-request/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleRequestContainer : () => <Redirect to="/verifyphone" /> : SingleRequestContainer,
  },
  {
    path: "/express/request/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : ExpressRequestContainer : () => <Redirect to="/verifyphone" /> : ExpressRequestContainer,
  },
  {
    path: "/single-mech-request/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleClientRequestMech : () => <Redirect to="/verifyphone" /> : SingleClientRequestMech,
  },
  {
    path: "/single-garage/:id",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SingleGarageContainer : () => <Redirect to="/verifyphone" /> : SingleGarageContainer,
  },
  {
    //Don't delete use this for mech dashboard http://localhost:4000/shops/Women/Basics
    path: "/shops/:category",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : CategoryContainer : () => <Redirect to="/verifyphone" /> : CategoryContainer,
  },
  {
    path: "/search",
    layout: BaseLayout,
    component: loggedInUser ? phoneVerified ? status === "empty-permissions" ? () => <Redirect to="/status" /> : SearchContainer : () => <Redirect to="/verifyphone" /> : SearchContainer,
  },
  {
    path: "/mech-register",
    exact: true,
    layout: MechRegisterBaseLayout,
    component: loggedInUser ? Home : status === "empty-permissions" ? () => <Redirect to="/status" /> : MechRegister,
  },
  {
    path: "/contact-us",
    exact: true,
    layout: BaseLayout,
    component: ContactUs,
  },
  {
    path: "/guide",
    exact: true,
    layout: BaseLayout,
    component: HowItWorks,
  },
  {
    path: "/thanks",
    exact: true,
    layout: BaseLayout,
    component: ThankYou,
  },
];

export default routes;
