import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

export const getAllCategories = () => async dispatch => {
    dispatch({
      type: GET_ALL_CATEGORIES_BEGIN
    });
  
    const invitationToken = undefined; //Todo
  
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
  
    //  console.log("shopTenantId "+tenantId);
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product-category`;
  
    //  console.log("shopUrl "+webApiUrl);
  
    await axios.get(
      webApiUrl,
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        }
      }).then(res => {
        console.log("CATEGORIES " + JSON.stringify(res));
        dispatch({
          type: GET_ALL_CATEGORIES_SUCCESS,
          payload: res
        });
      })
      .catch(error => {
        console.error("Error", error)
        dispatch({
          type: GET_ALL_CATEGORIES_FAIL,
          payload: { error }
        });
      })
  };
  export const getCategory = id => dispatch => {
    dispatch({
      type: GET_CATEGORY_BEGIN
    });
  
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product-category/${id}`;
  
    return axios.get(webApiUrl, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    ).then(res => {
      console.log("Category " + JSON.stringify(res));
      dispatch({
        type: GET_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
      .catch(error => {
        dispatch({
          type: GET_CATEGORY_FAIL,
          payload: { error }
        });
        return error;
      });
  };

  export const GET_ALL_CATEGORIES_BEGIN = "GET_ALL_CATEGORIES_BEGIN";
  export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
  export const GET_ALL_CATEGORIES_FAIL = "GET_ALL_CATEGORIES_FAIL";
  
  export const GET_CATEGORY_BEGIN = "GET_CATEGORY_BEGIN";
  export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
  export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";
  