/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { mechRegister, register,shopownerRegister, gstRegister } from "../../ServerRequest";
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import axios from 'axios';

let loggedInUser = JSON.parse(localStorage.getItem("auth"))

export const userRegister = (
  fullname,
  phone,
  email,
  userRole,
  password,
  verifyPassword
) => dispatch => {
  dispatch({
    type: POST_REGISTER_BEGIN
  });
  console.log("This is the user: ", fullname, phone, email, userRole, password, verifyPassword);

  return register(fullname, phone, email, userRole, password, verifyPassword)
    .then(res => {
      dispatch({
        type: POST_REGISTER_SUCCESS,
        payload: res
      });

      return res;
    })
    .catch(error => {
      dispatch({
        type: POST_REGISTER_FAIL,
        payload: { error }
      });

      throw error;
    });
};
export const guestRegister = (
  data
) => dispatch => {
  dispatch({
    type: POST_REGISTER_BEGIN
  });
  console.log("This is the user: ", data);

  return gstRegister(data)
    .then(res => {
      dispatch({
        type: POST_REGISTER_SUCCESS,
        payload: res
      });

      return res;
    })
    .catch(error => {
      dispatch({
        type: POST_REGISTER_FAIL,
        payload: { error }
      });

      throw error;
    });
};

export const mechanicRegister = (
  fullname,
  phone,
  email,
  userRole,
  password,
  verifyPassword,
  services,
  idno,
  images,
  locationName,
  locationDescription,
  coordinates
) => dispatch => {
  dispatch({
    type: POST_REGISTER_BEGIN
  });

  return mechRegister(fullname, phone, email, userRole, password, verifyPassword, services, idno, images, locationName, locationDescription, coordinates)
    .then(res => {
      dispatch({
        type: POST_REGISTER_SUCCESS,
        payload: res
      });

      return res;
    })
    .catch(error => {
      dispatch({
        type: POST_REGISTER_FAIL,
        payload: { error }
      });

      throw error;
    });
};

export const shopOwnerRegister = (
  fullname,
  phone,
  email,
  userRole,
  password,
  verifyPassword,
) => dispatch => {
  dispatch({
    type: POST_REGISTER_BEGIN
  });

  console.log("This is the user: ", fullname, phone, email, userRole, password, verifyPassword);
  
  return shopownerRegister(fullname, phone, email, userRole, password, verifyPassword)
    .then(res => {
      dispatch({
        type: POST_REGISTER_SUCCESS,
        payload: res
      });

      return res;
    })
    .catch(error => {
      dispatch({
        type: POST_REGISTER_FAIL,
        payload: { error }
      });

      throw error;
    });
};

export const POST_REGISTER_BEGIN = "POST_REGISTER_BEGIN";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAIL = "POST_REGISTER_FAIL";
