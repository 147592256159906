/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jumpTo from "../../../modules/Navigation";
import LoginRegister from "../../../components/LoginRegisterModal";
import { addGarage } from "../../../redux/actions/garageAction";
import { getAllLoggedInUserGarages } from "../../../redux/actions/garageAction";
import { getMechanicIdUsingUserId } from "../../../redux/actions/mechanicAction";
import MyGarages from "../../../components/Garage/MyGarages";
// import { addCar} from "../../../redux/actions/carAction";
import { getAllLocations } from "../../../redux/actions/locationAction";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
let loggedInUserIsMechanic = null;
if (loggedInUser) {
  loggedInUserIsMechanic = loggedInUser.tenants[0].roles[0];
}

const AddGarage = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [login, setLogin] = React.useState(true);
  const [garageName, setGarageName] = React.useState();
  const [garageOwnedByMechanic, setGarageOwnedByMechanic] = React.useState();
  const [garageDescription, setGarageDescription] = React.useState();
  const [garageLocationName, setGarageLocationName] = React.useState();
  const [garageLocationDesc, setGarageLocationDesc] = React.useState();
  const [showMessage, setShowMessage] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [coordinates, setCoordinates] = useState()
  const {
    // ready,
    value: locationName,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
    },
    debounce: 300,
  })

  const dispatch = useDispatch();

  useEffect(() => {
    if (!mounted) {
      dispatch(getAllLocations());

      dispatch(getMechanicIdUsingUserId(loggedInUser.id)).then((res) => {
        setGarageOwnedByMechanic(res);
        getAllLoggedInUserGarages(res);
      }).catch((error) => {
        console.log(error);
      });
    }
    setMounted(true);

  }, [dispatch, mounted]);

  const garages = useSelector(state => state.garage.garages);
  const locations = useSelector(state => state.location.locations);

  const showHideModal = () => {
    setModalShow(false);
  };

  const loginClicked = () => {
    setModalShow(true);
    setLogin(true);
  };
  const registerClicked = () => {
    setModalShow(true);
    setLogin(false);
  };
  const onLocationSelect = ({ description, structured_formatting: { main_text } }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    setGarageLocationName(description);
    setGarageLocationDesc(main_text);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setCoordinates([lat, lng].join(","))
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (showMessage === false) {
      setLoading(true);
      let data = {
        garageName,
        garageDescription,
        garageOwnedByMechanic,
        garageLocationName,
        garageLocationDesc,
        coordinates
      }
      dispatch(addGarage(data)).then((res) => {
        setLoading(false);
        jumpTo("/garages");
      })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  //  console.log("MAGee"+garages)

  if (!loggedInUser) {
    return <Redirect to="/" />;
  } else if (loggedInUserIsMechanic !== "userMechanic") {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className="container mt-5">
        {locations && (
          <div className="row mt-5">
            <div className="col-md-6 mb-5 mb-md-0">
              <form onSubmit={submitHandler}>
                <h2 className="h3 mb-3 text-black">Add Garage</h2>
                <br />
                <div className="p-3 p-lg-5 border">
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label htmlFor="garageName" className="text-black">
                        Enter Garage name{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="garageName"
                        name="garageName"
                        value={garageName}
                        onChange={e => setGarageName(e.target.value)}
                        placeholder="e.g. ABC Garage"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <label
                        htmlFor="garageDescription"
                        className="text-black"
                      >
                        Describe your Garage{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="garageDescription"
                        name="garageDescription"
                        value={garageDescription}
                        onChange={e => setGarageDescription(e.target.value)}
                        placeholder="e.g. We are aimed at ..."
                      />
                    </div>
                  </div>
                  {/* <div className="form-group row">
                            <div className="col-md-12">
                                <label htmlFor="c_car_reg_number" className="text-black">Car Owner <span
                                    className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="c_car_reg_number" name="c_car_reg_number"
                                      placeholder="Type your name" />
                            </div>
                        </div> */}

                  <div className="form-group">
                    <label htmlFor="carLocatedAt" className="text-black">Garage Located At <span
                      className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="carLocatedAt" name="carLocatedAt" value={locationName} onChange={(e) => { setValue(e.target.value); setShowMessage(false) }} />
                    {status === "OK" && <ul className="list-group">
                      {
                        data.map((suggestion) => {
                          const {
                            place_id,
                            structured_formatting: { main_text, secondary_text },
                          } = suggestion;

                          return (
                            <li className="list-group-item" key={place_id} onClick={onLocationSelect(suggestion)}>
                              <strong>{main_text}</strong><small>{` ${secondary_text}`}</small>
                            </li>
                          );
                        })
                      }
                    </ul>}
                  </div>
                  {showMessage && (
                    <p style={{ color: "red" }}>
                      You have to select the location to continue
                    </p>
                  )}
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      onClick={submitHandler}
                    >
                      Add Your Garage
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 mb-5 mb-md-0 border">
              {garages ? <MyGarages garages={garages} /> : null}
            </div>
          </div>
        )}
      </div>
      <LoginRegister
        show={modalShow}
        login={login}
        registerClicked={registerClicked}
        loginClicked={loginClicked}
        onHide={showHideModal}
      />
    </div>
  );
}

export default AddGarage;
