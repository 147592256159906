/*
** Author: Munyingi Ian
** Author URL: https://ellixar.com/
*/

import React, { Component } from "react";
import { Button, ListGroup, Row, Modal, Image } from "react-bootstrap";
import LoginRegister from "../../components/LoginRegisterModal";
import StarRatings from "react-star-ratings";
import Auth from "../../modules/Auth";
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import clientRequestDefaultProfile from "../../assets/images/mechwangu_default_request.svg";
import reqMob from "../../assets/images/req_mob.png";
import Loader from "../../components/Loader/Loader";
import LoadingButton from "../../components/LoadingButton";
import Select from "react-select";
import axios from "axios";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
class SingleClientRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
      size: "",
      pic: "",
      selectedSize: "",
      id: "",
      quantity: 1,
      modalShow: false,
      login: true,
      reason: "",
      rating: 0,
      comments: "Default: No comment",
      actionLoading: false,
      oldRating: [],
      mechLoading: false,
      mechView: true,
      mechanicId: undefined,
      errorModal: false,
      userLoc: undefined,
      matches: window.matchMedia("(min-width: 768px)").matches
    };
  }
  componentDidMount() {
    console.log("Locationz " + JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0]));
    this.props.getRequest(this.props.location.pathname.split("/").slice(-1)[0]).then(() => {
      if (this.props.request) {
        this.props.getCar(this.props.request.requestForCar.id).then((res) => {
          return this.setState({
            userLoc: res.data.carLocatedAt.locationName
          })
        })
        this.props.getResponse(this.props.request.id)
        let data = {
          mech: this.props.request.mechanicId._id,
          customer: loggedInUser.id
        }
        this.props.getRating(data).then((res) => this.setState({ oldRating: res }))

        let req = this.props.request;
        let exp = Date.parse(req.createdAt);
        let now = new Date();
        let diff = ((now - exp) / 1000) / 60;
        console.log("Diff in minutes " + diff);
        if (diff > 30) {
          this.setState({ mechView: false })
        }
      }
    });
    this.props.getAllMechanics()
    this.props.getVariantsByProductId(
      this.props.location.pathname.split("/").slice(-1)[0]
    );
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  handleThumbnailClick = (item) => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: "",
      id: item._id,
      cartItem: null,
    });
  };

  onAddClicked = () => {
    this.setState({ quantity: this.state.quantity + 1 });
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      true,
      false
    );
  };
  onRemoveClicked = () => {
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      false,
      true
    );

    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.props
        .postCart(
          this.state.id || this.props.location.pathname.split("/").slice(-1)[0]
        )
        .then((res) => {
          console.log(res);
        });
    } else {
      this.setState({ modalShow: true });
    }
  };

  productInCart = () => {
    let available = false;
    // let items = this.props.cart.items;
    // if (items !== undefined && items !== null) {
    //   let itemCheck = Object.keys(items).map(
    //     id => items[id].item.title === this.props.product.title
    //   );

    //   if (itemCheck !== undefined && itemCheck !== null) {
    //     this.setState({ cartItem: itemCheck });
    //     available = true;
    //   } else {
    //     available = false;
    //   }
    // }

    return available;
  };
  changeRating = (newRating, name) => {
    this.setState({
      rating: newRating
    });
  }

  changeMech = async () => {
    let req = this.props.request;
    let exp = Date.parse(req.createdAt);
    let now = new Date();
    let diff = ((now - exp) / 1000) / 60;
    if (diff > 30) {
      this.setState({ errorModal: true })
    } else {
      this.setState({ mechLoading: true })
      let data = this.props.request;
      data.mechanicId = this.state.mechanicId;
      this.props.updateRequest(data).then(() => {
        this.setState({
          //mechView: false, 
          mechLoading: false
        })
        window.location.reload()
      })
    }
  }

  submitHandler = async () => {
    this.setState({ actionLoading: true });
    let loggedInUser = JSON.parse(localStorage.getItem("auth"));
    let tenantId = AuthCurrentTenant.get();
    let data = {
      reviewOfMechanic: this.props.request.mechanicId._id,
      comments: this.state.comments,
      byCustomer: loggedInUser.id,
      mechanicReviewRating: this.state.rating,
      tenant: tenantId,
    }
    this.props.postRating(data).then(() => {
      this.setState({ actionLoading: false });
      window.location.reload();
    });
  }

  render() {
    console.log("Locationzz" + JSON.stringify(this.props.request));
    return (
      <div style={this.props.loading ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        {!this.props.loading ?
          <div className="container single_product_container">
            {this.props.request && (
              <div>
                <div className="row">
                  <div className="col">
                    <div className="breadcrumbs d-flex flex-row align-items-center">
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            Client Requests
                          </a>
                        </li>
                        <li className="active">
                          <a href="#">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            {this.props.request.requestDetail}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="single_product_pics">
                      <div className="row">
                        <div className="col-lg-9 image_col order-lg-2 order-1">
                          {!this.state.matches ?
                            <Image src={reqMob} />
                            : <div className="single_product_image">
                              <div
                                className="single_product_image_background"
                                style={{
                                  backgroundImage: `url(${clientRequestDefaultProfile
                                    })`,
                                }}
                              />
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="product_details">
                      <div className="product_details_title">
                        <h2>{this.props.request.requestDetail} </h2>
                        <span>
                          <i className="fas fa-car"></i> For Car <a href="/single-car/">{this.props.request.requestForCar.carRegNumber}</a>
                        </span>
                        <p>{this.props.request.affects.servicesName}</p>
                      </div>
                      <span>
                        <h6>Request for service: <b>{this.props.request.affects.servicesName}</b></h6>
                      </span>
                      <br />
                      {this.state.mechView &&
                        <>
                          <span style={{
                            "background-color": "green",
                            "color": "white",
                            "padding": "10px",
                            "border-radius": "5px",
                          }}>You are allowed free change of service provider within 30mins</span>
                          <div className="form-group"
                            style={{
                              "margin-top": "10px"
                            }}
                          >

                            <label htmlFor="mechanicId" className="text-black">Change Mechanic <span
                              className="text-danger">*</span></label>
                            {this.props.request.affects !== undefined && this.props.mechanics && this.state.userLoc &&
                              <MechVals
                                mechanics={this.props.mechanics}
                                affects={this.props.request.affects.id}
                                location={this.state.userLoc}
                                changeHandler={option => this.setState({ mechanicId: option.value })}
                                defaultValue={{
                                  value: this.props.request.mechanicId?._id,
                                  label: this.props.request.mechanicId?.mechanicUserId?.firstName
                                }}
                              />}
                            <br />
                            <LoadingButton
                              type="button"
                              className="btn btn-success"
                              loading={this.state.mechLoading}
                              disabled={this.state.mechanicId === undefined ? true : false}
                              onClick={() => this.changeMech()}>Submit</LoadingButton>
                          </div>
                        </>
                      }
                      <br />
                      {this.props.request?.status === "Complete" && <div style={{ "marginBottom": "30px" }}><i class="fa fa-phone" aria-hidden="true"></i>{"  "}<a href={`tel:${this.props.request.mechanicId.mechanicUserId.phoneNumber}`}>{this.props.request.mechanicId.mechanicUserId.phoneNumber}</a></div>}

                      {this.props.request?.status !== "Complete" && <div style={{ "marginBottom": "30px" }}><i class="fa fa-phone" aria-hidden="true"></i> Visible after payment</div>}

                      {this.props.response !== undefined ? this.props.response?.response === "Accept" ?
                        <span style={{ "backgroundColor": "green", "padding": "10px", "color": "white" }}>Accepted</span>
                        :
                        <span style={{ "backgroundColor": "red", "padding": "10px", "color": "white" }}>Rejected</span>
                        : null}
                      <br />
                      <br />
                      <div>
                        {this.state.oldRating.length > 0 &&
                          <>
                            <span>You rated this mechanic:</span>
                            <br />
                            <StarRatings
                              rating={parseInt(this.state.oldRating[0].mechanicReviewRating)}
                              starRatedColor="yellow"
                              numberOfStars={5}
                              name='rating'
                              starDimension="30px"
                              starSpacing="1px"
                            />
                          </>
                        }
                      </div>
                      {this.props.request.status === "Complete" && this.state.oldRating.length <= 0 ?
                        <>
                          <div>
                            Rating:
                            <div className="star_rating" style={{ "display": "inline" }}>
                              <StarRatings
                                rating={this.state.rating}
                                starRatedColor="yellow"
                                changeRating={this.changeRating}
                                numberOfStars={5}
                                name='rating'
                                starDimension="30px"
                                starSpacing="1px"
                              />
                            </div>
                          </div>
                          <br />
                          <div>
                            <div>
                              <div>
                                <textarea name="reason" id="reason" cols="5" rows="3"
                                  className="form-control" placeholder="Review comments. Fill before rating" onChange={(e) => this.setState({ comments: e.target.value })}></textarea>
                              </div>
                            </div>
                            <br />
                          </div>
                          <LoadingButton
                            type="button"
                            className="btn btn-success btn-lg btn-block"
                            loading={this.state.actionLoading}
                            onClick={() => this.submitHandler()}
                            disabled={this.state.rating ? false : true}
                          >
                            Rate
                          </LoadingButton>
                        </>
                        : null}
                      {/* <span>
                    <h6>Mech's Garages:</h6>
                    <ul className="star_rating">
                      {
                        this.props.request.mechanicsGarages.map(garage => <li>{garage.garageName}</li>)
                      }
                    </ul>
                  </span>
                  <div className="red_button product-add_to_cart_button align-items-center justify-content-center">
                    <span>Request service  </span>
                    <span>
                      <i className="fas fa-wrench"></i>
                    </span>
                  </div> */}
                      {/* <div className="original_price">
                    {" "}
                    KSh. {(parseFloat(this.props.request.price) + 30).toFixed(2)}
                  </div> */}
                      {/* <div className="product_price">
                    Located at {this.props.request.areaOfOperation.locationName}
                  </div> */}

                      <div className="quantity d-flex flex-column flex-sm-row align-items-sm-center">
                        {/* <span>Quantity:</span> */}

                        {/* <div className="quantity_selector">
                      <span
                        className={
                          this.state.quantity > 1 ? "minus" : "minus disabled"
                        }
                        onClick={() => this.onRemoveClicked()}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      <span id="quantity_value">{this.state.quantity}</span>
                      <span
                        className="plus"
                        onClick={() => this.onAddClicked()}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </div> */}

                        {/* <div
                      className="red_button product-add_to_cart_button"
                      onClick={this.addToBag}
                    >
                      <a href="#">add to cart</a>
                    </div> */}

                        {/* <div className="red_cart_button product_add_to_cart_icon">
                      <a href="#">
                        <i className="fas fa-cart-arrow-down"></i>
                      </a>
                    </div> */}

                        {/* <div className="product_favorite d-flex flex-column align-items-center justify-content-center">
                      <i className="far fa-heart"></i>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Modal
              size="sm"
              show={this.state.errorModal}
              onHide={() => this.setState({ errorModal: false })}
              centered
            >
              <Modal.Header closeButton />
              <Modal.Body centered>
                <strong>Sorry, 30mins have passed since you made the request!</strong>
              </Modal.Body>
            </Modal>
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          </div> : <Loader />}
      </div>
    );
  }
}
const MechVals = ({ mechanics, affects, location, changeHandler, defaultValue }) => {
  const [options, setOptions] = React.useState([]);
  const [fetch, setFetch] = React.useState(true);

  let mechs = mechanics.filter(mech => {
    let ids = mech.mechanicServiceSpecialities.map(item => item.id);
    return ids.includes(affects);
  })
  React.useEffect(() => {

    if (fetch) {
      let data = mechs.map(mechanic => {
        let mechLoc = mechanic.areaOfOperation.locationName;
        return axios.post(`https://mapi.mechwangu.co.ke/api/auth/directions`, {
          data: {
            origin: location,
            destination: mechLoc
          }
        }).then(res => {
          let option = {
            value: mechanic.id,
            label: mechanic.mechanicUserId.lastName === undefined || mechanic.mechanicUserId.lastName === null ?
              `${mechanic.mechanicUserId.firstName}: ${res.data.distance}, ${res.data.duration}`
              :
              `${mechanic.mechanicUserId.firstName} ${mechanic.mechanicUserId.lastName}: ${res.data.distance} ${res.data.duration}`,
            distance: parseFloat(res.data.distance.split(" ")[0]),
          }
          return option;
        })
      })
      Promise.all(data).then(res => {
        let sorted = res.sort((a, b) => a.distance - b.distance);
        setOptions(sorted);
      });
      setFetch(false);
    }
  }, [mechanics, affects, location, fetch, setFetch])

  if (mechs.length <= 0) return (
    <Select placeholder={"Select a Mechanic"} options={[]} value={null}></Select>
  )

  return (
    <Select placeholder={"Select a Mechanic"} options={options} onChange={changeHandler} defaultValue={defaultValue} />
  )
}

export default SingleClientRequest;
