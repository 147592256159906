import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import jumpTo from "../../modules/Navigation";
import { deleteShop } from "../../redux/actions/shopAction";
import DeleteConfirmationModal from "../ValidationModal/DeleteConfirmationModal";

function SingleShopHolder(props) {
    const {shopItem}=props;
    const [modalShow, setModalShow] = useState(true);
    const [shopIdToDelete, setShopIdToDelete] = useState(null);
    const dispatch = useDispatch();
    const OpenDeleteConfirmModal = (id) => {
        setShopIdToDelete(id); 
      };
    const CloseDeleteConfirmModal = () => {
        setShopIdToDelete(null);
      };
      const Delete = async (id) => {
        CloseDeleteConfirmModal();
        await dispatch(deleteShop(id));
        window.location.reload();
        
      };

  return (
          <div key={shopItem.id}>
              <div className="card">
                <div className="shop-card card-body">
                    <div className="pb-2 text-center justify-content-center align-items-center">
                        {shopItem.shopName}
                    </div>
                    <div className="shop-buttons">
                        <a href="/manageshopproducts" className="btn btn-outline-success mr-2 mb-2">View Products</a>
                        <button className="btn btn-outline-primary mr-2 mb-2" onClick={() =>jumpTo(`/singleshop/${shopItem._id}`)}>View Shop</button>
                        <button className="btn btn-outline-danger mr-2 mb-2" onClick={() => OpenDeleteConfirmModal(shopItem.id)}>Delete</button>
                    </div>
                </div>
              </div>
                    {shopIdToDelete && (
                      <DeleteConfirmationModal
                        show={modalShow}
                        onHide={setModalShow}
                        message={"Are you sure you want to delete this shop?"}
                        onConfirm={() => Delete(shopIdToDelete)}
                        onClose={() => CloseDeleteConfirmModal()}
                      />
                    )}
        </div>
  )
}

export default SingleShopHolder