/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component} from "react";
import Fuse from "fuse.js";
import LoginRegister from "../../components/LoginRegisterModal";
import Filter from "./components/Filter";
import NewArrivals from "../../components/Products/NewArrivals";
import CircleSpinner from "../../components/Spinner/Circle";

let fuseInstance;
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      modalShow: false,
      login: true,
      searchQuery: '',
      searchMessage: '',
      serviceIdForMechSearch: '',
      timeout: 0,
      matches: window.matchMedia("(min-width: 768px)").matches,
      serviceChecked: true,
      mechChecked: false,
    };
  }
  componentDidMount() {
    this.props.getAllServices();
  }
  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  handleChange = e => {
    const { value } = e.target;
    this.setState({ searchQuery: value });
  };

  setMessage = (message) => {
    this.setState({ searchMessage: message });
    console.log("searchMessage " + message);
  };

  getAllMechanicsAfresh = () => {
    this.props.getAllMechanics();
  };

  filteredMechanics = async (serviceId) => {
    await this.props.getMechanicsUsingSpecialityServiceId(serviceId)
  };

  search = async (query) => {
    const fuseInstance = new Fuse(this.props.services, {
      keys: [
        'servicesName',
      ],
      includeScore: true
    });
    const results = await fuseInstance.search(query);
    if (results.length > 0) {
      let id = results[0].item.id;
      this.setState({ searchMessage: results[0].item.servicesName });
      await this.props.getMechanicsUsingSpecialityServiceId(id)
      if (this.props.mechanics) this.setState({ searchResults: this.props.mechanics });
    } else this.setMessage("No service found");
  }
  handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      //check that searchQuery is not empty, not just spaces and return if it is
      if (this.state.searchQuery.trim() === '') return
      if (this.state.serviceChecked) {
        this.search(this.state.searchQuery);
      } else {
        let data = {
          name: this.state.searchQuery
        }
        await this.props.searchByName(data);
      }
    }
  }

  render() {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => this.setState({ matches: e.matches }));

    const { mechanics, services, departments, products, applyFilters } = this.props;
    const { searchMessage } = this.state;

    return (
      <div className="container product_section_container">
        <div className="row">
          <div className="col product_section clearfix">
            <div class="breadcrumbs d-flex flex-row align-items-center">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active">
                  <a href="/">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    {this.props.location.pathname
                      .split("/")[1]
                      .charAt(0)
                      .toUpperCase() +
                      this.props.location.pathname.split("/")[1].slice(1)}
                  </a>
                </li>
                <li class="active">
                  <a href="#">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                    {this.props.location.pathname.split("/")[3]}
                  </a>
                </li>
              </ul>
            </div>

            {this.state.matches && <div className="sidebar">
              <Filter services={this.props.services} loading={this.props.serviceLoading} />
            </div>}
            <div className="main_content">
              <div class="products_iso">
                <div className="row">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Mechanic by service here ..."
                    id="serchText"
                    name="search"
                    value={this.state.searchQuery}
                    onChange={this.handleChange}
                    autoComplete="false"
                    onKeyDown={this.handleKeyDown}
                    style={{
                      "width": "100%",
                    }}
                  />
                  <div className='form-group' style={{
                    "display": "flex",
                    "marginTop": "10px",
                  }}>
                    <label style={{
                      "marginRight": "10px",
                    }}>
                      <input type="radio" value="service" name="searchMethod"
                        id="searchMethod" checked={this.state.serviceChecked} onChange={(e) => {
                          if (e.currentTarget.value === 'service') {
                            this.setState({ serviceChecked: true, mechanicChecked: false });
                          } else {
                            this.setState({ serviceChecked: false, mechanicChecked: true });
                          }
                        }}
                        style={{
                          "marginRight": "5px",
                        }}
                      />by Service Name</label>
                    <label>
                      <input type="radio" value="mechName" name="searchMethod"
                        id="searchMethod" checked={this.state.mechChecked} onChange={(e) => {
                          if (e.currentTarget.value === 'mechName') {
                            this.setState({ serviceChecked: false, mechChecked: true });
                          } else {
                            this.setState({ serviceChecked: true, mechChecked: false });
                          }
                        }}
                        style={{
                          "marginRight": "5px",
                        }}
                      />by Mechanic Name</label>
                  </div>
                </div>
                <div className="row">
                  <p style={{
                    textAlign: "center",
                  }}>
                    {searchMessage != null ? searchMessage : null}
                  </p>
                </div>
                <div className="row">
                  {/* // Todo:  */}
                  {!this.props.loading ? <>
                    {this.state.searchResults?.length > 0 &&
                      <NewArrivals
                        products={this.state.searchResults}
                        addToBag={this.addToBag}
                        onHide={() => this.showHideModal()}
                        search={true}
                      />}
                    {this.props.searchResults?.length > 0 &&
                      <NewArrivals
                        products={this.props.searchResults}
                        addToBag={this.addToBag}
                        onHide={() => this.showHideModal()}
                        search={true}
                      />
                    }
                  </> : <CircleSpinner message={"Searching"} />}

                </div>
                {/* <div class="product_sorting_container product_sorting_container_bottom clearfix">
                  <ul class="product_sorting">
                    <li>
                      <span>Show:</span>
                      <span class="num_sorting_text">04</span>
                      <i class="fa fa-angle-down"></i>
                      <ul class="sorting_num">
                        <li class="num_sorting_btn">
                          <span>01</span>
                        </li>
                        <li class="num_sorting_btn">
                          <span>02</span>
                        </li>
                        <li class="num_sorting_btn">
                          <span>03</span>
                        </li>
                        <li class="num_sorting_btn">
                          <span>04</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <span class="showing_results">Showing 1–3 of 12 results</span>
                  <div class="pages d-flex flex-row align-items-center">
                    <div class="page_current">
                      <span>1</span>
                      <ul class="page_selection">
                        <li>
                          <a href="#">1</a>
                        </li>
                        <li>
                          <a href="#">2</a>
                        </li>
                        <li>
                          <a href="#">3</a>
                        </li>
                      </ul>
                    </div>
                    <div class="page_total">
                      <span>of</span> 3
                    </div>
                    <div id="next_page_1" class="page_next">
                      <a href="#">
                        <i
                          class="fas fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {!this.state.matches && <div>
              <Filter services={this.props.services} matches={this.state.matches} />
            </div>}
          </div>
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default Search;
