/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    updateProfile
} from "../../redux/actions/profileAction";
import { getAllServices } from "../../redux/actions/serviceAction";
import { getMechanicIdUsingUserId, getMechanic, updateMechanic } from "../../redux/actions/mechanicAction"
import { connect } from "react-redux";
import Profile from "./Profile"
import { getShopOwner, getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";

const mapStoreToProps = state => ({
    services: state.service.services,
    mechanic: state.mechanic.mechanic,
    shopowner: state.shopowner.shopowner,
});
const mapDispatchToProps = dispatch => ({
    updateProfile: data => dispatch(updateProfile(data)),
    getAllServices: () => dispatch(getAllServices()),
    getMechanicId: (id) => dispatch(getMechanicIdUsingUserId(id)),
    getShopOwnerId: (id) => dispatch(getShopOwnerIdUsingUserId(id)),
    getMechanic: (id) => dispatch(getMechanic(id)),
    getShopOwner: (id) => dispatch(getShopOwner(id)),
    updateMechanic: (data) => dispatch(updateMechanic(data)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Profile);
