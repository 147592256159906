/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import SingleClientRequestMech from "./SingleClientRequestMech";
import { getRequest, respondToRequest, getResponse } from "../../redux/actions/requestAction";
import { getVariantsByProductId } from "../../redux/actions/variantsAction";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  request: state.request.request,
  variants: state.variant.variants,
  response: state.request.response,
  loading: state.request.loading
});
const mapDispatchToProps = {
  getResponse,
  respondToRequest,
  getRequest,
  getVariantsByProductId,
  postCart,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleClientRequestMech);
