import {
    GET_PAYMENT_TOKEN_BEGIN,
    GET_PAYMENT_TOKEN_SUCCESS,
    GET_PAYMENT_TOKEN_FAIL,
    INITIATE_MPESA_BEGIN,
    INITIATE_MPESA_SUCCESS,
    INITIATE_MPESA_FAIL
} from "../actions/checkoutAction";

const initialState = {
    tokens: null,
    loading: false,
    error: null,
    requests: null,
    response: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_TOKEN_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_PAYMENT_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                tokens: action.payload.data
            };
        case GET_PAYMENT_TOKEN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case INITIATE_MPESA_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case INITIATE_MPESA_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload.data
            };
        case INITIATE_MPESA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};