import {
    UPDATE_PROFILE_BEGIN,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    GET_PROFILE_BEGIN,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAIL,
} from "../actions/profileAction";


const initialState = {
    success: false,
    loading: false,
    error: null,
    profile: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROFILE_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload.error,
            };
        case GET_PROFILE_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                profile: action.payload.data
            };
        case GET_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload.error,
            };

        default:
            return state;
    }
}