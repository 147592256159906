/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */


// Layout Types
import BaseLayout from "../layouts/BaseLayout";

import CartContainer from "../views/Cart/CartContainer";
import CheckoutContainer from "../views/Checkout/CheckoutContainer";
import ThankYou from "../views/ThankYou";

var PrivateRoutes = [
  {
    path: "/cart",
    layout: BaseLayout,
    component: CartContainer,
  },
  {
    path: "/checkout",
    layout: BaseLayout,
    component: CheckoutContainer,
  },
  {
    path: "/thank-you",
    layout: BaseLayout,
    component: ThankYou,
  },


];

export default PrivateRoutes;
