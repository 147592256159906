import API from "../../axioss/API";
import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

export const getShopOwnerIdUsingUserId = (loggedInUserID) => async dispatch => {
    let shopOwnerId;
    dispatch({
      type: GET_SHOPOWNER_ID_BEGIN
    });
  
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-owner?filter%5BisAlsoUser%5D=${loggedInUserID}&offset=0&limit=1`;
  
    await axios.get(webApiUrl, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    ).then(res => {
      shopOwnerId = res.data.rows[0].id;
      dispatch({
        type: GET_SHOPOWNER_ID_SUCCESS,
        payload: res.data.rows[0].id
      });
      return res.data.rows[0].id;
    })
      .catch(error => {
        dispatch({
          type: GET_SHOPOWNER_ID_FAIL,
          payload: { error }
        });
        return error;
      });
  
    return shopOwnerId;
  };

  export const getShopOwner = id => dispatch => {
    dispatch({
      type: GET_SHOPOWNER_BEGIN
    });
  
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get()|| "61d1ce08b967711d2952da3f";
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-owner/${id}`;
  
    return axios.get(webApiUrl, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    ).then(res => {
      console.log("ShopOwner " + JSON.stringify(res));
      dispatch({
        type: GET_SHOPOWNER_SUCCESS,
        payload: res
      });
      return res;
    })
      .catch(error => {
        dispatch({
          type: GET_SHOPOWNER_FAIL,
          payload: { error }
        });
        return error;
      });
  };
  export const getShopOwnerUserDetails = id => dispatch => {
    dispatch({
      type: GET_SHOPOWNER_DETAILS_BEGIN
    });
  
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/user/${id}`;
  
    return axios.get(webApiUrl, {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }
    ).then(res => {
      console.log("ShopOwnerDetails " + JSON.stringify(res));
      dispatch({
        type: GET_SHOPOWNER_DETAILS_SUCCESS,
        payload: res
      });
      return res;
    })
      .catch(error => {
        dispatch({
          type: GET_SHOPOWNER_DETAILS_FAIL,
          payload: { error }
        });
        return error;
      });
  };
  
  export const updateShopOwnerPackage = (id,data) => async dispatch => {
    const tenantId = AuthCurrentTenant.get();
    const token_stored = AuthToken.get();
    dispatch({
      type: UPDATE_SHOPOWNER_PACKAGE_BEGIN
    });
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-owner/${id}`;

    const { data: existingData } = await axios.get(webApiUrl, 
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },);

  const res = await axios.put(
    webApiUrl,
    {
      "data": {
        "isAlsoUser": existingData.isAlsoUser,
        "shops": existingData.shops,
        "shopOwnersAdverts": existingData.shopOwnersAdverts,
         "shopOwnersPackageSubscription": data.shopOwnersPackageSubscription
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("UpdateShopOwner " + JSON.stringify(res));
      dispatch({
        type: UPDATE_SHOPOWNER_PACKAGE_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_SHOPOWNER_PACKAGE_FAIL,
        payload: { error }
      });
      return res;
    })
  };
   

export const GET_SHOPOWNER_ID_BEGIN = "GET_SHOPOWNER_ID_BEGIN";
export const GET_SHOPOWNER_ID_SUCCESS = "GET_SHOPOWNER_ID_SUCCESS";
export const GET_SHOPOWNER_ID_FAIL = "GET_SHOPOWNER_ID_FAIL";

export const GET_SHOPOWNER_BEGIN = "GET_SHOPOWNER_BEGIN";
export const GET_SHOPOWNER_SUCCESS = "GET_SHOPOWNER_SUCCESS";
export const GET_SHOPOWNER_FAIL = "GET_SHOPOWNER_FAIL";

export const GET_SHOPOWNER_DETAILS_BEGIN = "GET_SHOPOWNER_DETAILS_BEGIN";
export const GET_SHOPOWNER_DETAILS_SUCCESS = "GET_SHOPOWNER_DETAILS_SUCCESS";
export const GET_SHOPOWNER_DETAILS_FAIL = "GET_SHOPOWNER_DETAILS_FAIL";

export const UPDATE_SHOPOWNER_PACKAGE_BEGIN = "UPDATE_SHOPOWNER_PACKAGE_BEGIN";
export const UPDATE_SHOPOWNER_PACKAGE_SUCCESS = "UPDATE_SHOPOWNER_PACKAGE_SUCCESS";
export const UPDATE_SHOPOWNER_PACKAGE_FAIL = "UPDATE_SHOPOWNER_PACKAGE_FAIL";

