import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

export const addShop = (data) => async dispatch => {
  const tenantId = AuthCurrentTenant.get();
  const token_stored = AuthToken.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop`;


  dispatch({
    type: ADD_SHOP_BEGIN
  });


  const res = await axios.post(
    webApiUrl,
    {
      "data": {
        "shopName": data.shopName,
        "shopProducts": [],
        "shopOfShopOwner": data.shopOfShopOwner
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("AddShop " + JSON.stringify(res));
      dispatch({
        type: ADD_SHOP_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: ADD_SHOP_FAIL,
        payload: { error }
      });
    })
    return res;
  }

export const getAllShops = (shopOwnerId) => async dispatch => {
  dispatch({
    type: GET_ALL_SHOPS_BEGIN
  });

  const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  //  console.log("shopTenantId "+tenantId);

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop?filter%5BshopOfShopOwner%5D=${shopOwnerId}&offset=0&limit=10&orderBy=createdAt_DESC`;

  //  console.log("shopUrl "+webApiUrl);

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("Shops " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_SHOPS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_SHOPS_FAIL,
        payload: { error }
      });
    })
};

export const getShop = id => dispatch => {
  dispatch({
    type: GET_SHOP_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Shop " + JSON.stringify(res));
    dispatch({
      type: GET_SHOP_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_SHOP_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const updateShop = (id, data) => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  console.log("Update Shop " + JSON.stringify(data));
  dispatch({
    type: UPDATE_SHOP_BEGIN
  });

  

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop/${id}`;

  const res = await axios.put(
    webApiUrl,
    {
      "data": {
        "shopName": data.shopName,
        "shopProducts": [],
        "shopOfShopOwner": data.shopOfShopOwner
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("UpdateShop " + JSON.stringify(res));
      dispatch({
        type: UPDATE_SHOP_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_SHOP_FAIL,
        payload: { error }
      });
      return res;
    })
  };
  export const deleteShop = (id) => async dispatch => {
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();

    dispatch({
      type: DELETE_SHOP_BEGIN
    });
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop?ids%5B%5D=${id}`;
  
    const res = await axios.delete(
      webApiUrl,
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
      ).then(res => {
        console.log("DeleteShop " + JSON.stringify(res));
        dispatch({
          type: DELETE_SHOP_SUCCESS,
          payload: res
        });
      }).catch(error => {
        console.error("Error", error)
        dispatch({
          type: DELETE_SHOP_FAIL,
          payload: { error }
        });
      })
      return res;
    };

export const ADD_SHOP_BEGIN = "ADD_SHOPS_BEGIN";
export const ADD_SHOP_SUCCESS = "ADD_SHOP_SUCCESS";
export const ADD_SHOP_FAIL = "ADD_SHOP_FAIL";

export const UPDATE_SHOP_BEGIN = "UPDATE_SHOP_BEGIN";
export const UPDATE_SHOP_SUCCESS = "UPDATE_SHOP_SUCCESS";
export const UPDATE_SHOP_FAIL = "UPDATE_SHOP_FAIL";

export const DELETE_SHOP_BEGIN = "DELETE_SHOP_BEGIN";
export const DELETE_SHOP_SUCCESS = "DELETE_SHOP_SUCCESS";
export const DELETE_SHOP_FAIL = "DELETE_SHOP_FAIL";

export const GET_ALL_SHOPS_BEGIN = "GET_ALL_SHOPS_BEGIN";
export const GET_ALL_SHOPS_SUCCESS = "GET_ALL_SHOPS_SUCCESS";
export const GET_ALL_SHOPS_FAIL = "GET_ALL_SHOPS_FAIL";

export const GET_SHOP_BEGIN = "GET_SHOP_BEGIN";
export const GET_SHOP_SUCCESS = "GET_SHOP_SUCCESS";
export const GET_SHOP_FAIL = "GET_SHOP_FAIL";
