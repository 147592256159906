import { connect } from "react-redux";
import { getShopProduct } from "../../redux/actions/shopProductAction";
import { getShopOwner, getShopOwnerUserDetails } from "../../redux/actions/ShopOwnerAction";
import SingleShopProductView from "./SingleShopProductView";

const mapStoreToProps = (state) => ({
  shopproduct: state.shopproduct.shopproduct,
  shopowner: state.shopowner.shopowner,
  shopownerdetails: state.shopowner.shopownerdetails
});
const mapDispatchToProps = {
  getShopProduct,
  getShopOwner,
  getShopOwnerUserDetails,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleShopProductView);
