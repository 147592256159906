 import {
    getAllShopProducts,
    applyFilters
  } from "../../redux/actions/shopProductAction";
  import { connect } from "react-redux";
  import { postCart } from "../../redux/actions/cartAction";
import ShopProduct from "./ShopProduct";
  
  const mapStoreToProps = state => ({
    shopproducts: state.shopproduct.shopproducts,
    departments: state.department.departments,
    sorted: state.product.sorted
  });
  const mapDispatchToProps = dispatch => ({
    getAllShopProducts: () => dispatch(getAllShopProducts()),
    applyFilters: filter_string => dispatch(applyFilters(filter_string)),
    postCart: shopproductId => dispatch(postCart(shopproductId))
  });
  
  export default connect(mapStoreToProps, mapDispatchToProps)(ShopProduct);
  