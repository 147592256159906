/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  GET_ALL_MECHANICS_BEGIN,
  GET_ALL_MECHANICS_SUCCESS,
  GET_ALL_MECHANICS_FAIL,
  GET_MECHANIC_BEGIN,
  GET_MECHANIC_SUCCESS,
  GET_MECHANIC_FAIL,
  GET_MECHANIC_ID_BEGIN,
  GET_MECHANIC_ID_SUCCESS,
  GET_MECHANIC_ID_FAIL,
  GET_MECHANICS_BY_CATEGORY_BEGIN,
  GET_MECHANICS_BY_CATEGORY_SUCCESS,
  GET_MECHANICS_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL,
  POST_RATING_BEGIN,
  POST_RATING_SUCCESS,
  POST_RATING_FAIL,
  SET_SORTED_DATA,
  MECH_SEARCH_BEGIN,
  MECH_SEARCH_SUCCESS,
  MECH_SEARCH_FAIL,
} from "../actions/mechanicAction";

const initialState = {
  mechanics: null,
  mechanic: null,
  rating: 0,
  loggedinmechanic: null,
  loading: false,
  error: null,
  sorted: null,
  searchResults: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MECHANICS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_MECHANICS_SUCCESS:
      return {
        ...state,
        loading: false,
        mechanics: action.payload.data.rows
      };
    case GET_ALL_MECHANICS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_MECHANIC_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_MECHANIC_SUCCESS:
      return {
        ...state,
        loading: false,
        mechanic: action.payload.data
      };
    case GET_MECHANIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_MECHANIC_ID_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_MECHANIC_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedinmechanic: action.payload
      };
    case GET_MECHANIC_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_MECHANICS_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_MECHANICS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        mechanics: action.payload.data.mechanics
      };
    case GET_MECHANICS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        mechanics: action.payload.data.mechanics
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        mechanics: action.payload.data.mechanics
      };
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case POST_RATING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case POST_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        rating: action.payload.data
      };
    case POST_RATING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_SORTED_DATA:
      return {
        ...state,
        sorted: action.payload
      };
    case MECH_SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MECH_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResults: action.payload.data
      };
    case MECH_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};
