let inMemoryToken = null;

export class AuthUser {
  static get() {
    return (
      inMemoryToken || localStorage.getItem('auth') || null
    );
  }

  static set(user, rememberMe) {
    if (rememberMe) {
      // localStorage.setItem('auth', user || '');
      localStorage.setItem("auth", JSON.stringify(user));
    } else {
      inMemoryToken = user;
      localStorage.setItem('auth', '');
    }
  }

  static applyFromLocationUrlIfExists() {
    const urlParams = new URLSearchParams(
      window.location.search,
    );
    const authToken = urlParams.get('authUser');

    if (!authToken) {
      return;
    }

    this.set(authToken, true);
    window.history.replaceState(
      {},
      document.title,
      window.location.origin,
    );
  }
}
