import {
    getAllShops,
  } from "../../redux/actions/shopAction";
  import { connect } from "react-redux";
import ShopList from "./ShopList";
import { getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";
  
  const mapStoreToProps = state => ({
    shops: state.shop.shops,
  });
  const mapDispatchToProps = dispatch => ({
    getAllShops: (shopOwnerId) => dispatch(getAllShops(shopOwnerId)),
    getShopOwnerIdUsingUserId: (userId) => dispatch(getShopOwnerIdUsingUserId(userId)),
  });
  
  export default connect(mapStoreToProps, mapDispatchToProps)(ShopList);