/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      responseDocumentResponseFiles: {
        id: 'responseDocumentResponseFiles',
        folder: 'tenant/:tenantId/responseDocument/responseFiles',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      mechanicResponseToClientRequestDocuments: {
        id: 'mechanicResponseToClientRequestDocuments',
        folder: 'tenant/:tenantId/mechanicResponseToClientRequest/documents',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      clientsResponseToMechanicResponseDocuments: {
        id: 'clientsResponseToMechanicResponseDocuments',
        folder: 'tenant/:tenantId/clientsResponseToMechanicResponse/documents',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      garageGarageImages: {
        id: 'garageGarageImages',
        folder: 'tenant/:tenantId/garage/garageImages',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      identificationDocumentIdImages: {
        id: 'identificationDocumentIdImages',
        folder: 'tenant/:tenantId/identificationDocument/idImages',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      mechanicImages: {
        id: 'mechanicImages',
        folder: 'tenant/:tenantId/mechanics/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      shopProductImages: {
        id: 'shopProductImages',
        folder: 'tenant/:tenantId/shopProducts/productImages',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      certificateOfRegistrationGarageCertificateImages: {
        id: 'certificateOfRegistrationGarageCertificateImages',
        folder: 'tenant/:tenantId/certificateOfRegistration/garageCertificateImages',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
    };
  }
}
