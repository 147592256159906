/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import SingleGarage from "./SingleGarage";
import { getGarage } from "../../redux/actions/garageAction";
import { getVariantsByProductId } from "../../redux/actions/variantsAction";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  garage: state.garage.garage,
  variants: state.variant.variants,
});
const mapDispatchToProps = {
  getGarage,
  getVariantsByProductId,
  postCart,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleGarage);
