/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";
import mechanicDefaultMechanic from "../../assets/images/mechwangu_default_prof.svg";
import { Redirect } from "react-router-dom";
import verified from "../../assets/images/Verified_Badge.svg.png";
import StarRatings from "react-star-ratings";

function MechanicHolderOnMechanicPage(props) {
  const { mechanicItem } = props;
  let loggedInUser = JSON.parse(localStorage.getItem("auth"));
  console.log("LoggedInUserXS " + loggedInUser)
  const [redirect, setRedirect] = React.useState();

  let mechImage = mechanicItem.image;

  const clickHandler = () => {
    setRedirect(true);
  }
  if (redirect) {
    return <Redirect to={{
      pathname: `/single-mechanic/${mechanicItem._id}`,
      state: {
        service: props.service
      }
    }} />
  }

  return (
    <div className="product-item men"
      style={{
        borderRadius: "24px",
        marginBottom: "50px"
      }}>
      <div
        className="product discount product_filter"
        style={{
          borderRadius: "24px"
        }}
      >
        <div className="col-md-4">
          <div className="product_image">
            {mechImage ?
              <img src={`https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${mechImage.privateUrl}`} alt=""
                className="img-fluid"
                style={{
                  borderRadius: "24px"
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = mechanicDefaultMechanic;
                }}
              /> :
              <img src={mechanicDefaultMechanic} alt="" className="img-fluid" />
            }
          </div>
        </div>
        {/* <div className="favorite favorite_left">
          <i className="far fa-heart"></i>
        </div> */}
        {/* <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
          <span>-$20</span>
        </div> */}
        <div className="col-md-8">
          <div className="product_info" style={{
            "alignItems": "left",
          }}>
            <div style={{
              "display": "flex",
              "marginTop": "5px",
            }}>
              <h6 className="product_name">
                <div style={{
                  "textTransform": "capitalize",
                }}>
                  {mechanicItem.mechanicUserId.fullName ? <><strong>{mechanicItem.mechanicUserId.fullName}</strong></> : <><strong>{mechanicItem.mechanicUserId.firstName || mechanicItem.mechanicUserId.lastName}</strong></>}
                </div>
              </h6>
            </div>
            {mechanicItem.mechanicUserId.phoneVerified && mechanicItem.mechanicUserId.tenants[0].status === "active" && mechanicItem.mechanicIdentificationDocument !== null ? <div style={{
              "display": "flex",
              "marginTop": "5px",
            }}>
              < img width="32" alt="Verified Badge" src={verified} style={{
                width: "20px",
                height: "20px",
              }} />
              <span style={{ "marginLeft": "5px", "color": "#1DA1F2" }}>Verified</span>
            </div> :
              <div style={{
                "display": "flex",
                "marginTop": "5px",
              }}>
                {/* <i class="fas fa-exclamation-circle" style={{
                    width: "20px",
                    height: "20px",
                    "color": "#FF0000",
                  }}></i> */}
                <span style={{ "marginLeft": "5px", "color": "red" }}>{" "}</span>
              </div>
            }
            <div style={{
              "display": "inline-block",
              "marginTop": "5px",
            }}>
              <StarRatings
                rating={mechanicItem.rating}
                starRatedColor="yellow"
                // changeRating={setRating}
                numberOfStars={5}
                name='rating'
                starDimension="25px"
                starSpacing="1px"
              />
              <span style={{
                "marginLeft": "5px",
              }}>({`${mechanicItem.mechanicReviews.length}`})</span>
            </div>
            <div style={{
              "display": "flex",
              "marginTop": "5px",
            }}>
              <i class="fas fa-map-marker-alt" style={{
                width: "20px",
                height: "20px",
              }}></i>
              <span style={{ "marginLeft": "5px" }}>{mechanicItem.areaOfOperation.locationName.split(",")[0]}</span>
            </div>
          </div >
          <button
            className="red_button add_to_cart_button"
            onClick={clickHandler}
            style={{
              color: "white",
              borderRadius: "14px",
              marginTop: "5px",
              border: "0px"
            }}
          >
            View Mech Profile
          </button>
          {/* <div
        className="red_button add_to_cart_button"
        onClick={() => props.addToBag(mechanicItem._id)}
      >
        <div style={{ color: "#ffffff" }}>View car</div>
      </div> */}
        </div>
      </div>
    </div>
  );
}

export default MechanicHolderOnMechanicPage;
