import React, { Component } from "react";
import Heading from "../Heading";
import SingleShopHolder from "./SingleShop";

// import "../../assets/css/button.css";

class MyShops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shops: this.props.shops
    };
  }

  render() {
    const { shops} = this.props;

    return (
      <div className="best_sellers">
        <div className="container">
          <div className="shoplist-title">
            <Heading title="Your Shops" data-aos="fade-up" />
            <a href="/addshop" className="btn btn-outline-secondary mr-3 mt-3">Add New Shop</a>
          </div>

          <div style={{ marginTop: 50, marginBottom: 50 }}>
            {shops &&
              shops.map((item, index) => {
                return (
                  <div
                    key={index}
                    data-aos="zoom-in"
                    style={{
                      "marginBottom":  "30px"
                    }}
                  >
                    <SingleShopHolder
                      shopItem={item}
                    />
                  </div>
                );
              })} 
          </div>
        </div>
      </div>
    );
  }
}

export default MyShops;
