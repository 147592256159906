/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllRequests,
  applyFilters
} from "../../../redux/actions/requestAction";
import { getAllCars } from "../../../redux/actions/carAction";
import { getAllServices } from "../../../redux/actions/serviceAction";
import { getAllMechanics } from "../../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import AddClientRequests from "./index";
import { postCart } from "../../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  cars: state.car.cars,
  services: state.service.services,
  mechanics: state.mechanic.mechanics,
  requests: state.request.requests,
  departments: state.department.departments,
  loading: state.request.loading
});
const mapDispatchToProps = dispatch => ({
  getAllCars: () => dispatch(getAllCars()),
  getAllServices: () => dispatch(getAllServices()),
  getAllMechanics: () => dispatch(getAllMechanics()),
  getAllRequests: () => dispatch(getAllRequests()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(AddClientRequests);
