/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
  GET_ALL_CARMAKES_BEGIN,
  GET_ALL_CARMAKES_SUCCESS,
  GET_ALL_CARMAKES_FAIL,
  GET_CARMAKE_BEGIN,
  GET_CARMAKE_SUCCESS,
  GET_CARMAKE_FAIL,
  GET_CARMAKES_BY_CATEGORY_BEGIN,
  GET_CARMAKES_BY_CATEGORY_SUCCESS,
  GET_CARMAKES_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL
} from "../actions/carMakesAction";

const initialState = {
  carmakes: null,
  carmake: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CARMAKES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_CARMAKES_SUCCESS:
      return {
        ...state,
        loading: false,
        carmakes: action.payload.data.rows
      };
    case GET_ALL_CARMAKES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_CARMAKE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CARMAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        carmake: action.payload.data
      };
    case GET_CARMAKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_CARMAKES_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_CARMAKES_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        carmakes: action.payload.data.carmakes
      };
    case GET_CARMAKES_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        carmakes: action.payload.data.carmakes
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        carmakes: action.payload.data.carmakes
      };
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    default:
      return state;
  }
};
