import React, { Component } from 'react'
import Heading from "../../components/Heading";
import SinglePackage from "../../components/Packages/SinglePackage";

export class Packages extends Component {
    constructor(props) {
        super(props);
        this.state = {
          adpackages: this.props.adpackages,

        };
      }

    componentDidMount() {
      this.props.getAllPackages();
    };

  render() {
    const {adpackages} = this.props;
    return (
        <div className="container pb-5">
        <Heading title="Browse Our Packages:" data-aos="fade-up" />
        {adpackages &&
                adpackages.slice(0,8).map((item, index) => {
                    return (
                        <div
                          key={index}
                          data-aos="zoom-in"
                        >
                          <SinglePackage
                            packageItem={item}
                          />
                        </div>
                      );
                    })}
        </div>
    )
  }
}

export default Packages