import { connect } from "react-redux";
import { getAdvert } from "../../redux/actions/advertisementAction";
import SingleAdvert from "./SingleAdvert";


const mapStoreToProps = (state) => ({
    advert: state.advert.advert,
});

const mapDispatchToProps = {
  getAdvert,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleAdvert);
