import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import { updateShopProductPriority } from "./shopProductAction";
import { getShopOwnerIdUsingUserId } from "./ShopOwnerAction";

export const addAdvert = (data) => async dispatch => {
  const tenantId = AuthCurrentTenant.get();
  const token_stored = AuthToken.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements`;


  dispatch({
    type: ADD_ADVERT_BEGIN
  });


  const res = await axios.post(
    webApiUrl,
    {
      "data": {
        "advertProduct": data.selectedshopproduct,
        "advertBelongToShopOwner": data.advertBelongToShopOwner,
        "advertExpiry": data.advertExpiry,
        "advertStatus": "Active",
        "advertPackage": data.advertPackage,
        "advertPaymentTransactionCode": data.advertPaymentTransactionCode,
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("AddAdvert " + JSON.stringify(res));
      dispatch({
        type: ADD_ADVERT_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: ADD_ADVERT_FAIL,
        payload: { error }
      });
    })
    return res;
  }

export const getAllAdverts = (shopOwnerId) => async dispatch => {
  dispatch({
    type: GET_ALL_ADVERTS_BEGIN
  });

  const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();


  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements?filter%5BadvertBelongToShopOwner%5D=${shopOwnerId}&offset=0&limit=10&orderBy=createdAt_DESC`;

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("Adverts " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_ADVERTS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_ADVERTS_FAIL,
        payload: { error }
      });
    })
};

export const getAdvert = id => dispatch => {
  dispatch({
    type: GET_ADVERT_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Advert " + JSON.stringify(res));
    dispatch({
      type: GET_ADVERT_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_ADVERT_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const updateAdvert = (id, data) => async dispatch => {
  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  console.log("Update Advert " + JSON.stringify(data));
  dispatch({
    type: UPDATE_ADVERT_BEGIN
  });

  

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements/${id}`;

  const res = await axios.put(
    webApiUrl,
    {
        "data": {
            "advertProduct": data.selectedshopproduct,
            "advertBelongToShopOwner": data.advertBelongToShopOwner,
            "advertExpiry": data.advertExpiry,
            "advertStatus": data.advertStatus,
            "advertPackage": data.advertPackage,
            "advertPaymentTransactionCode": data.advertPaymentTransactionCode,
        }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("UpdateAdvert " + JSON.stringify(res));
      dispatch({
        type: UPDATE_ADVERT_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_ADVERT_FAIL,
        payload: { error }
      });
      return res;
    })
  };

  export const updateAdvertStatus = (id,data) => async dispatch => {
    const tenantId = AuthCurrentTenant.get();
    const token_stored = AuthToken.get();
    dispatch({
      type: UPDATE_ADVERT_STATUS_BEGIN
    });
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements/${id}`;

    const { data: existingData } = await axios.get(webApiUrl, 
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },);

  const res = await axios.put(
    webApiUrl,
    {
      "data": {
        "advertProduct": data.advertProduct,
        "advertBelongToShopOwner": existingData.advertBelongToShopOwner,
        "advertExpiry": existingData.advertExpiry,
        "advertStatus": data.advertStatus,
        "advertPackage": existingData.advertPackage,
        "advertPaymentTransactionCode":existingData.advertPaymentTransactionCode,
    }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("Update Advert Status: " + JSON.stringify(res));
      dispatch({
        type: UPDATE_ADVERT_STATUS_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_ADVERT_STATUS_FAIL,
        payload: { error }
      });
    })
  };

  export const deleteAdvert = (id) => async dispatch => {
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();

    dispatch({
      type: DELETE_ADVERT_BEGIN
    });
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements?ids%5B%5D=${id}`;
  
    const res = await axios.delete(
      webApiUrl,
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
      ).then(res => {
        console.log("Delete Advert " + JSON.stringify(res));
        dispatch({
          type: DELETE_ADVERT_SUCCESS,
          payload: res
        });
      }).catch(error => {
        console.error("Error", error)
        dispatch({
          type: DELETE_ADVERT_FAIL,
          payload: { error }
        });
      })
      return res;
    };

    export const checkAdvertExpiry = () => async dispatch =>{
      const token_stored = AuthToken.get();
      const tenantId = AuthCurrentTenant.get();
      let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advertisements`;
              return axios.get(webApiUrl, {
                headers: {
                  "Authorization": `Bearer ${token_stored}`
                }
              }
              ).then(async res => {
                const today = new Date().toISOString();
                console.log("Date "+today)
                const adverts = res.data.rows;
                for(let i = 0; i < adverts.length;i++){
                  console.log("Advert" +adverts[i])
                  if(adverts[i].advertExpiry < today && adverts[i].advertStatus !== "Expired"){
                    console.log("Updating Status:" )
                    let updatedata = {
                      advertProduct: "",
                      advertExpiry : adverts[i].advertExpiry,
                      advertStatus: "Expired",
                    }
                    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product/${adverts[i].advertProduct.id}`;

                      const { data: existingData } = await axios.get(webApiUrl, 
                        {
                          headers: {
                            "Authorization": `Bearer ${token_stored}`
                          },
                        },);

                    const res = await axios.put(
                      webApiUrl,
                      {
                        "data": {
                          "productImage": existingData.productImage,
                          "productName": existingData.productName,
                          "productDescription": existingData.productDescription,
                          "productPrice": existingData.productPrice,
                          "productOfShop": existingData.productOfShop,
                          "productOfCategory": existingData.productOfCategory,
                          "productLocation":existingData.productLocation,
                          "productSponsorship": [],
                          "productSponsorshipExpiryDate": adverts[i].advertExpiry,
                          "productSponsorshipPriority": 0,
                        }
                      },
                      {
                        headers: {
                          "Authorization": `Bearer ${token_stored}`
                        },
                      },
                      ).then(res => {
                        console.log("UpdateShopProductPriority " + JSON.stringify(res));
                        updatedata.advertProduct = existingData;
                        dispatch( updateAdvertStatus(adverts[i]._id, updatedata));
                      }).catch(error => {
                        console.error("Error", error)
                      })      
                  }
                 }
                 
              })
                .catch(error => {
                  return error;
                });
    }

    export const startAdvertExpiryChecker = (interval = 86400000) => {
      return (dispatch) => {
        dispatch(checkAdvertExpiry());
        setInterval(() => {
          dispatch(checkAdvertExpiry());
        }, interval);
      };
    };

export const ADD_ADVERT_BEGIN = "ADD_ADVERTS_BEGIN";
export const ADD_ADVERT_SUCCESS = "ADD_ADVERT_SUCCESS";
export const ADD_ADVERT_FAIL = "ADD_ADVERT_FAIL";

export const UPDATE_ADVERT_BEGIN = "UPDATE_ADVERT_BEGIN";
export const UPDATE_ADVERT_SUCCESS = "UPDATE_ADVERT_SUCCESS";
export const UPDATE_ADVERT_FAIL = "UPDATE_ADVERT_FAIL";

export const UPDATE_ADVERT_STATUS_BEGIN = "UPDATE_ADVERT_STATUS_BEGIN";
export const UPDATE_ADVERT_STATUS_SUCCESS = "UPDATE_ADVERT_STATUS_SUCCESS";
export const UPDATE_ADVERT_STATUS_FAIL = "UPDATE_ADVERT_STATUS_FAIL";

export const DELETE_ADVERT_BEGIN = "DELETE_ADVERT_BEGIN";
export const DELETE_ADVERT_SUCCESS = "DELETE_ADVERT_SUCCESS";
export const DELETE_ADVERT_FAIL = "DELETE_ADVERT_FAIL";

export const GET_ALL_ADVERTS_BEGIN = "GET_ALL_ADVERTS_BEGIN";
export const GET_ALL_ADVERTS_SUCCESS = "GET_ALL_ADVERTS_SUCCESS";
export const GET_ALL_ADVERTS_FAIL = "GET_ALL_ADVERTS_FAIL";

export const GET_ADVERT_BEGIN = "GET_ADVERT_BEGIN";
export const GET_ADVERT_SUCCESS = "GET_ADVERT_SUCCESS";
export const GET_ADVERT_FAIL = "GET_ADVERT_FAIL";
