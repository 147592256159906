import React, { useRef, useState, useEffect } from "react";
import Validator from "../../utils/Validator";
import { DEFAULT_RULE } from "../../utils/Validator/rule"
import { connect, useDispatch } from "react-redux";
import LoadingButton from "../../components/LoadingButton";
import Storage from "../../security/storage";
import ImagesUploaderWrapper from "../../components/Wrappers/ImagesUploaderWrapper";
import ImageModal from "../../components/ImageModal/ImageModal";
import { uploadFile } from "../../utils/fileUploader";
import { addShopProduct } from "../../redux/actions/shopProductAction";
import { getAllShops } from "../../redux/actions/shopAction";
import { getAllCategories } from "../../redux/actions/productCategoryAction";
import Select from 'react-select'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import jumpTo from "../../modules/Navigation";
import ValidationModal from "../../components/ValidationModal/ValidationModal";
import { getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

const AddShopProduct = props => {
  const input = useRef(null);
  const [productname, setProductName] = useState("")
  const [productdescription, setProductDescription] = useState("")
  const [productprice, setProductPrice] = useState("")
  const [productofshop, setShop] = useState("")
  const [productofcategory, setcategory] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedShop, setSelectedShop] = useState([])
  const [image, setImage] = useState("")
  const [ifiles, setiFiles] = useState([])
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [storage] = useState(Storage.values)
  const [mounted, setMounted] = useState(false)
  const [coordinates, setCoordinates] = React.useState()
  const [productLocationName, setProductLocationName] = React.useState();
  const [productLocationDescription, setProductLocationDescription] = React.useState();
  const [nameErr, setNameErr] = useState(false)
  const [productLocationErr, setProductLocationErr] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);
  const {
    // ready,
    value: locationName,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
    },
    debounce: 300,
  })
  const dispatch = useDispatch();
  useEffect(() => {
    if (!mounted) {
      dispatch(getShopOwnerIdUsingUserId(loggedInUser.id)).then((res) => {
        dispatch(getAllShops(res));
      }).catch((error) => {
        console.log(error);
      });
    dispatch(getAllCategories());
    }
    setMounted(true)
  }, [dispatch, mounted])

  const focus = (e) => {
    switch (e.target.name) {
      case "productName":
        setNameErr(false);
        break;
      case "productLocation":
         setProductLocationErr(false);
        break;
      default:
        break;
    }
  }
  const blur = (e) => {
    switch (e.target.name) {
      case "productname":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
          setNameErr(true);
        }
        break;
      case "productLocation":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
            setProductLocationErr(true);
        }
        break;
      default:
        break;
    }
  }
  const onLocationSelect = ({ description, structured_formatting: { main_text } }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    setProductLocationName(description);
    setProductLocationDescription(main_text);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setCoordinates([lat, lng].join(","))
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  
  const handleSubmit = async () => {
    if (
      productname !== undefined &&
      productdescription !== undefined &&
      productprice !== undefined &&
      productofshop !== undefined &&
      productofcategory !== undefined &&
      productLocationName !== undefined &&
      productLocationDescription !== undefined &&
      coordinates !== undefined &&
      selectedShop.length > 0 &&
      selectedCategory.length > 0 &&
      !nameErr && !productLocationErr) {
        setLoading(true);
        let selectedcategory = selectedCategory.map(item => item.value)
        let selectedshop = selectedShop.map(item => item.value)
        let data = {
          productname,
          productdescription,
          productprice,
          files,
          selectedshop,
          selectedcategory,
          productLocationName,
          productLocationDescription,
          coordinates
        }
        await dispatch(addShopProduct(data))
        setLoading(false);
        jumpTo("/manageshopproducts");
    } else {
        setErrorModal(true);
    }
};

  const handleFileChange = async (event) => {
    event.preventDefault();
    try {
      const upload = event.target.files;
      const name = event.target.name;

      if (!upload || !upload.length) {
        return;
      }

      let file = upload[0];

      setiFiles([...ifiles, file])
      console.log("handleChange:file", file);
      // FileUploader.validate(file, {
      //     storage: props.storage,
      //     image: true,
      // });

      let res;
      if (name === "shopProduct") {
        res = await uploadFile(file, {
          storage: storage.shopProductImages,
          image: true,
        });
      } else {
        res = await uploadFile(file, {
          storage: storage.identificationDocumentIdImages,
          image: true,
        });
      }
      console.log("handleChange:res", res);
      res.type = name;

      input.current.value = "";
      setFiles([...files, res]);
    } catch (error) {
      input.current.value = "";
      console.error(error);
    }
  };
  const handleRemove = (id) => {
    const list = files.filter((item) => item.id !== id);
    setFiles(list);
  };
  const doPreviewImage = (image) => {
    setImage({
      src: image.downloadUrl,
      alt: image.name,
    });
  };

  const doCloseImageModal = () => {
    setImage(null);
  };

  return (

    <div className="" >
      <div className="container mt-5">
        <div className="row mt-5" style={{"paddingTop":"50px", "alignItems": "center" ,"justifyContent": "center"}}>
          <div className="col-md-6 text-center mt-3">
            <span style={{ "display": "flex", "marginLeft": "20%" }}>
              <h2 className="display-5 text-black">Add Shop Products</h2>
            </span>
            <div className="login-form mt-3">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Product Name "
                  id="productname"
                  name="productname"
                  value={productname}
                  style={nameErr ? { "border": "2px solid red" } : null}
                  onChange={e => setProductName(e.target.value)}
                  autoComplete="false"
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {nameErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: Pinon Gearbox</span>}
              </div>
              
              <div className="form-group">
                <textarea 
                className="form-control" 
                placeholder="Product Description" 
                id="productdescription" 
                name="productdescription" 
                value={productdescription}
                onChange={e=> setProductDescription(e.target.value)}
                autoComplete="false"
                cols="30" 
                rows="10"
                ></textarea>
              </div>
              <div className="form-group">
              <input
                  type="number"
                  className="form-control"
                  placeholder="Product Price "
                  id="productprice"
                  name="productprice"
                  value={productprice}
                  onChange={e => setProductPrice(e.target.value)}
                  autoComplete="false"
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
              </div>

              <div className="form-group">
                <Select placeholder={"Choose Product Category"} isMulti onChange={setSelectedCategory} options={props.categories?.map(category => {
                  return {
                    value: category.id,
                    label: category.categoryName
                  }
                })} />
              </div>
              <div className="form-group">
                <Select placeholder={"Choose Shop"} isMulti onChange={setSelectedShop} options={props.shops?.map(shop => {
                  return {
                    value: shop.id,
                    label: shop.shopName
                  }
                })} />
              </div>
              <div className="form-group">
                <ImagesUploaderWrapper>
                  <label
                    className="btn btn-outline-secondary px-4 mb-2 ml-3"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fas fa-plus"></i>
                    {" Add Product Image"}
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      type="file"
                      name="shopProduct"
                      onChange={(e) => handleFileChange(e)}
                      ref={input}
                    // disabled={files.length !== 2}
                    />
                  </label>
                  {files ? (
                    <div className="d-flex flex-row flex-wrap">
                      {files.map((item) => {
                        return (
                          <div
                            className="mr-2 mb-2 img-card"
                            style={{ height: "100px" }}
                            key={item.id}
                          >
                            <img
                              alt={item.name}
                              src={item.downloadUrl}
                              className="img-thumbnail"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />

                            <div className="img-buttons rounded-bottom">
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => doPreviewImage(item)}
                              >
                                <i className="fas fa-search"></i>
                              </button>
                              <button
                                type="button"
                                className="btn btn-link ml-2"
                                onClick={() => handleRemove(item.id)}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  {image && (
                    <ImageModal
                      src={image.src}
                      alt={image.alt}
                      onClose={() => doCloseImageModal()}
                    />
                  )}
                </ImagesUploaderWrapper>
              </div>
              <div className="form-group">
                <input type="text" id="productLocation" name="productLocation" className="form-control" value={locationName} placeholder="Product Location"
                  onChange={(e) => setValue(e.target.value)}
                  style={productLocationErr ? { "border": "2px solid red" } : null}
                  onBlur={e => blur(e)}
                  onFocus={e => focus(e)}
                />
                {productLocationErr && <span style={{ "fontSize": "15px", "color": "red" }}>Please select a location</span>}
                <i className="fa fa-map-marker"></i>
                {status === "OK" && <ul className="list-group">
                  {
                    data.map((suggestion) => {
                      const {
                        place_id,
                        structured_formatting: { main_text, secondary_text },
                      } = suggestion;

                      return (
                        <li className="list-group-item" key={place_id} onClick={onLocationSelect(suggestion)}>
                          <strong>{main_text}</strong><small>{` ${secondary_text}`}</small>
                        </li>
                      );
                    })
                  }
                </ul>}
              </div>
             
              <div style={{ "marginBottom": "20px" }}>
                {errorMessage && <span
                  style={{
                    "padding": "10px",
                    "backgroundColor": "red",
                    "color": "white",
                  }}
                >{errorMessage}</span>}</div>
              <ValidationModal show={errorModal} onHide={setErrorModal} message={
                `Please fill in all the required fields correctly. Ensure that you have uploaded at least the product image.`
              } />
              <LoadingButton
                  type="button"
                  className="log-btn"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Add Product
                </LoadingButton>
             
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

const mapDispatchToProps = dispatch=>( {
  addShopProduct:() => dispatch(addShopProduct()),
  getAllShops:(shopOfShopOwner) => dispatch(getAllShops(shopOfShopOwner)),
  getAllCategories:() => dispatch(getAllCategories()),
});
const mapStoreToProps = (state) => ({
    shops: state.shop.shops,
    categories: state.category.categories,
});

export default connect(mapStoreToProps, mapDispatchToProps)(AddShopProduct);