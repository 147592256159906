/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";

import BackgroundImage1 from "../../assets/images/slider_1.jpg";
import BackgroundImage2 from "../../assets/images/slider_2.jpg";
import BackgroundImage3 from "../../assets/images/slider_3.jpg";
import { Carousel } from "react-bootstrap";
import LoginRegister from "../LoginRegisterModal/index";

function HomeBanner(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [login, setLogin] = React.useState(false)
  const showHideModal = () => {
    setModalShow(false)
  };

  const registerClicked = () => {
    setModalShow(true)
  };
  const loginClicked = () => {
    setLogin(true)
  };
  return (
    <>
      <Carousel
        variant="dark"
        controls={false}
      >
        <Carousel.Item>
          <div
            className="d-block w-100 main_slider"
            style={{
              backgroundImage: `url(${BackgroundImage2})`,
            }}
          >
            <div className="container fill_height">
              <div className="row align-items-center fill_height">
                <div className="col">
                  <div className="main_slider_content" data-aos="fade-right">
                    <h6>Mech Wangu</h6>
                    <h1>Get connected to 1000+ Mechanics</h1>
                    <a href="/search">
                      <div className="red_button shop_now_button" style={{ "color": "white" }}>
                        Search
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="d-block w-100 main_slider"
            style={{
              backgroundImage: `url(${BackgroundImage3})`,
            }}
          >
            <div className="container fill_height">
              <div className="row align-items-center fill_height">
                <div className="col">
                  <div className="main_slider_content" data-aos="fade-right">
                    <h6>Mech Wangu</h6>
                    <h1>Search a veriety of services</h1>
                    <a href="/search">
                      <div className="red_button shop_now_button" style={{ "color": "white" }}>
                        Search
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            className="d-block w-100 main_slider"
            style={{
              backgroundImage: `url(${BackgroundImage1})`,
            }}
          >
            <div className="container fill_height">
              <div className="row align-items-center fill_height">
                <div className="col">
                  <div className="main_slider_content" data-aos="fade-right">
                    <h6>Mech Wangu</h6>
                    <h1>We have authentic reviews for you to choose better</h1>
                    <a href="/search">
                      <div className="red_button shop_now_button" style={{ "color": "white" }}>
                        Search
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      {modalShow ? (
        <LoginRegister
          show={modalShow}
          login={login}
          registerClicked={() => registerClicked()}
          loginClicked={() => loginClicked()}
          onHide={() => showHideModal()}
        />
      ) : null}
    </>
  );
}

export default HomeBanner;
