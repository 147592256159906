/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Button, Col, Image, ListGroup, Row } from "react-bootstrap";
import LoginRegister from "../../components/LoginRegisterModal";
import Auth from "../../modules/Auth";
import mechanicDefaultProfile from "../../assets/images/mechwangu_default_prof.svg";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Loader from "../../components/Loader/Loader";
import mechMob from "../../assets/images/mech_mob.svg";
import Select from 'react-select'

class SingleMechanic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
      size: "",
      pic: "",
      selectedSize: "",
      id: "",
      quantity: 1,
      modalShow: false,
      login: true,
      matches: window.matchMedia("(min-width: 768px)").matches,
      service: [],
      requested: false,
      rating: 0,
    };
  }
  componentDidMount() {
    this.props.getMechanic(this.props.location.pathname.split("/").slice(-1)[0]).then(res => {
      let rating = 0;
      res.data.mechanicReviews.map(review => {
        rating = rating + parseInt(review.mechanicReviewRating);
      })
      rating = rating / this.props.mechanic.mechanicReviews.length;
      rating = Number.isNaN(rating) ? 0 : rating;
      this.setState({
        rating: rating
      })
    })
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  handleThumbnailClick = (item) => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: "",
      id: item._id,
      cartItem: null,
    });
  };

  onAddClicked = () => {
    this.setState({ quantity: this.state.quantity + 1 });
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      true,
      false
    );
  };
  onRemoveClicked = () => {
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      false,
      true
    );

    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.props
        .postCart(
          this.state.id || this.props.location.pathname.split("/").slice(-1)[0]
        )
        .then((res) => {
          console.log(res);
        });
    } else {
      this.setState({ modalShow: true });
    }
  };

  productInCart = () => {
    let available = false;
    // let items = this.props.cart.items;
    // if (items !== undefined && items !== null) {
    //   let itemCheck = Object.keys(items).map(
    //     id => items[id].item.title === this.props.product.title
    //   );

    //   if (itemCheck !== undefined && itemCheck !== null) {
    //     this.setState({ cartItem: itemCheck });
    //     available = true;
    //   } else {
    //     available = false;
    //   }
    // }

    return available;
  };

  setService = (e) => {
    this.setState({ service: e.value });
  }

  render() {
    let mechImage = this.props.mechanic && this.props.mechanic.image
    console.log("Locationzz" + JSON.stringify(this.props.mechanic));
    return (
      <div style={!this.props.mechanic ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        {this.props.mechanic ?
          <div className="container single_product_container">
            {this.props.mechanic && (
              <div>
                <div className="row">
                  <div className="col">
                    <div className="breadcrumbs d-flex flex-row align-items-center">
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            Mechanics
                          </a>
                        </li>
                        <li className="active">
                          <a href="#">
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                            {this.props.mechanic.mechanicUserId.firstName}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <div className="single_product_pics">
                      <div className="row">
                        <div className="col-lg-9 image_col order-lg-2 order-1">
                          {!this.state.matches ?
                            <div style={!this.state.matches ? {
                              "display": "flex",
                              "alignItems": "center",
                              "justifyContent": "center",
                            } : null}>
                              <Image src={mechMob} />
                            </div>
                            : <div className="single_product_image">
                              <div
                                className="single_product_image_background"
                                style={{
                                  backgroundImage: `url(${mechImage ? `https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${mechImage.privateUrl}` : mechanicDefaultProfile
                                    })`,
                                }}
                              />
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="product_details">
                      <div className="product_details_title">
                        <h2 style={!this.state.matches ? {
                          "textAlign": "center",
                          "textTransform": "uppercase"
                        } : {
                          "textTransform": "uppercase"
                        }}>{this.props.mechanic.mechanicUserId.fullName ? <><strong>{this.props.mechanic.mechanicUserId.fullName}</strong></> : <><strong>{this.props.mechanic.mechanicUserId.firstName || this.props.mechanic.mechanicUserId.lastName}</strong></>}</h2>
                        <div style={!this.state.matches ? {
                          "display": "flex",
                          "alignItems": "center",
                          "justifyContent": "center",
                        } : null}>
                          <StarRatings
                            rating={this.state.rating}
                            starRatedColor="yellow"
                            // changeRating={setRating}
                            numberOfStars={5}
                            name='rating'
                            starDimension="30px"
                            starSpacing="1px"
                          />
                          <span>{`(${this.props.mechanic.mechanicReviews.length})`}</span>
                        </div>
                        <p style={!this.state.matches ? {
                          "textAlign": "center"
                        } : null}>
                          <i className="fas fa-map-marker"></i> {this.props.mechanic.areaOfOperation?.locationName}
                        </p>
                        <p style={!this.state.matches ? {
                          "textAlign": "center"
                        } : null}>{this.props.mechanic.description}</p>
                      </div>
                      <span style={!this.state.matches ? {
                        "textAlign": "center"
                      } : null}>
                        <h6><b>Mech's Speciality:</b></h6>
                        {
                          this.props.mechanic.mechanicServiceSpecialities.map(speciality => <li>{speciality.servicesName} </li>)
                        }
                      </span>
                      <br />
                      {/* <span>
                        <h6>Mech's Garages:</h6>
                        <ul className="star_rating">
                          {
                            // console.log("Mechani "+JSON.stringify(this.props.mechanic.mechanicsGarages))
                            this.props.mechanic.mechanicsGarages.map(garage => <li>{garage.garageName}</li>)
                          }
                        </ul>
                      </span> */}
                      {!this.state.requested ? <>
                        <div
                          className="red_button product-add_to_cart_button align-items-center justify-content-center"
                          onClick={() => this.setState({ requested: true })}
                          style={!this.state.matches ? {
                            "marginBottom": "10px",
                            "cursor": "pointer",
                          } : {
                            "marginTop": "10px",
                            "cursor": "pointer",
                          }}
                        >
                          <span>Request service  </span>
                          <span>
                            <i className="fas fa-wrench"></i>
                          </span>
                        </div></> : <>
                        <Button variant="secondary" style={{ "marginLeft": "20px", "width": "90%" }} onClick={(e) => this.setState({ requested: false })}>
                          <span>Cancel</span>
                          <span style={{
                            "marginLeft": "10px"
                          }}>
                            <i className="fas fa-times"></i>
                          </span>
                        </Button>
                        <div className="form-group">
                          <ServiceSelector services={this.props.mechanic.mechanicServiceSpecialities} set={this.setService} />
                        </div>
                        {this.state.service.length > 0 ? <>
                          <Link to={{ pathname: "/express", state: { mechId: this.props.mechanic._id, service: this.state.service, mech: this.props.mechanic } }} >
                            <div className="red_button product-add_to_cart_button align-items-center justify-content-center" style={!this.state.matches ? {
                              "marginBottom": "10px"
                            } : null}>
                              <span>Request service  </span>
                              <span>
                                <i className="fas fa-wrench"></i>
                              </span>
                            </div>
                          </Link>
                        </> : null}
                      </>}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div style={!this.state.matches ? {
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginTop": "20px"
                    } : {
                      "display": "flex"
                    }}>
                      <h3><strong>Reviews: </strong></h3>
                    </div>
                    {this.props.mechanic.mechanicReviews.length > 0 ?
                      this.props.mechanic.mechanicReviews.map(review => {
                        return (
                          <div>
                            <div style={{
                              "cursor": "pointer",
                              "marginTop": "10px",
                              "marginBottom": "10px",
                              "border": "0.5px solid #F5F5F5",
                              "borderRadius": "5px",
                              "padding": "10px",
                              "width": "100%",
                            }}>
                              <div>
                                <Row>
                                  <Col md={3}>
                                    <div style={!this.state.matches ? {
                                      "display": "flex",
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                    } : null}>
                                      <img class="rounded-circle" width="70px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" style={{
                                        marginBottom: "5px",
                                      }} />
                                    </div>
                                  </Col>
                                  <Col md={7}>
                                    <div style={!this.state.matches ? {
                                      "textAlign": "center"
                                    } : null}>
                                      <h5>{review.fullName ? review.fullName : "Anonymous"}</h5>
                                      <i class="far fa-star"></i> {parseInt(review.mechanicReviewRating)}
                                      <p>{review.comments}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      :
                      <div style={!this.state.matches ? {
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "marginTop": "10px"
                      } : {
                        "marginTop": "10px"
                      }}>
                        <h5>No reviews yet</h5>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )
            }

            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          </div > :
          <Loader />}
      </div>
    );
  }
}

const ServiceSelector = (props) => {
  return (
    <div className="form-group">
      <span>Choose a service:</span>
      <Select placeholder={"Click to choose"} onChange={e => props.set(e)} options={props.services?.map(service => {
        return {
          value: service.id,
          label: service.servicesName
        }
      })} />
    </div>
  )
}

export default SingleMechanic;
