/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import React, { Component } from "react";
 import { Image } from "react-bootstrap";
 import LoginRegister from "../../components/LoginRegisterModal";
 import garageDefaultProfile from "../../assets/images/mechwangu_default_garage.svg";
 import mechMob from "../../assets/images/mech_mob.svg";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import jumpTo from "../../modules/Navigation";
 
 class SingleShopProductView extends Component {
   constructor(props) {
     super(props);
     this.state = {
       color: "",
       size: "",
       pic: "",
       selectedSize: "",
       id: "",
       quantity: 1,
       showContactDetails: false,
       modalShow: false,
       login: true,
     };
     this.handleClick = this.handleClick.bind(this);
   }
   componentDidMount() {
     this.props.getShopProduct(this.props.location.pathname.split("/").slice(-1)[0]);
     console.log("ShopProduct " + JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0]));
    
   }
   async componentDidUpdate(prevProps){
    if(this.props.shopproduct !==prevProps.shopproduct){
     await this.props.getShopOwner(this.props.shopproduct.productOfShop.shopOfShopOwner); 
     await this.props.getShopOwnerUserDetails(this.props.shopowner.isAlsoUser._id);
    }

   }
 
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
   handleClick() {
    this.setState({ showContactDetails: true });
    };
   
 
   render() {
    let shopProductImage = this.props.shopproduct && this.props.shopproduct.productImage[0]
     console.log("Locationzz" + JSON.stringify(this.props.shopproduct));
     return (
      <div>
        {this.props.shopproduct ?
       <div className="container single_product_container">
         {this.props.shopproduct &&(
           <div>
             <div className="row">
               <div className="col">
                 <div className="breadcrumbs d-flex flex-row align-items-center my-3">
                   <ul>
                     <li>
                       <a href="/">Home</a>
                     </li>
                     <li>
                       <a href="/shop">
                         <i className="fa fa-angle-right" aria-hidden="true"></i>
                         Products
                       </a>
                     </li>
                     <li className="active">
                       <a href="#">
                         <i className="fa fa-angle-right" aria-hidden="true"></i>
                         {this.props.shopproduct.productName}
                       </a>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
 
             <div className="row">
               <div className="col-lg-12 text-center">
                 <div className="single_product_pics">
                   <div className="row">
                     <div className="col-lg-12 image_col order-lg-2 order-1">
                               <div className="single_product_image">
                                <div
                                  className="single_product_image_background"
                                  style={{
                                    backgroundImage: `url(${shopProductImage ? `https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${shopProductImage.privateUrl}` : garageDefaultProfile
                                      })`,
                                  }}
                                />
                              </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-lg-12 text-center">
                 <div className="product_details">
                   <div className="product_details_title">
                     <h2>{this.props.shopproduct.productName} </h2>
                     <p>{this.props.shopproduct.productOfCategory.categoryName}</p>
                     <span>
                        <h6>
                          <a className="text-danger"
                            href={
                              "#"}
                          >
                            {this.props.shopproduct.productOfShop.shopName}
                          </a>
                        </h6>
                        </span>
                      <h6>Product Description:</h6>
                      <p>{this.props.shopproduct.productDescription}</p>
                      <h6>Product Location:</h6> 
                      <p>{this.props.shopproduct.productLocation.locationName}</p>

                      <button className="btn btn-outline-danger mb-3" onClick={this.handleClick}>Show Contact Details</button>
                      {this.state.showContactDetails && (
                        <div>
                          <h6>To Order Contact:</h6> 
                          <p><strong className="text-danger">Name: </strong>{this.props.shopownerdetails.fullName}</p>
                          <p><strong className="text-danger">Phone Number: </strong><strong><a href={`tel:${this.props.shopownerdetails.phoneNumber}`}>{this.props.shopownerdetails.phoneNumber}</a></strong></p>
                          <p><strong className="text-danger">Email Address: </strong>{this.props.shopownerdetails.email}</p>
                        </div>
                      )}
                     {/* <div className="product_price">
                     Located at {this.props.garage.areaOfOperation.locationName}
                   </div> */}
 
                   </div>
                   <div
                       className="red_button product-add_to_cart_button align-items-center justify-content-center"
                       onClick={()=>jumpTo('/shop')}>
                       Back to Products
                       <i className="fas fa-cart-arrow-down ml-2"></i>
                     </div> 
                   
                   <div className="quantity d-flex flex-column flex-sm-row align-items-sm-center">
                     {/* <span>Quantity:</span> */}
 
                     {/* <div className="quantity_selector">
                       <span
                         className={
                           this.state.quantity > 1 ? "minus" : "minus disabled"
                         }
                         onClick={() => this.onRemoveClicked()}
                       >
                         <i className="fa fa-minus" aria-hidden="true"></i>
                       </span>
                       <span id="quantity_value">{this.state.quantity}</span>
                       <span
                         className="plus"
                         onClick={() => this.onAddClicked()}
                       >
                         <i className="fa fa-plus" aria-hidden="true"></i>
                       </span>
                     </div> */}
 
                      
 
 
                     {/* <div className="product_favorite d-flex flex-column align-items-center justify-content-center">
                       <i className="far fa-heart"></i>
                     </div> */}
                   </div>
                 </div>
               </div>
             </div>
         )
         }
 
         <LoginRegister
           show={this.state.modalShow}
           login={this.state.login}
           registerClicked={() => this.registerClicked()}
           loginClicked={() => this.loginClicked()}
           onHide={() => this.showHideModal()}
         />
       </div>
       :
         <Loader />}
       </div>
     );
   }
 }
 
 export default SingleShopProductView;
 