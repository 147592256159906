/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import Auth from "../../modules/Auth";
import NewArrivals from "../../components/Products/NewArrivals";
import LoginRegister from "../../components/LoginRegisterModal";
import Loader from "../../components/Loader/Loader";

class Mechanic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true,
      sorted: [],
      timeData: {},
    };
    this.addToBag = this.addToBag.bind(this);
  }

  componentDidMount() {
    this.props.getAllMechanics({
      limit: 9,
    })
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  addToBag = params => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      let cart = this.props.postCart(params);
      cart.then(res => {
        console.log(res);
      });
    } else {
      this.setState({ modalShow: true });
    }
  };

  render() {
    const mechanics = this.props.sorted ? this.props.sorted.data : this.props.mechanics;
    return (
      <div style={!mechanics ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        {mechanics ?
          <>
            <br />
            {/* <CarCategoryBanner /> */}
            {mechanics ? (
              <NewArrivals
                products={mechanics}
                addToBag={this.addToBag}
                onHide={() => this.showHideModal()}
              />
            ) : null}
            {/* <Benefit /> */}
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          </>
          :
          <Loader />}
      </div>
    );
  }
}

export default Mechanic;
