import React, { useState, useEffect } from "react";
import Validator from "../../utils/Validator";
import { DATE_RULE } from "../../utils/Validator/rule";
import { connect, useDispatch } from "react-redux";
import LoadingButton from "../../components/LoadingButton";
import { getShopOwnerShopProducts, updateShopProductPriority } from "../../redux/actions/shopProductAction";
import Select from 'react-select'
import ValidationModal from "../../components/ValidationModal/ValidationModal";
import { getShopOwner, getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";
import { updateAdvert } from "../../redux/actions/advertisementAction";
import jumpTo from "../../modules/Navigation";

const EditAdvert = props => {
  const [showMessage, setShowMessage] = useState(false)
  const [advertId, setAdvertId] = useState()
  const [advertExpiry, setAdvertExpiry] = useState()
  const [advertBelongToShopOwner, setAdvertBelongToShopOwner] = useState()
  const [advertPackage, setAdvertPackage] = useState()
  const [advertStatus, setAdvertStatus] = useState()
  const [advertPaymentTransactionCode, setAdvertPaymentTransactionCode] = useState()
  const [selectedShopProduct, setSelectedShopProduct] = useState([])
  const [sponsorshipPriority, setSponsorshipPriority] = useState()
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [dateErr, setDateErr] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  let loggedInUser = JSON.parse(localStorage.getItem("auth"));
  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!mounted) {
        dispatch(getShopOwnerIdUsingUserId(loggedInUser.id)).then((res) => {
            dispatch(getShopOwner(res)).then((response) => {
              setAdvertBelongToShopOwner(response.data);
              setAdvertPackage(response.data.shopOwnersPackageSubscription);
              setSponsorshipPriority(response.data.shopOwnersPackageSubscription.advertPackagePriorityLevel);
            })
          }).catch((error) => {
            console.log(error);
          });
        dispatch(getShopOwnerShopProducts(loggedInUser.id));
        setAdvertId(props.location.state.advert._id)
        setSelectedShopProduct(props.location.state.advert.advertProduct)
        setAdvertExpiry(props.location.state.advert.advertExpiry)
        setAdvertPaymentTransactionCode(props.location.state.advert.adOrderPaymentId)
        setAdvertStatus("Active")
        setMounted(true)
    }
  }, [dispatch, mounted])

  const focus = (e) => {
    switch (e.target.name) {
      case "advertExpiry":
        setDateErr(false);
        break;
      default:
        break;
    }
  }
  const blur = (e) => {
    switch (e.target.name) {
      case "advertExpiry":
        if (!Validator(e.target.value, DATE_RULE)) {
          setDateErr(true);
        }
        break;
      default:
        break;
    }
  }
 
  const handleSubmit = async () => {
    if (
      advertExpiry !== undefined &&
      advertPackage !== undefined &&
      advertPaymentTransactionCode !== undefined &&
      sponsorshipPriority !== undefined &&
      selectedShopProduct.length > 0 &&
      !dateErr) {
        setLoading(true);
        let selectedshopproduct = selectedShopProduct.value;
        let data = {
          selectedshopproduct,
          advertBelongToShopOwner,
          advertExpiry,
          advertPackage,
          advertStatus,
          sponsorshipPriority,
          advertPaymentTransactionCode,
        }
        await dispatch(updateShopProductPriority(selectedshopproduct,data))
        await dispatch(updateAdvert(advertId, data))
        setLoading(false);
        jumpTo("/manageadverts");
    } else {
        setErrorModal(true);
    }
};

  return (

    <div className="" >
      <div className="container mt-5">
        <div className="row mt-5" style={{"paddingTop":"50px", "alignItems": "center" ,"justifyContent": "center"}}>
          <div className="col-md-6 text-center mt-3">
            <span style={{ "display": "flex", "marginLeft": "20%" }}>
              <h2 className="display-5 text-black">Extend Promotion</h2>
            </span>
            <div className="login-form mt-3">
              <div className="form-group">
                <Select placeholder={"Choose Product to Promote"} onChange={setSelectedShopProduct} options={props.shopproducts?.map(shopproduct => {
                  return {
                    value: shopproduct.id,
                    label: shopproduct.productName
                  }
                })} />
              </div>
              </div>
              <div className="form-group">
                    <input
                    className="form-control"
                    type="date"
                    name="advertExpiry"
                    placeholder="Advert Expiry Date"
                    value={advertExpiry}
                    onBlur={e => blur(e)} onFocus={e => focus(e)}
                    onChange={e => setAdvertExpiry(e.target.value)}
                    style={dateErr ? { "border": "2px solid red" } : null}
                    {...dateErr && <span style={{ "fontSize": "15px", "color": "red" }}>Wrong format</span>}
                    required
                    />
                    </div>
              <div style={{ "marginBottom": "20px" }}>
                {errorMessage && <span
                  style={{
                    "padding": "10px",
                    "backgroundColor": "red",
                    "color": "white",
                  }}
                >{errorMessage}</span>}</div>
              <ValidationModal show={errorModal} onHide={setErrorModal} message={
                `Please fill in all the required fields correctly`
              } />
              <LoadingButton
                  type="button"
                  className="log-btn"
                  loading={loading}
                  onClick={() => handleSubmit()}
                >
                  Update Advert
                </LoadingButton>
             
            </div>
          </div>
        </div>
      </div>
  );
}

const mapDispatchToProps = {
  updateAdvert,
  updateShopProductPriority,
  getShopOwnerShopProducts,
  getShopOwner,
};
const mapStoreToProps = (state) => ({
    shopproducts: state.shopproduct.shopproducts,
    shopowner: state.shopowner.shopowner
});

export default connect(mapStoreToProps, mapDispatchToProps)(EditAdvert);