import React, { Component } from "react";
import Heading from "../Heading";
import SingleAdvertHolder from "./SingleAdvert";

// import "../../assets/css/button.css";

class MyAdverts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adverts: this.props.adverts
    };
  }

  render() {
    const { adverts} = this.props;

    return (
      <div className="best_sellers">
        <div className="container">
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            {adverts &&
              adverts.map((item, index) => {
                return (
                  <div
                    key={index}
                    data-aos="zoom-in"
                    style={{
                      "marginBottom":  "30px"
                    }}
                  >
                    <SingleAdvertHolder
                      advertItem={item}
                    />
                  </div>
                );
              })} 
          </div>
        </div>
      </div>
    );
  }
}

export default MyAdverts;
