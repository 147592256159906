/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllRequests,
  applyFilters,
  getRequestsByMechanicId
} from "../../redux/actions/requestAction";
import {
  getMechanicIdUsingUserId
} from "../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import ClientRequestsMech from "./ClientRequestsMech";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  requests: state.request.requests,
  departments: state.department.departments,
  loading: state.request.loading,
  mechRequests: state.request.mechRequests,
});
const mapDispatchToProps = dispatch => ({
  getMechanicIdUsingUserId: userId => dispatch(getMechanicIdUsingUserId(userId)),
  getRequestsByMechanicId: mechanicId => dispatch(getRequestsByMechanicId(mechanicId)),
  getAllRequests: () => dispatch(getAllRequests()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(ClientRequestsMech);
