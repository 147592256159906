/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { verifyPhoneNumber } from "../../ServerRequest";

export const phoneVerification = (otpcode) => dispatch => {
  dispatch({
    type: VERIFICATION_BEGIN
  });

  return verifyPhoneNumber(otpcode)
    .then(res => {
      dispatch({
        type: VERIFICATION_SUCCESS,
        payload: res
      });

      return res;
    })
    .catch(error => {
      dispatch({
        type: VERIFICATION_FAIL,
        payload: { error }
      });

      throw error;
    });
};

export const VERIFICATION_BEGIN = "VERIFICATION_BEGIN";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_FAIL = "VERIFICATION_FAIL";
export const INSERT_TOKEN_SUCCESS = "INSERT_TOKEN_SUCCESS";
export const INSERT_TOKEN_FAIL = "INSERT_TOKEN_FAIL";
