import {
    applyFilters,
    filterProductsByShopOwner,
    getAllShopProducts,
    sortProductsByPrice,
    sortProductsByProximity
  } from "../../redux/actions/shopProductAction";
  import { connect } from "react-redux";
import ManageShopProducts from "./ManageShopProducts";
import { getAllCategories } from "../../redux/actions/productCategoryAction";
import { getShopOwner, getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";
  
  const mapStoreToProps = state => ({
    shopproducts: state.shopproduct.shopproducts,
    shopowner: state.shopowner.shopowner,
    categories: state.category.categories,
    categoryLoading: state.category.loading,
    departments: state.department.departments,
    loading: state.shopproduct.loading,
    sorted: state.product.sorted
  });
  const mapDispatchToProps = dispatch => ({
    getAllShopProducts: () => dispatch(getAllShopProducts()),
    getAllCategories: () => dispatch(getAllCategories()),
    getShopOwner: (id) => dispatch(getShopOwner(id)),
    getShopOwnerIdUsingUserId: (userId) =>dispatch(getShopOwnerIdUsingUserId(userId)),
    sortProductsByPrice: () => dispatch(sortProductsByPrice()),
    sortProductsByProximity: () => dispatch(sortProductsByProximity()),
    filterProductsByShopOwner: () => dispatch(filterProductsByShopOwner()),
    applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  });
  
  export default connect(mapStoreToProps, mapDispatchToProps)(ManageShopProducts);
  