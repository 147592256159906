/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import API from "../../axioss/API";

 import axios from 'axios';
 import { AuthToken } from '../../axioss/modules/Auth/authToken';
 import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
 
 export const addShopProduct = (data) => async dispatch => {
    dispatch({
      type: ADD_SHOPPRODUCT_BEGIN
    });
  
    const tenantId = AuthCurrentTenant.get();
    const token_stored = AuthToken.get();
  
    let locationData = {
      locationName: data.productLocationName,
      locationDescription: data.productLocationDescription,
      coordinates: data.coordinates,
    }
    

    const { data: { _id } } = await axios.post(
      `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/location`, {
      data: locationData
    },
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
    );
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product`;
    
    if (_id) {
      return await axios.post(
        webApiUrl,
        {
          "data": {
            "productImage": data.files,
            "productName": data.productname,
            "productDescription": data.productdescription,
            "productPrice": data.productprice,
            "productOfShop": data.selectedshop,
            "productOfCategory": data.selectedcategory,
            "productLocation": _id,
            "productSponsorship": [],
            "productSponsorshipExpiryDate": "",
            "productSponsorshipPriority": 0
          }
        },
        {
          headers: {
            "Authorization": `Bearer ${token_stored}`
          },
        },
      ).then(res => {
        console.log("AddShopProduct " + JSON.stringify(res));
        dispatch({
          type: ADD_SHOPPRODUCT_SUCCESS,
          payload: res
        });
      }).catch(error => {
        console.error("Error", error)
        dispatch({
          type: ADD_SHOPPRODUCT_FAIL,
          payload: { error }
        });
      })
    }
  };

  export const updateShopProduct = (id,data) => async dispatch => {
    console.log("Update Shop Product " + JSON.stringify(data));
    dispatch({
      type: UPDATE_SHOPPRODUCT_BEGIN
    });
  
    const tenantId = AuthCurrentTenant.get();
    const token_stored = AuthToken.get();
  
    let locationData = {
      locationName: data.productLocationName,
      locationDescription: data.productLocationDescription,
      coordinates: data.coordinates,
    }
    

    const { data: { _id } } = await axios.post(
      `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/location`, {
      data: locationData
    },
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
    );
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product/${id}`;

    const { data: existingData } = await axios.get(webApiUrl, 
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },);
    
    if (_id) {
      return await axios.put(
        webApiUrl,
        {
          "data": {
            "productImage": data.files,
            "productName": data.productname,
            "productDescription": data.productdescription,
            "productPrice": data.productprice,
            "productOfShop": data.selectedshop,
            "productOfCategory": data.selectedcategory,
            "productLocation":_id,
            "productSponsorship": existingData.productSponsorship,
            "productSponsorshipExpiryDate": existingData.productSponsorshipExpiryDate,
            "productSponsorshipPriority": existingData.productSponsorshipPriority,
          }
        },
        {
          headers: {
            "Authorization": `Bearer ${token_stored}`
          },
        },
      ).then(res => {
        console.log("UPDATEShopProduct " + JSON.stringify(res));
        dispatch({
          type: UPDATE_SHOPPRODUCT_SUCCESS,
          payload: res
        });
      }).catch(error => {
        console.error("Error", error)
        dispatch({
          type: UPDATE_SHOPPRODUCT_FAIL,
          payload: { error }
        });
      })
    }
  };

  export const updateShopProductPriority = (id,data) => async dispatch => {
    console.log("Update Shop Product " + JSON.stringify(data));
    const tenantId = AuthCurrentTenant.get();
    const token_stored = AuthToken.get();
    dispatch({
      type: UPDATE_SHOPPRODUCT_PRIORITY_BEGIN
    });
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product/${id}`;

    const { data: existingData } = await axios.get(webApiUrl, 
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },);

  const res = await axios.put(
    webApiUrl,
    {
      "data": {
        "productImage": existingData.productImage,
        "productName": existingData.productName,
        "productDescription": existingData.productDescription,
        "productPrice": existingData.productPrice,
        "productOfShop": existingData.productOfShop,
        "productOfCategory": existingData.productOfCategory,
        "productLocation":existingData.productLocation,
        "productSponsorship": [],
        "productSponsorshipExpiryDate": data.advertExpiry,
        "productSponsorshipPriority": data.sponsorshipPriority
      }
    },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
    ).then(res => {
      console.log("UpdateShopProductPriority " + JSON.stringify(res));
      dispatch({
        type: UPDATE_SHOPPRODUCT_PRIORITY_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_SHOPPRODUCT_PRIORITY_FAIL,
        payload: { error }
      });
    })
  };
   
  

  export const deleteShopProduct = (id) => async dispatch => {
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();

    dispatch({
      type: DELETE_SHOPPRODUCT_BEGIN
    });
  
    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product?ids%5B%5D=${id}`;
  
    const res = await axios.delete(
      webApiUrl,
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
      ).then(res => {
        console.log("DeleteShopProduct " + JSON.stringify(res));
        dispatch({
          type: DELETE_SHOPPRODUCT_SUCCESS,
          payload: res
        });
      }).catch(error => {
        console.error("Error", error)
        dispatch({
          type: DELETE_SHOPPRODUCT_FAIL,
          payload: { error }
        });
      })
      return res;
    };
 export const getAllShopProducts = () => async dispatch => {
   dispatch({
     type: GET_ALL_SHOPPRODUCTS_BEGIN
   });
 
   //const invitationToken = undefined; //Todo
 
   const token_stored = AuthToken.get();
   const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
 
   let webApiUrlGetTenant = `https://mapi.mechwangu.co.ke/api/tenant`;
   axios.get(
     webApiUrlGetTenant,
   ).then(res => {
 
     let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product`;
 
 
     axios.get(
       webApiUrl,
       {
         headers: {
           "Authorization": `Bearer ${token_stored}`
         }
       }).then(res => {
         console.log("SHOPPRODUCTS " + JSON.stringify(res));
         dispatch({
           type: GET_ALL_SHOPPRODUCTS_SUCCESS,
           payload: res
         });
       })
       .catch(error => {
         console.error("Error", error)
         dispatch({
           type: GET_ALL_SHOPPRODUCTS_FAIL,
           payload: { error }
         });
       })
   })
 };
 export const getShopOwnerShopProducts = (userId) => async dispatch => {
   dispatch({
     type: GET_SHOPOWNER_SHOPPRODUCTS_BEGIN
   });
 
   //const invitationToken = undefined; //Todo
 
   const token_stored = AuthToken.get();
   const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
 
   let webApiUrlGetTenant = `https://mapi.mechwangu.co.ke/api/tenant`;
   axios.get(
     webApiUrlGetTenant,
   ).then(res => {
 
     let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product?filter%5BcreatedBy%5D=${userId}`;
 
 
     axios.get(
       webApiUrl,
       {
         headers: {
           "Authorization": `Bearer ${token_stored}`
         }
       }).then(res => {
         console.log("SHOPPRODUCTS " + JSON.stringify(res));
         dispatch({
           type: GET_SHOPOWNER_SHOPPRODUCTS_SUCCESS,
           payload: res
         });
       })
       .catch(error => {
         console.error("Error", error)
         dispatch({
           type: GET_SHOPOWNER_SHOPPRODUCTS_FAIL,
           payload: { error }
         });
       })
   })
 };
 
 export const getShopProduct = id => dispatch => {
   dispatch({
     type: GET_SHOPPRODUCT_BEGIN
   });
 
   const token_stored = AuthToken.get();
   const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
 
   let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product/${id}`;
 
   return axios.get(webApiUrl, {
     headers: {
       "Authorization": `Bearer ${token_stored}`
     }
   }
   ).then(res => {
     console.log("ShopProduct " + JSON.stringify(res));
     dispatch({
       type: GET_SHOPPRODUCT_SUCCESS,
       payload: res
     });
     return res;
   })
     .catch(error => {
       dispatch({
         type: GET_SHOPPRODUCT_FAIL,
         payload: { error }
       });
       return error;
     });
 };
 
 export const getShopProductsByCategory = categoryId => dispatch => {
   dispatch({
     type: GET_SHOPPRODUCTS_BY_CATEGORY_BEGIN
   });
   const token_stored = AuthToken.get();
   const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
 
   let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/shop-product?filter%5BproductOfCategory%5D=${categoryId}`;
 
   return axios.get(webApiUrl, {
     headers: {
       "Authorization": `Bearer ${token_stored}`
     }
   }
   ).then(res => {
    console.log("ShopProduct " + JSON.stringify(res));
       dispatch({
         type: GET_SHOPPRODUCTS_BY_CATEGORY_SUCCESS,
         payload: res
       });
       return res;
     })
     .catch(error => {
       dispatch({
         type: GET_SHOPPRODUCTS_BY_CATEGORY_FAIL,
         payload: { error }
       });
       return error;
     });
 };
 
 export const search = text => dispatch => {
   dispatch({
     type: SEARCH_BEGIN
   });
   return API({
     method: "GET",
     url: `/search?query=${text}`
   })
     .then(res => {
       dispatch({
         type: SEARCH_SUCCESS,
         payload: res
       });
       return res;
     })
     .catch(error => {
       dispatch({
         type: SEARCH_FAIL,
         payload: { error }
       });
       return error;
     });
 };
 
 export const applyFilters = filter_string => dispatch => {
   dispatch({
     type: APPLY_FILTERS_BEGIN
   });
   return API({
     method: "GET",
     url: `/products?${filter_string}`
   })
     .then(res => {
       dispatch({
         type: APPLY_FILTERS_SUCCESS,
         payload: res
       });
       return res;
     })
     .catch(error => {
       dispatch({
         type: APPLY_FILTERS_FAIL,
         payload: { error }
       });
       return error;
     });
 };
 
 export const setUserLocation = userlocation => {
  return {
    type: "SET_USER_LOCATION",
    payload: userlocation
  };
};

 export const sortProductsByProximity = () => {
  return (dispatch, getState) => {
    const { shopproducts, userLocation } = getState().shopproduct;
    if (userLocation) {

      const sortedshoproducts = shopproducts
        .map(shopproduct => {
          const coordinates=shopproduct.productLocation.coordinates.split(",");
          const lat=coordinates[0];
          const lng = coordinates[1];
          const shopproductLocation = {
            lat: lat,
            lng: lng
          };
          const distance = getDistance(userLocation, shopproductLocation);
          return { ...shopproduct, distance };
        })
        .sort((a, b) => {
          const priorityA = a.productSponsorshipPriority;
          const priorityB = b.productSponsorshipPriority;
          if(priorityA !== priorityB){
            return priorityB-priorityA
          }else{
          return a.distance - b.distance
          }
        });

      dispatch({
        type: "SORT_PRODUCTS_BY_PROXIMITY",
        payload: sortedshoproducts
      });
    }
  };
};
 export const sortProductsByPrice = () => {
  return (dispatch, getState) => {
    const { shopproducts  } = getState().shopproduct;
      const sortedshoproducts = shopproducts
        .map(shopproduct => {return {...shopproduct}})
        .sort((a, b) => {
          const priceA = a.productPrice;
          const priceB = b.productPrice;
          
            return priceA-priceB
        });

      dispatch({
        type: "SORT_PRODUCTS_BY_PRICE",
        payload: sortedshoproducts
      });
    }
  };
  export const filterProductsByShopOwner= (userId) =>{
    return (dispatch, getState) => {
      const { shopproducts  } = getState().shopproduct;
      const filteredproducts = shopproducts.filter((product)=> product.CreatedBy===userId)
      dispatch({
        type: "FILTER_PRODUCTS_BY_SHOPOWNER",
        payload: filteredproducts
      });
    }
  }

function getDistance(userLocation, shopproductLocation) {
  const lat1 = userLocation.latitude;
  const lon1 = userLocation.longitude;
  const lat2 = shopproductLocation.lat;
  const lon2 = shopproductLocation.lng;

  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2-lat1) * Math.PI / 180;
  const Δλ = (lon2-lon1) * Math.PI / 180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  const d = R * c; // in metres
  const distanceInKm = d/1000; // in km

  return distanceInKm;
}

 export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
 export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
 export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";
 
 export const SEARCH_BEGIN = "SEARCH_BEGIN";
 export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
 export const SEARCH_FAIL = "SEARCH_FAIL";
 
 export const GET_ALL_SHOPPRODUCTS_BEGIN = "GET_ALL_SHOPPRODUCTS_BEGIN";
 export const GET_ALL_SHOPPRODUCTS_SUCCESS = "GET_ALL_SHOPPRODUCTS_SUCCESS";
 export const GET_ALL_SHOPPRODUCTS_FAIL = "GET_ALL_SHOPPRODUCTS_FAIL";

 export const GET_SHOPOWNER_SHOPPRODUCTS_BEGIN = "GET_SHOPOWNER_SHOPPRODUCTS_BEGIN";
 export const GET_SHOPOWNER_SHOPPRODUCTS_SUCCESS = "GET_SHOPOWNER_SHOPPRODUCTS_SUCCESS";
 export const GET_SHOPOWNER_SHOPPRODUCTS_FAIL = "GET_SHOPOWNER_SHOPPRODUCTS_FAIL";
 
 export const GET_SHOPPRODUCT_BEGIN = "GET_SHOPPRODUCT_BEGIN";
 export const GET_SHOPPRODUCT_SUCCESS = "GET_SHOPPRODUCT_SUCCESS";
 export const GET_SHOPPRODUCT_FAIL = "GET_SHOPPRODUCT_FAIL";
 
 export const GET_SHOPPRODUCTS_BY_CATEGORY_BEGIN = "GET_SHOPPRODUCTS_BY_CATEGORY_BEGIN";
 export const GET_SHOPPRODUCTS_BY_CATEGORY_SUCCESS = "GET_SHOPPRODUCTS_BY_CATEGORY_SUCCESS";
 export const GET_SHOPPRODUCTS_BY_CATEGORY_FAIL = "GET_SHOPPRODUCTS_BY_CATEGORY_FAIL";
 
 export const ADD_SHOPPRODUCT_BEGIN = "ADD_SHOPPRODUCTS_BEGIN";
 export const ADD_SHOPPRODUCT_SUCCESS = "ADD_SHOPPRODUCT_SUCCESS";
 export const ADD_SHOPPRODUCT_FAIL = "ADD_SHOPPRODUCT_FAIL";

 export const UPDATE_SHOPPRODUCT_BEGIN = "UPDATE_SHOPPRODUCT_BEGIN";
 export const UPDATE_SHOPPRODUCT_SUCCESS = "UPDATE_SHOPPRODUCT_SUCCESS";
 export const UPDATE_SHOPPRODUCT_FAIL = "UPDATE_SHOPPRODUCT_FAIL";

 export const UPDATE_SHOPPRODUCT_PRIORITY_BEGIN = "UPDATE_SHOPPRODUCT_PRIORITY_BEGIN";
 export const UPDATE_SHOPPRODUCT_PRIORITY_SUCCESS = "UPDATE_SHOPPRODUCT_PRIORITY_SUCCESS";
 export const UPDATE_SHOPPRODUCT_PRIORITY_FAIL = "UPDATE_SHOPPRODUCT_PRIORITY_FAIL";

 export const DELETE_SHOPPRODUCT_BEGIN = "DELETE_SHOPPRODUCT_BEGIN";
export const DELETE_SHOPPRODUCT_SUCCESS = "DELETE_SHOPPRODUCT_SUCCESS";
export const DELETE_SHOPPRODUCT_FAIL = "DELETE_SHOPPRODUCT_FAIL";

export const SORT_PRODUCTS_BY_PROXIMITY = 'SORT_PRODUCTS_BY_PROXIMITY';
export const SORT_PRODUCTS_BY_PRICE = 'SORT_PRODUCTS_BY_PRICE';

export const FILTER_PRODUCTS_BY_SHOPOWNER = 'FILTER_PRODUCTS_BY_SHOPOWNER';

export const SET_USER_LOCATION="SET_USER_LOCATION"