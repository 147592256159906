import React from 'react'
import jumpTo from "../../modules/Navigation";

function SinglePackage(props) {
    const {packageItem} = props;

  return (
    <div className="mt-5">
            <div className="d-style btn btn-brc-tp border-2 bgc-white btn-outline-blue btn-h-outline-blue btn-a-outline-blue w-100 my-2 py-3 shadow-sm" data-aos="fade-up">
                <div className="row align-items-center">
                <div className="col-12 col-md-4">
                    <h4 className="pt-3 text-170 text-600 text-danger letter-spacing">
                    {packageItem.advertPackageName} Plan
                    </h4>
                    <div className="text-secondary-d1 text-120">
                    <span className="ml-n15 align-text-bottom">Ksh</span><span className="text-200">{packageItem.advertPackageCostOfPackage}</span>
                    </div>
                </div>
                <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
                        <li>
                             <span className="text-110">{packageItem.advertPackageDescription}</span>
                        </li>
                        <li>
                             <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
                             <span className="text-110">{packageItem.advertPackageNoOfProducts} Products</span>
                        </li>
                        <li>
                             <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
                             <span className="text-110">{packageItem.advertPackageDuration} Hours</span>
                        </li>
                        <li>
                             <i className="fa fa-check text-success text-110 mr-2 mt-1"></i>
                             <span className="text-110">{packageItem.advertPackageRadiusDistance} Kilometer Radius</span>
                        </li>
                </ul>
                <div className="col-12 col-md-4 text-center">
                    <a href="#" className="f-n-hover btn btn-outline-danger btn-raised px-4 py-25 w-75 text-600" onClick={() =>jumpTo(`/singlepackage/${packageItem._id}`)}>Get Started</a>
                </div>
                </div>
            </div>
      </div>
  )
}

export default SinglePackage