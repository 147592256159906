import { 
    ADD_ADVERT_BEGIN,
    ADD_ADVERT_SUCCESS,
    ADD_ADVERT_FAIL,
    GET_ALL_ADVERTS_BEGIN,
    GET_ALL_ADVERTS_SUCCESS,
    GET_ALL_ADVERTS_FAIL,
    GET_ADVERT_BEGIN,
    GET_ADVERT_SUCCESS,
    GET_ADVERT_FAIL, 
    UPDATE_ADVERT_BEGIN,
    UPDATE_ADVERT_SUCCESS,
    UPDATE_ADVERT_FAIL, 
    UPDATE_ADVERT_STATUS_BEGIN,
    UPDATE_ADVERT_STATUS_SUCCESS,
    UPDATE_ADVERT_STATUS_FAIL, 
    DELETE_ADVERT_BEGIN,
    DELETE_ADVERT_SUCCESS,
    DELETE_ADVERT_FAIL, 
    
    } from '../actions/advertisementAction';
    
    const initialState = {
      adverts: null,
      advert: null,
      loading: false,
      error: null
    };
    
    export default (state = initialState, action) => {
    switch (action.type) {
      case ADD_ADVERT_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case ADD_ADVERT_SUCCESS:
          return {
            ...state,
            loading: false,
            adverts: action.payload.data.rows
          };
        case ADD_ADVERT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
          case UPDATE_ADVERT_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case UPDATE_ADVERT_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case UPDATE_ADVERT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
          case UPDATE_ADVERT_STATUS_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case UPDATE_ADVERT_STATUS_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case UPDATE_ADVERT_STATUS_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
          case DELETE_ADVERT_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
        case DELETE_ADVERT_SUCCESS:
          return {
            ...state,
            loading: false,
            advert: action.payload.id
          };
        case DELETE_ADVERT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
    case GET_ALL_ADVERTS_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
    case GET_ALL_ADVERTS_SUCCESS:
          return {
            ...state,
            loading: false,
            adverts: action.payload.data.rows
          };
    case GET_ALL_ADVERTS_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
    case GET_ADVERT_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
    case GET_ADVERT_SUCCESS:
          return {
            ...state,
            loading: false,
            advert: action.payload.data
          };
    case GET_ADVERT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
    default:
    return state;
    }
    }
    
    