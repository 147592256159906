import React from "react";
import { Modal } from "react-bootstrap";

const ValidationModal = ({ show, onHide, message, title }) => {
    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            centered
        >
            <Modal.Header closeButton>
                {title && <Modal.Title>{title}</Modal.Title>}
            </Modal.Header>
            <Modal.Body centered>
                {message ? <span>{message}</span> : <strong>Fill in all fields</strong>}
            </Modal.Body>
        </Modal>
    )
}
export default ValidationModal;