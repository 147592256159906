/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import CarCategoryBanner from "../../components/Car/CarsBanner";
import MyRequests from "../../components/ClientRequest/MyRequests";
import jumpTo from "../../modules/Navigation";
import LoginRegister from "../../components/LoginRegisterModal";
import Loader from "../../components/Loader/Loader";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class ClientRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true
    };
  }

  componentDidMount() {
    this.props.getAllRequests();
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  modalHandler = route => {
    if (!loggedInUser) {
      this.setState({ modalShow: true });
    }
    jumpTo(route);
  };

  render() {
    const { requests, departments } = this.props;

    if (!loggedInUser) {
      return <Redirect to='/' />;
    }
    const role = loggedInUser?.tenants[0].roles[0];
    console.log("Requests " + requests);
    return (
      <div style={!requests ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        {requests ?
          <>
            <br />
            <CarCategoryBanner />
            {requests ? (
              <MyRequests
                title="My Requests"
                requests={role === "userMechanic" ? requests.filter(request => request.createdBy === loggedInUser.id) : requests}
                departments={departments}
                modalHandler={this.modalHandler}
              />
            ) : null}
            {/* <Benefit /> */}
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          </> :
          <Loader />}
      </div>
    );
  }
}

export default ClientRequest;
