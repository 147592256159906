import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Heading from "../../components/Heading";
import { addShop } from '../../redux/actions/shopAction';
import Validator from "../../utils/Validator";
import { SHOPNAME_RULE } from "../../utils/Validator/rule";
import LoadingButton from "../../components/LoadingButton";
import jumpTo from "../../modules/Navigation";
import ValidationModal from "../../components/ValidationModal/ValidationModal";
import { Redirect } from "react-router-dom";
import { getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));


const AddShop = props => {
const [shopName, setName] = useState();
const [shopNameErr, setShopNameErr] = useState(false);
const [shopOfShopOwner, setShopofShopOwner] = useState();
const [errorModal, setErrorModal] = useState(false);
const [actionLoading, setActionLoading] = useState(false);
const [mounted, setMounted] = useState(false);
const [modalShow, setModalShow] = useState(false);

const dispatch = useDispatch();

  useEffect(() => {
    if (!mounted) {
      dispatch(getShopOwnerIdUsingUserId(loggedInUser.id)).then((res) => {
        setShopofShopOwner(res);
      }).catch((error) => {
        console.log(error);
      });
    }
    setMounted(true);

  }, [dispatch, mounted]);

const submitHandler = async () => {
    if (shopName !== undefined) {
        setActionLoading(true);
        let data = {
            shopName,
            shopOfShopOwner,
        }
        await dispatch(addShop(data))
        setActionLoading(false);
        jumpTo("/shoplist");
    } else {
        setErrorModal(true);
    }
};
const showHideModal = () => {
    setModalShow(false);
};

const focus = (e) => {
    switch (e.target.name) {
        case "shopName":
            setShopNameErr(false);
            break;
            default:
            break;
        }
    }

const blur = (e) => {
    switch (e.target.name) {
        case "shopName":
             if (!Validator(e.target.value, SHOPNAME_RULE)) {
                    setShopNameErr(true);
                }
                break;
                default:
            break;
        }
    }
{/*const handleSubmit = event => {
event.preventDefault();
addNewShop(shopName);
setName('');
};*/}

if (!loggedInUser) {
    return <Redirect to='/' />;
}

return (
<div className="row mb-3 text-center" style={{"display": "flex", "flexDirection": "column","margin":"auto", "alignItems": "center" ,"justifyContent": "center", "paddingTop":"50px"}}>
<Heading title="Add Shop:" data-aos="fade-up" />
<form className="pt-5">
    <div className="form-group">
    <input
    className="form-control"
    type="text"
    name="shopName"
    placeholder="Shop Name"
    value={shopName}
    onBlur={e => blur(e)} onFocus={e => focus(e)}
    onChange={e => setName(e.target.value)}
    style={shopNameErr ? { "border": "2px solid red" } : null}
    {...shopNameErr && <span style={{ "fontSize": "15px", "color": "red" }}>Wrong format, Example: Test Enterprise</span>}
    required
    />
    </div>
    <div className="form-group">
        <LoadingButton
            type="button"
            className="btn btn-success btn-lg btn-block"
            loading={actionLoading}
            onClick={() => submitHandler()}
        >
        Add Shop
        </LoadingButton>
    </div>
</form>
<ValidationModal show={errorModal} onHide={setErrorModal} />
</div>

);
}

export default AddShop;