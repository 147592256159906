import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import axios from 'axios';

export const addAdvertPayment = data => dispatch => {
    console.log("Adding AdvertPayment " + JSON.stringify(data));
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
    dispatch({
        type: ADD_ADVERTPAYMENT_BEGIN
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advert-payment`;
    return axios.post(url, { data }, 
        {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
                },
        }
    ).then(res => {
        console.log("AdvertPayment response: " +JSON.stringify(res))
        dispatch({
            type: ADD_ADVERTPAYMENT_SUCCESS,
            payload: res
        });
        return res;
    }).catch(error => {
        dispatch({
            type: ADD_ADVERTPAYMENT_FAIL,
            payload: { error }
        });
    });
}

export const getAdvertPayment = id => dispatch => {
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();
    dispatch({
        type: GET_ADVERTPAYMENT_BEGIN
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advert-payment/${id}`;
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
        }
    }
    ).then(res => {
        dispatch({
            type: GET_ADVERTPAYMENT_SUCCESS,
            payload: res
        });
        return res;
    }).catch(error => {
        dispatch({
            type: GET_ADVERTPAYMENT_FAIL,
            payload: { error }
        });
        return error;
    });
}

export const ADD_ADVERTPAYMENT_BEGIN = "ADD_ADVERTPAYMENT_BEGIN";
export const ADD_ADVERTPAYMENT_SUCCESS = "ADD_ADVERTPAYMENT_SUCCESS";
export const ADD_ADVERTPAYMENT_FAIL = "ADD_ADVERTPAYMENT_FAIL";

export const GET_ADVERTPAYMENT_BEGIN = "GET_ADVERTPAYMENT_BEGIN"
export const GET_ADVERTPAYMENT_SUCCESS = "GET_ADVERTPAYMENT_SUCCESS"
export const GET_ADVERTPAYMENT_FAIL = "GET_ADVERTPAYMENT_FAIL"