/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import SingleMechanic from "./SingleMechanic";
import { getMechanic, postRating } from "../../redux/actions/mechanicAction";
import { getVariantsByProductId } from "../../redux/actions/variantsAction";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  mechanic: state.mechanic.mechanic,
  variants: state.variant.variants,
  rating: state.mechanic.rating,
});
const mapDispatchToProps = {
  getMechanic,
  getVariantsByProductId,
  postCart,
  postRating,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleMechanic);
