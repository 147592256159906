/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import SingleClientRequest from "./SingleClientRequest";
import { getCar } from "../../redux/actions/carAction";
import { getRequest, respondToRequest, getResponse, postRating, getRating, updateRequest } from "../../redux/actions/requestAction";
import { getVariantsByProductId } from "../../redux/actions/variantsAction";
import { postCart } from "../../redux/actions/cartAction";
import { getAllMechanics } from "../../redux/actions/mechanicAction";

const mapStoreToProps = (state) => ({
  request: state.request.request,
  variants: state.variant.variants,
  response: state.request.response,
  loading: state.request.loading,
  mechanics: state.mechanic.mechanics
});
const mapDispatchToProps = {
  getResponse,
  respondToRequest,
  getRequest,
  getVariantsByProductId,
  postCart,
  postRating,
  getRating,
  getAllMechanics,
  updateRequest,
  getCar
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleClientRequest);
