import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import axios from 'axios';

export const addOrder = data => dispatch => {
    console.log("Adding order " + JSON.stringify(data));
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";
    dispatch({
        type: ADD_ORDER_BEGIN
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-order`;
    return axios.post(url, { data }, {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
        }
    }
    ).then(res => {
        console.log("order response: " + res)
        dispatch({
            type: ADD_ORDER_SUCCESS,
            payload: res
        });
        return res;
    }).catch(error => {
        dispatch({
            type: ADD_ORDER_FAIL,
            payload: { error }
        });
    });
}
export const getOrder = id => dispatch => {
    const token_stored = AuthToken.get();
    const tenantId = AuthCurrentTenant.get();
    dispatch({
        type: GET_ORDER_BEGIN
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/client-order?filter%5BorderPaymentId%5D=${id}`;
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
        }
    }
    ).then(res => {
        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: res
        });
        return res;
    }).catch(error => {
        dispatch({
            type: GET_ORDER_FAIL,
            payload: { error }
        });
        return error;
    });
}

export const paymentToDb = data => dispatch => {
    const token_stored = AuthToken.get();
    data.requests.map(async req => {
        let url = `https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/client-request-to-mechanic/${req.id}`;

        return await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        }).then(res => {
            let entry = res.data;
            entry.status = "Complete";

            let url2 = `https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/client-request-to-mechanic/${entry.id}`;
            return axios.put(url2, {
                data: entry
            }, {
                headers: {
                    "Authorization": `Bearer ${token_stored}`,
                }
            }
            )
        }).catch(error => {
            console.log("Failed reqmod: " + error)
        })
    })

    let url = `https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/payment`;
    return axios.post(url, { data }, {
        headers: {
            "Authorization": `Bearer ${token_stored}`,
        }
    }
    ).then(res => {
        console.log("payment response: " + res)
        return res;
    }).catch(error => {
        console.log("payment error: " + error)
        return error;
    });
}

export const ADD_ORDER_BEGIN = "ADD_ORDER_BEGIN";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const UPDATE_ORDER_BEGIN = "UPDATE_ORDER_BEGIN"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

export const GET_ORDER_BEGIN = "GET_ORDER_BEGIN"
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS"
export const GET_ORDER_FAIL = "GET_ORDER_FAIL"