/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  applyFilters
} from "../../redux/actions/garageAction";
import { connect } from "react-redux";
import Garages from "./Garage";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  loggedinmechanic: state.mechanic.loggedinmechanic,
  garages: state.garage.garages,
  departments: state.department.departments,
  loading: state.car.loading
});
const mapDispatchToProps = dispatch => ({
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(Garages);
