 import { connect } from "react-redux";
 import { getShop } from "../../redux/actions/shopAction";

 import SingleShop from "./SingleShop";
 
 const mapStoreToProps = (state) => ({
   shop: state.shop.shop,
 });
 
 const mapDispatchToProps = {
   getShop,
 };
 
 export default connect(mapStoreToProps, mapDispatchToProps)(SingleShop);
 