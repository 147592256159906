import React, { Component } from "react";
import LoginRegister from "../../components/LoginRegisterModal";
import Filter from "../Category/components/Filter";
import Heading from "../../components/Heading";
import ManageShopProductCard from "../../components/ShopProducts/ManageShopProductCard";
import CircleSpinner from "../../components/Spinner/Circle";
import Fuse from "fuse.js";
import jumpTo from "../../modules/Navigation";
import ShopOwnerPackageValidatonModal from "../../components/ValidationModal/ShopOwnerPackageValidatonModal";

 
let loggedInUser = JSON.parse(localStorage.getItem("auth"));
 class ManageShopProducts extends Component {
   constructor(props) {
     super(props);
     this.state = {
       searchResults: [],
       searchQuery:'',
       searchMessage:'',
       shopproducts: this.props.shopproducts,
       productLimit: 0,
       sortingLabel: 'Default Sorting',
       modalShow: false,
       admodalShow:false,
       adlimitmodal:false,
       login: true
     };
     /*this.addToBag = this.addToBag.bind(this);*/
   }
   async componentDidMount() {
    await this.props.getShopOwnerIdUsingUserId(loggedInUser.id).then(async (res) => {
      await this.props.getShopOwner(res)
     }).catch((error) => {
       console.log(error);
     });
     await this.props.getAllShopProducts().then( () =>{
      setTimeout(() => {
        this.props.filterProductsByShopOwner(loggedInUser.id);
        this.setState({productLimit: this.props.shopproducts.length});
      },5000);
     })
     await this.props.getAllCategories();
   }
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
   hideAdModal = () => {
     this.setState({ admodalShow: false });
     this.setState({ adlimitmodal: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
 
   handleChange = e => {
    const { value } = e.target;
    this.setState({ searchQuery: value });
  };

  advertiseButtonHandler =() =>{
    const {shopowner} = this.props;
    const advertCount = shopowner.shopOwnersAdverts.length;
    const advertLimit = shopowner.shopOwnersPackageSubscription.advertPackageNoOfProducts;
    if(shopowner.shopOwnersPackageSubscription == null){
      this.setState({admodalShow: true});
    }else{
      if(advertCount < advertLimit){
        jumpTo(`/addadvert`);
      }else{
        this.setState({adlimitmodal: true});
      }
    }
  }

  setMessage = (message) => {
    this.setState({ searchMessage: message });
    console.log("searchMessage " + message);
  };

  search = async (query) => {
    const fuseInstance = new Fuse(this.props.shopproducts, {
      keys: [
        'productName',
        'productOfCategory.categoryName',
        'productOfShop.shopName'
      ],
      includeScore: true
    });
    const results = await fuseInstance.search(query);
    if (results.length > 0) {
      const productResults = results.map(shopproduct => shopproduct.item);
      this.setState({searchResults: productResults})
    } else this.setMessage("No service found");
  }
  handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      //check that searchQuery is not empty, not just spaces and return if it is
      if (this.state.searchQuery.trim() === '') return
      this.search(this.state.searchQuery);
      console.log("Search Query: "+this.state.searchQuery)
    }
  }

 
   render() {
     const { shopproducts, applyFilters } = this.props;
     const {productLimit} = this.state;
     const {sortingLabel} = this.state;
     return (
       <div className="container product_section_container">
        <Heading title="Manage Your Products:" data-aos="fade-up" />
         <div className="row pt-3">
           <div className="col product_section clearfix">
            <div className="quick-nav d-flex justify-content-between">
              <div className="breadcrumbs flex-row align-items-center my-3">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li className="active">
                    <a href="/manageshopproducts">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                      Products
                    </a>
                  </li>
                </ul>
              </div>
              <div className="product-buttons">
                  <div className="align-items-center">
                      <a href="/addshopproduct" className="btn btn-outline-secondary mr-2 mb-2 ">Add Products</a>
                  </div>
                  <div className="align-items-center">
                    <button className="btn btn-outline-secondary" onClick={() => this.advertiseButtonHandler()}>Promote Products</button>
                  </div>
              </div>
             </div>
             <div className="search-bar pb-3">
            <input
                  type="text"
                  className="form-control"
                  placeholder="Search for a Specific Product"
                  id="searchText"
                  name="search"
                  value={this.state.searchQuery}
                  onChange={this.handleChange}
                  autoComplete="false"
                  onKeyDown={this.handleKeyDown}
                  style={{
                   "width": "100%",
                    }}
                  />
            </div>
             <div className="sidebar">
             <Filter categories={this.props.categories} loading={this.props.categoryLoading} />
             </div>
             <div className="main_content">
               <div className="products_iso">
                 <div className="row">
                   <div className="col">
                     <div className="product_sorting_container product_sorting_container_top">
                       <ul className="product_sorting">
                         <li>
                           <span className="type_sorting_text">{sortingLabel}</span>
                           <i className="fa fa-angle-down"></i>
                           <ul className="sorting_type">
                             <li
                               className="type_sorting_btn"
                               onClick={()=>this.props.sortProductsByProximity()}
                             >
                               <span>Default Sorting</span>
                             </li>
                             <li
                               className="type_sorting_btn"
                               onClick={()=>{this.setState({sortingLabel: 'Price'}); this.props.sortProductsByPrice()}}
                             >
                               <span>Price</span>
                             </li>
                           </ul>
                         </li>
                         <li>
                           <span>Show</span>
                           <span className="num_sorting_text">{productLimit}</span>
                           <i className="fa fa-angle-down"></i>
                           <ul className="sorting_num">
                             <li onClick={()=>this.setState({productLimit: shopproducts.length})} className="num_sorting_btn">
                               <span>All</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 4})} className="num_sorting_btn">
                               <span>4</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 8})} className="num_sorting_btn">
                               <span>8</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 12})} className="num_sorting_btn">
                               <span>12</span>
                             </li>
                             <li onClick={()=>this.setState({productLimit: 24})} className="num_sorting_btn">
                               <span>24</span>
                             </li>
                           </ul>
                         </li>
                       </ul>
                       <div className="pages d-flex flex-row align-items-center">
                         {/*<div className="page_current">
                           <span>1</span>
                           <ul className="page_selection">
                             <li>
                               <a href="#">1</a>
                             </li>
                             <li>
                               <a href="#">2</a>
                             </li>
                             <li>
                               <a href="#">3</a>
                             </li>
                           </ul>
                         </div>
                         <div className="page_total">
                           <span>of</span> 3
                         </div>
                         <div id="next_page" className="page_next">
                           <a href="#">
                             <i className="fas fa-long-arrow-alt-right"></i>
                           </a>
                  </div>*/}
                       </div>
                     </div>
                   </div>
                 </div>
 
                 <div className="row py-3">
                 {!this.props.loading ? <>
                    {this.state.searchResults?.length > 0 &&
                    this.state.searchResults.slice(0, productLimit).map((item, index) => {
                      return(
                      <div
                          className="col-lg-3 col-sm-6"
                          key={index}
                          data-aos="zoom-in"
                        >
                          <ManageShopProductCard
                            shopproductItem={item}
                          />
                        </div>);
                      })}
                    {this.state.searchResults.length==0 && shopproducts &&
                     shopproducts.slice(0, productLimit).map((item, index) => {
                       return (
                         <div
                          className="col-lg-3 col-sm-6 mb-3"
                           key={index}
                           data-aos="zoom-in"
                         >
                           <ManageShopProductCard
                              shopproductItem={item} 
                            />
                         </div>
                       );
                     })}
                     </>:<CircleSpinner message={"Searching"}/>}
                 </div>
                 <div className="product_sorting_container product_sorting_container_bottom clearfix">
                   <span className="showing_results">Showing {productLimit} results</span>
                   <div className="pages d-flex flex-row align-items-center">
                     {/*<div className="page_current">
                       <span>1</span>
                       <ul className="page_selection">
                         <li>
                           <a href="#">1</a>
                         </li>
                         <li>
                           <a href="#">2</a>
                         </li>
                         <li>
                           <a href="#">3</a>
                         </li>
                       </ul>
                     </div>
                     <div className="page_total">
                       <span>of</span> 3
                     </div>
                     <div id="next_page_1" className="page_next">
                       <a href="#">
                         <i
                           className="fas fa-long-arrow-right"
                           aria-hidden="true"
                         ></i>
                       </a>
                    </div>*/}
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <ShopOwnerPackageValidatonModal show={this.state.adlimitmodal} onHide={() => this.hideAdModal()} title={`Advertisement Limit Reached`} message={
                `You have exceeded the limit of advertisements for your package subscription. You can delete them or wait for expiry.`
              }  link={`/manageadverts`} button={`Manage Advertisements`}/>
         <ShopOwnerPackageValidatonModal show={this.state.admodalShow} onHide={() => this.hideAdModal()} title={`Package Subscription Required!`} message={
                `You are not subscribed to any Package subscription. Please ensure you purchase a subscription inorder to advertise your products.`
              } link={`/#packages`} button={`Go to Packages`} />
         <LoginRegister
           show={this.state.modalShow}
           login={this.state.login}
           registerClicked={() => this.registerClicked()}
           loginClicked={() => this.loginClicked()}
           onHide={() => this.showHideModal()}
         />
       </div>
     );
   }
 }
 
 export default ManageShopProducts;
 