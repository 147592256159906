/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import Validator from "../../utils/Validator";
import { DEFAULT_RULE, EMAIL_RULE, PHONE_RULE, PASSWORD_RULE } from "../../utils/Validator/rule";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userRegister } from "../../redux/actions/RegisterAction";
import LoadingButton from "../LoadingButton";
import { FormControl, InputGroup } from "react-bootstrap";

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      password: "",
      errorModal: false,
      message: "",
      nameErr: false,
      emailErr: false,
      phoneErr: false,
      passwordErr: false,
      errorMessage: "",
      showPass: false,
    };
  }
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  focus = (e) => {
    switch (e.target.name) {
      case "name":
        this.setState({ nameErr: false });
        break;
      case "phone":
        this.setState({ phoneErr: false });
        break;
      case "email":
        this.setState({ emailErr: false });
        break;
      case "password":
        this.setState({ passwordErr: false });
        break;
      default:
        break;
    }
  }
  blur = (e) => {
    switch (e.target.name) {
      case "name":
        if (!Validator(e.target.value, DEFAULT_RULE)) {
          this.setState({ nameErr: true });
        }
        break;
      case "phone":
        if (!Validator(e.target.value, PHONE_RULE)) {
          this.setState({ phoneErr: true });
        }
        break;
      case "email":
        if (!Validator(e.target.value, EMAIL_RULE)) {
          this.setState({ emailErr: true });
        }
        break;
      case "password":
        if (!Validator(e.target.value, PASSWORD_RULE)) {
          this.setState({ passwordErr: true });
        }
        break;
      default:
        break;
    }
  }

  handleSubmit = () => {
    const { name, phone, email, password } = this.state;

    this.setState({ loading: true });
    this.props
      .userRegister(name, phone, email, password, password)
      .then(res => {
        console.log(res);
        this.props.loginClicked();
        this.setState({ loading: false });
      })
      .catch(error => {
        console.log(error.response);
        if(error.response.data.substring(0,6)==="E11000"){
          this.setState({ loading: false, errorMessage: "Email has already been registered to another user" });
        }else{
          this.setState({ loading: false, errorMessage: error.response.data });
        }
        
      });
  };
  subBtnEnable = () => {
    const { name, phone, email, password } = this.state;
    if (email && password && name && phone) {
      return true;
    } else {
      return false;
    }
  };
  passClickHandler = () => {
    this.setState({ showPass: !this.state.showPass });
  }

  render() {
    return (
      <div>
        <div style={{
          "marginTop": "5px",
          "padding": "10px",
          "color": "white",
          "backgroundColor": "grey",
          "borderRadius": "5px",
          "textAlign": "center",
          "fontSize": "20px",
          "fontWeight": "bold"
        }}>{"Register"}</div>
        <div className="login-form mt-3">
          {this.state.errorMessage ? <div style={{
            "marginTop": "5px",
            "padding": "5px",
            "color": "white",
            "backgroundColor": "red",
            "borderRadius": "5px",
            "textAlign": "center"
          }}>{this.state.errorMessage}</div> : null}
          <div className="form-group mt-3">
            <input
              type="text"
              className="form-control has-error"
              placeholder="Name "
              id="name"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              autoComplete="false"
              style={this.state.nameErr ? { "border": "2px solid red" } : null}
              onBlur={e => this.blur(e)}
              onFocus={e => this.focus(e)}
            />
            {this.state.nameErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: John Doe</span>}
            <i className="fa fa-user"></i>
          </div>

          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              placeholder="Phone "
              id="phone"
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
              autoComplete="false"
              style={this.state.phoneErr ? { "border": "2px solid red" } : null}
              onBlur={e => this.blur(e)}
              onFocus={e => this.focus(e)}
            />
            {this.state.phoneErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: 0712345678</span>}
            <i className="fa fa-phone"></i>
          </div>

          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              placeholder="Email "
              id="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              autoComplete="false"
              required={true}
              style={this.state.emailErr ? { "border": "2px solid red" } : null}
              onBlur={e => this.blur(e)}
              onFocus={e => this.focus(e)}
            />
            {this.state.emailErr && <span style={{ "fontSize": "15px", "color": "red" }}>Example format: name@email.com</span>}
            <i className="fa fa-envelope"></i>
          </div>

          <div className="form-group log-status">
            <InputGroup>
              <FormControl
                type={this.state.showPass ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                id="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                autoComplete="false"
                style={this.state.passwordErr ? { "border": "2px solid red" } : null}
                onBlur={e => this.blur(e)}
                onFocus={e => this.focus(e)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i onClick={this.passClickHandler} className={this.state.showPass ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {this.state.passwordErr && <span style={{ "fontSize": "15px", "color": "red" }}>Minimum eight characters, at least one capital letter and one number</span>}
          </div>
          <LoadingButton
            type="button"
            className="log-btn"
            loading={this.state.loading}
            onClick={() => this.handleSubmit()}
            disabled={!this.subBtnEnable()}
          >
            Register
          </LoadingButton>
          <div
            onClick={this.props.loginClicked}
            style={{
              textAlign: "center",
              fontSize: 12,
              color: "black",
              cursor: "pointer"
            }}
          >
            Already have an account ? Please login.
          </div>
        </div>
      </div>
    );
  }
}

RegisterForm.propTypes = {
  loginClicked: PropTypes.func
};

const mapDispatchToProps = {
  userRegister
};
const mapStoreToProps = state => ({
  register_loading: state.register.register_loading,
  register_error: state.register.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(RegisterForm);

