import axios from 'axios';


export const getPaymentToken = () => async dispatch => {
    dispatch({
        type: GET_PAYMENT_TOKEN_BEGIN
    });
    // const token_stored = AuthToken.get();
    // const tenantId = AuthCurrentTenant.get();

    let webApiUrl = `https://mapi.mechwangu.co.ke/api/gateway/token`;

    await axios.post(
        webApiUrl).then(res => {
            console.log("TokenData: " + JSON.stringify(res));
            dispatch({
                type: GET_PAYMENT_TOKEN_SUCCESS,
                payload: res
            });
        })
        .catch(error => {
            console.error("Error", error)
            dispatch({
                type: GET_PAYMENT_TOKEN_FAIL,
                payload: { error }
            });
        })
}
export const initiateMpesa = (data) => async dispatch => {
    dispatch({
        type: INITIATE_MPESA_BEGIN
    });
    // const token_stored = AuthToken.get();
    // const tenantId = AuthCurrentTenant.get();

    let webApiUrl = `https://mapi.mechwangu.co.ke/api/payment/express`;

    await axios.post(
        webApiUrl, {
        phone: data.phone,
        amount: data.amount,
    }).then(res => {
        dispatch({
            type: INITIATE_MPESA_SUCCESS,
            payload: res
        });
        //console.log("init mpesa response: " + JSON.stringify(res))
        return res;
    })
        .catch(error => {
            console.error("Mpesa Error", error)
            dispatch({
                type: INITIATE_MPESA_FAIL,
                payload: { error }
            });
            return error
        })
}

export const queryMpesa = (data) => async dispatch => {
    // const tenantId = AuthCurrentTenant.get();

    let webApiUrl = `https://mapi.mechwangu.co.ke/api/payment/query`;

    await axios.post(
        webApiUrl, {
        id: data.id,
    }).then(res => {
        console.log("query mpesa response: " + JSON.stringify(res))
        return res.data;
    })
        .catch(error => {
            console.error("query Mpesa Error", error)
            return error
        })
}


export const GET_PAYMENT_TOKEN_BEGIN = "GET_PAYMENT_TOKEN_BEGIN";
export const GET_PAYMENT_TOKEN_SUCCESS = "GET_PAYMENT_TOKEN_SUCCESS";
export const GET_PAYMENT_TOKEN_FAIL = "GET_PAYMENT_TOKEN_FAIL";

export const INITIATE_MPESA_BEGIN = "INITIATE_MPESA_BEGIN";
export const INITIATE_MPESA_SUCCESS = "INITIATE_MPESA_SUCCESS";
export const INITIATE_MPESA_FAIL = "INITIATE_MPESA_FAIL";