/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import Auth from "../../modules/Auth";
import CategoryBanner from "../../components/CategoryBanner/CategoryBanner";
import CategoryBannerMob from "../../components/CategoryBanner/CategoryBannerMob";
import NewArrivals from "../../components/Products/NewArrivals";
// import BestSeller from "../../components/Products/BestSeller";
import ValidationModal from "../../components/ValidationModal/ValidationModal";
import axios from "axios";
import CircleSpinner from '../../components/Spinner/Circle';
import ThreeBounce from '../../components/Spinner/ThreeBounceBanner';
import one from "../../assets/images/one.svg";
import two from "../../assets/images/two.svg";
import three from "../../assets/images/three.svg";
import PackagesContainer from "../Packages/PackagesContainer";
import LatestShopProducts from "../../components/ShopProducts/LatestShopProducts";
import LatestShopProductsContainer from "../../components/ShopProducts/LatestShopProductsContainer";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      modalShow: false,
      login: true,
      hasId: true,
      idModal: false,
      sorted: [],
      timeData: {},
      locationEnabled: false,
      message: "",
      mechanicsLoading: false,
      disabled: false,
      geocodeData: {},
      page: 1,
      limit: 6,
      getMoreLoading: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
    this.addToBag = this.addToBag.bind(this);
  }

  componentDidMount() {
    this.setState({
      message: `Click Allow to enable location`,
    })
    this.getLocation().then(res => {
      this.setState({
        disabled: false,
        message: "Waiting for location data",
      });
      return this.getGeocodedLocation(res);
    }).then(res => {
      let limit = this.state.limit;
      this.setState({
        locationEnabled: true,
        message: "Getting mechanics near you",
        mechanicsLoading: true,
        geocodeData: [...res.data],
      });
      return axios.post(`https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/mechanic/sorted/1/${limit}`, {
        data: {
          results: res.data.map(result => result.formatted_address)
        }
      }).then(res => {
        let timeData = {
          duration: res.data.duration,
          distance: res.data.distance,
          location: res.data.location,
        };
        this.setState({
          sorted: res.data.mechanics,
          timeData,
          mechanicsLoading: false
        });
        this.props.setSortedData({
          data: res.data.mechanics,
          timeData,
        })
      })
    }).catch(() => { })
    if (loggedInUser && loggedInUser.tenants[0].roles[0] === "userMechanic") {
      this.props.getMechanicId(loggedInUser.id).then(res => this.props.getMechanic(res).then(res => {
        let mech = res.data;
        if (mech.mechanicIdentificationDocument === null) {
          this.setState({ hasId: false, idModal: true });
        }
      }));
    }
    if (loggedInUser && loggedInUser.tenants[0].roles[0] === "userShopOwner") {
      this.props.startAdvertExpiryChecker();
    }
  }
  getOtherPage = () => {
    let limit = this.state.limit;
    let page = this.state.page + 1;
    let data = this.state.geocodeData.map(result => result.formatted_address);
    this.setState({ page, getMoreLoading: true });
    return axios.post(`https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/mechanic/sorted/${page}/${limit}`, {
      data: {
        results: data
      }
    }).then(res => {
      if (res.data.mechanics.length === 0) {
        this.setState({ getMoreLoading: false });
        return { ok: false };
      } else if (res.data.mechanics.length < limit) {
        let data = [...this.state.sorted, ...res.data.mechanics];
        this.setState({
          sorted: data,
          getMoreLoading: false,
        });
        return { ok: false };
      }
      let data = [...this.state.sorted, ...res.data.mechanics];
      this.setState({
        sorted: data,
        getMoreLoading: false,
      });
      return { ok: true };
    }).catch((err) => err)
  }
  getLocation = () => {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
          let data = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          resolve(data);
        }, () => {
          this.setState({
            message: "Location is disabled",
            disabled: true
          });
          reject();
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      }
      )
    } else {
      this.setState({
        message: "Geolocation is not supported by this browser",
      })
    }
  }
  getGeocodedLocation = async (loc) => {
    return await axios.post(`https://mapi.mechwangu.co.ke/api/auth/geocode`, {
      data: loc
    }).catch(err => {
      this.setState({
        message: "Geocoding error, please refresh the page",
      });
    })
  }
  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };
  locationModalHandler = (bool) => {
    this.setState({ nullLoc: bool });
  }

  addToBag = params => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      let cart = this.props.postCart(params);
      cart.then(res => {
        console.log(res);
      });
    } else {
      this.setState({ modalShow: true });
    }
  };

  render() {
    if (loggedInUser && loggedInUser.tenants[0].roles[0] === "userShopOwner") {
    return (
      <>
        <div>
          {/* <HomeBanner /> */}
          {<>
              <CategoryBanner />
            {!this.state.mechanicsLoading && this.state.locationEnabled ?
              <>
                {this.state.sorted.length > 0 ? <><div style={!this.state.matches ? {
                  "display": "flex",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "fontSize": "14px",
                  "marginTop": "10px",
                  "padding": "20px"
                } : {
                  "display": "flex",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "fontSize": "18px",
                  "marginTop": "10px",
                  "padding": "20px"
                }}>
                  <span>{`Showing mechanics around: `}<strong>{this.state.timeData.location}</strong></span>
                </div>
                  <div style={!this.state.matches ? {
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "15px",
                  } : {
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "16px",
                    "marginTop": "10px",
                  }}><span>{`Distance: `}<strong>{this.state.timeData.distance}</strong>{`, ${this.state.timeData.duration}`}</span>
                  </div></> :
                  <div style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontSize": "16px",
                    "marginTop": "10px",
                  }}><span>{`There are no mechanics around you `}<strong>yet!</strong></span>
                  </div>
                }
                <NewArrivals
                  products={this.state.sorted}
                  addToBag={this.addToBag}
                  onHide={() => this.showHideModal()}
                  getMore={this.getOtherPage}
                />
              </>
              :
              <div style={{
                "marginTop": "60px",
              }}>
                {this.state.locationEnabled ? <CircleSpinner message={this.state.message} /> :
                  <ThreeBounce message={this.state.message} alt={this.state.disabled && "Kindly enable to get mechanics near you"} matches={!this.state.matches} />
                }
              </div>
            }
            <LatestShopProductsContainer />
            {/* <Benefit /> */}
            <div style={{
              "display": "block",
              "alignItems": "center",
              "marginTop": "40px",
              "padding": "20px",
            }}>
              <div style={{
                "marginBottom": "10px",
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
              }}>Listen to our Podcast:</div>
              <div style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
              }}>
                <iframe src="https://anchor.fm/mech-wangu/embed" height="102px" width="400px" frameborder="0" scrolling="no" title="podcast"></iframe>
              </div>
            </div>
            {!this.state.matches && <div style={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "40px",
            }}><h3><strong>How it works</strong></h3></div>}
            <div className="row align-items-center" style={{
              "padding": "50px",
              "display": "flex",
            }}>
              <div className="col-md-4">
                <div id="home-why-details" className="row details animate">
                  <div className="col-md-12">
                    <div className="col-md-3" style={!this.state.matches ? {
                      "width": "150px",
                      "height": "150px",
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginLeft": "75px"
                    } : null}><img id="thumb-up" src={one} alt="Step 2" style={!this.state.matches ? {
                      "width": "100px",
                      "height": "100px",
                    } : {
                      "marginLeft": "10px"
                    }} /></div>
                    <div className="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                      <h3 style={{ "font-size": "22px", "font-weight": "bold", "text-transform": "none" }}> Search for the job you would like to be done on your car.</h3>
                      <p style={{ "font-size": "16px" }}>- We will list options of mechanics who can do the job closest to you.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div id="home-why-details" className="row details animate">
                  <div className="col-md-12">
                    <div className="col-md-3" style={!this.state.matches ? {
                      "width": "150px",
                      "height": "150px",
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginLeft": "75px"
                    } : null} ><img id="thumb-up" src={two} alt="Step 2" style={!this.state.matches ? {
                      "width": "100px",
                      "height": "100px",
                    } : null} /></div>
                    <div className="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                      <h3 style={{ "font-size": "22px", "font-weight": "bold", "text-transform": "none" }}>Choose the mechanic of your liking & proceed to checkout from the platform.</h3>

                      <p style={{ "font-size": "16px" }}>- We charge a convinience fee for the service</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div id="home-why-details" className="row details animate"><br />
                  <div className="col-md-12">
                    <div className="col-md-3" style={!this.state.matches ? {
                      "width": "150px",
                      "height": "150px",
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "marginLeft": "75px"
                    } : null}><img id="thumb-up" src={three} alt="Step 3" style={!this.state.matches ? {
                      "width": "100px",
                      "height": "100px",
                    } : null} /></div>
                    <div className="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                      <h3 style={{ "font-size": "22px", "font-weight": "bold", "text-transform": "none" }}>Get in touch with your mechanic using contacts provided.</h3>

                      <p style={{ "font-size": "16px" }}>- Pay your mechanic the balance</p>
                      <p style={{ "font-size": "16px" }}>- Don't forget to <strong>review</strong> the mechanic</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span style={{
              "marginBottom": "20px",
              "marginTop": "20px",
            }}>...</span> </>
          }
          {/* <Advertisement /> */}
          {/* {products ? (
          <BestSeller
            products={products}
            departments={departments}
            addToBag={this.addToBag}
          />
        ) : null} */}
        <div id="packages">
        <PackagesContainer />
        </div>
        </div>
        {this.state.hasId ? null : <ValidationModal
          show={this.state.idModal}
          message={"Kindly remember to upload your ID from the profile page"}
          title={"ID reminder"}
          onHide={() => this.setState({ idModal: false })}
        />}
      </>
    );
      } else {
        return (
          <>
            <div>
              {/* <HomeBanner /> */}
              {<>
                 <CategoryBanner />
                {!this.state.mechanicsLoading && this.state.locationEnabled ?
                  <>
                    {this.state.sorted.length > 0 ? <><div style={!this.state.matches ? {
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "fontSize": "14px",
                      "marginTop": "10px",
                      "padding": "20px"
                    } : {
                      "display": "flex",
                      "alignItems": "center",
                      "justifyContent": "center",
                      "fontSize": "18px",
                      "marginTop": "10px",
                      "padding": "20px"
                    }}>
                      <span>{`Showing mechanics around: `}<strong>{this.state.timeData.location}</strong></span>
                    </div>
                      <div style={!this.state.matches ? {
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "fontSize": "15px",
                      } : {
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "fontSize": "16px",
                        "marginTop": "10px",
                      }}><span>{`Distance: `}<strong>{this.state.timeData.distance}</strong>{`, ${this.state.timeData.duration}`}</span>
                      </div></> :
                      <div style={{
                        "display": "flex",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "fontSize": "16px",
                        "marginTop": "10px",
                      }}><span>{`There are no mechanics around you `}<strong>yet!</strong></span>
                      </div>
                    }
                    <NewArrivals
                      products={this.state.sorted}
                      addToBag={this.addToBag}
                      onHide={() => this.showHideModal()}
                      getMore={this.getOtherPage}
                    />
                  </>
                  :
                  <div style={{
                    "marginTop": "60px",
                  }}>
                    {this.state.locationEnabled ? <CircleSpinner message={this.state.message} /> :
                      <ThreeBounce message={this.state.message} alt={this.state.disabled && "Kindly enable to get mechanics near you"} matches={!this.state.matches} />
                    }
                  </div>
                }
                {/* <Benefit /> */}
                <LatestShopProductsContainer />
                <div style={{
                  "display": "block",
                  "alignItems": "center",
                  "marginTop": "40px",
                  "padding": "20px",
                }}>
                  <div style={{
                    "marginBottom": "10px",
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                  }}>Listen to our Podcast:</div>
                  <div style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                  }}>
                    <iframe src="https://anchor.fm/mech-wangu/embed" height="102px" width="400px" frameborder="0" scrolling="no" title="podcast"></iframe>
                  </div>
                </div>
                {!this.state.matches && <div style={{
                  "display": "flex",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "marginTop": "40px",
                }}><h3><strong>How it works</strong></h3></div>}
                <div className="row align-items-center" style={{
                  "padding": "50px",
                  "display": "flex",
                }}>
                  <div className="col-md-4">
                    <div id="home-why-details" className="row details animate">
                      <div className="col-md-12">
                        <div className="col-md-3" style={!this.state.matches ? {
                          "width": "150px",
                          "height": "150px",
                          "display": "flex",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginLeft": "75px"
                        } : null}><img id="thumb-up" src={one} alt="Step 2" style={!this.state.matches ? {
                          "width": "100px",
                          "height": "100px",
                        } : {
                          "marginLeft": "10px"
                        }} /></div>
                        <div className="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                          <h3 style={{ "font-size": "22px", "font-weight": "bold", "text-transform": "none" }}> Search for the job you would like to be done on your car.</h3>
                          <p style={{ "font-size": "16px" }}>- We will list options of mechanics who can do the job closest to you.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div id="home-why-details" className="row details animate">
                      <div className="col-md-12">
                        <div className="col-md-3" style={!this.state.matches ? {
                          "width": "150px",
                          "height": "150px",
                          "display": "flex",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginLeft": "75px"
                        } : null} ><img id="thumb-up" src={two} alt="Step 2" style={!this.state.matches ? {
                          "width": "100px",
                          "height": "100px",
                        } : null} /></div>
                        <div className="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                          <h3 style={{ "font-size": "22px", "font-weight": "bold", "text-transform": "none" }}>Choose the mechanic of your liking & proceed to checkout from the platform.</h3>
    
                          <p style={{ "font-size": "16px" }}>- We charge a convinience fee for the service</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div id="home-why-details" className="row details animate"><br />
                      <div className="col-md-12">
                        <div className="col-md-3" style={!this.state.matches ? {
                          "width": "150px",
                          "height": "150px",
                          "display": "flex",
                          "alignItems": "center",
                          "justifyContent": "center",
                          "marginLeft": "75px"
                        } : null}><img id="thumb-up" src={three} alt="Step 3" style={!this.state.matches ? {
                          "width": "100px",
                          "height": "100px",
                        } : null} /></div>
                        <div className="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                          <h3 style={{ "font-size": "22px", "font-weight": "bold", "text-transform": "none" }}>Get in touch with your mechanic using contacts provided.</h3>
    
                          <p style={{ "font-size": "16px" }}>- Pay your mechanic the balance</p>
                          <p style={{ "font-size": "16px" }}>- Don't forget to <strong>review</strong> the mechanic</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span style={{
                  "marginBottom": "20px",
                  "marginTop": "20px",
                }}>...</span> </>
              }
              {/* <Advertisement /> */}
              {/* {products ? (
              <BestSeller
                products={products}
                departments={departments}
                addToBag={this.addToBag}
              />
            ) : null} */}
            </div>
            {this.state.hasId ? null : <ValidationModal
              show={this.state.idModal}
              message={"Kindly remember to upload your ID from the profile page"}
              title={"ID reminder"}
              onHide={() => this.setState({ idModal: false })}
            />}
          </>
        );
      }
  }
}

export default Home;
