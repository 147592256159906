import React, {Component} from 'react';

class ContactUs extends Component {
    render() {
        return (
            <div className="site-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span className="icon-check_circle display-3 text-success">
                                    <i className="fas fa-phone"></i>
                            </span>
                            <h2 className="display-3 text-black">Contact us on!</h2>
                            <p className="lead mb-5">0705677598</p>
                            <p className="lead mb-5">Email us on: <a href="mailto:info@mechwangu.co.ke">info@mechwangu.co.ke</a></p>
                            <div className="footer_social d-flex flex-row align-items-center justify-content-lg-center justify-content-center">
                                <ul>
                                    <li>
                                    <a href="https://web.facebook.com/mech.wangu.9" target="_blank">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://twitter.com/MechWangu" target="_blank">
                                        <i className="fab fa-twitter"></i>{" "}
                                    </a>
                                    </li>
                                    <li>
                                    <a href="https://www.instagram.com/mechwangu/" target="_blank">
                                        <i className="fab fa-instagram"></i>{" "}
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            {/* <p><a href="/" className="btn btn-sm height-auto px-4 py-3 btn-primary">Back to
                                shop</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUs;
