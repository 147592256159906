/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */
 import React from "react";
 import jumpTo from "../../modules/Navigation";
 import mechanicDefaultProfile from "../../assets/images/mechwangu_default_prof.svg";
 
 function SingleShopProduct(props) {
   const { shopproductItem } = props;
   let productImage = shopproductItem.productImage[0];
   return (
     <div className="shop-product-item">
       <div
         className="shop-product discount product_filter"
       >
         <div className="product_image">
         {productImage ?
                <img src={`https://mapi.mechwangu.co.ke/api/file/download?privateUrl=${productImage.privateUrl}`} alt=""
                  className="img-fluid"
                  style={{
                    borderRadius: "24px"
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = mechanicDefaultProfile;
                  }}
                /> :
                <img src={mechanicDefaultProfile} alt="" className="img-fluid" />
              }
         </div>
         {/*<div className="favorite favorite_left">
           <i className="far fa-heart"></i>
            </div>*/}
         {/*<div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
           <span>-$20</span>
        </div>*/}
         <div className="product_info">
           <h6 className="product_name">
            <div>{shopproductItem.productName}</div> 
           </h6>
           <div className="meta product_category">{shopproductItem.productOfCategory.categoryName}</div>
           <div className="product_of_shop">{shopproductItem.productOfShop.shopName}</div>
            <div className="product_price">
             KSh. {shopproductItem.productPrice}
             {/*<span> KSh. {(parseFloat(shopproductItem.price) + 30).toFixed(2)}</span>*/}
           </div>
         </div>
       </div>
       <div
         className="red_button add_to_cart_button"
         onClick={() => jumpTo(`/singleshopproductview/${shopproductItem._id}`)}
       > 
         <div style={{ color: "#ffffff" }}>Get Order Details</div>
       </div>
     </div>
   );
 }
 
 export default SingleShopProduct;
 