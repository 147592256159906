/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import jumpTo from "../../modules/Navigation";
import { phoneVerification } from "../../redux/actions/verifyPhoneAction";
import Heading from "../Heading";
import LoadingButton from "../LoadingButton";
import axios from "axios";

import AuthCurrentTenant from "../../axioss/modules/Auth/authCurrentTenant";
import { AuthToken } from "../../axioss/modules/Auth/authToken";

// import "../../assets/css/button.css";

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otpcode: "",
            err: false,
            sent: false,
        };
    }

    isLoggedIn() {
        let loggedInUser = JSON.parse(localStorage.getItem("auth"));
        if (loggedInUser) {
            return true
        } else {
            return false
        }
    };

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        // let email = "bob@bob.com";
        // let password = "Ab123123";
        const { otpcode } = this.state;
        // if (!Validator(email, EMAIL_RULE)) {
        //   console.log("email Error");
        //   return;
        // }
        // if (!Validator(password, DEFAULT_RULE)) {
        //   console.log("Password Error");
        //   return;
        // }
        this.setState({ loading: true });
        this.props
            .phoneVerification(otpcode)
            .then(res => {
                console.log(res);
                this.setState({ loading: false });
                let user = JSON.parse(localStorage.getItem("auth"));
                user.phoneVerified = true;
                localStorage.setItem("auth", JSON.stringify(user));
                jumpTo("/status");
            })
            .catch(error => {
                // console.log('loginsignin error')
                console.log(error.response);
                this.setState({ loading: false });
                this.setState({ err: true });
            });
    };

    sendResetCode = async (phone) => {
        const token_stored = AuthToken.get();
        const tenantId = AuthCurrentTenant.get();

        await axios.post("https://mapi.mechwangu.co.ke/api/auth/reset-code", {
            phoneNumber: phone,
            tenantId
        }, {
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        let useLoggedIn = JSON.parse(localStorage.getItem("auth"));
        return (

            <div className="best_sellers">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span className="icon-check_circle display-3 text-success">
                                <i className="fas fa-check-circle"></i>
                            </span>
                            <Heading title="Verify Your Account" data-aos="fade-up" />
                            <br />
                            <p className="lead mb-5">Kindly enter the code sent to <b>{useLoggedIn.phoneNumber}</b></p>
                            {this.state.err &&
                                <div>
                                    <span
                                        style={{
                                            "padding": "10px",
                                            "backgroundColor": "red",
                                            "color": "white",
                                        }}
                                    >Code is invalid or has expired. Kindly try again.</span>
                                </div>
                            }
                            <form onSubmit={this.handleSubmit} style={{ "marginTop": "20px" }}>
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter code "
                                        id="otpcode"
                                        name="otpcode"
                                        value={this.state.otpcode}
                                        onChange={this.handleChange}
                                        autoComplete="false"
                                    />
                                    <i className="fa fa-asterisk"></i>
                                </div>
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-sm height-auto px-4 py-3 btn-success"
                                    loading={this.state.loading}
                                    onClick={() => this.handleSubmit}
                                >
                                    Verify
                                </LoadingButton>
                                <Link
                                    className="link"
                                    style={this.state.sent ? { "color": "green", "fontSize": "13px" } : { "color": "red", "fontSize": "13px" }}
                                    onClick={() => { this.setState({ sent: true }); this.sendResetCode(useLoggedIn.phoneNumber) }}
                                >
                                    {this.state.sent ? "Didn't receive code? Resend" : "Resend code?"}
                                </Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    phoneVerification
};
const mapStoreToProps = state => ({
    login_loading: state.login.login_loading,
    login_error: state.login.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(Verify);
