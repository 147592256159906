import React, { Component } from 'react';
import IMGHOWITWORKS from "../assets/images/how_it_works.png";
import { Link } from 'react-router-dom';
import one from "../assets/images/one.svg";
import two from "../assets/images/two.svg";
import three from "../assets/images/three.svg";
import four from "../assets/images/four.svg";

class HowItWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matches: window.matchMedia("(min-width: 768px)").matches
        }
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" style={!this.state.matches ? { "marginTop": "80px" } : { "marginTop": "10px" }}>
                            <span className="icon-check_circle display-3 text-success">
                                <i className="fas fa-question-circle"></i>
                            </span>
                            <div style={{ "text-align": "center" }}><h2><strong>What do I have to do to get started?</strong></h2></div>
                            {/* <h2 className="display-3 text-black">How It works</h2>
                            <p className="lead mb-5">Guide</p> */}
                            <div class="home-why" style={{ "margin-bottom": "0", "marginTop": "30px" }}>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-10 col-md-offset-1">
                                            <div id="home-why-details" class="row details animate">
                                                <div class="col-md-12" style={this.state.matches ? { "display": "flex" } : null}>
                                                    <div class="col-md-3" style={!this.state.matches ? {
                                                        "width": "150px",
                                                        "height": "150px",
                                                        "display": "flex",
                                                        "alignItems": "center",
                                                        "justifyContent": "center",
                                                        "marginLeft": "100px"
                                                    } : null}><img id="thumb-up" src={one} alt="Step 2" /></div>
                                                    <div class="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                                                        <h3 style={{ "font-size": "24px", "font-weight": "bold", "text-transform": "none" }}> Search for the job you would like to be done on your car.</h3>
                                                        <p style={{ "font-size": "16px" }}>- We will list options of mechanics who can do the job closest to you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="home-why-details" class="row details animate">
                                                <div class="col-md-12" style={this.state.matches ? { "display": "flex" } : null}>
                                                    <div class="col-md-3" style={!this.state.matches ? {
                                                        "width": "150px",
                                                        "height": "150px",
                                                        "display": "flex",
                                                        "alignItems": "center",
                                                        "justifyContent": "center",
                                                        "marginLeft": "100px"
                                                    } : null}><img id="thumb-up" src={two} alt="Step 2" /></div>
                                                    <div class="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                                                        <h3 style={{ "font-size": "24px", "font-weight": "bold", "text-transform": "none" }}>Choose the mechanic of your liking.</h3>
                                                        <p style={{ "font-size": "16px" }}>- Listing is done by distance and rating.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="home-why-details" class="row details animate"><br />
                                                <div class="col-md-12" style={this.state.matches ? { "display": "flex" } : null}>
                                                    <div class="col-md-3" style={!this.state.matches ? {
                                                        "width": "150px",
                                                        "height": "150px",
                                                        "display": "flex",
                                                        "alignItems": "center",
                                                        "justifyContent": "center",
                                                        "marginLeft": "100px"
                                                    } : null}><img id="thumb-up" src={three} alt="Step 3" /></div>
                                                    <div class="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                                                        <h3 style={{ "font-size": "24px", "font-weight": "bold", "text-transform": "none" }}>Proceed to checkout from the platform.</h3>
                                                        <p style={{ "font-size": "16px" }}>- We charge a convinience fee for the service</p>
                                                        <p style={{ "font-size": "16px" }}>- Pay your mechanic the balance</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="home-why-details" class="row details animate"><br />
                                                <div class="col-md-12" style={this.state.matches ? { "display": "flex" } : null}>
                                                    <div class="col-md-3" style={!this.state.matches ? {
                                                        "width": "150px",
                                                        "height": "150px",
                                                        "display": "flex",
                                                        "alignItems": "center",
                                                        "justifyContent": "center",
                                                        "marginLeft": "100px"
                                                    } : null}><img id="thumb-up" src={four} alt="Step 4" /></div>
                                                    <div class="col-md-9" style={!this.state.matches ? { "textAlign": "center" } : { "textAlign": "left" }}>
                                                        <h3 style={{ "font-size": "24px", "font-weight": "bold", "text-transform": "none" }}>Get in touch with your mechanic using contacts provided.</h3>
                                                        <p style={{ "font-size": "16px" }}>- Have your car sorted</p>
                                                        <p style={{ "font-size": "16px" }}>- Don't forget to <strong>review</strong> the mechanic</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <p><a href="/" className="btn btn-sm height-auto px-4 py-3 btn-primary">Back to
                                shop</a></p> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HowItWorks;
