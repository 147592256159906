/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import React, { Component } from "react";
 import LoginRegister from "../../components/LoginRegisterModal";
 import { Link } from "react-router-dom";
 import Loader from "../../components/Loader/Loader";
 
 class SingleAdvert extends Component {
   constructor(props) {
     super(props);
     this.state = {
       color: "",
       size: "",
       pic: "",
       selectedSize: "",
       id: "",
       quantity: 1,
       modalShow: false,
       login: true,
       matches: window.matchMedia("(min-width: 768px)").matches
     };
   }
   componentDidMount() {
     this.props.getAdvert(this.props.location.pathname.split("/").slice(-1)[0]);
   }
 
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
 
 
   render() {
     console.log("Locationzz" + JSON.stringify(this.props.advert));
     let expiryDate =this.props.advert && new Date(this.props.advert.advertExpiry);
     let createdDate =this.props.advert && new Date(this.props.advert.createdAt);
     return (
       <div style={!this.props.advert ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
         <div className="container single_product_container">
           {this.props.advert ? (
             <div>
               <div className="row">
                 <div className="col">
                   <div className="breadcrumbs d-flex flex-row align-items-center">
                     <ul>
                       <li>
                         <a href="/">Home</a>
                       </li>
                       <li>
                         <a href="/manageadverts">
                           <i className="fa fa-angle-right" aria-hidden="true"></i>
                           Promotions
                         </a>
                       </li>
                       <li className="active">
                         <a href="#">
                           <i className="fa fa-angle-right" aria-hidden="true"></i>
                           {this.props.advert.advertProduct.productName}
                         </a>
                       </li>
                     </ul>
                   </div>
                 </div>
               </div>
 
               <div className="row">
                 <div className="col-lg-12 text-center">
                   <div className="product_details">
                     <div className="product_details_title">
                       <h2>{this.props.advert.advertProduct.productName}</h2>
                       <p><span className="text-danger">Advert ID: </span> {this.props.advert._id}</p>
                       <p><span className="text-danger">Advert Expiry Date: </span>{expiryDate.toDateString()}</p>
                       <p><span className="text-danger">Advert Status: </span>{this.props.advert.advertStatus}</p>
                       <p><span className="text-danger">AdvertPackage: </span>{this.props.advert.advertPackage.advertPackageName}</p>
                       <p><span className="text-danger">Created At: </span>{createdDate.toString()}</p>
                     </div>
                     <Link to={{
                       pathname: `/editadvert/${this.props.advert._id}`,
                       state: {
                         advert: this.props.advert,
                       }
                     }}>
                       <div className="red_button product-add_to_cart_button align-items-center justify-content-center">
                         <span>Extend advertisement  </span>
                         <span>
                           <i className="fas fa-pen"></i>
                         </span>
                       </div>
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
           ) :
             <Loader />
           }
         </div>
         <LoginRegister
           show={this.state.modalShow}
           registerClicked={() => this.registerClicked()}
           loginClicked={() => this.loginClicked()}
           onHide={() => this.showHideModal()}
         />
       </div>
     );
   }
 }
 
 export default SingleAdvert;
 