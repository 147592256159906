/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import HomeCartViewContainer from "../HomeCartView/HomeCartViewContainer";
import MobileMenu from "../MobileMenu";
import device, { size } from "../../modules/mediaQuery";
import MediaQuery from "react-responsive";
import Logo from "../../assets/images/mechwangu_logo.png";
import Auth from "../../modules/Auth/userToken";
import { Button } from "react-bootstrap";
import jumpTo from "../../modules/Navigation";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
let loggedInUserIsMechanic = null;
if (loggedInUser) {
  let phoneVerified = loggedInUser.phoneVerified;
  console.log("phoneVerified " + phoneVerified);
  loggedInUserIsMechanic = loggedInUser.tenants[0].roles[0];
}

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      activeclass: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
    };
  }

  

  showHideModal = () => {
    this.setState({ modalShow: !this.state.modalShow });
  };

  handleMenuClicked = () => {
    this.setState({ activeclass: !this.state.activeclass });
  };

  logout = () => {
    Auth.logout()
  };
  render() {
    const {cart} = this.props;

    if (!loggedInUser) {
      return (
        <div className="main_nav_container pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-right">
                <div className="logo_container">
                  <Link to="/">
                    {this.state.matches ? <img src={Logo} alt="" height="70px" /> : <img src={Logo} alt="" height="55px" />}
                  </Link>
                </div>
                <nav className="navbar">
                  <ul className="navbar_menu">
                    <li>
                      <Link to="/home">home</Link>
                    </li>
                    <li>
                      <Link to="/mechanics">Mechanics</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <a href="/contact-us">contact</a>
                    </li>
                  </ul>
                  <ul className="navbar_user">
                    <li>
                      <a href="/search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="checkout">
                      <a href="#" onClick={() => this.showHideModal()}>
                        <i className="fas fa-shopping-bag"></i>
                        {cart.totalQty !== undefined && (
                          <span id="checkout_items" className="checkout_items">
                            {cart.totalQty}
                          </span>
                        )}
                      </a>
                    </li>
                    <li className="rate">
                      <Button variant="success" style={{
                        "padding": "10px",
                        "text-align": "center",
                        "margin-left": "15px",
                        "marginTop": "5px",
                        "color": "white",
                        "font-size": "14px",
                      }}
                        onClick={() => jumpTo("/rating")}
                      ><i class="fas fa-thumbs-up" style={{
                        "marginRight": "5px"
                      }}></i>{this.state.matches ? `Rate Mechanic` : `Rate`}</Button>
                    </li>
                  </ul>
                  <div
                    className="hamburger_container"
                    onClick={() => this.handleMenuClicked()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <MediaQuery query={device.max.tabletL}>
            <MobileMenu
              activeClass={this.state.activeclass}
              onClose={() => this.handleMenuClicked()}
            />
          </MediaQuery>
          {this.state.modalShow ? (
            <HomeCartViewContainer
              cart={cart}
              show={this.state.modalShow}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } else if (loggedInUser && (loggedInUserIsMechanic === "userMechanic")) {
      return (
        <div className="main_nav_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-right">
                <div className="logo_container">
                  <Link to="/">
                    {this.state.matches ? <img src={Logo} alt="" height="70px" /> : <img src={Logo} alt="" height="55px" />}
                  </Link>
                </div>
                <nav className="navbar">
                  <ul className="navbar_menu">
                    <li>
                      <Link to="/home">home</Link>
                    </li>
                    <li className="mega-drop-down">
                      {/* M Menu */}
                      <a href="#">
                        My Mechanic account <i className="fa fa-angle-down"></i>
                      </a>
                      <div className="mega-menu">
                        <div className="mega-menu-wrap">
                          <div className="mega-menu-content">
                            {/* <h5>Menu</h5> */}
                            <ul className="stander">
                              <li>
                                <Link to="/mechanic-requests"><b>Respond to Requests</b></Link>
                              </li>
                              <li>
                                <Link to="/profile">My Profile</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="mega-drop-down">
                      {/* M Menu */}
                      <a href="#">
                        My client account <i className="fa fa-angle-down"></i>
                      </a>
                      <div className="mega-menu">
                        <div className="mega-menu-wrap">
                          <div className="mega-menu-content">
                            {/* <h5>Menu</h5> */}
                            <ul className="stander">
                              <li>
                                <Link to="/mechanics">Mechanics</Link>
                              </li>
                              <li>
                                <Link to="/services">Services</Link>
                              </li>
                              {/* <li>
                                <Link to="/addgarage">Add Garage</Link>
                              </li>
                              <li>
                                <Link to="/garages">My Garages</Link>
                              </li> */}
                              {/*<li>
                                <Link to="/car">My Car</Link>
                              </li>
                              <li>
                                <Link to="/addcar">Add Car</Link>
                            </li>*/}
                              <li>
                                <Link to="/addrequest">Make a Request</Link>
                              </li>
                              <li>
                                <Link to="/requests">View My Requests</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <a href="/contact-us">contact</a>
                    </li>
                  </ul>
                  <ul className="navbar_user">
                    <li>
                      <a href="/search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="checkout">
                      <a href="#" onClick={() => this.showHideModal()}>
                        <i className="fas fa-shopping-bag"></i>
                        {cart.totalQty !== undefined && (
                          <span id="checkout_items" className="checkout_items">
                            {cart.totalQty}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => this.logout()}>
                        <i
                          className="fas fa-sign-out-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li className="rate">
                      <Button variant="success" style={{
                        "padding": "10px",
                        "text-align": "center",
                        "margin-left": "15px",
                        "marginTop": "5px",
                        "color": "white",
                        "font-size": "14px",
                      }}
                        onClick={() => jumpTo("/rating")}
                      ><i class="fas fa-thumbs-up" style={{
                        "marginRight": "5px"
                      }}></i>{this.state.matches ? `Rate Mechanic` : `Rate`}</Button>
                    </li>
                  </ul>
                  <div
                    className="hamburger_container"
                    onClick={() => this.handleMenuClicked()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <MediaQuery query={device.max.tabletL}>
            <MobileMenu
              activeClass={this.state.activeclass}
              onClose={() => this.handleMenuClicked()}
            />
          </MediaQuery>
          {this.state.modalShow ? (
            <HomeCartViewContainer
              cart={cart}
              show={this.state.modalShow}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } else if (loggedInUser && (loggedInUserIsMechanic === "userCustomer")) {
      return (
        <div className="main_nav_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-right">
                <div className="logo_container">
                  <Link to="/">
                    {this.state.matches ? <img src={Logo} alt="" height="70px" /> : <img src={Logo} alt="" height="55px" />}
                  </Link>
                </div>
                <nav className="navbar">
                  <ul className="navbar_menu">
                    <li>
                      <Link to="/home">home</Link>
                    </li>
                    <li>
                      <Link to="/mechanics">Mechanics</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>

                    <li className="mega-drop-down">
                      <a href="#">
                        My Shop <i className="fa fa-angle-down"></i>
                      </a>
                      <div className="mega-menu">
                        <div className="mega-menu-wrap">
                          <div className="mega-menu-content">
                            {/* <h5>Menu</h5> */}
                            <ul className="stander">
                              <li>
                                <Link to="/profile">My Profile</Link>
                              </li>
                              {/*<li>
                                <Link to="/car">My Car</Link>
                              </li>
                              <li>
                                <Link to="/addcar">Add Car</Link>
                              </li>*/}
                              <li>
                                <Link to="/requests">View Requests</Link>
                              </li>
                              <li>
                                <Link to="/addrequest">Make a Request</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="/contact-us">contact</a>
                    </li>
                  </ul>
                  <ul className="navbar_user">
                    <li>
                      <a href="/search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="checkout">
                      <a href="#" onClick={() => this.showHideModal()}>
                        <i className="fas fa-shopping-bag"></i>
                        {cart.totalQty !== undefined && (
                          <span id="checkout_items" className="checkout_items">
                            {cart.totalQty}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => this.logout()}>
                        <i
                          className="fas fa-sign-out-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li className="rate">
                      <Button variant="success" style={{
                        "padding": "10px",
                        "text-align": "center",
                        "margin-left": "15px",
                        "marginTop": "5px",
                        "color": "white",
                        "font-size": "14px",
                      }}
                        onClick={() => jumpTo("/rating")}
                      ><i class="fas fa-thumbs-up" style={{
                        "marginRight": "5px"
                      }}></i>{this.state.matches ? `Rate Mechanic` : `Rate`}</Button>
                    </li>
                  </ul>
                  <div
                    className="hamburger_container"
                    onClick={() => this.handleMenuClicked()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <MediaQuery query={device.max.tabletL}>
            <MobileMenu
              activeClass={this.state.activeclass}
              onClose={() => this.handleMenuClicked()}
            />
          </MediaQuery>
          {this.state.modalShow ? (
            <HomeCartViewContainer
              cart={cart}
              show={this.state.modalShow}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } else if (loggedInUser && (loggedInUserIsMechanic === "userShopOwner")) {
      return (
        <div className="main_nav_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-right">
                <div className="logo_container">
                  <Link to="/">
                    {this.state.matches ? <img src={Logo} alt="" height="70px" /> : <img src={Logo} alt="" height="55px" />}
                  </Link>
                </div>
                <nav className="navbar">
                  <ul className="navbar_menu">
                    <li>
                      <Link to="/home">home</Link>
                    </li>
                    <li>
                      <Link to="/mechanics">Mechanics</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>

                    <li className="mega-drop-down">
                      <a href="#">
                        My Shop <i className="fa fa-angle-down"></i>
                      </a>
                      <div className="mega-menu">
                        <div className="mega-menu-wrap">
                          <div className="mega-menu-content">
                            {/* <h5>Menu</h5> */}
                            <ul className="stander">
                              <li>
                                <Link to="/profile">My Profile</Link>
                              </li>
                              <li>
                                <Link to="/shoplist">Manage Shops</Link>
                              </li>
                              <li>
                                <Link to="/manageshopproducts">Manage Products</Link>
                              </li>
                              <li>
                                <Link to="/manageadverts">Manage Promotions</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="/contact-us">contact</a>
                    </li>
                  </ul>
                  <ul className="navbar_user">
                    <li>
                      <a href="/search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="checkout">
                      <a href="#" onClick={() => this.showHideModal()}>
                        <i className="fas fa-shopping-bag"></i>
                        {cart.totalQty !== undefined && (
                          <span id="checkout_items" className="checkout_items">
                            {cart.totalQty}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => this.logout()}>
                        <i
                          className="fas fa-sign-out-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    {/*<li className="rate">
                      <Button variant="success" style={{
                        "padding": "10px",
                        "text-align": "center",
                        "margin-left": "15px",
                        "marginTop": "5px",
                        "color": "white",
                        "font-size": "14px",
                      }}
                        onClick={() => jumpTo("/rating")}
                      ><i class="fas fa-thumbs-up" style={{
                        "marginRight": "5px"
                      }}></i>{this.state.matches ? `Rate Mechanic` : `Rate`}</Button>
                    </li>*/}
                  </ul>
                  <div
                    className="hamburger_container"
                    onClick={() => this.handleMenuClicked()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <MediaQuery query={device.max.tabletL}>
            <MobileMenu
              activeClass={this.state.activeclass}
              onClose={() => this.handleMenuClicked()}
            />
          </MediaQuery>
          {this.state.modalShow ? (
            <HomeCartViewContainer
              cart={cart}
              show={this.state.modalShow}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    }else {
      return (
        <div className="main_nav_container">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-right">
                <div className="logo_container">
                  <Link to="/">
                    {this.state.matches ? <img src={Logo} alt="" height="70px" /> : <img src={Logo} alt="" height="55px" />}
                  </Link>
                </div>
                <nav className="navbar">
                  <ul className="navbar_menu">
                    <li>
                      <Link to="/home">home</Link>
                    </li>
                    <li>
                      <Link to="/mechanics">Mechanics</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <a href="/contact-us">contact</a>
                    </li>
                  </ul>
                  <ul className="navbar_user">
                    <li>
                      <a href="/search">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="checkout">
                      <a href="#" onClick={() => this.showHideModal()}>
                        <i className="fas fa-shopping-bag"></i>
                        {cart.totalQty !== undefined && (
                          <span id="checkout_items" className="checkout_items">
                            {cart.totalQty}
                          </span>
                        )}
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => this.logout()}>
                        <i
                          className="fas fa-sign-out-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li className="rate">
                      <Button variant="success" style={{
                        "padding": "10px",
                        "text-align": "center",
                        "margin-left": "15px",
                        "marginTop": "5px",
                        "color": "white",
                        "font-size": "14px",
                      }}
                        onClick={() => jumpTo("/rating")}
                      ><i class="fas fa-thumbs-up" style={{
                        "marginRight": "5px"
                      }}></i>{this.state.matches ? `Rate Mechanic` : `Rate`}</Button>
                    </li>
                  </ul>
                  <div
                    className="hamburger_container"
                    onClick={() => this.handleMenuClicked()}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <MediaQuery query={device.max.tabletL}>
            <MobileMenu
              activeClass={this.state.activeclass}
              onClose={() => this.handleMenuClicked()}
            />
          </MediaQuery>
          {this.state.modalShow ? (
            <HomeCartViewContainer
              cart={cart}
              show={this.state.modalShow}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default NavBar;
