 import { connect } from "react-redux";
 import SingleShopProduct from "./SingleShopProduct";
 import { getShopProduct } from "../../redux/actions/shopProductAction";
 
 const mapStoreToProps = (state) => ({
   shopproduct: state.shopproduct.shopproduct,
 });
 const mapDispatchToProps = {
   getShopProduct,
   
 };
 
 export default connect(mapStoreToProps, mapDispatchToProps)(SingleShopProduct);
 