/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
    GET_ALL_GARAGES_BEGIN,
    GET_ALL_GARAGES_SUCCESS,
    GET_ALL_GARAGES_FAIL,
    GET_GARAGE_BEGIN,
    GET_GARAGE_SUCCESS,
    GET_GARAGE_FAIL,
    GET_GARAGES_BY_CATEGORY_BEGIN,
    GET_GARAGES_BY_CATEGORY_SUCCESS,
    GET_GARAGES_BY_CATEGORY_FAIL,
    SEARCH_BEGIN,
    SEARCH_SUCCESS,
    SEARCH_FAIL,
    APPLY_FILTERS_BEGIN,
    APPLY_FILTERS_SUCCESS,
    APPLY_FILTERS_FAIL,
    ADD_GARAGES_BEGIN,
    ADD_GARAGE_SUCCESS,
    ADD_GARAGE_FAIL
  } from "../actions/garageAction";
  
  const initialState = {
    garages: null,
    garage: null,
    loading: false,
    error: null
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case ADD_GARAGES_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case ADD_GARAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          garages: action.payload.data.rows
        };
      case ADD_GARAGE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case GET_ALL_GARAGES_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_ALL_GARAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          garages: action.payload.data.rows
        };
      case GET_ALL_GARAGES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case GET_GARAGE_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_GARAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          garage: action.payload.data
        };
      case GET_GARAGE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case GET_GARAGES_BY_CATEGORY_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_GARAGES_BY_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          garages: action.payload.data.garages
        };
      case GET_GARAGES_BY_CATEGORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case SEARCH_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          garages: action.payload.data.garages
        };
      case SEARCH_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case APPLY_FILTERS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case APPLY_FILTERS_SUCCESS:
        return {
          ...state,
          loading: false,
          garages: action.payload.data.garages
        };
      case APPLY_FILTERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      default:
        return state;
    }
  };
  