/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
    GET_ALL_SERVICES_BEGIN,
    GET_ALL_SERVICES_SUCCESS,
    GET_ALL_SERVICES_FAIL,
    GET_SERVICE_BEGIN,
    GET_SERVICE_SUCCESS,
    GET_SERVICE_FAIL,
    GET_SERVICES_BY_CATEGORY_BEGIN,
    GET_SERVICES_BY_CATEGORY_SUCCESS,
    GET_SERVICES_BY_CATEGORY_FAIL,
    SEARCH_BEGIN,
    SEARCH_SUCCESS,
    SEARCH_FAIL,
    APPLY_FILTERS_BEGIN,
    APPLY_FILTERS_SUCCESS,
    APPLY_FILTERS_FAIL,
    ADD_SERVICES_BEGIN,
    ADD_SERVICE_SUCCESS,
    ADD_SERVICE_FAIL
  } from "../actions/serviceAction";
  
  const initialState = {
    services: null,
    service: null,
    loading: false,
    error: null
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case ADD_SERVICES_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case ADD_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          services: action.payload.data.rows
        };
      case ADD_SERVICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case GET_ALL_SERVICES_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_ALL_SERVICES_SUCCESS:
        return {
          ...state,
          loading: false,
          services: action.payload.data.rows
        };
      case GET_ALL_SERVICES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case GET_SERVICE_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_SERVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          service: action.payload.data
        };
      case GET_SERVICE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case GET_SERVICES_BY_CATEGORY_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_SERVICES_BY_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          services: action.payload.data.services
        };
      case GET_SERVICES_BY_CATEGORY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case SEARCH_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          services: action.payload.data.services
        };
      case SEARCH_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      case APPLY_FILTERS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
      case APPLY_FILTERS_SUCCESS:
        return {
          ...state,
          loading: false,
          services: action.payload.data.services
        };
      case APPLY_FILTERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload.error.response.data
        };
      default:
        return state;
    }
  };
  