import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import axios from 'axios';

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

export const updateProfile = (data) => async dispatch => {
    const token_stored = AuthToken.get();
    //const tenantId = AuthCurrentTenant.get();

    let webApiUrl = `https://mapi.mechwangu.co.ke/api/auth/profile`;


    dispatch({
        type: UPDATE_PROFILE_BEGIN
    })
    const res = await axios.put(
        webApiUrl, { data },
        {
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        }).then(res => {
            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: res
            })
            return { success: true, message: "Profile updated successfully" };
        }).catch(error => {
            dispatch({
                type: UPDATE_PROFILE_FAIL,
                payload: { error }
            });
            return { success: false, message: "Profile update failed" };
        });
    if (res.success) {
        loggedInUser.fullname = data.fullname;
        loggedInUser.phoneNumber = data.phoneNumber;
        loggedInUser.email = data.email;
        localStorage.setItem("auth", JSON.stringify(loggedInUser));
    }
    return res;
}

export const getProfile = () => async dispatch => {
    const token_stored = AuthToken.get();
    let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';
    dispatch({
        type: GET_PROFILE_BEGIN
    })

    return await axios.get(
        webApiUrl,
        {
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        }).then((res) => {
            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: res
            })
            return res;
        }).catch((error) => {
            dispatch({
                type: GET_PROFILE_FAIL,
                payload: { error }
            });
        }
        );
}

export const UPDATE_PROFILE_BEGIN = 'UPDATE_PROFILE_BEGIN';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const GET_PROFILE_BEGIN = 'GET_PROFILE_BEGIN';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
