import { v4 as uuid } from 'uuid';
import axios from 'axios';
import AuthCurrentTenant from "../axioss/modules/Auth/authCurrentTenant";
import { AuthToken } from "../axioss/modules/Auth/authToken";

const extractExtensionFrom = (filename) => {
    if (!filename) {
        return null;
    }

    const regex = /(?:\.([^.]+))?$/;
    const exec = regex.exec(filename);

    if (!exec) {
        return null;
    }

    return exec[1];
}
const fetchFileCredentials = async (filename, config) => {
    const tenantId = AuthCurrentTenant.get() || '61d1ce08b967711d2952da3f'
    const token_stored = AuthToken.get();

    const { data } = await axios.get(
        `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/file/credentials`,
        {
            params: {
                filename: filename,
                storageId: config.storage.id,
            },
            headers: {
                "Authorization": `Bearer ${token_stored}`
            }
        },
    );

    return data;
    
}
const uploadToServer = async (file, uploadCredentials) => {
    try {
        const url = uploadCredentials.url;
        const formData = new FormData();

        for (const [key, value] of Object.entries(
            uploadCredentials.fields || {},
        )) {
            formData.append(key, JSON.stringify(value));
        }
        formData.append('file', file);

        return axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const uploadFile = async (file, config) => {
    const extension = extractExtensionFrom(file.name);
    const id = uuid();
    const filename = `${id}.${extension}`;

    const {
        uploadCredentials,
        downloadUrl,
        privateUrl,
    } = await fetchFileCredentials(filename, config);

    await uploadToServer(file, uploadCredentials);

    return {
        id: id,
        name: file.name,
        sizeInBytes: file.size,
        publicUrl:
            uploadCredentials &&
                uploadCredentials.publicUrl
                ? uploadCredentials.publicUrl
                : null,
        privateUrl,
        downloadUrl,
        new: true,
    };
}