/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import SingleMechanicHolderForMechanicPage from "./SingleMechanic";
import Heading from "../Heading";
import NewArrivals from "../Products/NewArrivals";

// import "../../assets/css/button.css";

class Mechanics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mechanics: this.props.mechanics
    };
  }

  render() {
    const { mechanics, departments } = this.props;

    console.log("Cars: " + mechanics)

    return (
      <div className="best_sellers">
        <div className="container">
          <div className="row">
            {this.props.service === undefined ?
              <Heading title="Mechanics" data-aos="fade-up" /> :
              <Heading title={`Mechanics Offering ${this.props.serviceName}`} data-aos="fade-up" />}
          </div>

          <div className="row" style={{ marginTop: 50 }}>
            {/* {mechanics &&
              mechanics.slice(0, 8).map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-sm-6"
                    key={index}
                    data-aos="zoom-in"
                  >
                    <SingleMechanicHolderForMechanicPage
                      mechanicItem={item}
                      addToBag={this.props.addToBag}
                      service={this.props.service}
                    />
                  </div>
                );
              })} */}
            {mechanics ? <NewArrivals
              products={mechanics}
              addToBag={this.addToBag}
              onHide={() => { }}
            /> : <span></span>}
          </div>
        </div>
      </div>
    );
  }
}

export default Mechanics;
