/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { connect} from "react-redux";
import { Link } from "react-router-dom";
import CircleSpinner from "../../../components/Spinner/Circle";
import { getShopProductsByCategory, sortProductsByProximity } from "../../../redux/actions/shopProductAction";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {this.props.matches ? (
          <div class="sidebar_section">
            <div class="sidebar_title">
              <h5>Product Category</h5>
            </div>
            <ul class="sidebar_categories">
              {this.props.categories && this.props.categories.map((category) => {
                return (
                  <li class="active">
                    <Link to={{
                      pathname: `#`,
                      state: { category: category.id, categoryName: category.categoryName }
                    }}>
                      <a>
                        <span>
                          <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                        </span>
                        {category.categoryName}
                      </a>
                    </Link>
                  </li>)
              })}
            </ul>
          </div>
        ) : (
          <div className="sidebar_section">
            <div className="sidebar_title">
              <h5>Product Category</h5>
            </div>
            {!this.props.loading ? <ul className="sidebar_categories">
              {this.props.categories && this.props.categories.map((category) => {
                return (
                  <li class="active">
                    <Link onClick={() =>this.props.getShopProductsByCategory(category.id).then(() =>this.props.sortProductsByProximity())} to={{
                      state: { category: category.id, categoryName: category.categoryName }
                    }}
                      style={{
                        "color": "red",
                      }}
                    >
                      <span>
                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                      </span>
                      {category.categoryName}
                    </Link>
                  </li>)
              })}
            </ul> : <CircleSpinner message="Loading" />}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch=>( {
  getShopProductsByCategory: (categoryId) => dispatch(getShopProductsByCategory(categoryId)),
  sortProductsByProximity: () => dispatch(sortProductsByProximity()),
});
const mapStoreToProps = (state) => ({
    categories: state.category.categories,
});

export default connect(mapStoreToProps, mapDispatchToProps)(Filter);
