/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import Auth from "../../modules/Auth";
import MyMechanic from "../../components/Mechanic/MechanicsPage";
import LoginRegister from "../../components/LoginRegisterModal";
import Loader from "../../components/Loader/Loader";


let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class SingleService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modalShow: false,
            login: true
        };
        this.addToBag = this.addToBag.bind(this);
    }

    componentDidMount() {
        console.log("Mechanicz " + JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0]));
        const id = this.props.location.pathname.split("/").slice(-1)[0];
        if (this.props.mechanics) window.location.reload();
        this.props.getMechanicsUsingSpecialityServiceId(id);
    }

    showHideModal = () => {
        this.setState({ modalShow: false });
    };

    loginClicked = () => {
        this.setState({ modalShow: true, login: true });
    };
    registerClicked = () => {
        this.setState({ modalShow: true, login: false });
    };

    addToBag = params => {
        if (
            Auth.getUserDetails() !== undefined &&
            Auth.getUserDetails() !== null &&
            Auth.getToken() !== undefined
        ) {
            let cart = this.props.postCart(params);
            cart.then(res => {
                console.log(res);
            });
        } else {
            this.setState({ modalShow: true });
        }
    };

    render() {
        const { mechanics, departments, location } = this.props;
        return (
            <div style={!mechanics ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
                {!this.props.loading ?
                    <>
                        <br />
                        {/* <CarCategoryBanner /> */}
                        {mechanics ? (
                            <MyMechanic
                                mechanics={mechanics.filter(mech => mech.mechanicUserId?.id !== loggedInUser?.id)}
                                service={location.state?.service}
                                serviceName={location.state?.serviceName}
                                loginClicked={this.loginClicked}
                            />
                        ) :
                            <MyMechanic
                                service={location.state?.service}
                                serviceName={location.state?.serviceName}
                                loginClicked={this.loginClicked}
                            />}
                        {/* <Benefit /> */}
                        <LoginRegister
                            show={this.state.modalShow}
                            login={this.state.login}
                            registerClicked={() => this.registerClicked()}
                            loginClicked={() => this.loginClicked()}
                            onHide={() => this.showHideModal()}
                        />
                    </>
                    :
                    <Loader />}
            </div>
        );
    }
}

export default SingleService;
