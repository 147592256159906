 import React, { Component } from "react";
 import Auth from "../../modules/Auth";
 import LoginRegister from "../../components/LoginRegisterModal";
 import Loader from "../../components/Loader/Loader";
import LatestShopProducts from "../../components/ShopProducts/LatestShopProducts";
 
 class ShopProduct extends Component {
   constructor(props) {
     super(props);
     this.state = {
       data: null,
       modalShow: false,
       login: true,
       sorted: [],
       timeData: {},
     };
     this.addToBag = this.addToBag.bind(this);
   }
 
   componentDidMount() {
     this.props.getAllShopProducts({
       limit: 9,
     })
   }
 
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
 
   addToBag = params => {
     if (
       Auth.getUserDetails() !== undefined &&
       Auth.getUserDetails() !== null &&
       Auth.getToken() !== undefined
     ) {
       let cart = this.props.postCart(params);
       cart.then(res => {
         console.log(res);
       });
     } else {
       this.setState({ modalShow: true });
     }
   };
 
   render() {
     const shopproducts = this.props.sorted ? this.props.sorted.data : this.props.shopproducts;
     return (
       <div style={!shopproducts ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
         {shopproducts ?
           <>
             <br />
             {shopproducts ? (
               <LatestShopProducts
                 shopproducts={shopproducts}
                 addToBag={this.addToBag}
                 onHide={() => this.showHideModal()}
               />
             ) : null}
             <LoginRegister
               show={this.state.modalShow}
               login={this.state.login}
               registerClicked={() => this.registerClicked()}
               loginClicked={() => this.loginClicked()}
               onHide={() => this.showHideModal()}
             />
           </>
           :
           <Loader />}
       </div>
     );
   }
 }
 
 export default ShopProduct;
 