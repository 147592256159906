/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import LoginRegister from "../LoginRegisterModal";
import Auth from "../../modules/Auth/userToken";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));
let loggedInUserIsMechanic = null;
let status;
if (loggedInUser) {
  let phoneVerified = loggedInUser.phoneVerified;
  console.log("phoneVerified " + phoneVerified);
  loggedInUserIsMechanic = loggedInUser.tenants[0].roles[0];
  status = loggedInUser.tenants[0].status;
}

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      login: true,
      registershopowner: false,
    };
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false, registershopowner: false});
  };
  registerShopOwnerClicked =() => {
    this.setState({modalShow: true, login: false, registershopowner: true})
  }
  logout = () => {
    Auth.logout();
  };
  render() {
    if (!loggedInUser) {
      return (
        <div
          className={
            this.props.activeClass ? "hamburger_menu active" : "hamburger_menu"
          }
        >
          <div className="hamburger_close" onClick={this.props.onClose}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="hamburger_menu_content text-right">
            <ul className="menu_top_nav">
              <li className="menu_item has-children">
                {Auth.getUserDetails() !== undefined &&
                  Auth.getUserDetails() !== null ? (
                  <li className="account">
                    <a href="">
                      {`Karibu ${Auth.getUserDetails().firstName}`}
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.logout()}>
                          <i
                            className="fas fa-sign-out-alt"
                            aria-hidden="true"
                          ></i>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <div>
                    <li className="account">
                      <a href="/mech-register">
                        SIGN UP AS MECHANIC
                      </a>
                      <a href="#" onClick={() => this.registerShopOwnerClicked()}>
                        <i className="fa fa-user-plus" aria-hidden="true"></i>
                        Shop Owner Sign Up
                      </a>
                    </li>
                    <br />
                    <li className="account">
                      <a href="#">
                        Sign In | Sign Up
                        <i className="fa fa-angle-down"></i>
                      </a>
                      <ul className="account_selection">
                        <li>
                          <a href="#" onClick={() => this.loginClicked()}>
                            <i
                              className="fas fa-sign-in-alt"
                              aria-hidden="true"
                            ></i>
                            Sign In
                          </a>
                        </li>
                        <li>
                          <a href="#" onClick={() => this.registerClicked()}>
                            <i className="fa fa-user-plus" aria-hidden="true"></i>
                            Sign Up
                          </a>
                        </li>
                      </ul>
                    </li>
                  </div>
                )}
              </li>
              <li className="menu_item">
                <Link to="/home">home</Link>
              </li>
              <li className="menu_item">
                <Link to="/mechanics">Mechanics</Link>
              </li>
              <li className="menu_item">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu_item">
                <Link to="/shop">Shop</Link>
              </li>
              <li className="menu_item">
                <a href="/contact-us">contact</a>
              </li>
            </ul>
          </div>
          {this.state.modalShow ? (
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registershopowner={this.state.registershopowner}
              registerClicked={() => this.registerClicked()}
              registerShopOwnerClicked={() => this.registerShopOwnerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } else if (loggedInUser && (loggedInUserIsMechanic === "userMechanic" || status === "empty-permissions")) {
      return (
        <div
          className={
            this.props.activeClass ? "hamburger_menu active" : "hamburger_menu"
          }
        >
          <div className="hamburger_close" onClick={this.props.onClose}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="hamburger_menu_content text-right">
            <ul className="menu_top_nav">
              <li className="menu_item has-children">
                {Auth.getUserDetails() !== undefined &&
                  Auth.getUserDetails() !== null ? (
                  <li className="account">
                    <a href="#">
                      {`Karibu ${Auth.getUserDetails().firstName}`}
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.logout()}>
                          <i
                            className="fas fa-sign-out-alt"
                            aria-hidden="true"
                          ></i>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="account">
                    <a href="#">
                      Sign In | Sign Up
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.loginClicked()}>
                          <i
                            className="fas fa-sign-in-alt"
                            aria-hidden="true"
                          ></i>
                          Sign In
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.registerClicked()}>
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                          Sign Up
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.registerShopOwnerClicked()}>
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                          Shop Owner Sign Up
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </li>
              <li className="menu_item">
                <Link to="/home">home</Link>
              </li>
              <li className="menu_item">
                <Link to="/mechanic-requests"><b>Respond to Requests</b></Link>
              </li>
              <li className="menu_item">
                <Link to="/profile"><b>My Profile</b></Link>
              </li>
              <li className="menu_item">
                <Link to="/mechanics">Mechanics</Link>
              </li>
              <li className="menu_item">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu_item">
                <Link to="/shop">Shop</Link>
              </li>
              {/*<li className="menu_item">
                <Link to="/car">My Car</Link>
              </li>
              <li className="menu_item">
                <Link to="/addcar">Add Car</Link>
                </li>*/}
              <li className="menu_item">
                <Link to="/requests">View My Requests</Link>
              </li>
              <li className="menu_item">
                <Link to="/addrequest">Make a Request</Link>
              </li>
              <li className="menu_item">
                <Link to="/profile">My Profile</Link>
              </li>
              {/* <li className="menu_item">
                <Link to="/addgarage">Add Garage</Link>
              </li>
              <li className="menu_item">
                <Link to="/garages">My Garages</Link>
              </li> */}
              <li className="menu_item">
                <a href="/contact-us">contact</a>
              </li>
            </ul>
          </div>
          {this.state.modalShow ? (
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registershopowner={this.state.registershopowner}
              registerClicked={() => this.registerClicked()}
              registerShopOwnerClicked={() => this.registerShopOwnerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } else if (loggedInUser && loggedInUserIsMechanic === "userCustomer") {
      return (
        <div
          className={
            this.props.activeClass ? "hamburger_menu active" : "hamburger_menu"
          }
        >
          <div className="hamburger_close" onClick={this.props.onClose}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="hamburger_menu_content text-right">
            <ul className="menu_top_nav">
              <li className="menu_item has-children">
                {Auth.getUserDetails() !== undefined &&
                  Auth.getUserDetails() !== null ? (
                  <li className="account">
                    <a href="#">
                      {`Karibu ${Auth.getUserDetails().firstName}`}
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.logout()}>
                          <i
                            className="fas fa-sign-out-alt"
                            aria-hidden="true"
                          ></i>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="account">
                    <a href="#">
                      Sign In | Sign Up
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.loginClicked()}>
                          <i
                            className="fas fa-sign-in-alt"
                            aria-hidden="true"
                          ></i>
                          Sign In
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.registerClicked()}>
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                          Sign Up
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.registerShopOwnerClicked()}>
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                          Shop Owner Sign Up
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </li>
              <li className="menu_item">
                <Link to="/home">home</Link>
              </li>
              <li className="menu_item">
                <Link to="/mechanics">Mechanics</Link>
              </li>
              <li className="menu_item">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu_item">
                <Link to="/shop">Shop</Link>
              </li>
              {/*<li className="menu_item">
                <Link to="/car">My Car</Link>
              </li>
              <li className="menu_item">
                <Link to="/addcar">Add Car</Link>
                </li>*/}
              <li className="menu_item">
                <Link to="/requests">View My Requests</Link>
              </li>
              <li className="menu_item">
                <Link to="/addrequest">Make a Request</Link>
              </li>
              <li className="menu_item">
                <Link to="/profile">My Profile</Link>
              </li>
              <li className="menu_item">
                <a href="/contact-us">contact</a>
              </li>
            </ul>
          </div>
          {this.state.modalShow ? (
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registershopowner={this.registershopowner}
              registerClicked={() => this.registerClicked()}
              registerShopOwnerClicked={() => this.registerShopOwnerClicked()}
              loginClicked={() => this.loginClicked()}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } else if (loggedInUser && loggedInUserIsMechanic === "userShopOwner") {
      return (
        <div
          className={
            this.props.activeClass ? "hamburger_menu active" : "hamburger_menu"
          }
        >
          <div className="hamburger_close" onClick={this.props.onClose}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <div className="hamburger_menu_content text-right">
            <ul className="menu_top_nav">
              <li className="menu_item has-children">
                {Auth.getUserDetails() !== undefined &&
                  Auth.getUserDetails() !== null ? (
                  <li className="account">
                    <a href="#">
                      {`Karibu ${Auth.getUserDetails().firstName}`}
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.logout()}>
                          <i
                            className="fas fa-sign-out-alt"
                            aria-hidden="true"
                          ></i>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="account">
                    <a href="#">
                      Sign In | Sign Up
                      <i className="fa fa-angle-down"></i>
                    </a>
                    <ul className="account_selection">
                      <li>
                        <a href="#" onClick={() => this.loginClicked()}>
                          <i
                            className="fas fa-sign-in-alt"
                            aria-hidden="true"
                          ></i>
                          Sign In
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.registerClicked()}>
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                          Sign Up
                        </a>
                      </li>
                      <li>
                        <a href="#" onClick={() => this.registerShopOwnerClicked()}>
                          <i className="fa fa-user-plus" aria-hidden="true"></i>
                          Shop Owner Sign Up
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </li>
              <li className="menu_item">
                <Link to="/home">home</Link>
              </li>
              <li className="menu_item">
                <Link to="/mechanics">Mechanics</Link>
              </li>
              <li className="menu_item">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu_item">
                <Link to="/shop">Shop</Link>
              </li>
              <li className="menu_item">
                <Link to="/shoplist">Manage Shops</Link>
              </li>
              <li className="menu_item">
                <Link to="/manageshopproducts">Manage Products</Link>
              </li>
              <li className="menu_item">
                <Link to="/manageadverts">Manage Promotions</Link>
              </li>
              <li className="menu_item">
                <Link to="/profile">My Profile</Link>
              </li>
              <li className="menu_item">
                <a href="/contact-us">contact</a>
              </li>
            </ul>
          </div>
          {this.state.modalShow ? (
            <LoginRegister
              show={this.state.modalShow}
              login={this.state.login}
              registershopowner={this.state.registershopowner}
              registerClicked={() => this.registerClicked()}
              loginClicked={() => this.loginClicked()}
              registerShopOwnerClicked={() => this.registerShopOwnerClicked()}
              onHide={() => this.showHideModal()}
            />
          ) : null}
        </div>
      );
    } 
  }
}
MobileMenu.propTypes = {
  activeClass: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MobileMenu;
