/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import React, { Component } from "react";
 import { Image } from "react-bootstrap";
 import LoginRegister from "../../components/LoginRegisterModal";
 import Auth from "../../modules/Auth";
 import carDefaultProfile from "../../assets/images/mechwangu_default_car.svg";
 import { Link } from "react-router-dom";
 import Loader from "../../components/Loader/Loader";
 import carMob from "../../assets/images/car_mob.png";
 
 class SingleShop extends Component {
   constructor(props) {
     super(props);
     this.state = {
       color: "",
       size: "",
       pic: "",
       selectedSize: "",
       id: "",
       quantity: 1,
       modalShow: false,
       login: true,
       matches: window.matchMedia("(min-width: 768px)").matches
     };
   }
   componentDidMount() {
     console.log("ShopId " + JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0]));
     this.props.getShop(this.props.location.pathname.split("/").slice(-1)[0]);
     console.log("Shop " + JSON.stringify(this.props.shop));
   }
 
   showHideModal = () => {
     this.setState({ modalShow: false });
   };
 
   loginClicked = () => {
     this.setState({ modalShow: true, login: true });
   };
   registerClicked = () => {
     this.setState({ modalShow: true, login: false });
   };
 
 
   render() {
     console.log("Locationzz" + JSON.stringify(this.props.shop));
     let createdDate =this.props.shop && new Date(this.props.shop.createdAt);
     return (
       <div style={!this.props.shop ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
         <div className="container single_product_container">
           {this.props.shop ? (
             <div>
               <div className="row">
                 <div className="col">
                   <div className="breadcrumbs d-flex flex-row align-items-center">
                     <ul>
                       <li>
                         <a href="/">Home</a>
                       </li>
                       <li>
                         <a href="/shoplist">
                           <i className="fa fa-angle-right" aria-hidden="true"></i>
                           Shops
                         </a>
                       </li>
                       <li className="active">
                         <a href="#">
                           <i className="fa fa-angle-right" aria-hidden="true"></i>
                           {this.props.shop.shopName}
                         </a>
                       </li>
                     </ul>
                   </div>
                 </div>
               </div>
 
               <div className="row">
                 <div className="col-lg-12 text-center">
                   <div className="product_details">
                     <div className="product_details_title">
                       <h2>{this.props.shop.shopName}</h2>
                       <p><span className="text-danger">Shop ID: </span>{this.props.shop._id}</p>
                       <p><span className="text-danger">Created At: </span>{createdDate.toString()}</p>
                       <p><span className="text-danger">Products in Shop: </span></p> 
                       <ul>
                        {this.props.shop.shopProducts.map(product=>(
                        <li>
                          <p>{product.productName}</p>
                        </li>
                        ))}
                       </ul>
                     </div>
                     <Link to={{
                       pathname: `/editshop/${this.props.shop._id}`,
                       state: {
                         shop: this.props.shop,
                       }
                     }}>
                       <div className="red_button product-add_to_cart_button align-items-center justify-content-center">
                         <span>Edit Shop  </span>
                         <span>
                           <i className="fas fa-pen"></i>
                         </span>
                       </div>
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
           ) :
             <Loader />
           }
         </div>
         <LoginRegister
           show={this.state.modalShow}
           registerClicked={() => this.registerClicked()}
           loginClicked={() => this.loginClicked()}
           onHide={() => this.showHideModal()}
         />
       </div>
     );
   }
 }
 
 export default SingleShop;
 