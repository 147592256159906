/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { combineReducers } from "redux";
import login from "./LoginReducer";
import verification from "./verifyReducer";
import register from "./RegisterReducer";
import department from "./DepartmentReducer";
import product from "./productReducer";
import variant from "./variantsReducer";
import cart from "./cartReducer";
import mechanic from "./mechanicReducer"
import car from "./carReducer"
import request from "./requestReducer"
import location from "./locationReducer"
import carmake from "./carMakesReducer"
import carmodel from "./carModelReducer"
import service from "./serviceReducer"
import garage from "./garageReducer"
import checkout from './checkoutReducer'
import order from './orderReducer'
import profile from './profileReducer'
import shop from'./shopReducer'
import shopowner from './ShopOwnerReducer'
import category from './productCategoryReducer'
import shopproduct from './shopProductReducer'
import adpackage from './packageReducer'
import packageorder from './packageOrderReducer'
import advert from './advertisementReducer'
import advertpayment from './advertPaymentReducer'

// import filter from './filterReducer'

export default combineReducers({
  department,
  login,
  register,
  product,
  variant,
  cart,
  mechanic,
  car,
  request,
  location,
  carmake,
  carmodel,
  service,
  garage,
  verification,
  checkout,
  order,
  profile,
  shop,
  shopowner,
  shopproduct,
  category,
  adpackage,
  packageorder,
  advert,
  advertpayment
});
