import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

export const getAllPackages = () => async dispatch => {
  dispatch({
    type: GET_ALL_PACKAGES_BEGIN
  });

  const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advert-package`;

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("packages " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_PACKAGES_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_PACKAGES_FAIL,
        payload: { error }
      });
    })
};

export const getPackage = id => dispatch => {
  dispatch({
    type: GET_PACKAGE_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get() || "61d1ce08b967711d2952da3f";

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/advert-package/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Package " + JSON.stringify(res));
    dispatch({
      type: GET_PACKAGE_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_PACKAGE_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const GET_ALL_PACKAGES_BEGIN = "GET_ALL_PACKAGES_BEGIN";
export const GET_ALL_PACKAGES_SUCCESS = "GET_ALL_PACKAGES_SUCCESS";
export const GET_ALL_PACKAGES_FAIL = "GET_ALL_PACKAGES_FAIL";

export const GET_PACKAGE_BEGIN = "GET_PACKAGE_BEGIN";
export const GET_PACKAGE_SUCCESS = "GET_PACKAGE_SUCCESS";
export const GET_PACKAGE_FAIL = "GET_PACKAGE_FAIL";
