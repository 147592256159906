/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllServices,
  applyFilters
} from "../../redux/actions/serviceAction";
import { connect } from "react-redux";
import Service from "./Service";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  services: state.service.services,
  departments: state.department.departments,
  loading: state.car.loading
});
const mapDispatchToProps = dispatch => ({
  getAllServices: () => dispatch(getAllServices()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: productId => dispatch(postCart(productId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(Service);
