/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */
import {
    getAllShopProducts,
    setUserLocation,
    sortProductsByProximity,  
  } from "../../redux/actions/shopProductAction";
import { connect } from "react-redux";
import LatestShopProducts from "./LatestShopProducts";
  
  const mapStoreToProps = state => ({
    shopproducts: state.shopproduct.sortedshopproducts,
    loading: state.shopproduct.loading
  });
  const mapDispatchToProps = dispatch => ({
    getAllShopProducts: () => dispatch(getAllShopProducts()),
    setUserLocation: (userLocation) =>dispatch(setUserLocation(userLocation)),
    sortProductsByProximity: () => dispatch(sortProductsByProximity()),
  });
  
  export default connect(mapStoreToProps, mapDispatchToProps)(LatestShopProducts);
  