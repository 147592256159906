import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import jumpTo from "../../modules/Navigation";

const PromptModal = ({ show, onHide }) => {
    return (
        <Modal
            size="sm"
            show={show}
            onHide={() => onHide(false)}
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body centered>
                <Row>
                    <Button
                        variant="outline-danger"
                        onClick={() => window.location.reload()}
                        style={{ "width": "90%", "margin-left": "5%", "margin-bottom": "10px" }}
                    >
                        Add more requests
                    </Button>
                </Row>
                <Row>
                    <Button
                        variant="success"
                        style={{ "width": "90%", "margin-left": "5%" }}
                        onClick={() => jumpTo("/checkout")}
                    >
                        Go to Checkout
                    </Button>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
export default PromptModal;