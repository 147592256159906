/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import Auth from "../../modules/Auth";
import jumpTo from "../../modules/Navigation";
import LoginRegister from "../../components/LoginRegisterModal";
import { addClientRequest } from "../../redux/actions/requestAction";
// import { addCar} from "../../redux/actions/carAction";
import LoadingButton from "../../components/LoadingButton";
import Loader from "../../components/Loader/Loader";

import Validator from "../../utils/Validator";
import { DEFAULT_RULE } from "../../utils/Validator/rule";

import ValidationModal from "../../components/ValidationModal/ValidationModal";
import PromptModal from "../../components/PromptModal";
import { Button, Row } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import CircleSpinner from "../../components/Spinner/Circle";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

class Express extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modalShow: false,
            errorModal: false,
            promptModal: false,
            login: true,
            loading: false,
            requestForCar: undefined,
            requestDetail: undefined,
            reqDetErr: false,
            affects: undefined,
            status: "Active",
            fetch: false,
            locationName: undefined,
            locationDescription: undefined,
            coordinates: undefined,
            mechanicId: undefined,
            changed: false,
            matches: window.matchMedia("(min-width: 768px)").matches,
            expressing: false,
            options: [],
            mech: undefined,
        };
        this.addToBag = this.addToBag.bind(this);
    }

    componentDidMount() {
        this.props.getAllServices();
        if (this.props.location.state !== undefined) {
            this.setState({
                affects: this.props.location.state.service,
                mechanicId: this.props.location.state.mechId,
                mech: this.props.location.state.mech,
            });
        }
    }

    showHideModal = () => {
        this.setState({ modalShow: false });
    };

    loginClicked = () => {
        this.setState({ modalShow: true, login: true });
    };
    registerClicked = () => {
        this.setState({ modalShow: true, login: false });
    };

    addToBag = params => {
        if (
            Auth.getUserDetails() !== undefined &&
            Auth.getUserDetails() !== null &&
            Auth.getToken() !== undefined
        ) {
            let cart = this.props.postCart(params);
            cart.then(res => {
                console.log(res);
            });
        } else {
            this.setState({ modalShow: true });
        }
    };

    changeHandler = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    // focus = (e) => {
    //     switch (e.target.name) {
    //         case "requestDetail":
    //             this.setState({ reqDetErr: false });
    //             break;
    //         default:
    //             break;
    //     }
    // }
    // blur = (e) => {
    //     switch (e.target.name) {
    //         case "requestDetail":
    //             if (!Validator(e.target.value, DEFAULT_RULE)) {
    //                 this.setState({ reqDetErr: true });
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // }

    submitHandler = () => {
        const { requestDetail, affects, mechanicId, locationName, locationDescription, coordinates } = this.state;
        let data;
        if (affects && mechanicId && locationName && locationDescription && coordinates) {
            let locationData = {
                locationName,
                locationDescription,
                coordinates
            }
            data = {
                service: affects,
                detail: requestDetail || "",
                mech: mechanicId,
                location: locationData
            }
            localStorage.setItem("express", JSON.stringify(data));
            return jumpTo("/express/checkout");
        } else {
            this.setState({ errorModal: true });
        }
    };

    handleServiceChange = async (service, location) => {

        this.setState({
            expressing: true,
        });

        return await axios.post(`https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/mechanic/express`, {
            data: {
                service,
                location
            }
        }).then((res) => {
            this.setState({
                expressing: false,
                options: res.data
            });
        }).catch(err => {
            this.setState({
                expressing: false,
                expressingErr: true,
            });
        });
    }

    carLocationHandler = () => {
        let car = this.props.cars.find(car => car.id === this.state.requestForCar)
        console.log(car)
        this.setState({
            userLoc: car.carLocatedAt.locationName
        })
    }

    render() {
        const { requestDetail, locations, carmakes, carmodels, departments } = this.state;

        return (
            <div style={this.props.loading ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
                {this.props.services ?
                    <>
                        <div className="container mt-2" style={{
                            "marginBottom": "20px"
                        }}>
                            {this.props.services && (
                                <div className="row mt-5">
                                    <div className="col-md-6 mt-5 mb-5 mb-md-0" style={!this.state.matches ? null : {
                                        "margin-left": "25%",
                                    }}>
                                        <form>
                                            <h2 className="h3 mb-3 text-black">Express Request</h2>
                                            <br />
                                            <div className="p-3 p-lg-5 border">

                                                {!this.props.location.state?.hideDesc && <div className="form-group row">
                                                    <div className="col-md-12">
                                                        {this.props.location.state?.vType ? <label htmlFor="requestDetail" className="text-black">{`Describe your ${this.props.location.state?.vType} issue`}<span
                                                            className="text-danger">*</span></label> : <label htmlFor="requestDetail" className="text-black">Describe your car issue <span
                                                                className="text-danger">*</span></label>}
                                                        <input type="text" className="form-control" id="requestDetail" name="requestDetail" value={requestDetail} onChange={this.changeHandler}
                                                            placeholder="Type your request here... e.g. I need a car oil change" />
                                                        {/* placeholder="Type your request here... e.g. I need a car oil change" style={this.state.reqDetErr ? { "border": "2px solid red" } : null} onBlur={e => this.blur(e)} onFocus={e => this.focus(e)} /> */}
                                                        {/* {this.state.reqDetErr && <span style={{ "fontSize": "15px", "color": "red" }}>This field is required</span>} */}
                                                    </div>
                                                </div>}
                                                <LocationField
                                                    setLocationName={locationName => {
                                                        this.setState({ locationName })
                                                        if (this.state.affects && !this.props.location.state?.mechId) {
                                                            this.handleServiceChange(this.state.affects, locationName)
                                                        };
                                                    }}
                                                    setLocationDescription={locationDescription => this.setState({ locationDescription })}
                                                    setCoordinates={coordinates => this.setState({ coordinates })}
                                                />
                                                <div className="form-group">
                                                    {this.props.location.state?.service ?
                                                        <><label htmlFor="affects" className="text-black">Selected service <span
                                                            className="text-danger">*</span></label>
                                                            <select id="affects" className="form-control" value={this.state.affects} disabled>
                                                                <option disabled="disabled">Change service?</option>
                                                                {
                                                                    this.props.services.map(service => <option value={service.id}>{service.servicesName}</option>)
                                                                }
                                                            </select></>
                                                        :
                                                        <><label htmlFor="affects" className="text-black">Select service <span
                                                            className="text-danger">*</span></label>
                                                            <select id="affects" className="form-control" onChange={(service => {
                                                                this.setState({
                                                                    affects: service.target.value,
                                                                    fetch: true
                                                                });
                                                                this.handleServiceChange(service.target.value, this.state.locationName);
                                                            })} defaultValue={`Select service e.g. Buffing`} value={this.state.affects}>
                                                                <option disabled="disabled">Select service e.g. Buffing</option>
                                                                {
                                                                    this.props.services.map(service => <option value={service.id}>{service.servicesName}</option>)
                                                                }
                                                            </select></>}
                                                </div>
                                                <div className="form-group">
                                                    {this.props.location.state?.mechId ? <><label htmlFor="affects" className="text-black">Selected mechanic <span
                                                        className="text-danger">*</span></label>
                                                        <select id="affects" className="form-control" value={this.props.location.state?.mechId} disabled>
                                                            {
                                                                <option value={this.state.mech.id}>{this.state?.mech?.mechanicUserId.fullName || this.state?.mech?.mechanicUserId.firstName || this.state.mech?.mechanicUserId.lastName || ""}</option>
                                                            }
                                                        </select></> : <><label htmlFor="mechanicId" className="text-black">Select Mechanic <span
                                                            className="text-danger">*</span></label>
                                                        {this.state.affects !== undefined && this.state.locationName ?
                                                            <>
                                                                {(!this.state.expressing && this.state.options.length > 0) ? <Select placeholder={"Select a Mechanic"} options={this.state.options} onChange={option => this.setState({ mechanicId: option.value })} value={this.state.mechanicId} /> :
                                                                    <CircleSpinner message={`Getting mechanics near ${this.state.locationName}`} />
                                                                }
                                                            </>
                                                            :
                                                            <Select placeholder={(this.state.affects && !this.state.locationName) ? "Kindly input your location first" : "Select a Mechanic"} />
                                                        }</>}
                                                </div>
                                                <div className="form-group">
                                                    <Button
                                                        className="btn btn-success btn-lg btn-block"
                                                        //disabled={this.state.affects === undefined || this.state.locationName === undefined || this.state.mechanicId === undefined}
                                                        onClick={this.submitHandler}
                                                    >Proceed</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                        <ValidationModal show={this.state.errorModal} onHide={(bool) => { this.setState({ errorModal: bool }); window.location.reload() }} />
                        <PromptModal show={this.state.promptModal} onHide={(bool) => { this.setState({ promptModal: bool }); jumpTo("/requests") }} />
                        <LoginRegister
                            show={this.state.modalShow}
                            login={this.state.login}
                            registerClicked={() => this.registerClicked()}
                            loginClicked={() => this.loginClicked()}
                            onHide={() => this.showHideModal()}
                        />
                    </>
                    : <Loader />
                }
            </div>
        );
    }
}

const LocationField = ({ setLocationName, setLocationDescription, setCoordinates }) => {
    const {
        // ready,
        value: locationName,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
        },
        debounce: 300,
    })
    const onLocationSelect = ({ description, structured_formatting: { main_text } }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();
        setLocationName(description);
        setLocationDescription(main_text);

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log("📍 Coordinates: ", { lat, lng });
                setCoordinates([lat, lng].join(","))
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };
    return (
        <div className="form-group">
            <label htmlFor="carLocatedAt" className="text-black">Car Located At <span
                className="text-danger">*</span></label>
            <input type="text" className="form-control" id="carLocatedAt" name="carLocatedAt" placeholder="Type in location and choose the correct one." value={locationName} onChange={(e) => setValue(e.target.value)} />
            {status === "OK" && <ul className="list-group">
                {
                    data.map((suggestion) => {
                        const {
                            place_id,
                            structured_formatting: { main_text, secondary_text },
                        } = suggestion;

                        return (
                            <li className="list-group-item" key={place_id} onClick={onLocationSelect(suggestion)}>
                                <strong>{main_text}</strong><small>{` ${secondary_text}`}</small>
                            </li>
                        );
                    })
                }
            </ul>}
        </div>
    );
}

const mapDispatchToProps = {
    addClientRequest
};
const mapStoreToProps = state => ({
    login_loading: state.login.login_loading,
    login_error: state.login.error
});

export default connect(mapStoreToProps, mapDispatchToProps)(Express);
