import { connect } from "react-redux";
import { getPackage } from "../../redux/actions/packageAction";
import SinglePackageView from "./SinglePackageView";

const mapStoreToProps = (state) => ({
  adpackage: state.adpackage.adpackage,
});

const mapDispatchToProps = {
  getPackage,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SinglePackageView);
