/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    getActiveRequests,
    cancelRequest
} from "../../redux/actions/requestAction";
import { connect } from "react-redux";
import HomeCartView from "./HomeCartView";

const mapStoreToProps = state => ({
    requests: state.request.activeReqs,
});
const mapDispatchToProps = dispatch => ({
    getActiveRequests: () => dispatch(getActiveRequests()),
    cancelRequest: (id) => dispatch(cancelRequest(id)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(HomeCartView);
