/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    addClientRequest,
    getAllRequests
} from "../../redux/actions/requestAction";
import { getAllServices } from "../../redux/actions/serviceAction"
import {
    getPaymentToken,
    initiateMpesa,
    queryMpesa,
} from "../../redux/actions/checkoutAction"
import {
    addOrder,
    paymentToDb
} from "../../redux/actions/orderAction";
import { connect } from "react-redux";
import Checkout from "./Checkout";
import { guestRegister, userRegister } from "../../redux/actions/RegisterAction";

const mapStoreToProps = state => ({
    requests: state.request.requests,
    tokens: state.checkout.tokens,
    services: state.service.services,
    response: state.checkout.response,
});
const mapDispatchToProps = dispatch => ({
    guestRegister: data => dispatch(guestRegister(data)),
    addRequest: (requestForCar, requestDetail, affects, mechanicId) => dispatch(addClientRequest(requestForCar, requestDetail, affects, mechanicId)),
    userRegister: (user) => dispatch(userRegister(user)),
    getAllRequests: () => dispatch(getAllRequests()),
    getPaymentToken: () => dispatch(getPaymentToken()),
    addOrder: (order) => dispatch(addOrder(order)),
    getAllServices: () => dispatch(getAllServices()),
    initiateMpesa: (data) => dispatch(initiateMpesa(data)),
    paymentToDb: (data) => dispatch(paymentToDb(data)),
    queryMpesa: (data) => dispatch(queryMpesa(data)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Checkout);
