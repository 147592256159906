/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import SingleServiceHolderForServicePage from "./SingleService";
import Heading from "../Heading";

// import "../../assets/css/button.css";

class MyServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: this.props.services
    };
  }

  render() {
    const { services, departments } = this.props;

    console.log("Cars: " + services)

    return (
      <div className="best_sellers">
        <div className="container">
          <div className="row">
            <Heading title="Services offered by our mechanics" data-aos="fade-up" />
          </div>

          <div className="row" style={{ marginTop: 50, marginBottom: 50 }}>
            {services &&
              services.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-sm-6"
                    key={index}
                    data-aos="zoom-in"
                    style={{
                      "marginBottom": "30px"
                    }}
                  >
                    <SingleServiceHolderForServicePage
                      serviceItem={item}
                      addToBag={this.props.addToBag}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default MyServices;
