/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import jumpTo from "../../../modules/Navigation";
import LoginRegister from "../../../components/LoginRegisterModal";
import { addCar } from "../../../redux/actions/carAction";
import LoadingButton from "../../../components/LoadingButton";
import Loader from "../../../components/Loader/Loader";
import {
    getAllCarMakes
} from "../../../redux/actions/carMakesAction";
import {
    getAllCarModels
} from "../../../redux/actions/carModelsAction";

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import Validator from "../../../utils/Validator";
import { LICENSE_PLATE_RULE, DEFAULT_RULE } from "../../../utils/Validator/rule";
import ValidationModal from "../../../components/ValidationModal/ValidationModal";

let loggedInUser = JSON.parse(localStorage.getItem("auth"));

const AddCar = props => {
    const [errorModal, setErrorModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [login, setLogin] = useState(true);
    const [carRegNumber, setCarRegNumber] = useState();
    const [carRegErr, setCarRegErr] = useState(false);
    const [carOwnedByUser, setCarOwnedByUser] = useState(loggedInUser.id);
    const [carOwnedErr, setCarOwnedErr] = useState(false);
    const [carModel, setCarModel] = useState();
    const [carModelErr, setCarModelErr] = useState(false);
    const [carMake, setCarMake] = useState();
    const [carMakeErr, setCarMakeErr] = useState(false);
    const [carsAdditionalInfo, setCarsAdditionalInfo] = useState();
    const [coordinates, setCoordinates] = useState()
    const [carLocationName, setCarLocationName] = useState();
    const [carLocationErr, setCarLocationErr] = useState(false);
    const [carLocationDescription, setCarLocationDescription] = useState();
    const [mounted, setMounted] = useState(false);
    const {
        // ready,
        value: locationName,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
        },
        debounce: 300,
    })
    const dispatch = useDispatch();
    useEffect(() => {
        if (!mounted) {
            dispatch(getAllCarMakes());
            dispatch(getAllCarModels());
            setMounted(true);
        }
    }, [dispatch, mounted])

    const showHideModal = () => {
        setModalShow(false);
    };

    const loginClicked = () => {
        setModalShow(true);
        setLogin(true);
    };
    const registerClicked = () => {
        setModalShow(true);
        setLogin(false);
    };
    const onLocationSelect = ({ description, structured_formatting: { main_text } }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();
        setCarLocationName(description);
        setCarLocationDescription(main_text);

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log("📍 Coordinates: ", { lat, lng });
                setCoordinates([lat, lng].join(","))
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const carMakes = useSelector(state => state.carmake.carmakes);
    const carModels = useSelector(state => state.carmodel.carmodels);

    const submitHandler = async () => {
        if (carRegNumber !== undefined && carOwnedByUser !== undefined && carModel !== undefined && carMake !== undefined) {
            setActionLoading(true);
            let data = {
                carRegNumber,
                carOwnedByUser,
                carModel,
                carMake,
                carsAdditionalInfo,
                carLocationName,
                carLocationDescription,
                coordinates,
            }
            await dispatch(addCar(data))
            setActionLoading(false);
            jumpTo("/addrequest");
        } else {
            setErrorModal(true);
        }
    };
    const focus = (e) => {
        switch (e.target.name) {
            case "carRegNumber":
                setCarRegErr(false);
                break;
            case "carOwnedByUser":
                setCarOwnedErr(false);
                break;
            case "carModel":
                setCarModelErr(false);
                break;
            case "carMake":
                setCarMakeErr(false);
                break;
            case "carLocatedAt":
                setCarLocationErr(false);
                break;
            default:
                break;
        }
    }
    const blur = (e) => {
        switch (e.target.name) {
            case "carRegNumber":
                if (!Validator(e.target.value, LICENSE_PLATE_RULE)) {
                    setCarRegErr(true);
                }
                break;
            case "carOwnedByUser":
                if (!Validator(e.target.value, DEFAULT_RULE)) {
                    setCarOwnedErr(true);
                }
                break;
            case "carModel":
                if (!Validator(e.target.value, DEFAULT_RULE)) {
                    setCarModelErr(true);
                }
                break;
            case "carMake":
                if (!Validator(e.target.value, DEFAULT_RULE)) {
                    setCarMakeErr(true);
                }
                break;
            case "carLocatedAt":
                if (!Validator(e.target.value, DEFAULT_RULE)) {
                    setCarLocationErr(true);
                }
                break;
            default:
                break;
        }
    }
    if (!loggedInUser) {
        return <Redirect to='/' />;
    }

    return (
        <div style={!carMakes && !carModels ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
            {carMakes && carModels ?
                <>
                    <div className="container mt-5">
                        <div className="row mt-5">
                            <div className="col-md-6 mt-5 mb-5 mb-md-0" style={{
                                "margin-left": "25%",
                            }}>
                                <form>
                                    <h2 className="h3 mb-3 text-black">Add Car</h2>
                                    <br />
                                    <div className="p-3 p-lg-5 border">
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="carRegNumber" className="text-black">Car Reg Number <span
                                                    className="text-danger">*</span></label>
                                                <input type="text" className="form-control" id="carRegNumber" name="carRegNumber" value={carRegNumber} onChange={(e) => setCarRegNumber(e.target.value)}
                                                    placeholder="e.g. KAA123A" style={carRegErr ? { "border": "2px solid red" } : null} onBlur={e => blur(e)} onFocus={e => focus(e)} />
                                                {carRegErr && <span style={{ "fontSize": "15px", "color": "red" }}>Wrong format, use: KAA123B</span>}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="carLocatedAt" className="text-black">Car Located At <span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="carLocatedAt" name="carLocatedAt" placeholder="Type in location and choose the correct one." value={locationName} onChange={(e) => setValue(e.target.value)} style={carLocationErr ? { "border": "2px solid red" } : null} onBlur={e => blur(e)} onFocus={e => focus(e)} />
                                            {carLocationErr && <span style={{ "fontSize": "15px", "color": "red" }}>This field is required</span>}
                                            {status === "OK" && <ul className="list-group">
                                                {
                                                    data.map((suggestion) => {
                                                        const {
                                                            place_id,
                                                            structured_formatting: { main_text, secondary_text },
                                                        } = suggestion;

                                                        return (
                                                            <li className="list-group-item" key={place_id} onClick={onLocationSelect(suggestion)}>
                                                                <strong>{main_text}</strong><small>{` ${secondary_text}`}</small>
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="carMake" className="text-black">Car Make <span
                                                className="text-danger">*</span></label>
                                            <select id="carMake" name="carMake" className="form-control" onChange={(e) => setCarMake(e.target.value)} required>
                                                <option selected="true" disabled="disabled">Select Make e.g. Toyota</option>
                                                {
                                                    carMakes?.map(carmake => <option value={carmake.id}>{carmake.carMakeName}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="carModel" className="text-black">Car Model <span
                                                className="text-danger">*</span></label>
                                            {carMake ? <CarModelVals
                                                carModels={carModels}
                                                carMakes={carMakes}
                                                selectedMake={carMake}
                                                changeHandler={setCarModel}
                                            /> : <select id="carModel" name="carModel" className="form-control" required>
                                                <option selected="true" disabled="disabled">Select Make e.g. Probox</option>
                                                <option disabled>Select car make</option>
                                            </select>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="carsAdditionalInfo" className="text-black">Cars Additional Info</label>
                                            <textarea name="carsAdditionalInfo" id="carsAdditionalInfo" cols="30" rows="5"
                                                className="form-control" placeholder="Write additional here..." value={carsAdditionalInfo} onChange={(e) => setCarsAdditionalInfo(e.target.value)}></textarea>
                                        </div>

                                        <div className="form-group">
                                            <LoadingButton
                                                type="button"
                                                className="btn btn-success btn-lg btn-block"
                                                loading={actionLoading}
                                                onClick={() => submitHandler()}
                                            >
                                                Add Car
                                            </LoadingButton>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>
                    <ValidationModal show={errorModal} onHide={setErrorModal} />
                    <LoginRegister
                        show={modalShow}
                        login={login}
                        registerClicked={() => registerClicked()}
                        loginClicked={() => loginClicked()}
                        onHide={() => showHideModal()}
                    />
                </> :
                <Loader />}
        </div>
    );
}

const CarModelVals = ({ carModels, carMakes, selectedMake, changeHandler }) => {
    let make = carMakes.find(carMake => carMake._id === selectedMake);
    make = make.carMakeName.toUpperCase();
    let models = carModels.filter(model => model.carModelDescription === make);
    if (models.length > 0) return (
        <select id="carModel" name="carModel" className="form-control" onChange={(e) => changeHandler(e.target.value)} required>
            <option selected="true" disabled="disabled">Select {make} model</option>
            {models.map(model => <option value={model.id}>{model.carModelName}</option>)}
        </select>

    );
    return (
        <select id="carModel" name="carModel" className="form-control" required>
            <option selected="true" disabled="disabled">Select Make e.g. Probox</option>
            <option disabled>No models available</option>
        </select>
    );
}


export default AddCar;
