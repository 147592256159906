/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import SingleCar from "./SingleCar";
import { getCar } from "../../redux/actions/carAction";
import { getVariantsByProductId } from "../../redux/actions/variantsAction";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  car: state.car.car,
  variants: state.variant.variants,
});
const mapDispatchToProps = {
  getCar,
  getVariantsByProductId,
  postCart,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleCar);
