/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
    getAllRequests,
    applyFilters
} from "../../redux/actions/requestAction";
import { getAllCars } from "../../redux/actions/carAction";
import { getAllServices } from "../../redux/actions/serviceAction";
import { getAllMechanics } from "../../redux/actions/mechanicAction";
import { connect } from "react-redux";
import Express from "./Express";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
    services: state.service.services,
    loading: state.service.loading,
});
const mapDispatchToProps = dispatch => ({
    getAllServices: () => dispatch(getAllServices()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Express);
