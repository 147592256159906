/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import CarHolderOnHomePage from "./SingleCar";
import Heading from "../Heading";

// import "../../assets/css/button.css";

class MyCars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: this.props.cars
    };
  }

  render() {
    const { cars, departments } = this.props;

    console.log("Cars: "+cars)

    return (
      <div className="best_sellers">
        <div className="container">
          <div className="row">
            <Heading title="My Cars" data-aos="fade-up" />
          </div>

          <div className="row" style={{ marginTop: 50 }}>
            {cars &&
              cars.slice(0, 8).map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-sm-6"
                    key={index}
                    data-aos="zoom-in"
                  >
                    <CarHolderOnHomePage
                      productItem={item}
                      addToBag={this.props.addToBag}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default MyCars;
