import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import jumpTo from "../../modules/Navigation";
import { deleteAdvert } from "../../redux/actions/advertisementAction";
import DeleteConfirmationModal from "../ValidationModal/DeleteConfirmationModal";

function SingleAdvertHolder(props) {
    const {advertItem}=props;
    const [modalShow, setModalShow] = useState(true);
    const [advertIdToDelete, setadvertIdToDelete] = useState(null);
    const dispatch = useDispatch();
    const OpenDeleteConfirmModal = (id) => {
        setadvertIdToDelete(id);
      };
    const CloseDeleteConfirmModal = () => {
        setadvertIdToDelete(null);
      };
      const Delete = async (id) => {
        CloseDeleteConfirmModal();
        await dispatch(deleteAdvert(id));
        window.location.reload();
        
      };
      const expiryDate = new Date(advertItem.advertExpiry);
  return (
          <div key={advertItem.id}>
              <div className="card">
                <div className="advert-card card-body">
                    <div>
                        {advertItem.advertProduct.productName}
                    </div>
                    <div>
                        <span className="text-danger">Expiry Date: </span>{expiryDate.toDateString()}
                    </div>
                    <div>
                    <span className="text-danger">Status: </span>{advertItem.advertStatus}
                    </div>
                    <div>
                        <button className="btn btn-outline-primary m-2"  onClick={() =>jumpTo(`/singleadvert/${advertItem._id}`)}>View Promotion Details</button>
                        <button className="btn btn-outline-danger" onClick={() => OpenDeleteConfirmModal(advertItem.id)}>Delete</button>
                    </div>
                </div>
              </div>
                    {advertIdToDelete && (
                      <DeleteConfirmationModal
                        show={modalShow}
                        onHide={setModalShow}
                        message={"Are you sure you want to delete this advertisement?"}
                        onConfirm={() => Delete(advertIdToDelete)}
                        onClose={() => CloseDeleteConfirmModal()}
                      />
                    )}
        </div>
  )
}

export default SingleAdvertHolder