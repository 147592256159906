/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

export const addCar = (data) => async dispatch => {
  dispatch({
    type: ADD_CARS_BEGIN
  });

  const tenantId = AuthCurrentTenant.get();
  const token_stored = AuthToken.get();

  let locationData = {
    locationName: data.carLocationName,
    locationDescription: data.carLocationDescription,
    coordinates: data.coordinates,
  }

  const { data: { _id } } = await axios.post(
    `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/location`, {
    data: locationData
  },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
  );

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/car`;


  if (_id) {
    return await axios.post(
      webApiUrl,
      {
        "data": {
          "carRegNumber": data.carRegNumber,
          "carOwnedByUser": data.carOwnedByUser,
          "carLocatedAt": _id,
          "carModel": data.carModel,
          "carMake": data.carMake,
          "carsAdditionalInfo": data.carsAdditionalInfo
        }
      },
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
    ).then(res => {
      console.log("AddCar " + JSON.stringify(res));
      dispatch({
        type: ADD_CAR_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: ADD_CAR_FAIL,
        payload: { error }
      });
    })
  }
};

export const getAllCars = () => async dispatch => {
  dispatch({
    type: GET_ALL_CARS_BEGIN
  });

  const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  //  console.log("CarTenantId "+tenantId);

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/61d1ce08b967711d2952da3f/car`;

  //  console.log("CarUrl "+webApiUrl);

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("Magari " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_CARS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_CARS_FAIL,
        payload: { error }
      });
    })
};

export const getCar = id => dispatch => {
  dispatch({
    type: GET_CAR_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/car/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Gari " + JSON.stringify(res));
    dispatch({
      type: GET_CAR_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_CAR_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const getCarsByCategory = c => dispatch => {
  dispatch({
    type: GET_CARS_BY_CATEGORY_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?category=${c}`
  })
    .then(res => {
      dispatch({
        type: GET_CARS_BY_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_CARS_BY_CATEGORY_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const search = text => dispatch => {
  dispatch({
    type: SEARCH_BEGIN
  });
  return API({
    method: "GET",
    url: `/search?query=${text}`
  })
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: SEARCH_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const applyFilters = filter_string => dispatch => {
  dispatch({
    type: APPLY_FILTERS_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?${filter_string}`
  })
    .then(res => {
      dispatch({
        type: APPLY_FILTERS_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: APPLY_FILTERS_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const updateCar = (id, data) => async dispatch => {
  console.log("Update Car " + JSON.stringify(data));
  dispatch({
    type: UPDATE_CAR_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let locationData = {
    locationName: data.carLocationName,
    locationDescription: data.carLocationDescription,
    coordinates: data.coordinates,
  }

  const { data: { _id } } = await axios.post(
    `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/location`, {
    data: locationData
  },
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      },
    },
  );

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/car/${id}`;

  if (_id) {
    return await axios.put(
      webApiUrl,
      {
        "data": {
          "carRegNumber": data.carRegNumber,
          "carOwnedByUser": data.carOwnedByUser,
          "carLocatedAt": _id,
          "carModel": data.carModel,
          "carMake": data.carMake,
          "carsAdditionalInfo": data.carsAdditionalInfo
        }
      },
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        },
      },
    ).then(res => {
      console.log("UpdateCar " + JSON.stringify(res));
      dispatch({
        type: UPDATE_CAR_SUCCESS,
        payload: res
      });
    }).catch(error => {
      console.error("Error", error)
      dispatch({
        type: UPDATE_CAR_FAIL,
        payload: { error }
      });
    })
  }
};

export const UPDATE_CAR_BEGIN = "UPDATE_CAR_BEGIN";
export const UPDATE_CAR_SUCCESS = "UPDATE_CAR_SUCCESS";
export const UPDATE_CAR_FAIL = "UPDATE_CAR_FAIL";

export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_CARS_BEGIN = "GET_ALL_CAR_BEGIN";
export const GET_ALL_CARS_SUCCESS = "GET_ALL_CARS_SUCCESS";
export const GET_ALL_CARS_FAIL = "GET_ALL_CARS_FAIL";

export const GET_CAR_BEGIN = "GET_CAR_BEGIN";
export const GET_CAR_SUCCESS = "GET_CAR_SUCCESS";
export const GET_CAR_FAIL = "GET_CAR_FAIL";

export const GET_CARS_BY_CATEGORY_BEGIN = "GET_CARS_BY_CATEGORY_BEGIN";
export const GET_CARS_BY_CATEGORY_SUCCESS = "GET_CARS_BY_CATEGORY_SUCCESS";
export const GET_CARS_BY_CATEGORY_FAIL = "GET_CARS_BY_CATEGORY_FAIL";

export const ADD_CARS_BEGIN = "ADD_CARS_BEGIN";
export const ADD_CAR_SUCCESS = "ADD_CAR_SUCCESS";
export const ADD_CAR_FAIL = "ADD_CAR_FAIL";
