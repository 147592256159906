/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import {
  getAllShopProducts,
  applyFilters,
  setUserLocation,
  sortProductsByProximity,
  getShopProductsByCategory,
  sortProductsByPrice,
} from "../../redux/actions/shopProductAction";
import { connect } from "react-redux";
import Category from "./Category";
import { postCart } from "../../redux/actions/cartAction";
import { getAllCategories } from "../../redux/actions/productCategoryAction";

const mapStoreToProps = state => ({
  shopproducts: state.shopproduct.sortedshopproducts,
  products: state.shopproduct.shopproducts,
  categories: state.category.categories,
  categoryLoading: state.category.loading,
  loading: state.shopproduct.loading
});
const mapDispatchToProps = dispatch => ({
  getAllShopProducts: () => dispatch(getAllShopProducts()),
  getShopProductsByCategory: (categoryId) => dispatch(getShopProductsByCategory(categoryId)),
  getAllCategories: () => dispatch(getAllCategories()),
  setUserLocation: (userLocation) =>dispatch(setUserLocation(userLocation)),
  sortProductsByProximity: () => dispatch(sortProductsByProximity()),
  sortProductsByPrice: () => dispatch(sortProductsByPrice()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: shopproductId => dispatch(postCart(shopproductId))
});

export default connect(mapStoreToProps, mapDispatchToProps)(Category);
