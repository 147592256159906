/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { Image } from "react-bootstrap";
import LoginRegister from "../../components/LoginRegisterModal";
import Auth from "../../modules/Auth";
import carDefaultProfile from "../../assets/images/mechwangu_default_car.svg";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import carMob from "../../assets/images/car_mob.png";

class SingleCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "",
      size: "",
      pic: "",
      selectedSize: "",
      id: "",
      quantity: 1,
      modalShow: false,
      login: true,
      matches: window.matchMedia("(min-width: 768px)").matches
    };
  }
  componentDidMount() {
    console.log("CarId " + JSON.stringify(this.props.location.pathname.split("/").slice(-1)[0]));
    this.props.getCar(this.props.location.pathname.split("/").slice(-1)[0]);
    this.props.getVariantsByProductId(
      this.props.location.pathname.split("/").slice(-1)[0]
    );
    console.log("Car " + JSON.stringify(this.props.car));
  }

  showHideModal = () => {
    this.setState({ modalShow: false });
  };

  loginClicked = () => {
    this.setState({ modalShow: true, login: true });
  };
  registerClicked = () => {
    this.setState({ modalShow: true, login: false });
  };

  handleThumbnailClick = (item) => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: "",
      id: item._id,
      cartItem: null,
    });
  };

  onAddClicked = () => {
    this.setState({ quantity: this.state.quantity + 1 });
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      true,
      false
    );
  };
  onRemoveClicked = () => {
    this.props.postCart(
      this.state.id || this.props.location.pathname.split("/").slice(-1)[0],
      false,
      true
    );

    if (this.state.quantity > 1) {
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      this.props
        .postCart(
          this.state.id || this.props.location.pathname.split("/").slice(-1)[0]
        )
        .then((res) => {
          console.log(res);
        });
    } else {
      this.setState({ modalShow: true });
    }
  };

  productInCart = () => {
    let available = false;
    // let items = this.props.cart.items;
    // if (items !== undefined && items !== null) {
    //   let itemCheck = Object.keys(items).map(
    //     id => items[id].item.title === this.props.product.title
    //   );

    //   if (itemCheck !== undefined && itemCheck !== null) {
    //     this.setState({ cartItem: itemCheck });
    //     available = true;
    //   } else {
    //     available = false;
    //   }
    // }

    return available;
  };

  render() {
    console.log("Locationzz" + JSON.stringify(this.props.car));
    return (
      <div style={!this.props.car ? { "marginTop": "25%" } : { "margin": "0 Auto" }}>
        <div className="container single_product_container">
          {this.props.car ? (
            <div>
              <div className="row">
                <div className="col">
                  <div className="breadcrumbs d-flex flex-row align-items-center">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                          Cars
                        </a>
                      </li>
                      <li className="active">
                        <a href="#">
                          <i className="fa fa-angle-right" aria-hidden="true"></i>
                          {this.props.car.carRegNumber}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-7">
                  <div className="single_product_pics">
                    <div className="row">
                      <div className="col-lg-9 image_col order-lg-2 order-1">
                        {!this.state.matches ?
                          <Image src={carMob}></Image>
                          : <div className="single_product_image">
                            <div
                              className="single_product_image_background"
                              style={{
                                backgroundImage: `url(${carDefaultProfile
                                  })`,
                              }}
                            />
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="product_details">
                    <div className="product_details_title">
                      <h2>{this.props.car.carRegNumber}</h2>
                      <span>
                        <i className="fas fa-car"></i> {this.props.car.carMake.carMakeName}, {this.props.car.carModel.carModelName}
                      </span>
                      <p>{this.props.car.carsAdditionalInfo}</p>
                    </div>
                    <Link to={{
                      pathname: `/edit-car/${this.props.car._id}`,
                      state: {
                        car: this.props.car,
                      }
                    }}>
                      <div className="red_button product-add_to_cart_button align-items-center justify-content-center">
                        <span>Edit Car  </span>
                        <span>
                          <i className="fas fa-pen"></i>
                        </span>
                      </div>
                    </Link>
                    {/* <div className="original_price">
                    {" "}
                    KSh. {(parseFloat(this.props.car.price) + 30).toFixed(2)}
                  </div> */}
                    {/* <div className="product_price">
                    Located at {this.props.car.areaOfOperation.locationName}
                  </div> */}

                    <div className="quantity d-flex flex-column flex-sm-row align-items-sm-center">
                      {/* <span>Quantity:</span> */}

                      {/* <div className="quantity_selector">
                      <span
                        className={
                          this.state.quantity > 1 ? "minus" : "minus disabled"
                        }
                        onClick={() => this.onRemoveClicked()}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </span>
                      <span id="quantity_value">{this.state.quantity}</span>
                      <span
                        className="plus"
                        onClick={() => this.onAddClicked()}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </div> */}

                      {/* <div
                      className="red_button product-add_to_cart_button"
                      onClick={this.addToBag}
                    >
                      <a href="#">add to cart</a>
                    </div> */}

                      {/* <div className="red_cart_button product_add_to_cart_icon">
                      <a href="#">
                        <i className="fas fa-cart-arrow-down"></i>
                      </a>
                    </div> */}

                      {/* <div className="product_favorite d-flex flex-column align-items-center justify-content-center">
                      <i className="far fa-heart"></i>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) :
            <Loader />
          }
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    );
  }
}

export default SingleCar;
