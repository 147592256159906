/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";
import mechanicDefaultService from "../../assets/images/mechwangu_default_services.svg";
import { Link } from "react-router-dom"

function ServiceHolderOnServicePage(props) {
  const { serviceItem } = props;
  return (
    <Link to={{
      pathname: `/single-service/${serviceItem._id}`,
      state: { service: serviceItem._id, serviceName: serviceItem.servicesName }
    }}>
      <div className="product-item men" style={{ height: "100%" , }}>
        <div
          className="product discount product_filter"
          // onClick={() =>
          //   jumpTo(`/single-service/${serviceItem._id}`)
          // }
          style={{ height: "100%" , flexDirection: "column" }}
        >
          <div className="product_image">
            <img src={serviceItem.imagePath || mechanicDefaultService} alt="" className="img-fluid" />
          </div>
          <div className="favorite favorite_left">
            <i className="far fa-heart"></i>
          </div>
          {/* <div className="product_bubble product_bubble_right product_bubble_red d-flex flex-column align-items-center">
            <span>-$20</span>
          </div> */}
          <div className="product_info">
            <h6 className="product_name">
              <div>{serviceItem.servicesName}</div>
            </h6>
            <p>Convinience Fee <b>{serviceItem.servicePrice}</b>/=</p>
            <p>Contact mechanic for actual cost</p>
            {/* <div className="product_price">
            KSh. {serviceItem.price}
            <span> KSh. {(parseFloat(serviceItem.price) + 30).toFixed(2)}</span>
          </div> */}
          </div>
        </div>
        {/* <div
        className="red_button add_to_cart_button"
        onClick={() => props.addToBag(serviceItem._id)}
      >
        <div style={{ color: "#ffffff" }}>View car</div>
      </div> */}
      </div>
    </Link>
  );
}

export default ServiceHolderOnServicePage;
