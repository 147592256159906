/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React from "react";

function Benefit(params) {
  return (
    <div className="benefit" data-aos="fade-up">
      <div className="container">
        <div className="row benefit_row">
          <div className="col-lg-4 benefit_col">
            <div className="benefit_item d-flex flex-row align-items-center">
              <div className="benefit_icon">
                <i className="fa fa-users" aria-hidden="true"></i>
              </div>
              <div className="benefit_content">
                <h6>Mechanics</h6>
                <p>We have a larger pool of service providers</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 benefit_col">
            <div className="benefit_item d-flex flex-row align-items-center">
              <div className="benefit_icon">
                <i className="far fa-money-bill-alt"></i>
              </div>
              <div className="benefit_content">
                <h6>Affordable</h6>
                <p>Our pricing is tailored to your convience</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 benefit_col">
            <div className="benefit_item d-flex flex-row align-items-center">
              <div className="benefit_icon">
                <i className="fa fa-undo" aria-hidden="true"></i>
              </div>
              <div className="benefit_content">
                <h6>45 days return</h6>
                <p>Making it Look Like Readable</p>
              </div>
            </div>
          </div> */}
          <div className="col-lg-4 benefit_col">
            <div className="benefit_item d-flex flex-row align-items-center">
              <div className="benefit_icon">
                <i className="far fa-clock"></i>
              </div>
              <div className="benefit_content">
                <h6>24hr Availability</h6>
                <p>You can access our services at any time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefit;
