import {
    getAllPackages,
  } from "../../redux/actions/packageAction";
  import { connect } from "react-redux";
  import Packages from "./Packages";

  
  const mapStoreToProps = state => ({
    adpackages: state.adpackage.adpackages,
  });
  const mapDispatchToProps = dispatch => ({
    getAllPackages: () => dispatch(getAllPackages()),
  });
  
  export default connect(mapStoreToProps, mapDispatchToProps)(Packages);
  