/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import { connect } from "react-redux";
import SingleClientRequest from "./ExpressClientRequest";
import { getCar } from "../../redux/actions/carAction";
import { getExpRequest, respondToRequest, getResponse, postRating, getRating, updateRequest } from "../../redux/actions/requestAction";
import { getMechanicsUsingSpecialityServiceId } from "../../redux/actions/mechanicAction";

const mapStoreToProps = (state) => ({
  request: state.request.request,
  response: state.request.response,
  loading: state.request.loading,
  mechanics: state.mechanic.mechanics
});
const mapDispatchToProps = {
  getResponse,
  respondToRequest,
  getExpRequest,
  postRating,
  getRating,
  getMechanicsUsingSpecialityServiceId,
  updateRequest,
  getCar
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleClientRequest);
