
import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';

export const getAllCarModels = () => async dispatch => {
  dispatch({
    type: GET_ALL_CARMODELS_BEGIN
  });

  /*const invitationToken = undefined;*/ //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  //  console.log("CarModelTenantId "+tenantId);

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/car-model`;

  //  console.log("CarModelUrl "+webApiUrl);

  await axios.get(
    webApiUrl,
    {
      headers: {
        "Authorization": `Bearer ${token_stored}`
      }
    }).then(res => {
      console.log("MaCarModels " + JSON.stringify(res));
      dispatch({
        type: GET_ALL_CARMODELS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      console.error("Error", error)
      dispatch({
        type: GET_ALL_CARMODELS_FAIL,
        payload: { error }
      });
    })
};

export const getCarModel = id => dispatch => {
  dispatch({
    type: GET_CARMODEL_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/car-model/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("KiCarModel " + JSON.stringify(res));
    dispatch({
      type: GET_CARMODEL_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_CARMODEL_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const getCarModelsByCategory = c => dispatch => {
  dispatch({
    type: GET_CARMODELS_BY_CATEGORY_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?category=${c}`
  })
    .then(res => {
      dispatch({
        type: GET_CARMODELS_BY_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_CARMODELS_BY_CATEGORY_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const search = text => dispatch => {
  dispatch({
    type: SEARCH_BEGIN
  });
  return API({
    method: "GET",
    url: `/search?query=${text}`
  })
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: SEARCH_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const applyFilters = filter_string => dispatch => {
  dispatch({
    type: APPLY_FILTERS_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?${filter_string}`
  })
    .then(res => {
      dispatch({
        type: APPLY_FILTERS_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: APPLY_FILTERS_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_CARMODELS_BEGIN = "GET_ALL_CARMODEL_BEGIN";
export const GET_ALL_CARMODELS_SUCCESS = "GET_ALL_CARMODELS_SUCCESS";
export const GET_ALL_CARMODELS_FAIL = "GET_ALL_CARMODELS_FAIL";

export const GET_CARMODEL_BEGIN = "GET_CARMODEL_BEGIN";
export const GET_CARMODEL_SUCCESS = "GET_CARMODEL_SUCCESS";
export const GET_CARMODEL_FAIL = "GET_CARMODEL_FAIL";

export const GET_CARMODELS_BY_CATEGORY_BEGIN = "GET_CARMODELS_BY_CATEGORY_BEGIN";
export const GET_CARMODELS_BY_CATEGORY_SUCCESS = "GET_CARMODELS_BY_CATEGORY_SUCCESS";
export const GET_CARMODELS_BY_CATEGORY_FAIL = "GET_CARMODELS_BY_CATEGORY_FAIL";
