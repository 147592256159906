import { 
    GET_ALL_PACKAGES_BEGIN,
    GET_ALL_PACKAGES_SUCCESS,
    GET_ALL_PACKAGES_FAIL,
    GET_PACKAGE_BEGIN,
    GET_PACKAGE_SUCCESS,
    GET_PACKAGE_FAIL, 
    
    } from '../actions/packageAction';
    
    const initialState = {
      adpackages: null,
      adpackage: null,
      loading: false,
      error: null
    };
    
    export default (state = initialState, action) => {
    switch (action.type) {
    case GET_ALL_PACKAGES_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
    case GET_ALL_PACKAGES_SUCCESS:
          return {
            ...state,
            loading: false,
            adpackages: action.payload.data.rows
          };
    case GET_ALL_PACKAGES_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response
          };
    case GET_PACKAGE_BEGIN:
          return {
            ...state,
            loading: true,
            error: null
          };
    case GET_PACKAGE_SUCCESS:
          return {
            ...state,
            loading: false,
            adpackage: action.payload.data
          };
    case GET_PACKAGE_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload.error.response.data
          };
    default:
    return state;
    }
    }
    
    