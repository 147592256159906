import React, { Component, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import EmptyCart from "../../assets/images/emptyCart.png";
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';
import mongoose from 'mongoose';


let loggedInUser = JSON.parse(localStorage.getItem("auth"));
class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {

            merchantCode: '4751314498',
            wallet: '0932844522',
            paymentTimeLimit: '15mins',
            tenant: AuthCurrentTenant.get(),
            postalCodeZip: '',
            address: '',
            totalAmount: 0,
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            phone: '',
            notes: '',
            orderRef: mongoose.Types.ObjectId(),
        };
    }
    componentDidMount() {
        this.props.getAllRequests();
        this.props.getPaymentToken();
        if (!loggedInUser) {
            return <Redirect to='/' />;
        } else console.log("loggedIn: " + loggedInUser)
    }
    setFirstName = (e) => {
        this.setState({
            firstName: e.target.value
        });
    }
    setLastName = (e) => {
        this.setState({
            lastName: e.target.value
        });
    }
    setCompanyName = (e) => {
        this.setState({
            companyName: e.target.value
        });
    }
    setEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }
    setPhone = (e) => {
        this.setState({
            phone: e.target.value
        });
    }
    setNotes = (e) => {
        this.setState({
            notes: e.target.value
        });
    }
    setAddress = (e) => {
        this.setState({
            address: e.target.value
        });
    }
    setPostalCodeZip = (e) => {
        this.setState({
            postalCodeZip: e.target.value
        });
    }
    liftTotalAmount = async (total) => {
        await this.setState({
            totalAmount: total
        });
    }
    liftActiveRequests = async (requests) => {
        await this.setState({
            activeRequests: requests
        });
    }

    GetActiveRequests = ({ requests, liftRequests }) => {

        let activeRequests;
        if (requests) {
            activeRequests = requests.filter(request => request.status === "Active" && request.createdBy === loggedInUser.id);
            useEffect(() => {
                liftRequests(activeRequests);
            }, [liftRequests, requests]);
        }

        return activeRequests.map(request => {
            return (
                <>
                    <tr>
                        <td>{request.affects.servicesName} <strong className="mx-2">x</strong> 1</td>
                        <td>KSh.{request.affects.servicePrice}</td>
                    </tr>
                </>
            )
        })
    }

    GetTotalAmount = ({ requests, liftTotalAmount }) => {
        let totalAmount = 0;
        if (requests) {
            totalAmount = requests.reduce((total, request) => {
                return total + request.affects.servicePrice;
            }, 0);
            useEffect(() => {
                liftTotalAmount(totalAmount);
            }, [liftTotalAmount, totalAmount]);
        }

        return (

            <>
                <tr>
                    <td className="text-black font-weight-bold"><strong>Cart Subtotal</strong>
                    </td>
                    <td className="text-black">KSh.{totalAmount}</td>
                </tr>
                <tr>
                    <td className="text-black font-weight-bold"><strong>Order Total</strong>
                    </td>
                    <td className="text-black font-weight-bold"><strong>KSh.{totalAmount}</strong></td>
                </tr>
            </>
        );
    }
    sendOrdertoDb = async () => {
        const userId = loggedInUser.id
        const reqIds = this.state.activeRequests.map(request => request.id)
        let data = {
            orderBillingFirstName: this.state.firstName,
            orderBillingLastName: this.state.lastName,
            orderClientRequestIds: reqIds,
            orderOfLoggedInUserId: userId,
            orderBillingPhoneNumber: this.state.phone,
            orderBillingEmail: this.state.email,
            orderBillingCompany: this.state.companyName,
            orderPaymentId: this.state.orderRef,
            orderTotal: 1,
            orderBillingAdditionalInfo: {},
            tenant: this.state.tenant,
        }
        await this.props.addOrder(data);
    }
    render() {

        return (
            <>
                <div className="container mt-5">

                    <div className="row mt-5">
                        <div className="col-md-6 mt-5 mb-5 mb-md-0">
                            <h2 className="h3 mb-3 text-black">Billing Details</h2>
                            <div className="p-3 p-lg-5 border">
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label htmlFor="c_fname" className="text-black">First Name <span
                                            className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="c_fname" name="c_fname" onChange={(e) => this.setFirstName(e)} placeholder='John' />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="c_lname" className="text-black">Last Name <span
                                            className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="c_lname" name="c_lname" onChange={(e) => this.setLastName(e)} placeholder='Doe' />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <label htmlFor="c_companyname" className="text-black">Company Name </label>
                                        <input type="text" className="form-control" id="c_companyname" name="c_companyname" placeholder='This is Optional' onChange={(e) => this.setCompanyName(e)} />
                                    </div>
                                </div>
                                <div className="form-group row mb-5">
                                    <div className="col-md-6">
                                        <label htmlFor="c_email_address" className="text-black">Email Address <span
                                            className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="c_email_address"
                                            name="c_email_address" onChange={(e) => this.setEmail(e)} placeholder='johndoe@mail.com' />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="c_phone" className="text-black">Phone <span
                                            className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="c_phone" name="c_phone"
                                            placeholder="0712345678" onChange={(e) => this.setPhone(e)} />
                                    </div>
                                </div>
                                <div className="form-group row mb-5">
                                    <div className="col-md-6">
                                        <label htmlFor="c_address" className="text-black">Address <span
                                            className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="c_address"
                                            name="c_address" onChange={(e) => this.setAddress(e)} placeholder='0000' />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="c_postal" className="text-black">Postal Code <span
                                            className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="c_postal" name="c_postal"
                                            placeholder="12345" onChange={(e) => this.setPostalCodeZip(e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="c_order_notes" className="text-black">Order Notes</label>
                                    <textarea name="c_order_notes" id="c_order_notes" cols="30" rows="5"
                                        className="form-control" placeholder="Write your notes here..." onChange={(e) => this.setNotes(e)}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-5">
                            <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Your Order</h2>
                                <div className="p-3 p-lg-5 border">
                                    <table className="table site-block-order-table mb-5">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.requests ?
                                                <>
                                                    {this.props.requests ? <this.GetActiveRequests requests={this.props.requests} liftRequests={this.liftActiveRequests} /> : <span></span>}
                                                    {this.state.activeRequests ? <this.GetTotalAmount requests={this.state.activeRequests} liftTotalAmount={this.liftTotalAmount} />
                                                        :
                                                        <div className="empty--basket">
                                                            <img src={EmptyCart} className="img-fluid" />
                                                            <h4 style={{ textAlign: "center" }}>Empty cart</h4>
                                                        </div>
                                                    }
                                                </> :
                                                <div style={{ "marginLeft": "50%" }}>
                                                    <Loader />
                                                </div>
                                            }
                                        </tbody>
                                    </table>
                                    <div className='form-group'>
                                        <label>
                                            <input type="radio" value="1" name="paymentMethod"
                                                id="c_create_account" checked /> Online Payment</label>
                                    </div>
                                    <div className="form-group">
                                        {this.props.tokens && this.state.activeRequests ?
                                            <form id="submitcheckout" action="https://v3.jengapgw.io/processPayment" method="POST" onSubmit={this.sendOrdertoDb}>
                                                <input type="hidden" id="token" name="token" value={this.props.tokens.accessToken} />
                                                <input type="hidden" id="merchantCode" name="merchantCode" value={this.state.merchantCode} />
                                                <input type="hidden" id="wallet" name="wallet" value={this.state.wallet} />
                                                <input type="hidden" id="orderAmount" name="orderAmount" value={this.state.totalAmount} />
                                                <input type="hidden" id="orderReference" name="orderReference" value={this.state.orderRef} />
                                                <input type="hidden" id="productType" name="productType" value={"Service"} />
                                                <input type="hidden" id="productDescription" name="productDescription" value={"Mechanic request"} />
                                                <input type="hidden" id=" paymentTimeLimit " name=" paymentTimeLimit " value={this.state.paymentTimeLimit} />
                                                <input type="hidden" id="customerFirstName" name="customerFirstName" value={this.state.firstName} />
                                                <input type="hidden" id="customerLastName" name="customerLastName" value={this.state.lastName} />
                                                <input type="hidden" id="customerPostalCodeZip" name="customerPostalCodeZip" value={this.state.postalCodeZip} />
                                                <input type="hidden" id="customerAddress" name="customerAddress" value={this.state.address} />
                                                <input type="hidden" id="customerEmail" name="customerEmail" value={this.state.email} />
                                                <input type="hidden" id="customerPhone" name="customerPhone" value={this.state.phone} />
                                                <input type="hidden" id="extraData" name="extraData" value={loggedInUser.id} />
                                                <input type="hidden" id="callbackUrl" name="callbackUrl" value={"https://mechwangu.co.ke/thank-you"} />
                                                <button type='submit' id="submitcheckout" className="btn btn-success btn-lg btn-block">Place Order</button>
                                            </form> : <span></span>
                                        }
                                    </div>
                                </div>
                                {/* <div>
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <h2 className="h3 mb-3 text-black">Coupon Code</h2>
                                        <div className="p-3 p-lg-5 border">
                                            <label htmlFor="c_code" className="text-black mb-3">Enter your coupon code if you
                                                have one</label>
                                            <div className="input-group w-75">
                                                <input type="text" className="form-control" id="c_code"
                                                    placeholder="Coupon Code" aria-label="Coupon Code"
                                                    aria-describedby="button-addon2" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary btn-sm px-4" type="button"
                                                        id="button-addon2">Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>

                    </div>

                </div>
            </>
        );
    }
}

export default Checkout;
