/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

 import {
  GET_ALL_LOCATIONS_BEGIN,
  GET_ALL_LOCATIONS_SUCCESS,
  GET_ALL_LOCATIONS_FAIL,
  GET_LOCATION_BEGIN,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
  GET_LOCATIONS_BY_CATEGORY_BEGIN,
  GET_LOCATIONS_BY_CATEGORY_SUCCESS,
  GET_LOCATIONS_BY_CATEGORY_FAIL,
  SEARCH_BEGIN,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  APPLY_FILTERS_BEGIN,
  APPLY_FILTERS_SUCCESS,
  APPLY_FILTERS_FAIL
} from "../actions/locationAction";

const initialState = {
  locations: null,
  location: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LOCATIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload.data.rows
      };
    case GET_ALL_LOCATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_LOCATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        location: action.payload.data
      };
    case GET_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case GET_LOCATIONS_BY_CATEGORY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_LOCATIONS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload.data.locations
      };
    case GET_LOCATIONS_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload.data.locations
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    case APPLY_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case APPLY_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload.data.locations
      };
    case APPLY_FILTERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response.data
      };
    default:
      return state;
  }
};
