/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { AuthUser } from '../../axioss/modules/Auth/authUser';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import axios from 'axios';
import jumpTo from "../../modules/Navigation";
import { phoneVerification } from "../../redux/actions/verifyPhoneAction";
import Heading from "../Heading";
import LoadingButton from "../LoadingButton";

// import "../../assets/css/button.css";
let useLoggedIn = JSON.parse(localStorage.getItem("auth"));
const Status = props => {
  const [loading, setLoading] = React.useState(false);
  if (useLoggedIn.tenants[0].status === "active") {
    return <Redirect to="/" />
  }

  let currentUser = null;

  return (
    <>
      <div className="best_sellers">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <span className="icon-check_circle display-3 text-success">
                <i className="fas fa-exclamation"></i>
              </span>
              <Heading title="Awaiting approval" data-aos="fade-up" />
              <br />
              <p className="lead mb-5"><b>Kindly wait for Admin Verification. <br />You will be notified by SMS. Otherwise contact us on 0705677598.</b></p>
              <LoadingButton
                type="button"
                className="btn-success"
                loading={loading}
                onClick={async () => {
                  setLoading(true);

                  const token_stored = await AuthToken.get();

                  let webApiUrl = 'https://mapi.mechwangu.co.ke/api/auth/me';

                  currentUser = await axios.get(
                    webApiUrl,
                    {
                      headers: {
                        "Authorization": `Bearer ${token_stored}`
                      }
                    }).then((response) => {
                      AuthUser.set(response.data, true);
                      return response;
                    },
                      (error) => {
                        return error;
                      }
                    )

                  window.location.reload();
                }}
              >
                Got a confirmation message? Retry
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Status;
