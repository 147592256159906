/*
 ** Author: Munyingi Ian
 ** Author URL: https://ellixar.com/
 */

import API from "../../axioss/API";

import axios from 'axios';
import { AuthToken } from '../../axioss/modules/Auth/authToken';
import AuthCurrentTenant from '../../axioss/modules/Auth/authCurrentTenant';


export const getAllServices = () => async dispatch => {
  dispatch({
    type: GET_ALL_SERVICES_BEGIN
  });

  //const invitationToken = undefined; //Todo

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let tenantIdz;

  console.log("TenantId " + tenantId);

  let webApiUrlGetTenant = `https://mapi.mechwangu.co.ke/api/tenant`;
  axios.get(
    webApiUrlGetTenant,
  ).then(res => {
    console.log("TenantID " + res.data.rows[0].id);
    // dispatch({
    //   type: GET_ALL_MECHANICS_SUCCESS,
    //   payload: res
    // });
    tenantIdz = res.data.rows[0].id;

    //  console.log("ServiceTenantId "+tenantId);

    let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantIdz}/services-offered`;

    //  console.log("ServiceUrl "+webApiUrl);

    axios.get(
      webApiUrl,
      {
        headers: {
          "Authorization": `Bearer ${token_stored}`
        }
      }).then(res => {
        console.log("Maservices " + JSON.stringify(res));
        dispatch({
          type: GET_ALL_SERVICES_SUCCESS,
          payload: res
        });
      })
      .catch(error => {
        console.error("Error", error)
        dispatch({
          type: GET_ALL_SERVICES_FAIL,
          payload: { error }
        });
      })
  })
    .catch(error => {
      console.error("Error", error)
      // dispatch({
      //   type: GET_ALL_MECHANICS_FAIL,
      //   payload: { error }
      // });
    })

};

export const getService = id => dispatch => {
  dispatch({
    type: GET_SERVICE_BEGIN
  });

  const token_stored = AuthToken.get();
  const tenantId = AuthCurrentTenant.get();

  let webApiUrl = `https://mapi.mechwangu.co.ke/api/tenant/${tenantId}/services-offered/${id}`;

  return axios.get(webApiUrl, {
    headers: {
      "Authorization": `Bearer ${token_stored}`
    }
  }
  ).then(res => {
    console.log("Gari " + JSON.stringify(res));
    dispatch({
      type: GET_SERVICE_SUCCESS,
      payload: res
    });
    return res;
  })
    .catch(error => {
      dispatch({
        type: GET_SERVICE_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const getServicesByCategory = c => dispatch => {
  dispatch({
    type: GET_SERVICES_BY_CATEGORY_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?category=${c}`
  })
    .then(res => {
      dispatch({
        type: GET_SERVICES_BY_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: GET_SERVICES_BY_CATEGORY_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const search = text => dispatch => {
  dispatch({
    type: SEARCH_BEGIN
  });
  return API({
    method: "GET",
    url: `/search?query=${text}`
  })
    .then(res => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: SEARCH_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const applyFilters = filter_string => dispatch => {
  dispatch({
    type: APPLY_FILTERS_BEGIN
  });
  return API({
    method: "GET",
    url: `/products?${filter_string}`
  })
    .then(res => {
      dispatch({
        type: APPLY_FILTERS_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch(error => {
      dispatch({
        type: APPLY_FILTERS_FAIL,
        payload: { error }
      });
      return error;
    });
};

export const APPLY_FILTERS_BEGIN = "APPLY_FILTERS_BEGIN";
export const APPLY_FILTERS_SUCCESS = "APPLY_FILTERS_SUCCESS";
export const APPLY_FILTERS_FAIL = "APPLY_FILTERS_FAIL";

export const SEARCH_BEGIN = "SEARCH_BEGIN";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const GET_ALL_SERVICES_BEGIN = "GET_ALL_SERVICE_BEGIN";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAIL = "GET_ALL_SERVICES_FAIL";

export const GET_SERVICE_BEGIN = "GET_SERVICE_BEGIN";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAIL = "GET_SERVICE_FAIL";

export const GET_SERVICES_BY_CATEGORY_BEGIN = "GET_SERVICES_BY_CATEGORY_BEGIN";
export const GET_SERVICES_BY_CATEGORY_SUCCESS = "GET_SERVICES_BY_CATEGORY_SUCCESS";
export const GET_SERVICES_BY_CATEGORY_FAIL = "GET_SERVICES_BY_CATEGORY_FAIL";

export const ADD_SERVICES_BEGIN = "ADD_SERVICES_BEGIN";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";