
  import { connect } from "react-redux";
import { getShopOwner, getShopOwnerIdUsingUserId } from "../../redux/actions/ShopOwnerAction";
import { getAllAdverts, startAdvertExpiryChecker, updateAdvertStatus } from "../../redux/actions/advertisementAction";
import AdvertList from "./AdvertList";
import { updateShopProductPriority } from "../../redux/actions/shopProductAction";
  
  const mapStoreToProps = state => ({
    adverts: state.advert.adverts,
    shopowner: state.shopowner.shopowner,
  });
  const mapDispatchToProps = dispatch => ({
    getAllAdverts: (shopOwnerId) => dispatch(getAllAdverts(shopOwnerId)),
    getShopOwnerIdUsingUserId: (userId) => dispatch(getShopOwnerIdUsingUserId(userId)),
    updateAdvertStatus: (id,data) => dispatch(updateAdvertStatus(id,data)),
    updateShopProductPriority: (id,data) => dispatch(updateShopProductPriority(id,data)),
    getShopOwner: (id) => dispatch(getShopOwner(id)),
    startAdvertExpiryChecker: () => dispatch(startAdvertExpiryChecker()),
  });
  
  export default connect(mapStoreToProps, mapDispatchToProps)(AdvertList);